import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, Alert, TextArea, Breadcrumb, BreadcrumbItem } from 'reactstrap';
//Import Breadcrumb

import { getTags, updateTag } from '../../store/tags/actions';

import axios from 'axios';
import { API_URL } from '../../constants/apiurl';
import Loader from '../../components/Common/Loader';
import { Image } from 'react-bootstrap';
import CropEasy from '../../components/Crop/CropEasy';
import SweetAlert from 'react-bootstrap-sweetalert';
import { joinSpans } from '@fullcalendar/react';
class TagDetails extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            tags: {},
            tagtypes: [],
            tagstatus: [],
            name: '',
            vname: '',
            type: '',
            base64: '',
            description: '',
            statuss: '',
            rawDescription: '',
            //editorState: EditorState.createEmpty(),
            dtype: '',
            dstatus: '',
            loading: false,
            id: '',
            editStatus: false,
            file: '',
            imagePreviewUrl: '',

            show: false,
            viewStatus: false,
            thumbnail: '',
            setPageStatus: '',
            successFlag: false,
            showAlert: false,
            alertMessage: '',
            showAlertWarning: false,
            vimage: '',
            setImageFlag: false
        };
    }
    componentDidMount() {
        this.getTagTypes();
        this.getTagStatus();
        const {
            match: { params }
        } = this.props;

        if (params.idd === 'view') {
            this.setState({ viewStatus: true, setPageStatus: 'View' });
        }
        if (params.idd === 'post') {
            this.setState({ setPageStatus: 'Create' });
        }
        if (params.idd === 'edit') {
            this.setState({ setPageStatus: 'Edit' });
        }

        if (params.id !== 'new') {
            this.setState({ editStatus: true });
            this.getTagDetails(params.id);
        } else {
            this.setState({ editStatus: false });
        }
    }

    getTagTypes = () => {
        let obj = this.getAuthDetails();
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + obj.access_token
            }
        };

        axios.get(API_URL + '/tt/tag/types', axiosConfig).then(response => {
            this.setState({
                tagtypes: response.data
            });
        });
    };

    getTagStatus = () => {
        let obj = this.getAuthDetails();
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + obj.access_token
            }
        };

        axios.get(API_URL + '/tt/tag/status ', axiosConfig).then(response => {
            this.setState({
                tagstatus: response.data
            });
        });
    };

    getTagDetails = id => {
        let obj = this.getAuthDetails();

        this.setState({ loading: true, imagePreviewUrl: '' });

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + obj.access_token
            }
        };

        axios
            .get(API_URL + '/tt/tag/' + id, axiosConfig)
            .then(response => {
                this.setState({
                    loading: false,
                    tags: response.data,
                    name: response.data.name,
                    statuss: response.data.status,
                    type: response.data.type,
                    description: response.data.description,
                    id: response.data.id,
                    thumbnail: API_URL + response.data.thumbnail,
                    imagePreviewUrl: API_URL + response.data.thumbnail
                });
            })
            .catch(e => {
                this.setState({ loading: false });
            });
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    submitForm = e => {
        e.preventDefault();

        const {
            match: { params }
        } = this.props;
        if (this.state.name === '' || this.state.name === null) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vname: 'Tag name is required!' });
        }
        if (this.state.statuss === '' || this.state.statuss === null) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ dstatus: 'Select Status!' });
        }
        if (this.state.type === '' || this.state.type === null) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ dtype: 'Select Tag type!' });
        }
        if (this.state.imagePreviewUrl === '' || this.state.imagePreviewUrl === null) {
            // window.scrollTo({ top: 0, behavior: "smooth" });
            this.setState({ vimage: 'Image is required!' });
        }

        if (this.state.name !== '' && this.state.statuss !== '' && this.state.type !== '' && this.state.imagePreviewUrl !== '') {
            let postParams = {
                name: this.state.name,
                type: this.state.type,
                base64: this.state.imagePreviewUrl,
                description: this.state.description,
                status: this.state.statuss,
                id: this.state.id
            };
            this.submitFormData(postParams, params.id);
        }
    };

    submitFormData = (postParams, id) => {
        let obj = this.getAuthDetails();
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + obj.access_token
            }
        };

        if (id === 'new') {
            //this.getTagDetails();
            axios
                .post(API_URL + '/tt/tag', postParams, axiosConfig)
                .then(response => {
                    this.setState({ showAlert: true, alertMessage: 'Created Successfully', successFlag: true });
                    this.setState({ loading: false, id: response.data.id });

                    window.scrollTo({ top: 0, behavior: 'smooth' });

                    this.getTagDetails(response.data.id);
                })
                .catch(err => console.log('error ' + JSON.stringify(err)));
        } else {
            this.props.onUpdateTag(postParams);
            this.setState({ showAlert: true, alertMessage: 'Updated Successfully', successFlag: true, thumbnail: null, imagePreviewUrl: '' });
            this.getTagDetails(this.state.id);

            window.scrollTo({ top: 0, behavior: 'smooth' });

            // this.props.history.push("/web/tags");
        }
    };

    onInputChange = e => {
        // convert image file to base64 string
        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

        if (size > 100) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure image size is less than 100 KB',
                selectedUploadtype: '',
                vselectedUploadtype: ''
            });
            e.target.value = null;
        } else {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64: reader.result, show: true, vimage: '' });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
                //this.setState({imagePreviewUrl: file})
            }
        }
    };

    handleCallback = childData => {
        this.setState({ thumbnail: null, show: false, loading: true });
        setTimeout(() => {
            this.setState({ imagePreviewUrl: childData, loading: false });
        }, '1000');
    };

    render() {
        const { name, description, statuss, type, base64 } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tag Details</title>
                    </MetaTags>
                    {this.state.show ? (
                        <CropEasy ratio={1} photoURL={base64} parentCallback={this.handleCallback} />
                    ) : (
                        <Container fluid={true}>
                            <CardTitle className="mb-1">Tag</CardTitle>
                            <Breadcrumb>
                                <BreadcrumbItem tag="p" href="#">
                                    Tag List
                                </BreadcrumbItem>
                                <BreadcrumbItem tag="p" href="#" active>
                                    {this.state.setPageStatus} Tag
                                </BreadcrumbItem>
                            </Breadcrumb>

                            {this.state.showAlert ? (
                                <SweetAlert
                                    success={this.state.successFlag}
                                    title=""
                                    onConfirm={() => {
                                        this.setState({ showAlert: false });
                                        this.getTagDetails(this.state.id);
                                        if (this.state.setPageStatus !== 'Edit') {
                                            this.props.history.push('/web/tags');
                                        }
                                    }}
                                >
                                    {this.state.alertMessage}
                                </SweetAlert>
                            ) : null}

                            {this.state.showAlertWarning ? (
                                <SweetAlert warning title="" onConfirm={() => this.setState({ showAlertWarning: false })}>
                                    {this.state.alertMessage}
                                </SweetAlert>
                            ) : null}

                            <Row>
                                <Col lg={12}>
                                    {this.state.loading ? (
                                        <Loader color={'green'} loading={this.state.loading} />
                                    ) : (
                                        <Card>
                                            <div style={{ margin: '15px' }} className="position-absolute top-0 end-0">
                                                <button
                                                    onClick={() => {
                                                        this.props.history.push('/web/tags');
                                                    }}
                                                    type="button"
                                                    className="btn btn-secondary"
                                                >
                                                    <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                                    Back
                                                </button>
                                            </div>
                                            <CardBody>
                                                <CardTitle className="mb-4">
                                                    {this.state.editStatus && (
                                                        <span className={statuss === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{statuss}</span>
                                                    )}
                                                </CardTitle>

                                                <Form className="form" onSubmit={e => this.submitForm(e)}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Tag *</Label>
                                                                <Input
                                                                    readOnly={this.state.viewStatus}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Enter Tags"
                                                                    name="name"
                                                                    /*invalid={
                                    this.state.vname !== "" ? true : false
                                  }*/
                                                                    value={name}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            name: e.target.value,
                                                                            vname: ''
                                                                        })
                                                                    }
                                                                />
                                                                {this.state.vname !== '' && <span style={{ color: 'red' }}>{this.state.vname}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Tag Type *</Label>
                                                                <select
                                                                    /* invalid={
                                    this.state.dtype !== "" ? true : false
                                  }*/
                                                                    value={type}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            type: e.target.value,
                                                                            dtype: ''
                                                                        })
                                                                    }
                                                                    className="form-select"
                                                                >
                                                                    <option value="">Select Type</option>
                                                                    {this.state.tagtypes.map((item, idx) => (
                                                                        <option key={idx} value={item}>
                                                                            {item}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {this.state.dtype !== '' && <span style={{ color: 'red' }}>{this.state.dtype}</span>}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={6}>
                                                            <Label htmlFor="formrow-firstname-Input">Tag Description</Label>

                                                            <textarea
                                                                readOnly={this.state.viewStatus}
                                                                type="textarea"
                                                                rows="6"
                                                                className="form-control"
                                                                id="basicpill-expiration-input13"
                                                                placeholder="Enter Tag Description"
                                                                value={description}
                                                                onChange={e => this.setState({ description: e.target.value })}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Status *</Label>
                                                                <select
                                                                    disabled={this.state.viewStatus}
                                                                    value={statuss}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            statuss: e.target.value,
                                                                            dstatus: ''
                                                                        })
                                                                    }
                                                                    className="form-select"
                                                                >
                                                                    <option value="">Select Status</option>
                                                                    {this.state.tagstatus.map((item, idx) => (
                                                                        <option key={idx} value={item}>
                                                                            {item}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {this.state.dstatus !== '' && <span style={{ color: 'red' }}>{this.state.dstatus}</span>}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mt-4">
                                                                <div>
                                                                    <Label htmlFor="formFile" className="form-label">
                                                                        Select Thumbnail Image
                                                                    </Label>
                                                                    <input
                                                                        disabled={this.state.viewStatus}
                                                                        name="base64"
                                                                        className="form-control"
                                                                        id="formFile"
                                                                        type="file"
                                                                        accept=".jpg,.jpeg,.png"
                                                                        onChange={e => this.onInputChange(e)}
                                                                    />
                                                                    {this.state.vimage !== '' && <span style={{ color: 'red' }}>{this.state.vimage}</span>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mt-4">{this.state.imagePreviewUrl !== '' && <img width={200} height={150} src={this.state.imagePreviewUrl} />}</div>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <br />
                                                    <div className="text-center mt-4">
                                                        <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

TagDetails.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,

    onUpdateTag: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    onUpdateTag: tag => dispatch(updateTag(tag))
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TagDetails));
