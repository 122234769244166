import axios from 'axios';
import { resolve } from './resolve.js';
import { API_URL } from '../../constants/apiurl';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});
const axiosHeaderConfigMutipart = token => ({
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export async function callSize() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/size/types', axiosconf).then(res => res.data));
}

export async function callStatus() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/status', axiosconf).then(res => res.data));
}

export async function updateParticleStatus(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    let pdata = {};
    return await resolve(axios.patch(API_URL + '/tt/particle/' + id, pdata, axiosconf).then(res => res.data));
}

export async function callUploadTypes() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/upload/types', axiosconf).then(res => res.data));
}

export async function postParticle(postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/particle', postData, axiosconf).then(res => res.data));
}

export async function updateParticle(postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.put(API_URL + '/tt/particle/' + postData.id, postData, axiosconf).then(res => res.data));
}
export async function callParticleInfomation(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/' + id, axiosconf).then(res => res.data));
}

export async function callParticleImages(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);

    return await resolve(axios.get(API_URL + '/tt/particle/' + id + '/file', axiosconf).then(res => res.data));
}

export async function uploadParticleImage(id, type, formData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfigMutipart(obj.access_token);
    /*if(echeck && fid !== ""){
      return await resolve(axios.put(API_URL + '/tt/particle/'+id+'/file?type='+type, formData, axiosconf).then(res => res.data));
    }else{
      return await resolve(axios.post(API_URL + '/tt/particle/'+id+'/file?type='+type, formData, axiosconf).then(res => res.data));
    }*/
    return await resolve(axios.post(API_URL + '/tt/particle/' + id + '/file?type=' + type, formData, axiosconf).then(res => res.data));
}

export async function deleteParticleImages(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.delete(API_URL + '/tt/particle/' + id + '/file', axiosconf).then(res => res.data));
}

//callParticleArtworks

export async function callParticleArtworks(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/' + id + '/artwork/mapping', axiosconf).then(res => res.data));
}
export async function postPrarticleArtworks(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/particle/' + id + '/artwork/mapping', postData, axiosconf).then(res => res.data));
}

//callParticleContents, postPrarticleContents

export async function callParticleContents(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/' + id + '/content/mapping', axiosconf).then(res => res.data));
}
export async function postPrarticleContents(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/particle/' + id + '/content/mapping', postData, axiosconf).then(res => res.data));
}

export async function callParticleCreators(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/' + id + '/creator/mapping', axiosconf).then(res => res.data));
}
export async function postPrarticleCreators(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/particle/' + id + '/creator/mapping', postData, axiosconf).then(res => res.data));
}

export async function callParticleVenues(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/' + id + '/venue/mapping', axiosconf).then(res => res.data));
}
export async function postPrarticleVenues(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/particle/' + id + '/venue/mapping', postData, axiosconf).then(res => res.data));
}

export async function callParticleExhibitions(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/' + id + '/event/mapping', axiosconf).then(res => res.data));
}
export async function postPrarticleExhibitions(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/particle/' + id + '/event/mapping', postData, axiosconf).then(res => res.data));
}

export async function postExternalLinks(postdata, id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/particle/' + id + '/link', postdata, axiosconf).then(res => res.data));
}

export async function getExternalLinks(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle/' + id + '/link', axiosconf).then(res => res.data));
}
