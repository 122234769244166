import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import Profile from './auth/profile/reducer'
import Dashboard from './dashboard/reducer'
import allusers from './allusers/reducer'
import artworks from './artworks/reducer'
import tags from './tags/reducer'
import venues from './venues/reducer'
import cindividuals from './creator-individual/reducer'
import ccompanies from './creator-company/reducer'
import creators from './creator/reducer'
import themes from './themes/reducer'
import { contents } from './contents/reducer'
import { link } from './link/reducer'
import galleries from './content-galleries/reducer'
import exhibitions from './exhibitions/reducer'
import particles from './particles/reducer'
import Layout from './layout/reducer'
import Login from './auth/login/reducer'
import Account from './auth/register/reducer'

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware, routerMiddleware(history)]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    combineReducers({
        Account,
        allusers,
        artworks,
        ccompanies,
        cindividuals,
        contents,
        creators,
        Dashboard,
        exhibitions,
        galleries,
        Layout,
        link,
        Login,
        particles,
        Profile,
        router: connectRouter(history),
        tags,
        themes,
        venues,
    }),
    composeEnhancers(applyMiddleware(...middleware))
    )

sagaMiddleware.run(rootSaga)

