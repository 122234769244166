import {
    DELETE_GALLERIES_SUCCESS,
    DELETE_GALLERIES_FAIL,
    GET_GALLERIES_SUCCESS,
    GET_GALLERIES_FAIL,
    GET_GALLERIES,
    DELETE_GALLERIES,
    UPDATE_GALLERIES,
    UPDATE_GALLERIES_SUCCESS,
    UPDATE_GALLERIES_FAIL
} from './actionTypes';
//---
export const getGalleries = () => ({
    type: GET_GALLERIES
});
export const getGalleriesSuccess = galleries => ({
    type: GET_GALLERIES_SUCCESS,
    payload: galleries
});

export const getGalleriesFail = error => ({
    type: GET_GALLERIES_FAIL,
    payload: error
});
//-----

export const deleteGalleries = galleries => ({
    type: DELETE_GALLERIES,
    payload: galleries
});
export const deleteGalleriesSuccess = galleries => ({
    type: DELETE_GALLERIES_SUCCESS,
    payload: galleries
});

export const deleteGalleriesFailed = error => ({
    type: DELETE_GALLERIES_FAIL,
    payload: error
});

//---

export const updateGallery = galleries => ({
    type: UPDATE_GALLERIES,
    payload: galleries
});
export const updateGalleriesSuccess = galleries => ({
    type: UPDATE_GALLERIES_SUCCESS,
    payload: galleries
});
