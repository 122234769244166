import axios from 'axios';
import { resolve } from './resolve.js';
import { API_URL } from '../../constants/apiurl';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});
const axiosHeaderConfigMutipart = token => ({
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export async function callArtworkStatus() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/artwork/status', axiosconf).then(res => res.data));
}

export async function callArtworkDetailsById(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/artwork/' + id, axiosconf).then(res => res.data));
}

export async function postArtwork(postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/artwork', postData, axiosconf).then(res => res.data));
}

export async function uploadArtworkImage(id, type, formData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfigMutipart(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/artwork/' + id + '/file?type=' + type, formData, axiosconf).then(res => res.data));
}

export async function deleteArtworkImage(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.delete(API_URL + '/tt/artwork/file/' + id, axiosconf).then(res => res.data));
}

export async function callArtworkVenues(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/artwork/' + id + '/venue/mapping', axiosconf).then(res => res.data));
}
export async function postArtworkVenues(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/artwork/' + id + '/venue/mapping', postData, axiosconf).then(res => res.data));
}

export async function postExternalLinks(postdata, id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/artwork/' + id + '/link', postdata, axiosconf).then(res => res.data));
}

export async function getExternalLinks(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/artwork/' + id + '/link', axiosconf).then(res => res.data));
}

export async function postArtworksOrder(id, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/exhibition/' + id + '/artwork/mapping/order', postdata, axiosconf).then(res => res.data));
}

export async function postArtworkContentOrder(id, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(`${API_URL}/tt/artwork/${id}/content/mapping/order`, postdata, axiosconf).then(res => res.data));
}

export async function postCreatorArtworksOrder(id, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/creator/' + id + '/artwork/mapping/order', postdata, axiosconf).then(res => res.data));
}