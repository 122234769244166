/* Tags */
export const GET_CREATORS = 'GET_CREATORS';
export const GET_CREATORS_FAIL = 'GET_CREATORS_FAIL';
export const GET_CREATORS_SUCCESS = 'GET_CREATORS_SUCCESS';
export const DELETE_CREATORS = 'DELETE_CREATORS';
export const DELETE_CREATORS_FAIL = 'DELETE_CREATORS_FAIL';
export const DELETE_CREATORS_SUCCESS = 'DELETE_CREATORS_SUCCESS';
export const UPDATE_CREATORS_SUCCESS = 'UPDATE_CREATORS_SUCCESS';
export const UPDATE_CREATORS = 'UPDATE_CREATORS';

//others------
