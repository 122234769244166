//REGISTER
export const POST_FAKE_REGISTER = '/post-fake-register';

//LOGIN
export const POST_FAKE_LOGIN = '/post-fake-login';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/fake-forget-pwd';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';
export const SOCIAL_LOGIN = '/social-login';

//export const POST_LOGIN = "realms/particle-dev/protocol/openid-connect/token"
export const POST_LOGIN = 'realms/particle-prod/protocol/openid-connect/token';
export const POST_LOGOUT = 'auth/users/logout';
//ARTWORKS
export const GET_ARTWORKS = '/artworks-list';
export const DELETE_ARTWORKS = '/delete/artworks';

//TAGS
export const GET_TAGS = '/tags';
export const DELETE_TAGS = '/delete/tags';

//Venues
export const GET_VENUES = '/venues';
export const DELETE_VENUES = '/delete/venues';

//THEMES
export const GET_THEMES = '/themes';
export const DELETE_THEMES = '/delete/themes';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/post-fake-profile';

//CONTACTS
export const GET_USERS = '/usersssss';
export const GET_USER_PROFILE = '/user';
export const ADD_NEW_USER = '/add/user';
export const UPDATE_USER = '/update/user';
export const DELETE_USER = '/delete/user';
