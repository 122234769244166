import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Row, Table} from 'reactstrap';
//Import Breadcrumb

import axios from 'axios';
import {API_URL} from '../../../constants/apiurl';
import Loader from '../../../components/Common/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';

import {ReactSearchAutocomplete} from 'react-search-autocomplete';

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class RelatedExhibitions extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            relatedExhibitions: [],
            selectedRelatedExhibitions: [],
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,
            selectedType: ''
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getExhibitions();
        }
    }

    getExhibitions = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let eid = this.getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({
                loading: true
            });
            axios
                .get(API_URL + '/tt/exhibition/' + eid.id + '/related/exhibition/mapping', axiosconf)
                .then(response => {
                    this.setState({
                        selectedRelatedExhibitions: response.data,
                        loading: false
                    });
                })
                .catch(e => {
                    console.log('------GET exhibition ERROR-----' + JSON.stringify(e));
                });
        }
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    //------- Info handling ---- Country | State | City
    handleOnSearch = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/exhibition?page=0&size=20&key=' + string + '&sort=title,asc', axiosconf)
            .then(response => {
                console.log('short data.... ' + JSON.stringify(response.data));

                this.setState({
                    relatedExhibitions: response.data.content.map(obj => ({...obj, name: obj.title}))
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    getExhibId = () => {
        if (localStorage.getItem('exhibid')) {
            const obj = JSON.parse(localStorage.getItem('exhibid'));
            return obj;
        }
    };

    postRelatedExhibitions = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let eid = this.getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({
                loading: true
            });

            axios
                .post(API_URL + '/tt/exhibition/' + eid.id + '/related/exhibition/mapping', this.state.selectedRelatedExhibitions, axiosconf)
                .then(response => {
                    this.setState({
                        selectedRelatedExhibitions: []
                    });
                    this.setState({showAlertSuccess: true, successMessage: 'Updated Successfully!'});
                    this.getExhibitions();
                })
                .catch(e => {
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        this.setState({showAlert: true, alertMessage: error.message});
                    }
                });
        }


    };

    render() {
        return (
            <div>
                <div className="text-center mt-0">
                    <h5>Related Exhibitions</h5>
                </div>
                {this.state.showAlert ? (
                    <SweetAlert warning title={''} onConfirm={() => this.setState({showAlert: false})}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showAlertSuccess ? (
                    <SweetAlert success title={''} onConfirm={() => this.setState({showAlertSuccess: false})}>
                        {this.state.successMessage}
                    </SweetAlert>
                ) : null}

                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading}/>
                ) : (
                    <div>
                        <Row style={{marginTop: '20px'}}>
                            <Col md={6}>
                                <ReactSearchAutocomplete
                                    styling={{
                                        backgroundColor: '#fff',
                                        zIndex: 2,
                                        placeholderColor: '#939294',
                                        borderRadius: '4px',
                                        border: '1px solid #ced4da',
                                        height: '35px',
                                        fontSize: 16
                                    }}
                                    items={this.state.relatedExhibitions}
                                    placeholder={'Type exhibition title'}
                                    onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                    onHover={() => {
                                    }}
                                    onSelect={item => {
                                        let found = this.state.selectedRelatedExhibitions.find(a => a.relatedExhibition.id === item.id);

                                        if (!found) {
                                            this.setState({
                                                selectedRelatedExhibitions: [...this.state.selectedRelatedExhibitions, {
                                                    id: '',
                                                    relatedExhibition: item
                                                }]
                                            });
                                        }
                                    }}
                                    onFocus={() => {
                                        this.setState({});
                                    }}
                                    formatResult={formatResult}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: '15px'}}>
                            <Col md={12}>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead className="table-light">
                                        <tr>
                                            <th>#</th>
                                            <th>ID</th>
                                            <th>Title</th>
                                            <th>Event Status</th>
                                            <th>Status</th>
                                            <th>Remove</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.selectedRelatedExhibitions.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <th scope="row">{idx + 1}</th>
                                                    <td>{item.relatedExhibition.id}</td>
                                                    <td>{item.relatedExhibition.title}</td>
                                                    <td>{item.relatedExhibition.eventStatus}</td>
                                                    <td>
                                                        <span
                                                            className={item.relatedExhibition.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>
                                                            {item.relatedExhibition.status}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {' '}
                                                        <i
                                                            className="mdi mdi-delete font-size-18"
                                                            id="deletetooltip"
                                                            onClick={() => {
                                                                const reducedArr = this.state.selectedRelatedExhibitions.filter((item, itemIndex) => {
                                                                    return itemIndex !== idx;
                                                                });

                                                                this.setState({
                                                                    selectedRelatedExhibitions: reducedArr
                                                                });
                                                            }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <div className="text-center mt-4">
                            <button onClick={() => this.postRelatedExhibitions()} disabled={this.props.viewStatus}
                                    type="submit" className="btn btn-primary w-md">
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

RelatedExhibitions.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab3: PropTypes.func,

    //t: PropTypes.any,
    history: PropTypes.any,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RelatedExhibitions));

const formatResult = item => {
    return (
        <>
            <span style={{display: 'block', textAlign: 'left'}}>{item.name}</span>
        </>
    );
};
