import { GET_VENUES_SUCCESS, GET_VENUES_FAIL, DELETE_VENUES_SUCCESS, DELETE_VENUES_FAIL, UPDATE_VENUES_SUCCESS } from './actionTypes';

const INIT_STATE = {
    venues: [],
    error: {}
};

const venues = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_VENUES_SUCCESS:
            return {
                ...state,
                venues: action.payload
            };

        case GET_VENUES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_VENUES_SUCCESS:
            return {
                ...state,
                venues: state.venues.filter(venues => venues.id.toString() !== action.payload.id.toString())
            };

        case DELETE_VENUES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_VENUES_SUCCESS:
            return {
                ...state,
                venues: state.venues.map(venue => (venue.id.toString() === action.payload.id.toString() ? { venue, ...action.payload } : venue))
            };

        //----others ----

        default:
            return state;
    }
};

export default venues;
