import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Col, Row, Button, Table} from 'reactstrap'
import axios from 'axios'
import {API_URL} from '../../../constants/apiurl'
import Loader from '../../../components/Common/Loader'
import SweetAlert from 'react-bootstrap-sweetalert'
import DragAndDropList from "../../../components/DragAndDropList"
import {ReactSearchAutocomplete} from 'react-search-autocomplete'
import {postRelatedCreatorsOrder} from '../../../helpers/Api/creator_apis'
import {getCreatorId} from "../../../constants/utils";

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
})

class Creators extends Component {
    constructor(props) {
        super(props)
        this.node = React.createRef()
        this.state = {
            creators: [],
            selectedCreators: [],
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,
            selectedType: '',

            // Creators Ordering
            showOrdering: false,
            orderingList: [],
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        (previousProps.activeTab !== this.props.activeTab) && this.getCreators()
    }

    getCreators = () => {
        let obj = this.getAuthDetails()
        let axiosconf = axiosHeaderConfig(obj.access_token)
        let cid = this.getSelectedCreatorId()

        if (!cid || !cid.id)
            return

        this.setState({loading: true})

        axios
            .get(`${API_URL}/tt/creator/${cid.id}/creator/mapping`, axiosconf)
            .then(response => {
                this.setState({
                    selectedCreators: response.data.sort((a, b) => a.orderPosition - b.orderPosition),
                    loading: false
                })
            })
            .catch(e => {
                console.log('------GET creator ERROR-----' + JSON.stringify(e))
            })

    }

    getAuthDetails = () =>
        localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser'))


    //------- Info handling ---- Country | State | City
    handleOnSearch = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/creator?page=0&size=20&key=' + string + '&status=Published&sort=name,asc', axiosconf)
            .then(response => {
                console.log('short data.... ' + JSON.stringify(response.data));

                this.setState({
                    creators: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    getSelectedCreatorId = () =>
        getCreatorId()


    postCreators = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let cid = this.getSelectedCreatorId();
        if (cid.id === undefined || cid === undefined) {
            return;
        } else {
            var ccount = 0;
            this.state.selectedCreators.map((item, idx) => {
                if (item.isDefault) {
                    ccount++;
                }
            });

            if (ccount > 2) {
                this.setState({showAlert: true, alertMessage: 'Max 2 selections allowed!'});
                ccount = 0;
            } else {
                let checkRole = false;
                this.state.selectedCreators.map((item, idx) => {
                    if (item.creatorRole === '') {
                        checkRole = true;
                    }
                });
                if (checkRole) {
                    this.setState({showAlert: true, alertMessage: 'Please select the main role.'});
                    checkRole = false;
                } else {
                    this.setState({
                        loading: true
                    });

                    axios
                        .post(API_URL + '/tt/creator/' + cid.id + '/creator/mapping', this.state.selectedCreators, axiosconf)
                        .then(response => {
                            this.setState({
                                selectedCreators: []
                            });
                            ccount = 0;
                            this.setState({showAlertSuccess: true, successMessage: 'Updated Successfully!'});
                            this.getCreators();
                        })
                        .catch(error => {
                            ccount = 0;
                            if (error.response) {
                                // Request made and server responded
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log('Error', error.message);
                                this.setState({showAlert: true, alertMessage: error.message});
                            }
                        })
                }
            }
        }
    }

    setCreatorsOrder = () => {
        let cid = this.getSelectedCreatorId()

        if (!cid || !cid.id) return

        this.setState({loading: true})
        postRelatedCreatorsOrder(cid.id, this.state.orderingList)
            .then(response => {
                this.setState({
                    selectedCreators: response.sort((a, b) => a.orderPosition - b.orderPosition)
                })
            })
            .catch(e => console.log('SET CREATORS ORDER ERROR', e))
            .finally(() => {
                this.setState({loading: false})
                this.handleCloseOrdering()
            })
    }

    handleCloseOrdering = () => {
        this.setState({
            showOrdering: false,
            orderingList: [],
        })
    }


    handleDragEnd = ({destination, source}) => {
        if (!destination) return

        this.setState({orderingList: this.reorder(this.state.orderingList, source.index, destination.index)})
    }

    reorder = (list, startIndex, endIndex) => {
        const result = list
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    handleAction = (ordering) =>
        ordering
            ? this.setCreatorsOrder()
            : this.postCreators()

    render() {
        return (
            <div>
                {this.state.showAlert && <SweetAlert warning title={''}
                                                     onConfirm={() => this.setState({showAlert: false})}> {this.state.alertMessage} </SweetAlert>}
                {this.state.showAlertSuccess && <SweetAlert success title={''}
                                                            onConfirm={() => this.setState({showAlertSuccess: false})}> {this.state.successMessage}</SweetAlert>}
                <div>
                    <div className="text-center mt-0">
                        <h5>{this.state.showOrdering ? "Creators List | Ordering" : "Creators List"}</h5>
                    </div>
                    {this.state.showOrdering &&
                        <div style={{display: 'flex'}}>
                            <button onClick={this.handleCloseOrdering}
                                    style={{marginLeft: 'auto'}}
                                    type="button"
                                    className="btn btn-primary mb-5">
                                <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                Back to Creators
                            </button>
                        </div>}
                    {this.state.loading
                        ? <Loader color={'green'} loading={this.state.loading}/>
                        : <>
                            {this.state.showOrdering
                                ? <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead className="table-light">
                                        <tr>
                                            <th>#</th>
                                            <th>Creator Name</th>
                                            <th>Type</th>
                                            <th>Role</th>
                                        </tr>
                                        </thead>
                                        <DragAndDropList
                                            list={this.state.orderingList}
                                            onDragEnd={this.handleDragEnd}
                                            dragItemStyle={{
                                                background: 'lightgreen',
                                                borderRadius: '16px',
                                            }}
                                            dragListStyle={{
                                                background: 'lightblue',
                                                borderRadius: '16px',
                                            }}
                                        >
                                            {(item) => (
                                                <>
                                                    <td>{item.id}</td>
                                                    <td>{item.relatedCreator.name}</td>
                                                    <td>
                                                        <Button style={{margin: '2px'}} color={'light'}
                                                                className="btn-rounded btn-sm">
                                                            {item.relatedCreator.identifier}
                                                        </Button>
                                                    </td>
                                                    <td>{item.creatorRole}</td>
                                                </>
                                            )}
                                        </DragAndDropList>
                                    </Table>
                                </div>
                                : <div>
                                    <Row style={{marginTop: '20px'}}>
                                        <Col md={6}>
                                            <ReactSearchAutocomplete
                                                styling={{
                                                    backgroundColor: '#fff',
                                                    zIndex: 2,
                                                    placeholderColor: '#939294',
                                                    borderRadius: '4px',
                                                    border: '1px solid #ced4da',
                                                    height: '35px',
                                                    fontSize: 16
                                                }}
                                                items={this.state.creators}
                                                placeholder={'Type Creator name or Company here'}
                                                onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                                onHover={() => {
                                                }}
                                                onSelect={item => {
                                                    let found = this.state.selectedCreators.find(a => a.relatedCreator.id === item.id);

                                                    if (!found) {
                                                        this.setState({
                                                            selectedCreators: [...this.state.selectedCreators, {
                                                                id: '',
                                                                relatedCreator: item,
                                                                creatorRole: '',
                                                                isDefault: false
                                                            }]
                                                        });
                                                    }
                                                }}
                                                onFocus={() => {
                                                    this.setState({});
                                                }}
                                                formatResult={formatResult}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <button
                                                onClick={() =>
                                                    this.setState({
                                                        showOrdering: true,
                                                        orderingList: this.state.selectedCreators,
                                                    })
                                                }
                                                className="btn btn-primary w-xm mt-2">
                                                Change order
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: '15px'}}>
                                        <Col md={12}>
                                            <div className="table-responsive">
                                                <Table className="table mb-0">
                                                    <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Creator Name</th>
                                                        <th>Type</th>
                                                        <th>Role</th>
                                                        <th>Showcase on home (Max 2)</th>
                                                        <th>Remove</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.selectedCreators.map((item, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <th scope="row">{idx + 1}</th>
                                                                <td>{item.relatedCreator.name}</td>
                                                                <td>
                                                                    <Button style={{margin: '2px'}} color={'light'}
                                                                            className="btn-rounded btn-sm">
                                                                        {item.relatedCreator.identifier}
                                                                    </Button>
                                                                </td>
                                                                <td>
                                                                    {' '}
                                                                    <select
                                                                        value={item.creatorRole}
                                                                        onChange={e => {
                                                                            this.setState(prevState => ({
                                                                                selectedCreators: prevState.selectedCreators.map(creator =>
                                                                                    creator.relatedCreator.name === item.relatedCreator.name
                                                                                        ? {
                                                                                            ...creator,
                                                                                            creatorRole: e.target.value
                                                                                        }
                                                                                        : creator
                                                                                )
                                                                            }));
                                                                        }}
                                                                        className="form-select"
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {item.relatedCreator.types?.map((item, idx) => (
                                                                            <option key={idx} value={item}>
                                                                                {item}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>

                                                                <td align="center">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customCheckcolor1"
                                                                        name="isDefault"
                                                                        checked={item.isDefault}
                                                                        onChange={e => {
                                                                            this.setState(prevState => ({
                                                                                selectedCreators: prevState.selectedCreators.map(creator =>
                                                                                    creator.relatedCreator.name === item.relatedCreator.name
                                                                                        ? {
                                                                                            ...creator,
                                                                                            isDefault: e.target.checked
                                                                                        }
                                                                                        : creator
                                                                                )
                                                                            }));
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {' '}
                                                                    <i
                                                                        className="mdi mdi-delete font-size-18"
                                                                        id="deletetooltip"
                                                                        onClick={() => {
                                                                            const reducedArr = this.state.selectedCreators.filter((item, itemIndex) => {
                                                                                return itemIndex !== idx;
                                                                            });

                                                                            this.setState({
                                                                                selectedCreators: reducedArr
                                                                            });
                                                                        }}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>}
                            <div className="text-center mt-4">
                                <button
                                    disabled={this.props.viewStatus}
                                    onClick={() => this.handleAction(this.state.showOrdering)}
                                    type="submit" className="btn btn-primary w-md">
                                    Save {this.state.showOrdering ? " order" : ""}
                                </button>
                            </div>
                        </>}

                </div>
            </div>
        );
    }
}

Creators.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab3: PropTypes.func,

    //t: PropTypes.any,
    history: PropTypes.any,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Creators));

const formatResult = item => {
    return (
        <>
            <span style={{display: 'block', textAlign: 'left'}}>{item.name}</span>
        </>
    );
};
