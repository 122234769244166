import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Modal, Button, ModalHeader, ModalBody, Table, CardTitle } from 'reactstrap';

import axios from 'axios';

//Import Breadcrumb
import DeleteModal from '../../components/Common/DeleteModal';

import { deleteParticles, deleteParticlesFailed } from '../../store/particles/actions';
import { API_URL } from '../../constants/apiurl';
import SweetAlert from 'react-bootstrap-sweetalert';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { searchParticles } from '../../helpers/Api/common_apis';
import Loader from '../../components/Common/Loader';

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class Particles extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            particles: [],
            autocompleteParticles: [],
            particle: {},
            modal: false,
            deleteModal: false,
            totalElements: '',
            totalPages: '',
            page: 0,
            id: 'id',
            seq: 'desc',
            searchFlag: false
        };

        this.toggle = this.toggle.bind(this);

        this.onClickDelete = this.onClickDelete.bind(this);
    }

    componentDidMount() {
        this.getParticles(0, this.state.id, this.state.seq);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    getParticles = (page, id, seq) => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({
            loading: true
        });
        axios
            .get(API_URL + '/tt/particle?page=' + page + '&size=15&sort=' + id + ',' + seq, axiosconf)
            .then(response => {
                this.setState({
                    particles: response.data.content,
                    loading: false,
                    totalElements: response.data.totalElements,
                    totalPages: response.data.totalPages
                });
            })
            .catch(e => {
                console.log('------GET artwork ERROR-----' + JSON.stringify(e));
            });
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    async handleOnSearch(string, results) {
        const response = await searchParticles(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ autocompleteParticles: response.data.content, searchFlag: true });
        }
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    };

    onClickDelete = particles => {
        this.setState({ particle: particles });
        this.setState({ deleteModal: true });
    };

    handleDeleteParticles = () => {
        const { onDeleteParticles } = this.props;
        const { particle } = this.state;
        if (particle.id !== undefined) {
            onDeleteParticles(particle);
            this.setState({ deleteModal: false, particles: [], totalElements: '', totalPages: '' });

            setTimeout(() => {
                this.getParticles(this.state.page, this.state.id, this.state.seq);
            }, 500);
        }
    };

    render() {
        const { isEdit, deleteModal } = this.state;

        return (
            <React.Fragment>
                <DeleteModal show={deleteModal} onDeleteClick={this.handleDeleteParticles} onCloseClick={() => this.setState({ deleteModal: false })} />
                {this.props.error?.response?.data?.message !== undefined && this.props.error?.response?.data?.message !== '' ? (
                    <SweetAlert
                        error
                        title=""
                        onConfirm={() => {
                            this.setState({ showAlert: false });
                            this.props.onDeleteFailed(null);
                        }}
                    >
                        {this.props.error?.response?.data?.message}
                    </SweetAlert>
                ) : null}
                <div className="page-content">
                    <MetaTags>
                        <title>Particle List</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <CardTitle className="mb-3">Particle List | Count(s) - {this.state.totalElements}</CardTitle>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <React.Fragment>
                                            <Row className="mb-2">
                                                <Col sm="4">
                                                    <ReactSearchAutocomplete
                                                        styling={{
                                                            backgroundColor: '#fff',
                                                            zIndex: 2,
                                                            placeholderColor: '#939294',
                                                            borderRadius: '4px',
                                                            border: '1px solid #ced4da',
                                                            height: '35px',
                                                            fontSize: 16
                                                        }}
                                                        //items={this.state.autocompleteArtworks}
                                                        fuseOptions={{ keys: ['name', 'size'] }}
                                                        resultStringKeyName="name"
                                                        placeholder={'Search'}
                                                        onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                                        onHover={() => {}}
                                                        showClear={true}
                                                        onClear={() => {
                                                            setTimeout(() => {
                                                                this.setState({ searchFlag: false });
                                                            }, 500);
                                                        }}
                                                        onSelect={item => {
                                                            this.setState({ searchFlag: true, particle: item });
                                                        }}
                                                        onFocus={() => {}}
                                                        //  formatResult={formatResult}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                    {this.state.searchFlag && (
                                                        <Button
                                                            color="primary"
                                                            style={{ marginTop: '3px' }}
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => {
                                                                this.setState({ searchFlag: false });
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col sm="6">
                                                    <div className="text-sm-end">
                                                        <Button
                                                            color="primary"
                                                            className="font-16 btn-block btn btn-primary"
                                                            onClick={() => {
                                                                localStorage.setItem('pid', JSON.stringify({ id: undefined }));
                                                                this.props.history.push(`/web/particle-details/new/post`);
                                                            }}
                                                        >
                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                            Create New
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col xl="12">
                                                    <div className="table-responsive">
                                                        <Table className="table mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th
                                                                        onClick={() => {
                                                                            this.setState({ id: 'id', seq: this.state.seq === 'asc' ? 'desc' : 'asc' });
                                                                            setTimeout(() => {
                                                                                this.getParticles(this.state.page, this.state.id, this.state.seq);
                                                                            }, 500);
                                                                        }}
                                                                    >
                                                                        ID
                                                                        {this.state.seq === 'asc' ? (
                                                                            <span className="react-bootstrap-table-sort-order">
                                                                                <span className="caret"></span>
                                                                            </span>
                                                                        ) : (
                                                                            <span className="react-bootstrap-table-sort-order dropup">
                                                                                <span className="caret"></span>
                                                                            </span>
                                                                        )}
                                                                    </th>
                                                                    <th>Particle Name</th>
                                                                    <th>Bubble Size</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            {this.state.loading ? (
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={'5'} align="center">
                                                                            <Loader color={'green'} loading={this.state.loading} />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ) : (
                                                                <tbody>
                                                                    {this.state.searchFlag
                                                                        ? this.state.autocompleteParticles.map((particle, idx) => (
                                                                              <tr key={idx}>
                                                                                  <th scope="row">{particle.id}</th>
                                                                                  <td>{particle.name}</td>
                                                                                  <td>{particle.size}</td>
                                                                                  <td>
                                                                                      <span className={particle.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>
                                                                                          {particle.status}
                                                                                      </span>
                                                                                  </td>
                                                                                  <td>
                                                                                      <div className="d-flex gap-3">
                                                                                          <i
                                                                                              className="mdi mdi-eye font-size-18"
                                                                                              id="deletetooltip"
                                                                                              onClick={() => {
                                                                                                  localStorage.setItem('pid', JSON.stringify({ id: undefined }));
                                                                                                  this.props.history.push(`/web/particle-details/` + particle.id + `/view`);
                                                                                              }}
                                                                                          ></i>

                                                                                          <Link className="text-success" to="#">
                                                                                              <i
                                                                                                  className="mdi mdi-pencil font-size-18"
                                                                                                  id="edittooltip"
                                                                                                  onClick={() => {
                                                                                                      localStorage.setItem('pid', JSON.stringify({ id: undefined }));
                                                                                                      this.props.history.push(`/web/particle-details/` + particle.id + `/edit`);
                                                                                                      // localStorage.removeItem("exhibid");
                                                                                                  }}
                                                                                              ></i>
                                                                                          </Link>
                                                                                          <Link className="text-danger" to="#">
                                                                                              <i
                                                                                                  className="mdi mdi-delete font-size-18"
                                                                                                  id="deletetooltip"
                                                                                                  onClick={() => this.onClickDelete(particle)}
                                                                                              ></i>
                                                                                          </Link>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          ))
                                                                        : this.state.particles?.map((particle, idx) => (
                                                                              <tr key={idx}>
                                                                                  <th scope="row">{particle.id}</th>
                                                                                  <td>{particle.name}</td>
                                                                                  <td>{particle.size}</td>
                                                                                  <td>
                                                                                      <span className={particle.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>
                                                                                          {particle.status}
                                                                                      </span>
                                                                                  </td>
                                                                                  <td>
                                                                                      <div className="d-flex gap-3">
                                                                                          <i
                                                                                              className="mdi mdi-eye font-size-18"
                                                                                              id="deletetooltip"
                                                                                              onClick={() => {
                                                                                                  localStorage.setItem('pid', JSON.stringify({ id: undefined }));
                                                                                                  this.props.history.push(`/web/particle-details/` + particle.id + `/view`);
                                                                                              }}
                                                                                          ></i>

                                                                                          <Link className="text-success" to="#">
                                                                                              <i
                                                                                                  className="mdi mdi-pencil font-size-18"
                                                                                                  id="edittooltip"
                                                                                                  onClick={() => {
                                                                                                      localStorage.setItem('pid', JSON.stringify({ id: undefined }));
                                                                                                      this.props.history.push(`/web/particle-details/` + particle.id + `/edit`);
                                                                                                      // localStorage.removeItem("exhibid");
                                                                                                  }}
                                                                                              ></i>
                                                                                          </Link>
                                                                                          <Link className="text-danger" to="#">
                                                                                              <i
                                                                                                  className="mdi mdi-delete font-size-18"
                                                                                                  id="deletetooltip"
                                                                                                  onClick={() => this.onClickDelete(particle)}
                                                                                              ></i>
                                                                                          </Link>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          ))}
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br />
                                            {this.state.searchFlag === false && (
                                                <ReactPaginate
                                                    previousLabel={'Previous'}
                                                    nextLabel={'Next'}
                                                    breakLabel={'...'}
                                                    pageCount={Math.ceil(this.state.totalPages)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={ee => {
                                                        this.setState({ page: ee.selected });
                                                        this.getParticles(ee.selected, this.state.id, this.state.seq);
                                                    }}
                                                    containerClassName={'pagination justify-content-end'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link'}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link'}
                                                    activeClassName={'active'}
                                                />
                                            )}
                                        </React.Fragment>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

Particles.propTypes = {
    className: PropTypes.any,

    onDeleteParticles: PropTypes.func,
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.object,
    onDeleteFailed: PropTypes.func
};

const mapStateToProps = ({ particles }) => ({
    error: particles?.error
});

const mapDispatchToProps = dispatch => ({
    onDeleteParticles: particles => dispatch(deleteParticles(particles)),
    onDeleteFailed: err => dispatch(deleteParticlesFailed(err))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Particles));
