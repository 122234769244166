export const INITIAL_LIST_STATE = {
    error: {},
    items: [],
    loading: false,
    pagination: {
        page: 0,
        size: 15,
    },

    sort: {
        field: 'id',
        order: 'desc'
    },
    totalItems: 0,
    totalPages: 0,
    type: '',
}
