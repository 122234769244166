import { call, put, select, takeLatest } from 'redux-saga/effects'
import { deleteArticles } from '../../../helpers/fakebackend_helper'
import { getContentsList } from '../apis'
import * as selectors from './selectors'
import * as actions from './actions'
import * as types from './types'
import { LOCATION_CHANGE } from 'connected-react-router'

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'))
        return obj;
    }
}

function* handleLocationChange({ payload }) {
    const { pathname } = payload.location

    if(pathname.startsWith('/web/articles'))
        return yield put(actions.LoadContentsList('Article'))
  }
  

function* handleLoadContentsList({ payload: type }) {
    const pagination = yield select(selectors.GetContentsListPagination)
    const sort = yield select(selectors.GetContentsListSort)
    try {
        const response = yield call(getContentsList, type, pagination, sort)
        yield put(actions.LoadContentsListSuccess(response))
    } catch (error) {
        yield put(actions.LoadContentsListFail(error))
    }
}

function* onDeleteArticles({ payload: articles }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: articles.id };
        const response11 = yield call(deleteArticles, objj);
        yield put(actions.deleteArticlesSuccess(response11));
    } catch (error) {
        yield put(actions.deleteArticlesFailed(error));
    }
}

export default function* contentsListSaga() {
    yield takeLatest(LOCATION_CHANGE, handleLocationChange)
    yield takeLatest(types.LOAD_CONTENTS_LIST, handleLoadContentsList)
    yield takeLatest(types.DELETE_ARTICLES, onDeleteArticles)
}

