//import { venues } from "common/data"
import {
    DELETE_CINDIVIDUALS_SUCCESS,
    DELETE_CINDIVIDUALS_FAIL,
    GET_CINDIVIDUALS_SUCCESS,
    GET_CINDIVIDUALS_FAIL,
    GET_CINDIVIDUALS,
    DELETE_CINDIVIDUALS,
    UPDATE_CINDIVIDUALS_SUCCESS,
    UPDATE_CINDIVIDUALS, DELETE_CREATORS, DELETE_CREATORS_FAIL
} from './actionTypes';

export const getCIndividuals = () => ({
    type: GET_CINDIVIDUALS
});

export const deleteCIndividuals = cindividuals => ({
    type: DELETE_CINDIVIDUALS,
    payload: cindividuals
});

export const getCIndividualsSuccess = cindividuals => ({
    type: GET_CINDIVIDUALS_SUCCESS,
    payload: cindividuals
});

export const getCIndividualsFail = error => ({
    type: GET_CINDIVIDUALS_FAIL,
    payload: error
});
export const deleteCIndividualsSuccess = cindividuals => ({
    type: DELETE_CINDIVIDUALS_SUCCESS,
    payload: cindividuals
});

export const deleteCIndividualsFailed = error => ({
    type: DELETE_CINDIVIDUALS_FAIL,
    payload: error
});

export const updateCIndividuals = cindividuals => ({
    type: UPDATE_CINDIVIDUALS,
    payload: cindividuals
});

export const updateCIndividualsSuccess = cindividuals => ({
    type: UPDATE_CINDIVIDUALS_SUCCESS,
    payload: cindividuals
});


//-----Others
