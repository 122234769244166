import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postFakeLogin, postJwtLogin, postSocialLogin, postLogout, testGetdata } from '../../../helpers/fakebackend_helper';

const fireBaseBackend = getFirebaseBackend();
const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

function* loginUser({ payload: { user, history } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(fireBaseBackend.loginUser, user.email, user.password);
            yield put(loginSuccess(response));
        } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
            /*
      var details = {
        client_id:"particle-app",
        username: "api-user",
        password: "password",
        grant_type: "password"
      };
      var formBody = [];
for (var property in details) {
  var encodedKey = encodeURIComponent(property);
  var encodedValue = encodeURIComponent(details[property]);
  formBody.push(encodedKey + "=" + encodedValue);
}
formBody = formBody.join("&");

      fetch('http://184.168.126.140:8080/realms/particle-dev/protocol/openid-connect/token', {
        method: "POST",
        mode: "no-cors",
        body: formBody,
        cache: "no-cache",
        headers:{
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then((res)=>console.log("res --- "+JSON.stringify(res))).catch((err)=>{console.log(JSON.stringify(err))})
*/

            // const response11 = yield call(testGetdata)
            //console.log(user.password+" Get test data... >>>>>"+user.email)
            /* const response = yield call(postJwtLogin, {
        client_id:"particle-app",
        username: user.email,
        password: user.password,
        grant_type: "password"
      }) */

            const response = yield call(postJwtLogin, {
                client_id: 'particle-client',
                username: user.email,
                password: user.password,
                grant_type: 'password'
            });

            localStorage.setItem('authUser', JSON.stringify(response));
            yield put(loginSuccess(response));
        } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
            const response = yield call(postFakeLogin, {
                email: user.email,
                password: user.password
            });
            localStorage.setItem('authUser', JSON.stringify(response));
            yield put(loginSuccess(response));
        }
        history.push('/web/dashboard');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        // let obj = getAuthDetails();
        // const response = yield call(postLogout, obj.access_token);
        localStorage.removeItem('authUser');

        // console.log("logut == "+JSON.stringify(response));
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(fireBaseBackend.logout);
            yield put(logoutUserSuccess(response));
        }
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* socialLogin({ payload: { data, history, type } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const fireBaseBackend = getFirebaseBackend();
            const response = yield call(fireBaseBackend.socialLoginUser, data, type);
            localStorage.setItem('authUser', JSON.stringify(response));
            yield put(loginSuccess(response));
        } else {
            const response = yield call(postSocialLogin, data);
            localStorage.setItem('authUser', JSON.stringify(response));
            yield put(loginSuccess(response));
        }
        history.push('/web/dashboard');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeLatest(SOCIAL_LOGIN, socialLogin);
    yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
