import { INITIAL_DETAIL_STATE } from './state'
import * as types from './types'

export const detail = (state = INITIAL_DETAIL_STATE, action) => {
    switch (action.type) {

        case types.RESET_CONTENT_DETAIL_STATE:
            return INITIAL_DETAIL_STATE

        case types.LOAD_CONTENT_DETAIL:
            return {
                ...state,
                activeTab: 'information',
                mode: action.payload.mode || state.mode,
                loading: true,
            }

        case types.LOAD_TAG_STATUS_SUCCESS:
            return {
                ...state,
                tagStatus: action.payload,
            }

        case types.NEW_CONTENT_DETAIL:
            return {
                ...state,
                activeTab: 'information',
            }

    
        case types.LOAD_CONTENT_FILES:
            return {
                ...state,
                activeTab: 'files',
                loading: true,
            }

        case types.LOAD_CONTENT_CREATORS:
            return {
                ...state,
                activeTab: 'creators',
                loading: true,
            }

        case types.LOAD_CONTENT_VENUES:
            return {
                ...state,
                activeTab: 'venues',
                loading: true,
            }

        case types.LOAD_CONTENT_LINKS:
            return {
                ...state,
                activeTab: 'links',
                loading: true,
            }
    
        case types.SAVE_CONTENT_DETAIL:
        case types.SAVE_CONTENT_VENUES:
        case types.SAVE_CONTENT_CREATORS:
        case types.SAVE_CONTENT_LINKS:
        case types.SAVE_CONTENT_FILES:
        case types.DELETE_CONTENT_FILES:
            return {
                ...state,
                loading: true,
            }

        case types.LOAD_CONTENT_DETAIL_SUCCESS:
        case types.SAVE_CONTENT_DETAIL_SUCCESS:
            return {
                ...state,
                item: action.payload,
                loading: false,
            }

        case types.CREATE_CONTENT_DETAIL_SUCCESS:
            return {
                ...state,
                item: action.payload,
                loading: false,
                activeTab: 'files',
            }
    
        case types.LOAD_CONTENT_DETAIL_FAIL:
        case types.SAVE_CONTENT_DETAIL_FAIL:
        case types.CREATE_CONTENT_DETAIL_FAIL:
        case types.LOAD_CONTENT_FILES_FAIL:
        case types.LOAD_CONTENT_CREATORS_FAIL:
        case types.LOAD_CONTENT_VENUES_FAIL:
        case types.LOAD_CONTENT_LINKS_FAIL:
        case types.SAVE_CONTENT_VENUES_FAIL:
        case types.SAVE_CONTENT_CREATORS_FAIL:
        case types.SAVE_CONTENT_LINKS_FAIL:
        case types.SAVE_CONTENT_FILES_FAIL:
        case types.DELETE_CONTENT_FILES_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }

        case types.LOAD_CONTENT_VENUES_SUCCESS:
        case types.SAVE_CONTENT_VENUES_SUCCESS:
            return {
                ...state,
                venues: action.payload,
                loading: false,
            }

        case types.ADD_CONTENT_VENUE:
            return {
                ...state,
                venues: [...state.venues, { id: '', venue: action.payload }],
            }

        case types.REMOVE_CONTENT_VENUE:
            return {
                ...state,
                venues: state.venues.filter(v => v.id !== action.payload),
            }

        case types.ADD_CONTENT_CREATOR:
            return {
                ...state,
                creators: [...state.creators, { id: '', creator: action.payload }],
            }

        case types.REMOVE_CONTENT_CREATOR:
            return {
                ...state,
                creators: state.creators.filter(c => c.id !== action.payload),
            }

        case types.LOAD_CONTENT_CREATORS_SUCCESS:
        case types.SAVE_CONTENT_CREATORS_SUCCESS:
            return {
                ...state,
                loading: false,
                creators: action.payload,
            }

        case types.ADD_CONTENT_LINK:
            return {
                ...state,
                links: [...state.links, action.payload],
            }

        case types.REMOVE_CONTENT_LINK:
            return {
                ...state,
                links: state.links.filter(l => l.id !== action.payload),
            }

        case types.LOAD_CONTENT_LINKS_SUCCESS:
        case types.SAVE_CONTENT_LINKS_SUCCESS:
            return {
                ...state,
                loading: false,
                links: action.payload,
            }
    
        case types.DELETE_CONTENT_FILES_SUCCESS:
            return {
                ...state,
                loading: false,
                files: state.files.filter(v => v.id !== action.payload),
            }

        case types.LOAD_CONTENT_FILES_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    files: action.payload.content,
                }    

        default:
            return state
    }
}
