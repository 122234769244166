export function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

export const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = error => {
    };
};

export function getExhibId() {
    if (localStorage.getItem('exhibid')) {
        const obj = JSON.parse(localStorage.getItem('exhibid'));
        return obj;
    }
}

export function getPId() {
    if (localStorage.getItem('pid')) {
        const obj = JSON.parse(localStorage.getItem('pid'));
        return obj;
    }
}

export function getContentId() {
    if (localStorage.getItem('contentid')) {
        const obj = JSON.parse(localStorage.getItem('contentid'));
        return obj;
    }
}

export function getCreatorId() {
    if (localStorage.getItem('creatorid')) {
        const obj = JSON.parse(localStorage.getItem('creatorid'));
        return obj;
    }
}
