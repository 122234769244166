import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import {DELETE_CREATORS, GET_CREATORS, UPDATE_CREATORS} from './actionTypes';

import {
    getCreatorsSuccess,
    getCreatorsFail,
    deleteCreatorsFailed,
    deleteCreatorsSuccess
} from './actions';

//Include Both Helper File with needed methods
import {
    deleteCreators,
    getCreators,
    updateCreatorsByID
} from '../../helpers/fakebackend_helper';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

function* fetchCreators() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getCreators, obj.access_token);

        yield put(getCreatorsSuccess(response.content));
    } catch (error) {
        yield put(getCreatorsFail(error));
    }
}
function* onDeleteCreators({ payload: creators }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: creators.id };
        const response11 = yield call(deleteCreators, objj);
        yield put(deleteCreatorsSuccess(response11));
    } catch (error) {
        yield put(deleteCreatorsFailed(error));
    }
}

function* updateCreatorsById({ payload: creators }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: creators.id, creators: creators };

        const response11 = yield call(updateCreatorsByID, objj);

        const response = yield call(getCreators, obj.access_token);
        yield put(getCreatorsSuccess(response.content));
    } catch (error) {
        yield put(getCreatorsFail(error));
    }
}

function* creatorsSaga() {
    yield takeEvery(GET_CREATORS, fetchCreators);
    yield takeEvery(UPDATE_CREATORS, updateCreatorsById);
    yield takeEvery(DELETE_CREATORS, onDeleteCreators);
}

export default creatorsSaga;
