import axios from 'axios';
import Loader from '../../../components/Common/Loader';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Col, Card, Label, Row, Table, Input} from 'reactstrap';
import {API_URL} from '../../../constants/apiurl';
import {ReactSearchAutocomplete} from "react-search-autocomplete";
import {searchArtwork, searchContent} from "../../../helpers/Api/common_apis";
import moment from "moment/moment";

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

const getExhibId = () => {
    if (localStorage.getItem('exhibid')) {
        const obj = JSON.parse(localStorage.getItem('exhibid'));
        return obj;
    }
};

class RouteStepPageLink extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            // Global
            viewStatus: false,
            loading: false,
            showAlert: false,
            alertMessage: '',
            showErrorAlert: false,
            alertErrorMessage: '',

            id: '',
            pageLinks: [],
            artworks: [],
            artwork: '',
            contents: [],
            content: '',
            creators: [],
            creator: '',
            venues: [],
            venue: '',
            externalLinkUrl: '',
            externalLinkLabel: '',
            vexternalLinkLabel: '',
            vexternalLinkUrl: '',
            vgeneric: '',
            artworkSearchFlag: false,
            autocompleteArtworks: [],
            creatorSearchFlag: false,
            autocompleteCreators: [],
            contentSearchFlag: false,
            autocompleteContents: [],
            venueSearchFlag: false,
            autocompleteVenues: []
        };

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getArtworks();
        this.getContents();
        this.getCreators();
        this.getVenues();
        this.getPageLinks();
    }

    componentDidUpdate(previousProps) {
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getArtworks();
            this.getContents();
            this.getCreators();
            this.getVenues();
            this.getPageLinks();
        }
    }

    getArtworks = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            //.get(API_URL + '/tt/exhibition/' + eid.id + '/artwork/mapping', axiosconf)
            .get(API_URL + '/tt/artwork', axiosconf)
            .then(response => {
                this.setState({artworks: response.data.content});
            })
            .catch(e => console.log('GET ARTWORKS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    getCreators = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id + '/creator/mapping', axiosconf)
            .then(response => {
                this.setState({creators: response.data.map(c => c.creator)});
            })
            .catch(e => console.log('GET CREATORS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    getContents = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id + '/content/mapping', axiosconf)
            .then(response => {
                this.setState({contents: response.data.map(c => c.content)});
            })
            .catch(e => console.log('GET CONTENTS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    getVenues = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id, axiosconf)
            .then(response => {
                this.setState({venues: [response.data.venue]});
            })
            .catch(e => console.log('GET VENUES ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    getPageLinks = () => {
        let pageId = this.props.pageId;
        if (pageId === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/route/step/page/' + pageId + '/links', axiosconf)
            .then(response => {
                this.setState({pageLinks: response.data, loading: false});
            })
            .catch(e => console.log('GET PAGE LINKS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    createPageLink = async () => {

        // Validation
        if (this.state.externalLinkLabel !== '' && this.state.externalLinkUrl === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vexternalLinkUrl: 'This field is required when External Link Label is filled! '});
        }

        if (this.state.externalLinkLabel === '' && this.state.externalLinkUrl !== '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vexternalLinkLabel: 'This field is required when External Link Url is filled! '});
        }

        let check = [
            this.state.artwork !== '',
            this.state.creator !== '',
            this.state.content !== '',
            this.state.venue !== '',
            this.state.externalLinkLabel !== '' && this.state.externalLinkUrl !== ''
        ]

        if(check.filter((value) => value === true).length > 1) {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vgeneric: 'ERROR: Only one field type must be filled!'});
        } else if (
            this.state.artwork === '' &&
            this.state.creator === '' &&
            this.state.content === '' &&
            this.state.venue === '' &&
            this.state.externalLinkLabel === '' &&
            this.state.externalLinkUrl === ''
        ) {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vgeneric: 'ERROR: A field must be filled!'});
        } else {
            const postData = {
                id: this.state.id || null,
                artwork: this.state.artwork ? {id: this.state.artwork} : undefined,
                creator: this.state.creator ? {id: this.state.creator} : undefined,
                content: this.state.content ? {id: this.state.content} : undefined,
                venue: this.state.venue ? {id: this.state.venue} : undefined,
                externalLinkUrl: this.state.externalLinkUrl ? this.state.externalLinkUrl : undefined,
                externalLinkLabel: this.state.externalLinkLabel ? this.state.externalLinkLabel : undefined
            };

            let pageId = this.props.pageId;
            if (pageId === undefined) {
                return;
            }


            this.setState({loading: true});

            let obj = getAuthDetails();
            let axiosconf = axiosHeaderConfig(obj.access_token);

            try {
                const create = await axios.post(API_URL + '/tt/exhibition/route/step/page/' + pageId + '/link', postData, axiosconf);

                this.setState({
                    id: '',
                    artwork: '',
                    creator: '',
                    content: '',
                    venue: '',
                    externalLinkUrl: '',
                    externalLinkLabel: ''
                });

                this.getPageLinks();
            } catch (e) {
                console.log('CREATE PAGE LINK ERROR', e);
            } finally {
                this.setState({loading: false});
            }
        }
    };

    removePageLink = id => {
        if (confirm('Are you sure?')) {
            let pageId = this.props.pageId;
            if (pageId === undefined) {
                return;
            }

            this.setState({loading: true});

            let obj = getAuthDetails();
            let axiosconf = axiosHeaderConfig(obj.access_token);
            axios
                .delete(API_URL + '/tt/exhibition/route/step/page/' + pageId + '/link/' + id, axiosconf)
                .then(() => {
                    this.setState({
                        showAlertSuccess: true,
                        successMessage: 'Delete Successfully!'
                    });

                    this.getPageLinks();
                })
                .catch(e => console.log('DELETE PAGE LINK ERROR', e))
                .finally(() => this.setState({loading: false}));
        }
    };

    getAuthDetails = () =>
        localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser'))

    handleOnSearchArtwork = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        let obj = this.getAuthDetails()
        let axiosconf = obj && axiosHeaderConfig(obj.access_token)

        axios
            .get(`${API_URL}/tt/artwork?page=0&size=20&key=${string}&status=Published&sort=name,asc`, axiosconf)
            .then(response => {
                this.setState({autocompleteArtworks: response.data.content})
            })
            .catch(e => {
                console.log(` error ${JSON.stringify(e)}`)
            })
    }

    handleOnSearchCreator = (string, results) => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/creator?page=0&size=20&key=' + string + '&status=Published&sort=name,asc', axiosconf)
            .then(response => {
                this.setState({autocompleteCreators: response.data.content});
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    handleOnSearchContent = (string, results) => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/content?page=0&size=20&key=' + string + '&status=Published&sort=title,asc', axiosconf)
            .then(response => {
                this.setState({autocompleteContents: response.data.content});
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    handleOnSearchVenue = (string, results) => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/venue?page=0&size=20&key=' + string + '&status=Published&sort=name,asc', axiosconf)
            .then(response => {
                this.setState({autocompleteVenues: response.data.content});
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    render() {
        return (
            <div>
                <h4>Page Link ({this.props.pageTitle})</h4>
                {this.state.showAlert ? (
                    <SweetAlert warning title={''} onConfirm={() => this.setState({showAlert: false})}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showAlertSuccess ? (
                    <SweetAlert success title={''} onConfirm={() => this.setState({showAlertSuccess: false})}>
                        {this.state.successMessage}
                    </SweetAlert>
                ) : null}

                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading}/>
                ) : (
                    <Card>
                        <div style={{display: 'flex'}}>
                            <button onClick={() => this.props.parentCloseLinks()} style={{marginLeft: 'auto'}}
                                    type="button" className="btn btn-primary">
                                <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                Back to page
                            </button>
                        </div>
                        <div>
                            <Row>
                                <Row>
                                    {this.state.vgeneric !== '' &&
                                        <h4 style={{color: 'red'}}>{this.state.vgeneric}</h4>}
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <Col md={3}>
                                        <Label htmlFor="formrow-firstname-Input">Artworks</Label>
                                        <ReactSearchAutocomplete
                                            styling={{
                                                backgroundColor: '#fff',
                                                zIndex: 2,
                                                placeholder: 'Type Country',
                                                placeholderColor: '#939294',
                                                borderRadius: '4px',
                                                border: '1px solid #ced4da',
                                                height: '35px',
                                                fontSize: 16
                                            }}
                                            items={this.state.autocompleteArtworks}
                                            placeholder={'Type artwork name here'}
                                            onSearch={(ss, ee) => this.handleOnSearchArtwork(ss, ee)}
                                            onHover={() => {
                                            }}
                                            onSelect={item => {
                                                this.setState({
                                                    artwork: item.id,
                                                    vexternalLinkUrl: '',
                                                    vexternalLinkLabel: '',
                                                    vgeneric: ''
                                                });
                                            }}
                                            onFocus={() => {
                                            }}
                                            formatResult={formatResult}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Label htmlFor="formrow-firstname-Input">Creators</Label>
                                        <ReactSearchAutocomplete
                                            styling={{
                                                backgroundColor: '#fff',
                                                zIndex: 2,
                                                placeholder: 'Type Country',
                                                placeholderColor: '#939294',
                                                borderRadius: '4px',
                                                border: '1px solid #ced4da',
                                                height: '35px',
                                                fontSize: 16
                                            }}
                                            items={this.state.autocompleteCreators}
                                            placeholder={'Type Creator name or Company here'}
                                            onSearch={(ss, ee) => this.handleOnSearchCreator(ss, ee)}
                                            onHover={() => {
                                            }}
                                            onSelect={item => {
                                                this.setState({
                                                    creator: item.id,
                                                    vexternalLinkUrl: '',
                                                    vexternalLinkLabel: '',
                                                    vgeneric: ''
                                                });
                                            }}
                                            onFocus={() => {
                                            }}
                                            formatResult={formatResult}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Label htmlFor="formrow-firstname-Input">Contents</Label>
                                        <ReactSearchAutocomplete
                                            styling={{
                                                backgroundColor: '#fff',
                                                zIndex: 2,
                                                placeholder: 'Type Country',
                                                placeholderColor: '#939294',
                                                borderRadius: '4px',
                                                border: '1px solid #ced4da',
                                                height: '35px',
                                                fontSize: 16
                                            }}
                                            items={this.state.autocompleteContents}
                                            placeholder={'Type Content title here'}
                                            onSearch={(ss, ee) => this.handleOnSearchContent(ss, ee)}
                                            onHover={() => {
                                            }}
                                            onSelect={item => {
                                                this.setState({
                                                    content: item.id,
                                                    vexternalLinkUrl: '',
                                                    vexternalLinkLabel: '',
                                                    vgeneric: ''
                                                });
                                            }}
                                            onFocus={() => {
                                            }}
                                            formatResult={formatResultContent}
                                            fuseOptions={{
                                                keys:["title"]
                                            }}
                                            resultStringKeyName="title"
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Label htmlFor="formrow-firstname-Input">Venues</Label>
                                        <ReactSearchAutocomplete
                                            styling={{
                                                backgroundColor: '#fff',
                                                zIndex: 2,
                                                placeholder: 'Type Country',
                                                placeholderColor: '#939294',
                                                borderRadius: '4px',
                                                border: '1px solid #ced4da',
                                                height: '35px',
                                                fontSize: 16
                                            }}
                                            items={this.state.autocompleteVenues}
                                            placeholder={'Type Venues name'}
                                            onSearch={(ss, ee) => this.handleOnSearchVenue(ss, ee)}
                                            onHover={() => {
                                            }}
                                            onSelect={item => {
                                                this.setState({
                                                    venue: item.id,
                                                    vexternalLinkUrl: '',
                                                    vexternalLinkLabel: '',
                                                    vgeneric: ''
                                                });
                                            }}
                                            onFocus={() => {
                                            }}
                                            formatResult={formatResult}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <Col md={4}>
                                        <Label htmlFor="formrow-firstname-Input">External Link Label</Label>
                                        <Input
                                            readOnly={this.state.viewStatus}
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder=""
                                            name="externalLinkLabel"
                                            value={this.state.externalLinkLabel}
                                            invalid={this.state.vexternalLinkLabel !== '' ? true : false}
                                            onChange={e => this.setState({
                                                externalLinkLabel: e.target.value,
                                                vexternalLinkLabel: '',
                                                vgeneric: ''
                                            })}
                                        />
                                        {this.state.vexternalLinkLabel !== '' &&
                                            <span style={{color: 'red'}}>{this.state.vexternalLinkLabel}</span>}
                                    </Col>
                                    <Col md={4}>
                                        <Label htmlFor="formrow-firstname-Input">External Link Url</Label>
                                        <Input
                                            readOnly={this.state.viewStatus}
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder=""
                                            name="externalLinkUrl"
                                            value={this.state.externalLinkUrl}
                                            invalid={this.state.vexternalLinkUrl !== '' ? true : false}
                                            onChange={e => this.setState({
                                                externalLinkUrl: e.target.value,
                                                vexternalLinkUrl: '',
                                                vgeneric: ''
                                            })}
                                        />
                                        {this.state.vexternalLinkUrl !== '' &&
                                            <span style={{color: 'red'}}>{this.state.vexternalLinkUrl}</span>}
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <Col md={12}>
                                        <div className="text-center">
                                            <button disabled={this.state.viewStatus}
                                                    className="btn btn-primary w-md"
                                                    onClick={() => this.createPageLink()}>
                                                {this.state.id ? 'Edit' : 'Add'}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '15px'}}>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Artwork</th>
                                                    <th>Creator</th>
                                                    <th>Content</th>
                                                    <th>Venue</th>
                                                    <th>External Link Url</th>
                                                    <th>External Link Label</th>
                                                    <th style={{width: 160}}></th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {this.state.pageLinks.map((item, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <th scope="row">{idx + 1}</th>
                                                            <td>{item.artwork?.name}</td>
                                                            <td>{item.creator?.name}</td>
                                                            <td>{item.content?.title}</td>
                                                            <td>{item.venue?.name}</td>
                                                            <td>{item.externalLinkUrl}</td>
                                                            <td>{item.externalLinkLabel}</td>
                                                            <td>
                                                                <button
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            id: item.id,
                                                                            artwork: item.artwork?.id || '',
                                                                            creator: item.creator?.id || '',
                                                                            content: item.content?.id || '',
                                                                            venue: item.venue?.id || '',
                                                                            externalLinkUrl: item.externalLinkUrl || '',
                                                                            externalLinkLabel: item.externalLinkLabel || ''
                                                                        });
                                                                    }}
                                                                    className="btn btn-primary w-xm"
                                                                >
                                                                    Edit
                                                                </button>

                                                                <button
                                                                    onClick={() => this.removePageLink(item.id)}
                                                                    className="btn btn-danger w-xm">
                                                                    Remove
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        </div>
                    </Card>
                )}
            </div>
        );
    }
}

RouteStepPageLink.propTypes = {
    viewStatus: PropTypes.bool,
    activeTab: PropTypes.string,
    pageId: PropTypes.number,
    pageTitle: PropTypes.string,
    parentCloseLinks: PropTypes.func
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouteStepPageLink));

const formatResultContent = item => {
    return (
        <>
            <span style={{display: 'block', textAlign: 'left'}}>{item.title}</span>
        </>
    );
};

const formatResult = item => {
    return (
        <>
            <span style={{display: 'block', textAlign: 'left'}}>{item.name}</span>
        </>
    );
};

