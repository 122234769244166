import * as types from './types'

export const ResetContentDetailState = () => ({ type: types.RESET_CONTENT_DETAIL_STATE })

export const LoadContentDetail = (id, mode) => ({ type: types.LOAD_CONTENT_DETAIL, payload: {id, mode} })
export const LoadContentDetailSuccess = (content) => ({ type: types.LOAD_CONTENT_DETAIL_SUCCESS, payload: content })
export const LoadContentDetailFail = (error) => ({ type: types.LOAD_CONTENT_DETAIL_FAIL, payload: error })
export const SaveContentDetail = (detail) => ({ type: types.SAVE_CONTENT_DETAIL, payload: detail})
export const SaveContentDetailSuccess = (content) => ({ type: types.SAVE_CONTENT_DETAIL_SUCCESS, payload: content })
export const SaveContentDetailFail = (error) => ({ type: types.SAVE_CONTENT_DETAIL_FAIL, payload: error })

export const NewContentDetail = () => ({ type: types.NEW_CONTENT_DETAIL })
export const CreateContentDetail = (detail) => ({ type: types.CREATE_CONTENT_DETAIL, payload: detail})
export const CreateContentDetailSuccess = (content) => ({ type: types.CREATE_CONTENT_DETAIL_SUCCESS, payload: content })
export const CreateContentDetailFail = (error) => ({ type: types.CREATE_CONTENT_DETAIL_FAIL, payload: error })

export const LoadContentTagStatus = () => ({ type: types.LOAD_TAG_STATUS })
export const LoadContentTagStatusSuccess = (tagStatus) => ({ type: types.LOAD_TAG_STATUS_SUCCESS, payload: tagStatus })
export const LoadContentTagStatusFail = (error) => ({ type: types.LOAD_TAG_STATUS_FAIL, payload: error })

export const LoadContentFiles = () => ({ type: types.LOAD_CONTENT_FILES })
export const LoadContentFilesSuccess = (files) => ({ type: types.LOAD_CONTENT_FILES_SUCCESS, payload: files })
export const LoadContentFilesFail = (error) => ({ type: types.LOAD_CONTENT_FILES_FAIL, payload: error })
export const AddContentFile = () => ({ type: types.ADD_CONTENT_FILE })
export const SaveContentFiles = (type, files) => ({ type: types.SAVE_CONTENT_FILES, payload: {type, files} })
export const SaveContentFilesSuccess = (files) => ({ type: types.SAVE_CONTENT_FILES_SUCCESS, payload: files })
export const SaveContentFilesFail = (error) => ({ type: types.SAVE_CONTENT_FILES_FAIL, payload: error })
export const DeleteContentFiles = (id) => ({ type: types.DELETE_CONTENT_FILES, payload: id })
export const DeleteContentFilesSuccess = (id) => ({ type: types.DELETE_CONTENT_FILES_SUCCESS, payload: id })
export const DeleteContentFilesFail = (error) => ({ type: types.DELETE_CONTENT_FILES_FAIL, payload: error })

export const LoadContentCreators = () => ({ type: types.LOAD_CONTENT_CREATORS })
export const LoadContentCreatorsSuccess = (creators) => ({ type: types.LOAD_CONTENT_CREATORS_SUCCESS, payload: creators })
export const LoadContentCreatorsFail = (error) => ({ type: types.LOAD_CONTENT_CREATORS_FAIL, payload: error })
export const AddContentCreator = (creator) => ({ type: types.ADD_CONTENT_CREATOR, payload: creator })
export const RemoveContentCreator = (id) => ({ type: types.REMOVE_CONTENT_CREATOR, payload: id })
export const SaveContentCreators = () => ({ type: types.SAVE_CONTENT_CREATORS })
export const SaveContentCreatorsSuccess = (creators) => ({ type: types.SAVE_CONTENT_CREATORS_SUCCESS, payload: creators })
export const SaveContentCreatorsFail = (error) => ({ type: types.SAVE_CONTENT_CREATORS_FAIL, payload: error })

export const LoadContentLinks = () => ({ type: types.LOAD_CONTENT_LINKS })
export const LoadContentLinksSuccess = (links) => ({ type: types.LOAD_CONTENT_LINKS_SUCCESS, payload: links })
export const LoadContentLinksFail = (error) => ({ type: types.LOAD_CONTENT_LINKS_FAIL, payload: error })
export const AddContentLink = (link) => ({ type: types.ADD_CONTENT_LINK, payload: link })
export const RemoveContentLink = (id) => ({ type: types.REMOVE_CONTENT_LINK, payload: id })
export const SaveContentLinks = () => ({ type: types.SAVE_CONTENT_LINKS })
export const SaveContentLinksSuccess = (links) => ({ type: types.SAVE_CONTENT_LINKS_SUCCESS, payload: links })
export const SaveContentLinksFail = (error) => ({ type: types.SAVE_CONTENT_LINKS_FAIL, payload: error })

export const LoadContentVenues = () => ({ type: types.LOAD_CONTENT_VENUES })
export const LoadContentVenuesSuccess = (venues) => ({ type: types.LOAD_CONTENT_VENUES_SUCCESS, payload: venues })
export const LoadContentVenuesFail = (error) => ({ type: types.LOAD_CONTENT_VENUES_FAIL, payload: error })
export const AddContentVenue = (venue) => ({ type: types.ADD_CONTENT_VENUE, payload: venue })
export const RemoveContentVenue = (id) => ({ type: types.REMOVE_CONTENT_VENUE, payload: id })
export const SaveContentVenues = () => ({ type: types.SAVE_CONTENT_VENUES })
export const SaveContentVenuesSuccess = (venues) => ({ type: types.SAVE_CONTENT_VENUES_SUCCESS, payload: venues })
export const SaveContentVenuesFail = (error) => ({ type: types.SAVE_CONTENT_VENUES_FAIL, payload: error })
