import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import { Label } from 'reactstrap';

class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: this.props.value || '',
            displayColorPicker: false
        };
    }

    render() {
        return (
            <React.Fragment>
                <Label htmlFor="formrow-firstname-Input">{this.props.label}</Label>
                <div className="d-flex align-items-center">
                    <div
                        style={{
                            background: '#fff',
                            borderRadius: '1px',
                            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                            display: 'block',
                            cursor: 'pointer',
                            height: '35px',
                            width: '70px',
                            padding: 4,
                            marginRight: 2
                        }}
                        onClick={() => this.setState({ displayColorPicker: true })}
                    >
                        <div style={{ width: '100%', height: '100%', borderRadius: '2px', background: this.state.color }} />
                    </div>
                    {this.state.color ? (
                        <i
                            className="mdi mdi-delete font-size-18"
                            style={{ color: 'red' }}
                            onClick={() => {
                                this.setState({ color: '' });
                                this.props.onChange('');
                            }}
                        />
                    ) : null}
                    {!this.props.readOnly && this.state.displayColorPicker ? (
                        <div style={{ position: 'absolute', zIndex: '2' }}>
                            <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }} onClick={() => this.setState({ displayColorPicker: false })} />
                            <ChromePicker
                                color={this.state.color}
                                onChange={color => {
                                    this.setState({ color: color.hex });
                                    this.props.onChange(color.hex);
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

ColorPicker.propTypes = {
    readOnly: PropTypes.boolean,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default ColorPicker;
