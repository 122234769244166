/* GALLERIES */
export const GET_GALLERIES = 'GET_GALLERIES';
export const GET_GALLERIES_FAIL = 'GET_GALLERIES_FAIL';
export const GET_GALLERIES_SUCCESS = 'GET_GALLERIES_SUCCESS';

export const DELETE_GALLERIES = 'DELETE_GALLERIES';
export const DELETE_GALLERIES_SUCCESS = 'DELETE_GALLERIES_SUCCESS';
export const DELETE_GALLERIES_FAIL = 'DELETE_GALLERIES_FAIL';

export const UPDATE_GALLERIES = 'UPDATE_GALLERIES';
export const UPDATE_GALLERIES_SUCCESS = 'UPDATE_GALLERIES_SUCCESS';
export const UPDATE_GALLERIES_FAIL = 'UPDATE_GALLERIES_FAIL';
//others------
