import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_GALLERIES, DELETE_GALLERIES, UPDATE_GALLERIES } from './actionTypes';

import { getGalleriesSuccess, getGalleriesFail, deleteGalleriesFailed, deleteGalleriesSuccess } from './actions';

//Include Both Helper File with needed methods
import { getGalleries, deleteGalleries, updateGalleriesByID } from '../../helpers/fakebackend_helper';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
function* fetchGalleries() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getGalleries, obj.access_token);

        yield put(getGalleriesSuccess(response.content));
    } catch (error) {
        yield put(getGalleriesFail(error));
    }
}

function* updateGalleriesById({ payload: galleries }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: galleries.id, galleries: galleries };
        const response11 = yield call(updateGalleriesByID, objj);
        const response = yield call(getGalleries, obj.access_token);
        yield put(getGalleriesSuccess(response.content));
        //const response = yield call(getTags,  obj.access_token)
        //yield put(getThemesSuccess(response11))
    } catch (error) {
        yield put(getGalleriesFail(error));
    }
}

function* onDeleteGalleries({ payload: galleries }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: galleries.id };
        const response11 = yield call(deleteGalleries, objj);
        yield put(deleteGalleriesSuccess(response11));
    } catch (error) {
        yield put(deleteGalleriesFailed(error));
    }
}

function* galleriesSaga() {
    yield takeEvery(GET_GALLERIES, fetchGalleries);
    yield takeEvery(DELETE_GALLERIES, onDeleteGalleries);

    yield takeEvery(UPDATE_GALLERIES, updateGalleriesById);
}

export default galleriesSaga;
