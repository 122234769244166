import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import CropEasyParticle from '../../../components/Crop/CropEasy';
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Alert,
    TextArea,
    Breadcrumb,
    BreadcrumbItem,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table
} from 'reactstrap';
//Import Breadcrumb

import axios from 'axios';
import { API_URL } from '../../../constants/apiurl';
import Loader from '../../../components/Common/Loader';
import { Image } from 'react-bootstrap';
import CropEasy from '../../../components/Crop/CropEasy';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import { dataURLtoBlob } from '../../../constants/utils';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { postExternalLinks, getExternalLinks } from '../../../helpers/Api/Occurences/occurances_apis';
import { getExhibId } from '../../../constants/utils';
class ExternalLinks extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            links: [],
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,
            selectedType: '',
            type: '',
            vtype: '',
            linktext: '',
            vlinktext: '',
            url: '',
            vurl: '',
            show: false,
            base64: '',
            setPreviewUrl: '',
            showPreview: false,
            selectedUploadtype: '',
            vselectedUploadtype: '',
            verror: '',
            status: 'Draft'
        };

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {}

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getELinks();
        }
    }

    handleCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = dataURLtoBlob(childData);
        }
        this.setState({
            show: false,
            base64: childData,
            setPreviewUrl: cdata,
            showPreview: true,
            verror: ''
        });
    };

    onInputChange = e => {
        const file = e.target.files[0];
        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
        if (size > 300) {
            this.setState({
                showAlert: true,
                alertMessage: 'Please ensure image size is less than 300 KB',
                selectedUploadtype: '',
                vselectedUploadtype: ''
            });
            e.target.value = null;
        } else {
            //var filename = file.name;
            //this.setState({ filename: filename, filename: e.target.files[0].name })
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64: reader.result, show: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
                //this.setState({imagePreviewUrl: file})
            }
        }
    };

    postLink = async () => {
        let postData = [];

        this.state.links.map(item => {
            let edata = {
                id: item.id === null ? null : item.id,
                type: item.type,
                title: item.title,
                link: item.link,
                thumbnail: item.thumbnail,
                status: this.state.status
            };
            postData.push(edata);
        });
        let eid = getExhibId();

        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            console.log('post data...... ' + JSON.stringify(postData));
            this.setState({ loading: true });
            const response = await postExternalLinks(postData, eid.id);
            if (response.error) {
                console.log('error ---- ' + JSON.stringify(response));
                this.setState({ loading: false });
            } else {
                // console.log("response external link ------ "+JSON.stringify(response))
                this.setState({
                    showAlertSuccess: true,
                    successMessage: 'Inserted Successfully!'
                });
                this.setState({ loading: false });
                this.getELinks();
                //this.getCreators();
            }
        }
    };

    getELinks = async () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({ loading: true, links: [] });

            const response = await getExternalLinks(eid.id);
            if (response.error) {
                console.log('error ---- ' + JSON.stringify(response));
                this.setState({ loading: false });
            } else {
                //console.log("response get link ------>>>>> "+JSON.stringify(response))
                this.setState({
                    loading: false,
                    links: response.data,
                    status: response.data.length === 0 ? 'Draft' : response.data[0].status
                });
                //this.getCreators();
            }
        }
    };

    render() {
        return (
            <div>
                <div className="text-center mt-0">
                    <h5>External Links</h5>
                </div>
                {this.state.showAlert ? (
                    <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showAlertSuccess ? (
                    <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}>
                        {this.state.successMessage}
                    </SweetAlert>
                ) : null}

                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <div>
                        {this.state.show ? (
                            <CropEasyParticle ratio={1} photoURL={this.state.base64} parentCallback={this.handleCallback} shape={'round'} />
                        ) : (
                            <Row>
                                <Row style={{ marginTop: '20px' }}>
                                    <Col md={3}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Link Type</Label>
                                            <select
                                                disabled={this.state.viewStatus}
                                                value={this.state.type}
                                                onChange={e => {
                                                    this.setState({
                                                        type: e.target.value,
                                                        verror: ''
                                                    });
                                                }}
                                                className="form-select"
                                            >
                                                <option value="">Select Type</option>
                                                <option value="Digital Exhibition Link">Digital Exhibition Link</option>
                                                <option value="AR Experience Link">AR Experience Link</option>
                                                <option value="Join the Discussion">Join the Discussion</option>
                                                <option value="Buy Tickets">Buy Tickets</option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Link Text</Label>
                                            <Input
                                                readOnly={this.state.viewStatus}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter Link Text"
                                                name="linktext"
                                                // invalid={this.state.vtitle !== "" ? true : false}
                                                value={this.state.linktext}
                                                onChange={e =>
                                                    this.setState({
                                                        linktext: e.target.value,
                                                        verror: ''
                                                    })
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">URL</Label>
                                            <Input
                                                readOnly={this.state.viewStatus}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter URL"
                                                name="url"
                                                // invalid={this.state.vtitle !== "" ? true : false}
                                                value={this.state.url}
                                                onChange={e =>
                                                    this.setState({
                                                        url: e.target.value,
                                                        verror: ''
                                                    })
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col md={1}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Thumbnail</Label>
                                            {this.state.showPreview ? (
                                                <>
                                                    <img width={50} style={{ borderRadius: '25px' }} height={50} src={this.state.base64} />
                                                    <i
                                                        className="mdi mdi-delete font-size-14"
                                                        style={{ color: 'red' }}
                                                        id="deletetooltip"
                                                        onClick={() => {
                                                            this.setState({
                                                                show: false,
                                                                base64: '',
                                                                showPreview: false,
                                                                verror: ''
                                                            });
                                                        }}
                                                    ></i>
                                                </>
                                            ) : (
                                                <>
                                                    {/*<input disabled={this.state.viewStatus === true} name="base64" className="form-control" id="formFile" type="file" accept=".jpg,.jpeg,.png"
                                    onChange={(e) => this.onInputChange(e)} />*/}
                                                    <Button style={{ backgroundColor: '#C0C0C0' }} className="btn-rounded btn-sm" onClick={() => this.fileInputRef.current.click()}>
                                                        <i
                                                            className="mdi mdi-plus font-size-45"
                                                            style={{
                                                                color: '#2E2E2E',
                                                                width: '70px',
                                                                height: '70px'
                                                            }}
                                                            id="deletetooltip"
                                                        ></i>
                                                    </Button>
                                                    <input onChange={e => this.onInputChange(e)} name="base64" multiple={false} ref={this.fileInputRef} accept=".jpg,.jpeg,.png" type="file" hidden />
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">{'\t'}</Label>
                                            <br />
                                            <Button
                                                style={{
                                                    marginTop: '3px',
                                                    marginLeft: '8px',
                                                    width: '80px'
                                                }}
                                                color={'primary'}
                                                disabled={this.state.links.length > 3 ? true : false}
                                                onClick={() => {
                                                    if ((this.state.type === '' || this.state.linktext === '' || this.state.url === '', this.state.base64 === '')) {
                                                        this.setState({
                                                            verror: 'All fields are required!'
                                                        });
                                                    } else {
                                                        const exists = this.state.links.filter(item => item.type === this.state.type).length > 0;
                                                        if (exists) {
                                                            this.setState({
                                                                showAlert: true,
                                                                alertMessage: this.state.type + ' already added!'
                                                            });
                                                            return;
                                                        } else {
                                                            this.setState({
                                                                links: [
                                                                    ...this.state.links,
                                                                    {
                                                                        id: null,
                                                                        type: this.state.type,
                                                                        title: this.state.linktext,
                                                                        link: this.state.url,
                                                                        thumbnail: this.state.base64,
                                                                        statue: ''
                                                                    }
                                                                ]
                                                            });

                                                            this.setState({
                                                                linktext: '',
                                                                url: '',
                                                                type: '',
                                                                thumbnail: '',
                                                                base64: '',
                                                                showPreview: false,
                                                                verror: ''
                                                            });
                                                        }
                                                    }
                                                }}
                                                className="btn-md"
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="text-center mt-4">{this.state.verror !== '' && <span style={{ color: 'red' }}>{this.state.verror}</span>}</div>
                                </Row>
                                <Row style={{ marginTop: '15px' }}>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Link Type</th>
                                                        <th>Link Text</th>
                                                        <th>URL</th>
                                                        <th>Thumbnail</th>
                                                        <th>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.links.map((item, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <th scope="row">{idx + 1}</th>
                                                                <td>{item.type}</td>
                                                                <td>{item.title}</td>
                                                                <td>{item.link}</td>
                                                                <td align="center">
                                                                    <img width={50} style={{ borderRadius: '25px' }} height={50} src={item.id === null ? item.thumbnail : API_URL + item.thumbnail} />
                                                                </td>
                                                                <td>
                                                                    <i
                                                                        className="mdi mdi-delete font-size-18"
                                                                        id="deletetooltip"
                                                                        onClick={() => {
                                                                            const reducedArr = this.state.links.filter((item, itemIndex) => {
                                                                                return itemIndex !== idx;
                                                                            });

                                                                            this.setState({
                                                                                links: reducedArr
                                                                            });
                                                                        }}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: '20px', marginLeft: '350px' }}>
                                    {/* <Col md={1}>
                                <div className="text-center mt-2">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Status
                                    </Label>
                                </div>
                            </Col> */}
                                    <Col md={2}>
                                        {/* 
                                <select
                                    disabled={this.state.viewStatus}
                                    value={this.state.status}
                                    onChange={(e) => {
                                        this.setState({
                                            status: e.target.value,
                                        })
                                    }

                                    } className="form-select">
                                    <option value="Draft">Draft</option>
                                    <option value="Publish">Publish</option>
                                </select>*/}
                                    </Col>
                                    <Col md={2}>
                                        <button onClick={() => this.postLink()} disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                            Save
                                        </button>
                                    </Col>
                                </Row>
                            </Row>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

ExternalLinks.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab3: PropTypes.func,

    //t: PropTypes.any,
    history: PropTypes.any,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExternalLinks));

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </>
    );
};
