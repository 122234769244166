import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
//Import Breadcrumb
import axios from 'axios';
import Loader from '../../../components/Common/Loader';
import {API_URL} from '../../../constants/apiurl';

import {ReactSearchAutocomplete} from 'react-search-autocomplete';

import {Editor} from '@tinymce/tinymce-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {getExhibitions, updateExhibition} from '../../../store/exhibitions/actions';

import {searchPublishTags, searchPublishThemes} from '../../../helpers/Api/common_apis';

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class Informations extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            loading: false,
            title: '',
            vtitle: '',
            subtitle: '',
            vsubtitle: '',
            event_status: '',
            exhib_type_list: [],
            exhib_type: '',
            vexhib_type: '',
            start_date: '',
            end_date: '',

            paginateCountries: [],
            cstates: [],
            cities: [],

            selectedCountry: '',
            country: '',
            vcountry: '',

            selectedState: '',
            statee: '',
            vstate: '',

            city: '',
            vcity: '',

            opening_info: '',
            vopening_info: '',
            description: '',
            vdescription: '',
            id: '',
            status: '',
            statuslist: [],
            vstatus: '',
            selectedVenue: '',
            venueid: '',
            vvenueid: '',
            venuelist: [],

            tags: [],
            selectedTags: [],
            selectTag: '',

            themes: [],
            selectedThemes: [],
            selectTheme: '',
            vthemes: '',

            hided: false,
            interactionsAudio: false,
            interactionsVideo: false,
            interactionsAR: false,
            interactionsReaction: false,
            interactionsToken: false,

            iapAndroidId: '',
            iapIosId: ''
        };
        this.editorRef = null;
        this.editorRefDescrip = null;
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {
        this.getStatus();
        this.getExhibitionTypes();
        const {
            match: { params }
        } = this.props;

        if (params.id !== 'new') {
            this.getExhibitionInformation(params.id);
        }
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getExhibitionInformation(this.state.id);
        }
    }

    async handleOnSearchTags(string, results) {
        const response = await searchPublishTags(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ tags: response.data.content, searchFlag: true });
        }
    }

    async handleOnSearchThemes(string, results) {
        const response = await searchPublishThemes(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ themes: response.data.content, searchFlag: true });
        }
    }

    //------- Info handling ----
    handleOnSearch = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/venue?page=0&size=20&key=' + string, axiosconf)
            .then(response => {
                this.setState({
                    venuelist: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    getExhibitionTypes = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios.get(API_URL + '/tt/exhibition/types', axiosconf).then(response => {
            this.setState({
                exhib_type_list: response.data
            });
        });
    };

    getStatus = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({ loading: true });

        axios.get(API_URL + '/tt/exhibition/status', axiosconf).then(response => {
            this.setState({
                statuslist: response.data
            });
            this.getVenues();
        });
    };

    getVenues = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios.get(API_URL + '/tt/venue', axiosconf).then(response => {
            //console.log(" status ----> " + JSON.stringify(response.data))
            this.setState({
                venuelist: response.data.content,
                loading: false
            });
        });
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    getExhibitionInformation = id => {
        let obj = this.getAuthDetails();

        this.setState({ loading: true });

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/exhibition/' + id, axiosconf)
            .then(response => {
                var dd = response.data.startDate ? new Date(response.data.startDate).toISOString() : '';
                var dd1 = response.data.endDate ? new Date(response.data.endDate).toISOString() : '';

                this.setState({
                    loading: false,
                    title: response.data.title,
                    subtitle: response.data.subtitle,
                    exhib_type: response.data.exhibitionType,
                    status: response.data.status,
                    event_status: response.data.eventStatus,
                    selectedVenue: response.data.venue.name,
                    hided: response.data.hided,
                    interactionsAudio: response.data.interactionsAudio,
                    interactionsVideo: response.data.interactionsVideo,
                    interactionsAR: response.data.interactionsAR,
                    interactionsReaction: response.data.interactionsReaction,
                    interactionsToken: response.data.interactionsToken,
                    opening_info: response.data.opneningHourAndInfo,
                    description: response.data.description,
                    start_date: dd.substring(0, 10),
                    end_date: dd1.substring(0, 10),
                    country: response.data.venue.contact.country,
                    statee: response.data.venue.contact.state,
                    city: response.data.venue.contact.city,
                    id: response.data.id,
                    venueid: response.data.venue.id,
                    //tags: response.data.tags,
                    selectedTags: response.data.tags,
                    //themes: response.data.themes,
                    selectedThemes: response.data.themes,
                    iapAndroidId: response.data.iapAndroid,
                    iapIosId: response.data.iapIos
                });

                localStorage.setItem('exhibid', JSON.stringify({ id: response.data.id }));
            })
            .catch(e => {
                this.setState({ loading: false });
            });
    };
    submitForm = e => {
        e.preventDefault();
        console.log('testing... ' + this.state.opening_info);
        console.log('opening info checkiing... ' + this.editorRef.getContent({ format: 'text' }).trim().length);

        if (this.state.title === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vtitle: 'This field is required!' });
        }

        if (this.state.exhib_type === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vexhib_type: 'This field is required!' });
        }

        if (this.state.status === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vstatus: 'This field is required!' });
        }
        if (this.state.venueid === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vvenueid: 'This field is required!' });
        }
        if (this.state.title !== '' && this.state.exhib_type !== '' && this.state.status !== '' && this.state.venueid !== '') {
            let postParams = {
                title: this.state.title,
                subtitle: this.state.subtitle,
                type: 'Exhibition',
                exhibitionType: this.state.exhib_type,
                startDate: this.state.start_date ? new Date(this.state.start_date) : null,
                id: this.state.id,
                endDate: this.state.end_date ? new Date(this.state.end_date) : null,
                status: this.state.status,
                hided: this.state.hided,
                interactionsAudio: this.state.interactionsAudio,
                interactionsVideo: this.state.interactionsVideo,
                interactionsAR: this.state.interactionsAR,
                interactionsReaction: this.state.interactionsReaction,
                interactionsToken: this.state.interactionsToken,
                opneningHourAndInfo:
                    this.editorRef.getContent({ format: 'text' }).trim().length === 0
                        ? null
                        : "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                        this.state.opening_info.replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                        '</body></html>',
                // description: "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>"+this.state.description+"</body></html>",
                description:
                    this.editorRefDescrip.getContent({ format: 'text' }).trim().length === 0
                        ? null
                        : "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                        this.state.description.replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                        '</body></html>',
                venueid: this.state.venueid,
                tags: this.state.selectedTags,
                themes: this.state.selectedThemes,
                iapAndroid: this.state.iapAndroidId,
                iapIos: this.state.iapIosId
            };
            this.submitFormData(postParams);
        }
    };

    submitFormData = postParams => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        if (this.props.paramId === 'new') {
            if (this.state.id === '') {
                //this.getTagDetails();
                axios
                    .post(API_URL + '/tt/exhibition/for/' + this.state.venueid, postParams, axiosconf)
                    .then(response => {
                        this.getExhibitionInformation(response.data.id);
                        this.props.onGetExhibition();
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        localStorage.setItem('exhibid', JSON.stringify({ id: response.data.id }));

                        let passData = {
                            set_tab: '2',
                            new_status: false,
                            title: response.data.title,
                            showSAlert: true,
                            alertMessage: 'Created Successfully!'
                        };
                        this.props.parentCallbackTab1(passData);

                        // this.props.history.push('/articles')
                    })
                    .catch(err => console.log('error ' + JSON.stringify(err)));
            } else {
                this.props.onUpdateExhibition(postParams);
                this.getExhibitionInformation(this.state.id);
                this.props.onGetExhibition();
                localStorage.setItem('exhibid', JSON.stringify({ id: this.state.id }));

                let passData = {
                    set_tab: '2',
                    new_status: false,
                    exhibition_id: this.state.id,
                    title: postParams.title,
                    showSAlert: true,
                    alertMessage: 'Updated Successfully!'
                };
                this.props.parentCallbackTab1(passData);

                //this.props.history.push('/articles');
            }
        } else {
            this.props.onUpdateExhibition(postParams);
            this.getExhibitionInformation(this.state.id);
            this.props.onGetExhibition();
            localStorage.setItem('exhibid', JSON.stringify({ id: this.state.id }));

            let passData = {
                set_tab: '2',
                new_status: false,
                exhibition_id: this.state.id,
                title: postParams.title,
                showSAlert: true,
                alertMessage: 'Updated Successfully!'
            };

            this.props.parentCallbackTab1(passData);
            //this.props.history.push('/articles');
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4">
                                {this.props.editStatus && (
                                    <span className={this.state.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{this.state.status}</span>
                                )}
                            </CardTitle>

                            <Form className="form" onSubmit={e => this.submitForm(e)}>
                                <Row>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Title *</Label>
                                            <Input
                                                readOnly={this.props.viewStatus}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter Title"
                                                name="title"
                                                invalid={this.state.vtitle !== '' ? true : false}
                                                value={this.state.title}
                                                onChange={e => this.setState({ title: e.target.value, vtitle: '' })}
                                            />
                                            {this.state.vtitle !== '' && <span style={{ color: 'red' }}>{this.state.vtitle}</span>}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Subtitle </Label>
                                            <Input
                                                readOnly={this.props.viewStatus}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter Subtitle"
                                                name="subtitle"
                                                invalid={this.state.vsubtitle !== '' ? true : false}
                                                value={this.state.subtitle}
                                                onChange={e =>
                                                    this.setState({
                                                        subtitle: e.target.value,
                                                        vsubtitle: ''
                                                    })
                                                }
                                            />
                                            {this.state.vsubtitle !== '' && <span style={{ color: 'red' }}>{this.state.vsubtitle}</span>}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Event Status</Label>
                                            <br/>
                                            {this.state.event_status && (<Label><span style={{fontSize: "large"}} className={'bg-primary badge bg-primary'}>{this.state.event_status}</span></Label>)}
                                            {!this.state.event_status && (<Label><span style={{fontSize: "large"}} className={'bg-warning badge bg-warning'}>Undefined</span></Label>)}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Experience Type</Label>
                                            <select
                                                disabled={this.props.viewStatus}
                                                value={this.state.exhib_type}
                                                onChange={e =>
                                                    this.setState({
                                                        exhib_type: e.target.value,
                                                        vexhib_type: ''
                                                    })
                                                }
                                                className="form-select"
                                            >
                                                <option value="">Select Type</option>
                                                {this.state.exhib_type_list.map((item, idx) => (
                                                    <option key={idx} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                            {this.state.vexhib_type !== '' && <span style={{ color: 'red' }}>{this.state.vexhib_type}</span>}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Start Date</Label>
                                            <Input
                                                readOnly={this.props.viewStatus}
                                                type="date"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter Start Date"
                                                name="start_date"
                                                value={this.state.start_date}
                                                onChange={e =>
                                                    this.setState({
                                                        start_date: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">End Date</Label>
                                            <Input
                                                readOnly={this.props.viewStatus}
                                                type="date"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter Subtitle"
                                                name="end_date"
                                                value={this.state.end_date}
                                                onChange={e =>
                                                    this.setState({
                                                        end_date: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Hosting Venue *</Label>
                                            <ReactSearchAutocomplete
                                                styling={{
                                                    backgroundColor: '#fff',
                                                    zIndex: 2,
                                                    placeholderColor: '#939294',
                                                    borderRadius: '4px',
                                                    border: '1px solid #ced4da',
                                                    height: '35px',
                                                    fontSize: 16
                                                }}
                                                items={this.state.venuelist}
                                                //placeholder={"Type Venue here"}
                                                placeholder={this.state.selectedVenue === '' ? 'Type venue here' : this.state.selectedVenue}
                                                onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                                onHover={() => {}}
                                                onSelect={item => {
                                                    this.setState({
                                                        venueid: item.id,
                                                        selectedVenue: item.name,
                                                        vvenueid: '',
                                                        country: item.contact.country,
                                                        statee: item.contact.state,
                                                        city: item.contact.city
                                                    });
                                                }}
                                                onFocus={() => {}}
                                                formatResult={formatResult}
                                            />
                                            {this.state.vvenueid !== '' && <span style={{ color: 'red' }}>{this.state.vvenueid}</span>}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">Country </Label>

                                            <Input
                                                readOnly={true}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter Title"
                                                name="coutry"
                                                value={this.state.country}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">State</Label>
                                            <Input readOnly={true} type="text" className="form-control" id="formrow-firstname-Input" placeholder="" name="statee" value={this.state.statee} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">City</Label>
                                            <Input readOnly={true} type="text" className="form-control" id="formrow-firstname-Input" placeholder="" name="city" value={this.state.city} />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">Tags</Label>

                                            <ReactSearchAutocomplete
                                                styling={{
                                                    backgroundColor: '#fff',
                                                    zIndex: 2,
                                                    placeholder: 'Type Nationality',
                                                    placeholderColor: '#939294',

                                                    height: '35px',
                                                    fontSize: 16,
                                                    borderRadius: '4px',
                                                    border: '1px solid #ced4da'
                                                }}
                                                items={this.state.tags}
                                                placeholder={'Select Tags'}
                                                onSearch={(ss, ee) => this.handleOnSearchTags(ss, ee)}
                                                onHover={() => {}}
                                                onSelect={item => {
                                                    let found = this.state.selectedTags.find(a => a.tagId === item.id);
                                                    if (!found) {
                                                        this.setState({
                                                            selectTag: item.id,
                                                            vtag: ''
                                                        });
                                                        this.setState({
                                                            selectedTags: [
                                                                ...this.state.selectedTags,
                                                                {
                                                                    id: '',
                                                                    tagId: item.id,
                                                                    name: item.name
                                                                }
                                                            ]
                                                        });
                                                    }
                                                }}
                                                onFocus={() => {}}
                                                formatResult={formatResult}
                                            />
                                            {this.state.vtag !== '' && <span style={{ color: 'red' }}>{this.state.vtag}</span>}
                                            <br />
                                            {this.state.selectedTags.map((item, idx) => (
                                                <button
                                                    style={{ marginBottom: '5px', marginLeft: '5px' }}
                                                    key={idx}
                                                    onClick={e => {
                                                        const reducedArr = this.state.selectedTags.filter((item, itemIndex) => {
                                                            return itemIndex !== idx;
                                                        });

                                                        this.setState({
                                                            selectedTags: reducedArr
                                                        });
                                                    }}
                                                    type="button"
                                                    className="btn btn-light btn-label"
                                                >
                                                    <i className="dripicons-tag-delete label-icon "></i>
                                                    {item.name}
                                                </button>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">Themes</Label>

                                            <ReactSearchAutocomplete
                                                styling={{
                                                    backgroundColor: '#fff',
                                                    zIndex: 1,
                                                    placeholder: 'Type Nationality',
                                                    placeholderColor: '#939294',

                                                    height: '35px',
                                                    fontSize: 16,
                                                    borderRadius: '4px',
                                                    border: '1px solid #ced4da'
                                                }}
                                                items={this.state.themes}
                                                placeholder={'Select Themes'}
                                                onSearch={(ss, ee) => this.handleOnSearchThemes(ss, ee)}
                                                onHover={() => {}}
                                                onSelect={item => {
                                                    let found = this.state.selectedThemes.find(a => a.name === item.name);
                                                    if (!found) {
                                                        this.setState({
                                                            selectTheme: item.id,
                                                            vtheme: ''
                                                        });
                                                        this.setState({
                                                            selectedThemes: [
                                                                ...this.state.selectedThemes,
                                                                {
                                                                    id: '',
                                                                    themeId: item.id,
                                                                    name: item.name
                                                                }
                                                            ]
                                                        });
                                                    }
                                                }}
                                                onFocus={() => {}}
                                                formatResult={formatResult}
                                            />
                                            {this.state.vthemes !== '' && <span style={{ color: 'red' }}>{this.state.vthemes}</span>}
                                            <br />
                                            {this.state.selectedThemes.map((item, idx) => (
                                                <button
                                                    style={{ marginBottom: '5px', marginLeft: '5px' }}
                                                    key={idx}
                                                    onClick={e => {
                                                        const reducedArr = this.state.selectedThemes.filter((item, itemIndex) => {
                                                            return itemIndex !== idx;
                                                        });

                                                        this.setState({
                                                            selectedThemes: reducedArr
                                                        });
                                                    }}
                                                    type="button"
                                                    className="btn btn-light btn-label"
                                                >
                                                    <i className="dripicons-tag-delete label-icon "></i>
                                                    {item.name}
                                                </button>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={2}>
                                        <div className="mb-4">
                                        <Label htmlFor="formrow-firstname-Input">Visibility</Label>
                                        <FormGroup switch>
                                            <Input
                                                readOnly={this.state.viewStatus}
                                                type="switch"
                                                checked={this.state.hided}
                                                onChange={e =>
                                                    this.setState({
                                                        hided: !this.state.hided
                                                    })
                                                }
                                            />
                                            <Label check>Hide in Experience section</Label>
                                        </FormGroup>
                                            </div>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md={3}>
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">In-App Purchase ID (Android)</Label>
                                            <Input
                                                readOnly={this.props.viewStatus}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter Android IAP ID"
                                                name="iapAndroidId"
                                                value={this.state.iapAndroidId}
                                                onChange={e => this.setState({ iapAndroidId: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">In-App Purchase ID (iOS)</Label>
                                            <Input
                                                readOnly={this.props.viewStatus}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter iOS IAP ID"
                                                name="iapIosId"
                                                value={this.state.iapIosId}
                                                onChange={e => this.setState({ iapIosId: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <Label htmlFor="formrow-firstname-Input">Interactions</Label>

                                        <Row>
                                            <Col>
                                                <FormGroup switch>
                                                    <Input
                                                        readOnly={this.props.viewStatus}
                                                        type="switch"
                                                        checked={this.state.interactionsAudio}
                                                        onChange={e =>
                                                            this.setState({
                                                                interactionsAudio: !this.state.interactionsAudio
                                                            })
                                                        }
                                                    />
                                                    <Label check>Audio</Label>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup switch>
                                                    <Input
                                                        readOnly={this.props.viewStatus}
                                                        type="switch"
                                                        checked={this.state.interactionsVideo}
                                                        onChange={e =>
                                                            this.setState({
                                                                interactionsVideo: !this.state.interactionsVideo
                                                            })
                                                        }
                                                    />
                                                    <Label check>Video</Label>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup switch>
                                                    <Input
                                                        readOnly={this.props.viewStatus}
                                                        type="switch"
                                                        checked={this.state.interactionsAR}
                                                        onChange={e =>
                                                            this.setState({
                                                                interactionsAR: !this.state.interactionsAR
                                                            })
                                                        }
                                                    />
                                                    <Label check>AR</Label>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup switch>
                                                    <Input
                                                        readOnly={this.props.viewStatus}
                                                        type="switch"
                                                        checked={this.state.interactionsReaction}
                                                        onChange={e =>
                                                            this.setState({
                                                                interactionsReaction: !this.state.interactionsReaction
                                                            })
                                                        }
                                                    />
                                                    <Label check>Reaction</Label>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup switch>
                                                    <Input
                                                        readOnly={this.props.viewStatus}
                                                        type="switch"
                                                        checked={this.state.interactionsToken}
                                                        onChange={e =>
                                                            this.setState({
                                                                interactionsToken: !this.state.interactionsToken
                                                            })
                                                        }
                                                    />
                                                    <Label check>Token</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col md={6}>
                                        <Label htmlFor="formrow-firstname-Input">Opening Hours Info</Label>

                                        <Editor
                                            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                            cloudChannel="dev"
                                            onInit={(evt, editor) => (this.editorRef = editor)}
                                            //onBlur={(e) => handelBlur(editorRef)}
                                            value={this.state.opening_info}
                                            init={{
                                                height: 300,
                                                apply_source_formatting: true,
                                                menubar: false,
                                                plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                                paste_as_text: true,
                                                mobile: {
                                                    toolbar_drawer: 'floating'
                                                },
                                                toolbar:
                                                    'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                            }}
                                            onEditorChange={content => {
                                                //console.log("-------- "+JSON.stringify(content))
                                                this.setState({ opening_info: content });
                                            }}
                                        />

                                        {this.state.vopening_info !== '' && <span style={{ color: 'red' }}>{this.state.vopening_info}</span>}
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">Description</Label>

                                            {/*<textarea
                                    rows={18}
                                    type="textarea"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Enter Description"
                                    name="description"
                                    value={this.state.description}
                                    onChange={e =>
                                      this.setState({
                                        description: e.target.value,
                                      })
                                    }
                                  />*/}

                                            <Editor
                                                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                                cloudChannel="dev"
                                                onInit={(evt, editor) => (this.editorRefDescrip = editor)}
                                                value={this.state.description}
                                                init={{
                                                    height: 300,
                                                    menubar: false,
                                                    plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                                    paste_as_text: true,
                                                    mobile: {
                                                        toolbar_drawer: 'floating'
                                                    },
                                                    toolbar:
                                                        'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                                }}
                                                onEditorChange={content => this.setState({ description: content })}
                                            />

                                            {this.state.vdescription !== '' && <span style={{ color: 'red' }}>{this.state.vdescription}</span>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className="mb-4">
                                            <Label htmlFor="formrow-firstname-Input">Status *</Label>
                                            <select
                                                disabled={this.state.viewStatus}
                                                value={this.state.status}
                                                onChange={e => this.setState({ status: e.target.value, vstatus: '' })}
                                                className="form-select"
                                            >
                                                <option value="">Select Status</option>
                                                {this.state.statuslist.map((item, idx) => (
                                                    <option key={idx} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                            {this.state.vstatus !== '' && <span style={{ color: 'red' }}>{this.state.vstatus}</span>}
                                        </div>
                                    </Col>
                                </Row>

                                <br />
                                <br />
                                <div className="text-center mt-4">
                                    {this.state.id === '' ? (
                                        <button disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                            Save & Next
                                        </button>
                                    ) : (
                                        <button disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                            Save
                                        </button>
                                    )}
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                )}
            </React.Fragment>
        );
    }
}

Informations.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab1: PropTypes.func,

    //t: PropTypes.any,
    history: PropTypes.any,
    paramId: PropTypes.string,
    onGetExhibition: PropTypes.func,
    onUpdateExhibition: PropTypes.func,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
    onGetExhibition: () => dispatch(getExhibitions()),
    onUpdateExhibition: exhibitions => dispatch(updateExhibition(exhibitions))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Informations));

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </>
    );
};
