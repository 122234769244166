/* Tags */
export const GET_CCOMPANIES = 'GET_CCOMPANIES';
export const GET_CCOMPANIES_FAIL = 'GET_CCOMPANIES_FAIL';
export const GET_CCOMPANIES_SUCCESS = 'GET_CCOMPANIES_SUCCESS';

export const DELETE_CCOMPANIES = 'DELETE_CCOMPANIES';
export const DELETE_CCOMPANIES_SUCCESS = 'DELETE_CCOMPANIES_SUCCESS';
export const DELETE_CCOMPANIES_FAIL = 'DELETE_CCOMPANIES_FAIL';

export const UPDATE_CCOMPANIES_SUCCESS = 'UPDATE_CCOMPANIES_SUCCESS';
export const UPDATE_CCOMPANIES = 'UPDATE_CCOMPANIES';
//others------
