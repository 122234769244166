import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_THEMES, DELETE_THEMES, ADD_NEW_THEME, GET_THEME, UPDATE_THEMES } from './actionTypes';

import { getThemesSuccess, getThemesFail, deleteThemesFailed, deleteThemesSuccess, getTheme, getThemeFail, getThemeSuccess, updateTheme, updateThemeFailed, updateThemeSuccess } from './actions';

//Include Both Helper File with needed methods
import { getThemes, deleteThemes, getThemeByID, updateThemeByID } from '../../helpers/fakebackend_helper';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
function* fetchThemes() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getThemes, obj.access_token);

        yield put(getThemesSuccess(response.content));
    } catch (error) {
        yield put(getThemesFail(error));
    }
}

function* fetchThemesById({ payload: themes }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: themes };
        const response = yield call(getThemeByID, objj);

        yield put(getTheme(response));
    } catch (error) {
        yield put(getThemeFail(error));
    }
}

function* updateThemeById({ payload: themes }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: themes.id, theme: themes };
        const response11 = yield call(updateThemeByID, objj);
        const response = yield call(getThemes, obj.access_token);
        yield put(getThemesSuccess(response.content));
        //const response = yield call(getTags,  obj.access_token)
        //yield put(getThemesSuccess(response11))
    } catch (error) {
        yield put(updateThemeFailed(error));
    }
}

function* onDeleteThemes({ payload: themes }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: themes.id };
        const response11 = yield call(deleteThemes, objj);
        yield put(deleteThemesSuccess(response11));
    } catch (error) {
        yield put(deleteThemesFailed(error));
    }
}

function* themesSaga() {
    yield takeEvery(GET_THEMES, fetchThemes);
    yield takeEvery(DELETE_THEMES, onDeleteThemes);

    yield takeEvery(UPDATE_THEMES, updateThemeById);
    yield takeEvery(GET_THEME, fetchThemesById);
}

export default themesSaga;
