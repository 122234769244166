import axios from 'axios';
import accessToken from './jwt-token-access/accessToken';

//pass new generated access token here
const token = '';

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL + '/';
//const API_URL = "http://184.168.126.140:5000/"
//const API_URL = "https://www.mobile.particle.art/"

const axiosApi = axios.create({
    baseURL: API_URL
});

const API_URL_LOGIN = process.env.REACT_APP_API_AUTH_URL + '/';
//const API_URL_LOGIN = "http://184.168.126.140:8080/"
//const API_URL_LOGIN = "https://www.auth.particle.art/"

const axiosApiLogin = axios.create({
    baseURL: API_URL_LOGIN
});

axiosApiLogin.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
);
//axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
);

export async function get(url, config = {}) {
    return await axiosApi
        .get(url, { ...config })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            if (err.response.status) {
                //console.log("error.... "+JSON.stringify(err.response.status))
                return err.response.status;
            }
        });
}

export async function postlogin(url, data, config = {}) {
    return axiosApiLogin.post(url, data, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
    return axiosApi.post(url, { ...data }, { ...config }).then(response => response.data);
}

export async function put(url, data, config = {}) {
    return axiosApi.put(url, { ...data }, { ...config }).then(response => response.data);
}

export async function del(url, config = {}) {
    return await axiosApi.delete(url, { ...config }).then(response => response.data);
}
