import {Cancel} from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import PropTypes from 'prop-types';
import {Box, Button, DialogActions, DialogContent, Slider, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/cropImage';

const CropEasy = ({photoURL, parentCallback, ratio, shape, isPng}) => {
    const [photourl1, setPhotoURL] = useState('');
    const [defaultRatio, setDefaultRatio] = useState(ratio);
    const [internalCrop, setInternalCrop] = useState(ratio);
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [minZoom, setMinZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [naturalWidth, setNaturalWidth] = useState(0);
    const [naturalHeight, setNaturalHeight] = useState(0);
    const [cropWidth, setCropWidth] = useState(0);
    const [cropHeight, setCropHeight] = useState(0);
    const cropperContainerRef = useRef(null);
    const [cropperContainerWidth, setCropperContainerWidth] = useState(0.0);

    const [objectFit, setObjectFit] = useState("contain");
    const [restrictPosition, setRestrictPosition] = useState(true);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [disableCropper, setDisableCropper] = useState(false);
    const [zoomWithScroll, setZoomWithScroll] = useState(true);
    const [variantDefault, setVariantDefault] = useState('outlined');
    const [variantFill, setVariantFill] = useState('contained');
    const [variantFit, setVariantFit] = useState('contained');
    const [disabledDefault, setDisabledDefault] = useState(false);
    const [disabledFill, setDisabledFill] = useState(false);
    const [disabledFit, setDisabledFit] = useState(false);
    const [hasTransparency, setHasTransparency] = useState(isPng);

    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onDefaultSelected = () => {
        setButtonsStyle('default')
        setRestrictPosition(true)
        setZoomWithScroll(true)
        setInternalCrop(defaultRatio);
        setMinZoom(1)
        setZoom(1)
        setDisableCropper(false)
    };

    const onCropSizeChanged = (size) => {
        setCropWidth(size.width)
        setCropHeight(size.height)
    };

    const setChangedCrop = (location) => {
        if(!disableCropper)
            setCrop(location)
    };

    const onFillSelected = () => {
        setButtonsStyle('fill')
        setRestrictPosition(true)
        setZoomWithScroll(true)
        setInternalCrop(naturalWidth / naturalHeight);
        setMinZoom(1)
        setZoom(1)
        setDisableCropper(false)
    };

    const onFitSelected = () => {
        setButtonsStyle('fit')
        setRestrictPosition(false)
        setZoomWithScroll(false)
        setMinZoom(0.1)
        setZoom((naturalHeight / cropHeight) / (naturalWidth / cropWidth))
        setChangedCrop({x: 0, y: crop.y})
        setDisableCropper(true)
    };

    const onTrigger = url => {
        parentCallback(url);
    };

    const setButtonsStyle = (mode) => {
        switch (mode) {
            case 'default':
                setVariantDefault('outlined')
                setVariantFit('contained')
                setVariantFill('contained')
                setDisabledDefault(false)
                setDisabledFit(false)
                setDisabledFill(false)
                break;
            case 'fit':
                setVariantDefault('contained')
                setVariantFit('outlined')
                setVariantFill('contained')
                setDisabledDefault(false)
                setDisabledFit(false)
                setDisabledFill(true)
                break;
            case 'fill':
                setVariantDefault('contained')
                setVariantFit('contained')
                setVariantFill('outlined')
                setDisabledDefault(false)
                setDisabledFit(true)
                setDisabledFill(false)
                break;
        }
    };

    const cropImage = async () => {
        const urldata = await getCroppedImg(photoURL, croppedAreaPixels, rotation, hasTransparency);

        setPhotoURL(urldata);
        onTrigger(urldata);
    };

    useEffect(() => {
        setCropperContainerWidth(cropperContainerRef.current.offsetWidth);
    }, []);

    return (
        <>
            <DialogActions sx={{flexDirection: 'column', mx: 3, my: 2}}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap'
                    }}
                >
                    <Button variant="outlined" startIcon={<Cancel/>} onClick={() => onTrigger('')}>
                        Cancel
                    </Button>
                    <Button variant="contained" startIcon={<CropIcon/>} onClick={cropImage}>
                        Crop
                    </Button>
                </Box>
                <Box sx={{width: '100%', mb: 1}}>
                    <Box>
                        <Typography>Zoom: {zoomPercent(zoom)}</Typography>
                        <Slider disabled={disableCropper} valueLabelDisplay="auto" valueLabelFormat={zoomPercent} min={1} max={3} step={0.1}
                                value={zoom} onChange={(e, zoom) => setZoom(zoom)}/>
                    </Box>
                    <Box>
                        <Typography>Rotation: {rotation + '°'}</Typography>
                        <Slider valueLabelDisplay="auto" min={0} max={360} value={rotation}
                                onChange={(e, rotation) => setRotation(rotation)}/>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap'
                    }}
                >
                    <Button variant={variantDefault}
                            disabled={disabledDefault}
                            onClick={onDefaultSelected}>
                        Default
                    </Button>
                    <Button variant={variantFill}
                            disabled={disabledFill}
                            onClick={onFillSelected}>
                        Fill
                    </Button>

                    <Button
                        variant={variantFit}
                        disabled={disabledFit}
                        onClick={onFitSelected}>
                        Fit
                    </Button>
                </Box>
            </DialogActions>
            <DialogContent
                dividers
                sx={{
                    background: '#333',
                    position: 'relative',
                    height: 500,
                    width: 'auto',
                    minWidth: {sm: 550}
                }}
                ref={cropperContainerRef}
            >
                <Cropper
                    image={photoURL}
                    crop={crop}
                    zoom={zoom}
                    minZoom={minZoom}
                    rotation={rotation}
                    cropShape={shape}
                    aspect={internalCrop}
                    onZoomChange={setZoom}
                    objectFit={objectFit}
                    restrictPosition={restrictPosition}
                    onRotationChange={setRotation}
                    onCropChange={setChangedCrop}
                    onCropSizeChange={onCropSizeChanged}
                    onCropComplete={cropComplete}
                    zoomWithScroll={zoomWithScroll}
                    onMediaLoaded={(mediaSize) => {
                        // Adapt zoom based on media size to fit max height
                        setNaturalWidth(mediaSize.naturalWidth)
                        setNaturalHeight(mediaSize.naturalHeight)
                    }}
                    //cropSize={{width: '50%', height: '50%'}}
                />
            </DialogContent>
        </>
    );
};
CropEasy.propTypes = {
    photoURL: PropTypes.string,
    parentCallback: PropTypes.func,
    ratio: PropTypes.number,
    shape: PropTypes.string,
    isPng: PropTypes.bool
};
export default CropEasy;

const zoomPercent = value => {
    return `${Math.round(value * 100)}%`;
};
