import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback, Breadcrumb, BreadcrumbItem, Toast, ToastHeader } from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { API_URL } from '../../constants/apiurl';
import Loader from '../../components/Common/Loader';
import CropEasy from '../../components/Crop/CropEasy';
import { getThemes, updateTheme } from '../../store/themes/actions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDeeplink } from '../../helpers/Api/common_apis';
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class ThemesDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            vname: '',
            base64: '',
            nameColour: '#141c20',
            vnameColour: '',
            type: '',
            vtype: '',
            description: '',
            vdescription: '',
            descriptionColour: '#141c20',
            vdescriptionColor: '',
            backgroundColour: '#ffffff',
            vbackgroundColour: '',
            status: '',
            vstatus: '',
            id: '',
            rawDescription: '',
            types: [],
            statuslist: [],

            editStatus: false,
            viewStatus: false,
            setPageStatus: '',

            imagePreviewUrl: '',
            vimage: '',

            show: false,
            previewtype: 'Theme',
            b1: 'btn btn-primary w-sm',
            b2: 'btn btn-light w-sm',

            showAlert: false,
            alertMessage: '',
            successFlag: false,
            showAlertWarning: false,
            deeplink: '',
            copied: false
        };
    }

    componentDidMount() {
        this.getThemeTypes();
        this.getThemeStatus();
        const {
            match: { params }
        } = this.props;
        if (params.idd === 'view') {
            this.setState({ viewStatus: true, setPageStatus: 'View' });
        }

        if (params.idd === 'edit') {
            this.setState({ setPageStatus: 'Edit' });
        }

        if (params.idd === 'post') {
            this.setState({ setPageStatus: 'Create' });
        }

        if (params.id !== 'new') {
            this.setState({ editStatus: true });
            this.getThemeDetails(params.id);
            this.getDeeplinkData(params.id);
        } else {
            this.setState({ editStatus: false });
        }
    }
    async getDeeplinkData(id) {
        let postdata = {
            type: 'Theme',
            reference: id,
            contentType: null
        };
        const data = await getDeeplink(postdata);
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else {
            //console.log("------ "+JSON.stringify(data))
            this.setState({ deeplink: data.data.shortLink });
        }
    }
    onDragRgb(c1) {
        this.setState({ colorRgb: c1, nameColour: c1 });
    }
    onDragRgb2(c1) {
        this.setState({ colorRgb2: c1, descriptionColour: c1 });
    }
    onDragRgb3(c1) {
        this.setState({ colorRgb3: c1, backgroundColour: c1 });
    }
    getThemeTypes = () => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/theme/types', axiosconf)
            .then(response => {
                this.setState({
                    types: response.data
                });
            })
            .catch(e => {});
    };

    getThemeStatus = () => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/theme/status ', axiosconf)
            .then(response => {
                this.setState({
                    statuslist: response.data
                });
            })
            .catch(e => {});
    };

    getThemeDetails = id => {
        let obj = this.getAuthDetails();

        this.setState({ loading: true });

        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/theme/' + id, axiosconf)
            .then(response => {
                this.setState({
                    loading: false,
                    name: response.data.name,
                    base64: response.data.base64,
                    nameColour: response.data.nameColour,
                    type: response.data.type,
                    description: response.data.description,
                    descriptionColour: response.data.descriptionColour,
                    backgroundColour: response.data.backgroundColour,
                    status: response.data.status,
                    id: response.data.id,
                    imagePreviewUrl: API_URL + response.data.thumbnail
                });
            })
            .catch(e => {
                this.setState({ loading: false });
            });
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    submitForm(e) {
        e.preventDefault();
        //console.log(`Theme Name:` + JSON.stringify(this.state));
        if (this.state.name === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vname: 'Theme name is required!' });
        }

        if (this.state.description === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vdescription: 'Description is required' });
        }
        if (this.state.status === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vstatus: 'Status is required' });
        }
        if (this.state.type === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vtype: 'Type is required' });
        }
        if (this.state.imagePreviewUrl === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vimage: 'Image is required' });
        }

        if (this.state.name !== '' && this.state.description !== '' && this.state.status !== '' && this.state.type !== '' && this.state.imagePreviewUrl !== '') {
            console.log('okk');

            let obj = this.getAuthDetails();
            const {
                match: { params }
            } = this.props;
            this.setState({ loading: true });

            let axiosconf = axiosHeaderConfig(obj.access_token);

            let postParams = {
                name: this.state.name,
                base64: this.state.imagePreviewUrl,
                nameColour: this.state.nameColour,
                type: this.state.type,
                description: this.state.description,
                descriptionColour: this.state.descriptionColour,
                backgroundColour: this.state.backgroundColour,
                status: this.state.status,
                id: this.state.id
            };

            if (params.id === 'new') {
                //this.getTagDetails();
                axios
                    .post(API_URL + '/tt/theme', postParams, axiosconf)
                    .then(response => {
                        this.setState({ showAlert: true, alertMessage: 'Created Successfully', successFlag: true });
                        this.setState({ loading: false });

                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        this.getThemeDetails(response.data.id);
                    })
                    .catch(err => console.log('error ' + JSON.stringify(err)));
            } else {
                this.props.onUpdateThemes(postParams);
                //this.props.history.push("/web/themes");

                this.setState({ showAlert: true, alertMessage: 'Updated Successfully', successFlag: true });
                this.setState({ loading: false });

                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.getThemeDetails(this.state.id);
            }
        }
    }

    onInputChange = e => {
        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

        if (size > 300) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure image size is less than 300 KB',
                selectedUploadtype: '',
                vselectedUploadtype: ''
            });
            e.target.value = null;
        } else {
            // convert image file to base64 string
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64: reader.result, show: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
                //this.setState({imagePreviewUrl: file})
            }
        }
    };

    handleCallback = childData => {
        this.setState({
            imagePreviewUrl: childData,
            show: false,
            previewtype: 'Image',
            b2: 'btn btn-primary w-sm',
            b1: 'btn btn-light w-sm',
            vimage: ''
        });
    };

    render() {
        const { name, nameColour, description, descriptionColour, backgroundColour, status, type } = this.state;
        const {
            match: { params }
        } = this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Theme Details</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <CardTitle className="mb-1">Theme</CardTitle>
                        <Breadcrumb>
                            <BreadcrumbItem tag="p" href="#">
                                Theme List
                            </BreadcrumbItem>
                            <BreadcrumbItem tag="p" href="#" active>
                                {this.state.setPageStatus} Theme
                            </BreadcrumbItem>
                        </Breadcrumb>
                        {this.state.showAlertWarning ? (
                            <SweetAlert warning title="" onConfirm={() => this.setState({ showAlertWarning: false })}>
                                {this.state.alertMessage}
                            </SweetAlert>
                        ) : null}

                        {this.state.showAlert ? (
                            <SweetAlert
                                success={this.state.successFlag}
                                title=""
                                onConfirm={() => {
                                    this.setState({ showAlert: false });
                                    if (this.state.setPageStatus !== 'Edit') {
                                        this.props.history.push('/web/themes');
                                    } else {
                                        this.getThemeDetails(this.state.id);
                                    }
                                }}
                            >
                                {this.state.alertMessage}
                            </SweetAlert>
                        ) : null}

                        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: '1005' }}>
                            <Toast isOpen={this.state.copied}>
                                <ToastHeader toggle={() => this.setState({ copied: !this.state.copied })}>Copied!</ToastHeader>
                            </Toast>
                        </div>
                        {params.id !== 'new' && (
                            <div style={{ display: 'flex', position: 'absolute', top: '150px', right: '30px' }}>
                                <Label htmlFor="formrow-firstname-Input" style={{ marginTop: '7px', marginRight: '10px' }}>
                                    Deep Link
                                </Label>
                                <div style={{ width: '350px' }}>
                                    <Input
                                        readOnly={true}
                                        ref={textarea => (this.textArea = textarea)}
                                        type="readonly"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        name="deeplink"
                                        value={this.state.deeplink}
                                    />
                                </div>
                                <CopyToClipboard text={this.state.deeplink} onCopy={() => this.setState({ copied: true })}>
                                    <button>
                                        <i className="dripicons-copy" />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        )}

                        {this.state.loading ? (
                            <Loader color={'green'} loading={this.state.loading} />
                        ) : (
                            <Card>
                                <div style={{ margin: '15px' }} className="position-absolute top-0 end-0">
                                    <button
                                        onClick={() => {
                                            this.props.onGetThemes();
                                            this.props.history.push('/web/themes');
                                        }}
                                        type="button"
                                        className="btn btn-secondary"
                                    >
                                        <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                        Back
                                    </button>
                                </div>
                                {this.state.show ? (
                                    <CropEasy ratio={1 / 1.57} photoURL={this.state.base64} parentCallback={this.handleCallback} />
                                ) : (
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            {this.state.editStatus && <span className={status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{status}</span>}
                                        </CardTitle>
                                        <br />
                                        <Row>
                                            <Col lg={6}>
                                                <div className="text-center mt-4">
                                                    <div className="btn-group btn-group-example mb-3" role="group">
                                                        <button
                                                            type="button"
                                                            className={this.state.b1}
                                                            onClick={() =>
                                                                this.setState({
                                                                    previewtype: 'Theme',
                                                                    b1: 'btn btn-primary w-sm',
                                                                    b2: 'btn btn-light w-sm'
                                                                })
                                                            }
                                                        >
                                                            Text
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className={this.state.b2}
                                                            onClick={() =>
                                                                this.setState({
                                                                    previewtype: 'Image',
                                                                    b2: 'btn btn-primary w-sm',
                                                                    b1: 'btn btn-light w-sm'
                                                                })
                                                            }
                                                        >
                                                            Image
                                                        </button>
                                                    </div>
                                                </div>

                                                <div style={{ marginTop: '-100px' }} className="device device-iphone-8 device-spacegray">
                                                    <div className="device-frame">
                                                        {this.state.previewtype === 'Theme' && (
                                                            <div style={{ marginTop: '50px' }}>
                                                                <div style={{ margin: '10px' }}>
                                                                    <Card
                                                                        style={{
                                                                            padding: '15px',
                                                                            backgroundColor: this.state.backgroundColour
                                                                        }}
                                                                    >
                                                                        <b style={{ color: this.state.nameColour }}>{this.state.name}</b>
                                                                        <p
                                                                            style={{
                                                                                color: this.state.descriptionColour,
                                                                                marginTop: '10px',
                                                                                whiteSpace: 'pre-wrap'
                                                                            }}
                                                                        >
                                                                            {this.state.description}
                                                                        </p>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {this.state.previewtype === 'Image' && (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '85%',
                                                                    backgroundImage: 'url(' + this.state.imagePreviewUrl + ')',
                                                                    backgroundSize: 'cover'
                                                                }}
                                                            ></div>
                                                        )}
                                                    </div>
                                                    <div className="device-stripe"></div>
                                                    <div className="device-header"></div>
                                                    <div className="device-sensors"></div>
                                                    <div className="device-btns"></div>
                                                    <div className="device-power"></div>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <Form className="form" onSubmit={e => this.submitForm(e)}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Select Background color</Label>

                                                                <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                                                    <input
                                                                        className="form-control form-control-color mw-100"
                                                                        type="color"
                                                                        id="example-color-input"
                                                                        value={this.state.backgroundColour}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                backgroundColour: e.target.value
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <div>
                                                                    <Label htmlFor="formFile" className="form-label">
                                                                        Select Image
                                                                    </Label>
                                                                    <input
                                                                        disabled={this.state.viewStatus}
                                                                        title="Hello...."
                                                                        name="base64"
                                                                        className="form-control"
                                                                        id="formFile"
                                                                        type="file"
                                                                        accept=".jpg,.jpeg,.png"
                                                                        onChange={e => this.onInputChange(e)}
                                                                    />
                                                                </div>
                                                                {this.state.vimage !== '' && <span style={{ color: 'red' }}>{this.state.vimage}</span>}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Theme name *</Label>
                                                                <Input
                                                                    readOnly={this.state.viewStatus}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-themename-Input"
                                                                    placeholder="Enter Theme name"
                                                                    name="name"
                                                                    invalid={this.state.vname !== '' ? true : false}
                                                                    value={name}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            name: e.target.value,
                                                                            vname: ''
                                                                        })
                                                                    }
                                                                />
                                                                {this.state.vname !== '' && <span style={{ color: 'red' }}>{this.state.vname}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Select Theme name color</Label>

                                                                <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                                                    <input
                                                                        className="form-control form-control-color mw-100"
                                                                        type="color"
                                                                        id="example-color-input"
                                                                        value={this.state.nameColour}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                nameColour: e.target.value
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Select Description color</Label>

                                                                <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                                                    <input
                                                                        className="form-control form-control-color mw-100"
                                                                        type="color"
                                                                        id="example-color-input"
                                                                        value={this.state.descriptionColour}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                descriptionColour: e.target.value
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Description *</Label>
                                                                <Input
                                                                    readOnly={this.state.viewStatus}
                                                                    type="textarea"
                                                                    rows="6"
                                                                    className="form-control"
                                                                    id="basicpill-expiration-input13"
                                                                    placeholder="Enter Description"
                                                                    value={description}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            description: e.target.value,
                                                                            vdescription: ''
                                                                        })
                                                                    }
                                                                    name="description"
                                                                    invalid={this.state.vdescription !== '' ? true : false}
                                                                />
                                                                {this.state.vdescription !== '' && <span style={{ color: 'red' }}>{this.state.vdescription}</span>}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Status *</Label>
                                                                <select
                                                                    value={status}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            status: e.target.value,
                                                                            vstatus: ''
                                                                        })
                                                                    }
                                                                    className="form-select"
                                                                >
                                                                    <option value="">Select Status</option>
                                                                    {this.state.statuslist.map((item, idx) => (
                                                                        <option key={idx} value={item}>
                                                                            {item}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {this.state.vstatus !== '' && <span style={{ color: 'red' }}>{this.state.vstatus}</span>}
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">Theme Type *</Label>
                                                                <select
                                                                    value={type}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            type: e.target.value,
                                                                            vtype: ''
                                                                        })
                                                                    }
                                                                    className="form-select"
                                                                >
                                                                    <option value="">Select Type</option>
                                                                    {this.state.types.map((item, idx) => (
                                                                        <option key={idx} value={item}>
                                                                            {item}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {this.state.vtype !== '' && <span style={{ color: 'red' }}>{this.state.vtype}</span>}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <div className="text-center mt-4">
                                                        <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                )}
                            </Card>
                        )}
                    </Container>
                    {/* container-fluid */}
                </div>
            </React.Fragment>
        );
    }
}

ThemesDetails.propTypes = {
    themes: PropTypes.array,
    className: PropTypes.any,
    match: PropTypes.object,
    onGetThemes: PropTypes.func,
    onUpdateThemes: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({ theme }) => ({
    theme: theme
});

const mapDispatchToProps = dispatch => ({
    onGetThemes: () => dispatch(getThemes()),
    onUpdateThemes: theme => dispatch(updateTheme(theme))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ThemesDetails));
