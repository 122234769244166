import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_ARTWORKS, DELETE_ARTWORKS, UPDATE_ARTWORKS } from './actionTypes';

import { getArtWorksSuccess, getArtWorksFail, deleteArtWorksSuccess, deleteArtWorksFail } from './actions';

//Include Both Helper File with needed methods
import { getArtWorks, updateArtworksByID, deleteArtWorks } from '../../helpers/fakebackend_helper';
const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

function* fetchArtWorks() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getArtWorks, obj.access_token);

        yield put(getArtWorksSuccess(response.content));
    } catch (error) {
        yield put(getArtWorksFail(error));
    }
}
function* updateArtworksById({ payload: artworks }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: artworks.id, artworks: artworks };

        const response11 = yield call(updateArtworksByID, objj);

        const response = yield call(getArtWorks, obj.access_token);

        yield put(getArtWorksSuccess(response.content));
    } catch (error) {
        yield put(getArtWorksFail(error));
    }
}

function* onDeleteArtworks({ payload: artworks }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: artworks.id };

        const response11 = yield call(deleteArtWorks, objj);
        //  const response = yield call(getArtWorks, obj.access_token)
        yield put(deleteArtWorksSuccess(response11));
    } catch (error) {
        yield put(deleteArtWorksFail(error));
    }
}

function* artWorksSaga() {
    yield takeEvery(GET_ARTWORKS, fetchArtWorks);
    yield takeEvery(UPDATE_ARTWORKS, updateArtworksById);
    yield takeEvery(DELETE_ARTWORKS, onDeleteArtworks);
}

export default artWorksSaga;
