import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_TAGS, GET_TAG, GET_TAG_FAIL, DELETE_TAG, GET_USER_PROFILE, ADD_NEW_USER, UPDATE_USER, UPDATE_TAG } from './actionTypes';

import {
    getArtWorksSuccess,
    getArtWorksFail,
    deleteArtWorksSuccess,
    deleteArtWorksFail,
    getUserProfileSuccess,
    getUserProfileFail,
    addUserFail,
    addUserSuccess,
    updateUserSuccess,
    updateUserFail,
    getTagsSuccess,
    getTagsFail,
    getTag,
    getTagFail,
    deletTagsFailed,
    updateTagFailed,
    deleteTagSuccess
} from './actions';

//Include Both Helper File with needed methods
import { getTags, deleteTags, getTagById, updateTagbyId } from '../../helpers/fakebackend_helper';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
function* fetchTags() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getTags, obj.access_token);
        yield put(getTagsSuccess(response.content));
    } catch (error) {
        yield put(getTagsFail(error));
    }
}

function* fetchTagById({ payload: tags }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: tags.payload.id };

        const response = yield call(getTagById, objj);
        // console.log("tags....>>>> "+JSON.stringify(response))
        yield put(getTag(response));
    } catch (error) {
        yield put(getTagFail(error));
    }
}

function* updateTagById({ payload: tags }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: tags.id, tag: tags };
        const response11 = yield call(updateTagbyId, objj);
        const response = yield call(getTags, obj.access_token);
        yield put(getTagsSuccess(response.content));
    } catch (error) {
        yield put(updateTagFailed(error));
    }
}

function* onDeleteTags({ payload: tags }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: tags.id };
        const response11 = yield call(deleteTags, objj);
        yield put(deleteTagSuccess(response11));
    } catch (error) {
        yield put(deletTagsFailed(error));
    }
}

function* tagsSaga() {
    yield takeEvery(GET_TAGS, fetchTags);
    yield takeEvery(GET_TAG, fetchTagById);
    yield takeEvery(DELETE_TAG, onDeleteTags);
    yield takeEvery(UPDATE_TAG, updateTagById);
}

export default tagsSaga;
