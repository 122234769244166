import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_VENUES, DELETE_VENUES, UPDATE_VENUES } from './actionTypes';

import { getVenuesSuccess, getVenuesFail, deleteVenuesFailed, deleteVenuesSuccess } from './actions';

//Include Both Helper File with needed methods
import { getVenues, deleteVenues, updateVenuesByID, postLogout } from '../../helpers/fakebackend_helper';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

function* fetchVenues() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getVenues, obj.access_token);

        if (response === 401) {
            //localStorage.removeItem("authUser")
            const response = yield call(postLogout, obj.access_token);
        }

        yield put(getVenuesSuccess(response.content));
    } catch (error) {
        yield put(getVenuesFail(error));
    }
}
function* onDeleteVenues({ payload: venues }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: venues.id };
        const response11 = yield call(deleteVenues, objj);
        yield put(deleteVenuesSuccess(response11));
    } catch (error) {
        yield put(deleteVenuesFailed(error));
    }
}

function* updateVenueById({ payload: venues }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: venues.id, venues: venues };

        const response11 = yield call(updateVenuesByID, objj);

        const response = yield call(getVenues, obj.access_token);
        yield put(getVenuesSuccess(response.content));
    } catch (error) {
        yield put(getVenuesFail(error));
    }
}

function* venuesSaga() {
    yield takeEvery(GET_VENUES, fetchVenues);
    yield takeEvery(UPDATE_VENUES, updateVenueById);
    yield takeEvery(DELETE_VENUES, onDeleteVenues);
}

export default venuesSaga;
