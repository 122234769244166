import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import Loader from '../../../components/Common/Loader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Input, Label, Row } from 'reactstrap';
import { updateExhibition } from '../../../store/exhibitions/actions';
import { API_URL } from '../../../constants/apiurl';

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class Insights extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,
            insightsTitle: '',
            insightsDescription1: '',
            insightsDescription2: ''
        };
    }

    componentDidMount() {
        this.getExhibitionInformation();
    }

    componentDidUpdate(previousProps) {
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getExhibitionInformation();
        }
    }

    getExhibitionInformation = () => {
        let eid = this.getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({ loading: true });
            let obj = this.getAuthDetails();
            let axiosconf = axiosHeaderConfig(obj.access_token);
            axios
                .get(API_URL + '/tt/exhibition/' + eid.id, axiosconf)
                .then(response => {
                    this.setState({
                        insightsTitle: response.data.insightsTitle,
                        insightsDescription1: response.data.insightsDescription1,
                        insightsDescription2: response.data.insightsDescription2,
                        loading: false
                    });
                })
                .catch(e => {
                    if (e.response.status === 500) {
                        this.setState({
                            loading: false
                        });
                    }
                    this.setState({ loading: false });
                });
        }
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    getExhibId = () => {
        if (localStorage.getItem('exhibid')) {
            const obj = JSON.parse(localStorage.getItem('exhibid'));
            return obj;
        }
    };

    convertToText(htmltext) {
        // Create a new div element
        var tempDivElement = document.createElement('html');

        // Set the HTML content with the given value
        tempDivElement.innerHTML = htmltext;

        // Retrieve the text property of the element
        return tempDivElement.textContent || tempDivElement.innerText || '';
    }

    postInsights = async () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        let eid = this.getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            var postData = {
                insightsTitle: this.state.insightsTitle,
                insightsDescription1:
                    "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                    this.state.insightsDescription1.replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                    '</body></html>',
                insightsDescription2:
                    "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                    this.state.insightsDescription2.replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                    '</body></html>'
            };

            this.setState({
                loading: true
            });

            axios
                .patch(API_URL + '/tt/exhibition/modify/' + eid.id, postData, axiosconf)
                .then(() => {
                    this.setState({
                        showAlertSuccess: true,
                        successMessage: 'Updated Successfully!'
                    });
                })
                .catch(err => console.log('error ' + JSON.stringify(err)))
                .finally(() =>
                    this.setState({
                        loading: false
                    })
                );
        }
    };

    render() {
        return (
            <div>
                {this.state.showAlert ? (
                    <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showAlertSuccess ? (
                    <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}>
                        {this.state.successMessage}
                    </SweetAlert>
                ) : null}

                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <div>
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={12}>
                                <h4>Insights</h4>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Title</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Enter Title"
                                        name="insightsTitle"
                                        value={this.state.insightsTitle}
                                        onChange={e => this.setState({ insightsTitle: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Description 1</Label>
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                        cloudChannel="dev"
                                        value={this.state.insightsDescription1}
                                        init={{
                                            height: 300,
                                            apply_source_formatting: true,
                                            menubar: false,
                                            plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                            paste_as_text: true,
                                            mobile: {
                                                toolbar_drawer: 'floating'
                                            },
                                            toolbar:
                                                'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                        }}
                                        onEditorChange={content => this.setState({ insightsDescription1: content })}
                                    />
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Description 2</Label>
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                        cloudChannel="dev"
                                        value={this.state.insightsDescription2}
                                        init={{
                                            height: 300,
                                            apply_source_formatting: true,
                                            menubar: false,
                                            plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                            paste_as_text: true,
                                            mobile: {
                                                toolbar_drawer: 'floating'
                                            },
                                            toolbar:
                                                'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                        }}
                                        onEditorChange={content => this.setState({ insightsDescription2: content })}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className="text-center mt-4">
                            <button onClick={() => this.postInsights()} disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Insights.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    activeTab: PropTypes.string,

    onUpdateExhibition: PropTypes.func
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    onUpdateExhibition: exhibitions => dispatch(updateExhibition(exhibitions))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Insights));
