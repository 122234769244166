/* Artworks */
export const GET_ARTWORKS = 'GET_ARTWORKS';
export const GET_ARTWORKS_FAIL = 'GET_ARTWORKS_FAIL';
export const GET_ARTWORKS_SUCCESS = 'GET_ARTWORKS_SUCCESS';

export const DELETE_ARTWORKS = 'DELETE_ARTWORKS';
export const DELETE_ARTWORKS_SUCCESS = 'DELETE_ARTWORKS_SUCCESS';
export const DELETE_ARTWORKS_FAIL = 'DELETE_ARTWORKS_FAIL';

export const UPDATE_ARTWORKS = 'UPDATE_ARTWORKS';
//others------

/* USERS PROFILE */
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const ADD_NEW_USER = 'ADD_NEW_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
