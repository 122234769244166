import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Alert, Card, CardBody, Col, Container, Row, Label } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Social Media Imports
import { GoogleLogin } from 'react-google-login';
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

//Import config
import { facebook, google } from '../../config';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// actions
import { apiError, loginUser, socialLogin } from '../../store/actions';

// import images
import { ReactComponent as LoginLogo } from '../../assets/icons/login_logo/login.svg';

//background
import ParticleBackground from '../../components/ParticleBackground';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.apiError('');
    }

    signIn = (res, type) => {
        const { socialLogin } = this.props;
        if (type === 'google' && res) {
            const postData = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                token: res.tokenObj.access_token,
                idToken: res.tokenId
            };
            socialLogin(postData, this.props.history, type);
        } else if (type === 'facebook' && res) {
            const postData = {
                name: res.name,
                email: res.email,
                token: res.accessToken,
                idToken: res.tokenId
            };
            socialLogin(postData, this.props.history, type);
        }
    };

    //handleGoogleLoginResponse
    googleResponse = response => {
        this.signIn(response, 'google');
    };

    //handleTwitterLoginResponse
    twitterResponse = () => {};

    //handleFacebookLoginResponse
    facebookResponse = response => {
        this.signIn(response, 'facebook');
    };

    render() {
        return (
            <React.Fragment>
                <ParticleBackground />
                {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div> */}
                <div className="account-pages">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden" style={{ height: '100%' }}>
                                    {/* <div className="bg-primary bg-soft"> */}

                                    <Row>
                                        <Col className="col-12">
                                            <LoginLogo
                                                style={{
                                                    position: 'absolute',
                                                    top: '12%',
                                                    left: '40%'
                                                }}
                                            />
                                            <div className="auth-header">
                                                <h5>Welcome!</h5>
                                                <p>Login to continue with Particle</p>
                                            </div>
                                        </Col>
                                        {/*<Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
    </Col>*/}
                                    </Row>
                                    {/* </div> */}
                                    <CardBody className="pt-4">
                                        {/*<div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
  </div>
  </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
  </div>*/}
                                        <div className="p-2">
                                            {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    email: (this.state && this.state.email) || '',
                                                    password: (this.state && this.state.password) || ''
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    email: Yup.string().required('Please Enter Username'),
                                                    password: Yup.string().required('Please Enter Valid Password')
                                                })}
                                                onSubmit={values => {
                                                    this.props.loginUser(values, this.props.history);
                                                }}
                                            >
                                                {({ errors, status, touched }) => (
                                                    <Form className="form-horizontal">
                                                        <div className="mb-3">
                                                            <Label for="email" className="form-label">
                                                                Email/User ID
                                                            </Label>
                                                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label for="password" className="form-label">
                                                                Password
                                                            </Label>
                                                            <div className="input-group auth-pass-inputgroup">
                                                                <Field
                                                                    name="password"
                                                                    type="password"
                                                                    autoComplete="true"
                                                                    className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                                                                />
                                                                {/*<button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="mdi mdi-eye-outline"></i>
                                </button>*/}
                                                            </div>
                                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                        </div>

                                                        {/*<div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                                </div>*/}

                                                        <div className="mt-3 d-grid">
                                                            <button className="btn btn-primary btn-block" type="submit">
                                                                Login
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                        <div className="mt-5 text-center">
                                            {/*<p>
                    Don&apos;t have an account ?
                    <Link to="register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                    </p>*/}
                                            <p>© {new Date().getFullYear()} Particle.</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

Login.propTypes = {
    apiError: PropTypes.any,
    error: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
    socialLogin: PropTypes.func
};

const mapStateToProps = state => {
    const { error } = state.Login;
    return { error };
};

export default withRouter(connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login));
