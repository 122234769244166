import axios from 'axios';
import Loader from '../../../components/Common/Loader';
import {getDeeplink} from '../../../helpers/Api/common_apis';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Label, Row, Table, Input} from 'reactstrap';
import CropEasyParticle from '../../../components/Crop/CropEasy';
import {API_URL} from '../../../constants/apiurl';
import {IconButton} from '@mui/material';
import QrCode2 from '@mui/icons-material/QrCode2';

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

const getExhibId = () => {
    if (localStorage.getItem('exhibid')) {
        const obj = JSON.parse(localStorage.getItem('exhibid'));
        return obj;
    }
};

class PhysicalPoint extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,

            id: '',
            artworks: [],
            artwork: '',
            contents: [],
            content: '',
            creators: [],
            creator: '',
            venues: [],
            venue: '',
            physicalPoints: [],
            externalLink: '',
            number: '',
            note: '',
            alternativeUrl: null
        };

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getArtworks();
        this.getContents();
        this.getCreators();
        this.getVenues();
        this.getPhysicalPoints();
    }

    componentDidUpdate(previousProps) {
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getArtworks();
            this.getContents();
            this.getCreators();
            this.getVenues();
            this.getPhysicalPoints();
        }
    }

    getArtworks = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id + '/artwork/mapping', axiosconf)
            .then(response => {
                this.setState({artworks: response.data.map(a => a.artwork)});
            })
            .catch(e => console.log('GET ARTWORKS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    getCreators = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id + '/creator/mapping', axiosconf)
            .then(response => {
                this.setState({creators: response.data.map(c => c.creator)});
            })
            .catch(e => console.log('GET CREATORS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    getContents = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id + '/content/mapping', axiosconf)
            .then(response => {
                this.setState({contents: response.data.map(c => c.content)});
            })
            .catch(e => console.log('GET CONTENTS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    getVenues = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id, axiosconf)
            .then(response => {
                this.setState({venues: [response.data.venue]});
            })
            .catch(e => console.log('GET VENUES ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    getPhysicalPoints = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id + '/physical/point', axiosconf)
            .then(response => {
                this.setState({physicalPoints: response.data, loading: false});
            })
            .catch(e => console.log('GET PHYSICAL POINTS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    createPhysicalPoint = async () => {
        const postData = {
            id: this.state.id || null,
            artworkId: this.state.artwork ? +this.state.artwork : undefined,
            creatorId: this.state.creator ? +this.state.creator : undefined,
            contentId: this.state.content ? +this.state.content : undefined,
            venueId: this.state.venue ? +this.state.venue : undefined,
            externalLink: this.state.externalLink ? this.state.externalLink : undefined,
            number: this.state.number ? +this.state.number : undefined,
            note: this.state.note ? this.state.note : undefined,
            alternativeUrl: this.state.alternativeUrl ? this.state.alternativeUrl : undefined
        };

        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        try {
            const create = await axios.post(API_URL + '/tt/exhibition/' + eid.id + '/physical/point', postData, axiosconf);
            const pp = {
                id: create.data.id,
                artworkId: create.data.artwork ? create.data.artwork.id : undefined,
                creatorId: create.data.creator ? create.data.creator.id : undefined,
                contentId: create.data.content ? create.data.content.id : undefined,
                venueId: create.data.venue ? create.data.venue.id : undefined,
                externalLink: create.data.externalLink,
                number: create.data.number,
                note: create.data.note,
                alternativeUrl: create.data.alternativeUrl ? create.data.alternativeUrl : undefined
            };
            pp.deepLink = await this.getDeeplinkData(pp.id, pp.alternativeUrl);

            await axios.post(API_URL + '/tt/exhibition/' + eid.id + '/physical/point', pp, axiosconf);

            this.setState({
                id: '',
                artworkId: '',
                creatorId: '',
                contentId: '',
                venueId: '',
                externalLink: '',
                number: '',
                note: '',
                alternativeUrl: undefined
            });

            this.getPhysicalPoints();
        } catch (e) {
            console.log('CREATE PHYSICAL POINTS ERROR', e);
        } finally {
            this.setState({loading: false});
        }
    };

    async getDeeplinkData(id, alternativeUrl) {
        let postdata = {
            type: 'ExhibitionPhysicalPoint',
            reference: id,
            contentType: null,
            alternativeUrl: alternativeUrl ? alternativeUrl : null,
            dynamicLinkInfo: {
                navigationInfo: {
                    enableForcedRedirect: true
                }
            }
        };

        const data = await getDeeplink(postdata);
        if (data.error) {
            console.log('error ---- ' + JSON.stringify(data));
            return '';
        }

        return data.data.shortLink;
    }

    removePhysicalPoint = id => {
        if (confirm('Are you sure?')) {
            let eid = getExhibId();
            if (eid.id === undefined || eid === undefined) {
                return;
            }

            this.setState({loading: true});

            let obj = getAuthDetails();
            let axiosconf = axiosHeaderConfig(obj.access_token);
            axios
                .delete(API_URL + '/tt/exhibition/' + eid.id + '/physical/point/' + id, axiosconf)
                .then(() => {
                    this.setState({
                        showAlertSuccess: true,
                        successMessage: 'Delete Successfully!'
                    });

                    this.getPhysicalPoints();
                })
                .catch(e => console.log('DELETE PHYSICAL POINTS ERROR', e))
                .finally(() => this.setState({loading: false}));
        }
    };

    render() {
        return (
            <div>
                <h4>Physical Point</h4>
                {this.state.showAlert ? (
                    <SweetAlert warning title={''} onConfirm={() => this.setState({showAlert: false})}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showAlertSuccess ? (
                    <SweetAlert success title={''} onConfirm={() => this.setState({showAlertSuccess: false})}>
                        {this.state.successMessage}
                    </SweetAlert>
                ) : null}

                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading}/>
                ) : (
                    <div>
                        {this.state.showCropEditor ? (
                            <CropEasyParticle ratio={1} photoURL={this.state.image} parentCallback={this.handleCallback}
                                              shape="rect"/>
                        ) : (
                            <Row>
                                <Row style={{marginTop: '20px'}}>
                                    <Col md={2}>
                                        <Label htmlFor="formrow-firstname-Input">Artworks</Label>
                                        <select
                                            readOnly={this.state.viewStatus}
                                            value={this.state.artwork}
                                            onChange={e => this.setState({
                                                artwork: e.target.value,
                                                creator: '',
                                                content: '',
                                                venue: '',
                                                externalLink: ''
                                            })}
                                            className="form-select"
                                        >
                                            <option value="">Select artwork</option>
                                            {this.state.artworks.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name} - {item.creator.name}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col md={2}>
                                        <Label htmlFor="formrow-firstname-Input">Creators</Label>
                                        <select
                                            readOnly={this.state.viewStatus}
                                            value={this.state.creator}
                                            onChange={e => this.setState({
                                                creator: e.target.value,
                                                artwork: '',
                                                content: '',
                                                venue: '',
                                                externalLink: ''
                                            })}
                                            className="form-select"
                                        >
                                            <option value="">Select creator</option>
                                            {this.state.creators.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col md={2}>
                                        <Label htmlFor="formrow-firstname-Input">Content</Label>
                                        <select
                                            readOnly={this.state.viewStatus}
                                            value={this.state.content}
                                            onChange={e => this.setState({
                                                content: e.target.value,
                                                artwork: '',
                                                creator: '',
                                                venue: '',
                                                externalLink: ''
                                            })}
                                            className="form-select"
                                        >
                                            <option value="">Select content</option>
                                            {this.state.contents.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col md={2}>
                                        <Label htmlFor="formrow-firstname-Input">Venues</Label>
                                        <select
                                            readOnly={this.state.viewStatus}
                                            value={this.state.venue}
                                            onChange={e => this.setState({
                                                venue: e.target.value,
                                                artwork: '',
                                                creator: '',
                                                content: '',
                                                externalLink: ''
                                            })}
                                            className="form-select"
                                        >
                                            <option value="">Select venues</option>
                                            {this.state.venues.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col md={2}>
                                        <Label htmlFor="formrow-firstname-Input">External Link</Label>
                                        <Input
                                            readOnly={this.state.viewStatus}
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder=""
                                            name="externalLink"
                                            value={this.state.externalLink}
                                            onChange={e => this.setState({
                                                externalLink: e.target.value,
                                                venue: '',
                                                artwork: '',
                                                creator: '',
                                                content: ''
                                            })}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <Col md={3}>
                                        <Label htmlFor="formrow-firstname-Input">Note</Label>
                                        <Input
                                            readOnly={this.state.viewStatus}
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder=""
                                            name="note"
                                            value={this.state.note}
                                            onChange={e => this.setState({note: e.target.value})}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label htmlFor="formrow-firstname-Input">Alternative DeepLink Url</Label>
                                        <Input
                                            readOnly={this.state.viewStatus}
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder=""
                                            name="alternativeUrl"
                                            value={this.state.alternativeUrl}
                                            onChange={e => this.setState({ alternativeUrl: e.target.value })}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <Col md={12}>
                                        <div className="text-center">
                                            <button disabled={this.state.viewStatus}
                                                    className="btn btn-primary w-md"
                                                    onClick={() => this.createPhysicalPoint()}>
                                                {this.state.id ? 'Edit' : 'Add'}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '15px'}}>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Artwork</th>
                                                    <th>Creator</th>
                                                    <th>Content</th>
                                                    <th>Venue</th>
                                                    <th>External Link</th>
                                                    <th>Note</th>
                                                    <th>Positions</th>
                                                    <th>Deep Link</th>
                                                    <th>Alternative Deep Link Url</th>
                                                    <th>QR Code</th>
                                                    <th style={{width: 160}}></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.physicalPoints.map((item, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <th scope="row">{idx + 1}</th>
                                                            <td>{item.artwork?.name}</td>
                                                            <td>{item.creator?.name}</td>
                                                            <td>{item.content?.title}</td>
                                                            <td>{item.venue?.name}</td>
                                                            <td>{item.externalLink}</td>
                                                            <td>{item.note}</td>
                                                            <td>{item.positions.toString()}</td>
                                                            <td>{item.deepLink}</td>
                                                            <td>{item.alternativeUrl}</td>
                                                            <td>
                                                                <IconButton aria-label="download" size="large"
                                                                            disabled={item.qrCode == null}
                                                                            href={API_URL + item.qrCode}
                                                                            color="default">
                                                                    <QrCode2/>
                                                                </IconButton>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            id: item.id,
                                                                            artwork: item.artwork?.id || '',
                                                                            creator: item.creator?.id || '',
                                                                            content: item.content?.id || '',
                                                                            venue: item.venue?.id || '',
                                                                            externalLink: item.externalLink || '',
                                                                            number: item.number || '',
                                                                            note: item.note,
                                                                            alternativeUrl: item.alternativeUrl || undefined
                                                                        });
                                                                    }}
                                                                    className="btn btn-primary w-xm"
                                                                >
                                                                    Edit
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    onClick={() => this.removePhysicalPoint(item.id)}
                                                                    className="btn btn-danger w-xm">
                                                                    Remove
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

PhysicalPoint.propTypes = {
    viewStatus: PropTypes.bool,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PhysicalPoint));
