import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Col, Row, Table } from 'reactstrap'
import axios from 'axios'
import { API_URL } from '../../../constants/apiurl'
import Loader from '../../../components/Common/Loader'
import SweetAlert from 'react-bootstrap-sweetalert'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { postExhibitionContentOrder } from '../../../helpers/Api/Occurences/occurances_apis'
import DragAndDropList from "../../../components/DragAndDropList"
import { postArtworkContentOrder } from '../../../helpers/Api/artwork_apis'

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
})

class Contents extends Component {
    constructor(props) {
        super(props)
        this.node = React.createRef()
        this.state = {
            contents: [],
            selectedContents: [],
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,

            // Contents Ordering
            showOrdering: false,
            orderingList: []
        }
    }

    componentDidMount() {
        this.getContents();
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getContents();
        }
    }

    getContents = () => {
        switch (this.props.section){
            case "exhibition":
                this.getExhibitionContents()
                break;
            case "artwork":
                this.getArtworkContents()
                break;
            case "creator":
                this.getCreatorContents()
                break;
        }
    };

    getExhibitionContents = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let eid = this.getId();
        if (eid === undefined || eid === '') {
            return;
        } else {
            this.setState({
                loading: true
            });
            axios
                .get(API_URL + '/tt/exhibition/' + eid + '/content/mapping', axiosconf)
                .then(response => {
                    this.setState({
                        selectedContents: response.data.sort((a, b) => a.orderPosition - b.orderPosition),
                        loading: false
                    });
                })
                .catch(e => {
                    console.log('------GET content ERROR-----' + JSON.stringify(e));
                });
        }
    };

    getArtworkContents = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let aid = this.getId();
        if (aid === undefined || aid === '') {
            return;
        } else {
            this.setState({
                loading: true
            });
            axios
                .get(API_URL + '/tt/artwork/' + aid + '/content/mapping', axiosconf)
                .then(response => {
                    this.setState({
                        selectedContents: response.data.sort((a, b) => a.orderPosition - b.orderPosition),
                        loading: false
                    });
                })
                .catch(e => {
                    console.log('------GET content ERROR-----' + JSON.stringify(e));
                });
        }
    };

    getCreatorContents = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let cid = this.getId();
        if (cid === undefined || cid === '') {
            return;
        } else {
            this.setState({
                loading: true
            });
            axios
                .get(API_URL + '/tt/creator/' + cid + '/content/mapping', axiosconf)
                .then(response => {
                    this.setState({
                        selectedContents: response.data,
                        loading: false
                    });
                })
                .catch(e => {
                    console.log('------GET content ERROR-----' + JSON.stringify(e));
                });
        }
    };

    getId = () => {
        switch(this.props.section) {
            case 'exhibition': {
                if (localStorage.getItem('exhibid')) {
                    const obj = JSON.parse(localStorage.getItem('exhibid'));
                    return obj.id;
                } else {
                    return '';
                }
            }
            case 'artwork':
                return this.props.relatedId;
            case 'creator':
                return this.props.relatedId;
            default:
                return '';
        }
    };

    postContents = () => {
        switch (this.props.section){
            case "exhibition":
                this.postExhibitionContents()
                break;
            case "artwork":
                this.postArtworkContents()
                break;
            case "creator":
                this.postCreatorContents()
                break;
        }
    };

    postArtworkContents = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let aid = this.getId();
        if (aid === undefined || aid === '') {
            return;
        } else {
            var ccount = 0;
            this.state.selectedContents.map((item, idx) => {
                if (item.isDefault) {
                    ccount++;
                }
            });

            if (ccount > 2) {
                this.setState({ showAlert: true, alertMessage: 'Max 2 selections allowed!' });
                ccount = 0;
            } else {
                this.setState({
                    loading: true
                });

                axios
                    .post(API_URL + '/tt/artwork/' + aid + '/content/mapping', this.state.selectedContents, axiosconf)
                    .then(response => {
                        this.setState({
                            selectedContents: []
                        });
                        ccount = 0;
                        this.setState({ showAlertSuccess: true, successMessage: 'Updated Successfully!' });
                        this.getContents();
                    })
                    .catch(e => {
                        ccount = 0;
                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.setState({ showAlert: true, alertMessage: error.message });
                        }
                    });
            }
        }
    };


    postExhibitionContents = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let eid = this.getId();
        if (eid === undefined || eid === '') {
            return;
        } else {
            var ccount = 0;
            this.state.selectedContents.map((item, idx) => {
                if (item.isDefault) {
                    ccount++;
                }
            });

            if (ccount > 2) {
                this.setState({ showAlert: true, alertMessage: 'Max 2 selections allowed!' });
                ccount = 0;
            } else {
                this.setState({
                    loading: true
                });

                axios
                    .post(API_URL + '/tt/exhibition/' + eid + '/content/mapping', this.state.selectedContents, axiosconf)
                    .then(response => {
                        this.setState({
                            selectedContents: []
                        });
                        ccount = 0;
                        this.setState({ showAlertSuccess: true, successMessage: 'Updated Successfully!' });
                        this.getContents();
                    })
                    .catch(e => {
                        ccount = 0;
                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.setState({ showAlert: true, alertMessage: error.message });
                        }
                    });
            }
        }
    };

    postCreatorContents = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let cid = this.getId();
        if (cid === undefined || cid === '') {
            return;
        } else {
            var ccount = 0;
            this.state.selectedContents.map((item, idx) => {
                if (item.isDefault) {
                    ccount++;
                }
            });

            if (ccount > 2) {
                this.setState({ showAlert: true, alertMessage: 'Max 2 selections allowed!' });
                ccount = 0;
            } else {
                this.setState({
                    loading: true
                });

                axios
                    .post(API_URL + '/tt/creator/' + cid + '/content/mapping', this.state.selectedContents, axiosconf)
                    .then(response => {
                        this.setState({
                            selectedContents: []
                        });
                        ccount = 0;
                        this.setState({ showAlertSuccess: true, successMessage: 'Updated Successfully!' });
                        this.getContents();
                    })
                    .catch(e => {
                        ccount = 0;
                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.setState({ showAlert: true, alertMessage: error.message });
                        }
                    });
            }
        }
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    //------- Info handling ---- Country | State | City
    handleOnSearch111 = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        ///tt/content?page=0&size=20&name=
        axios
            .get(API_URL + '/tt/content?page=0&size=20&key=' + string + '&sort=title,asc', axiosconf)
            .then(response => {
                this.setState({
                    contents: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    }


    getExhibId = () =>
        localStorage.getItem('exhibid') && JSON.parse(localStorage.getItem('exhibid'))

    handleCloseOrdering = () => {
        this.setState({
            showOrdering: false,
            orderingList: []
        })
    }

    handleDragEnd = ({destination, source}) => {
        if (!destination) return

        this.setState({orderingList: this.reorder(this.state.orderingList, source.index, destination.index)})
    }

    handleAction = (ordering) =>
        ordering
            ? this.setContentsOrder()
            : this.postContents()

    reorder = (list, startIndex, endIndex) => {
        const result = list
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    setContentsOrder = () => {
        switch (this.props.section){
            case "exhibition":
                this.setExhibitionContentsOrder()
                break;
            case "artwork":
                this.setArtworkContentsOrder()
                break;
        }
    }

    setExhibitionContentsOrder = () => {
        let eid = this.getExhibId()

        if (!eid || !eid.id) return

        this.setState({loading: true})

        postExhibitionContentOrder(eid.id, this.state.orderingList)
            .then(response => { this.setState({ selectedContents: response.data.sort((a, b) => a.orderPosition - b.orderPosition) })})
            .catch(e => console.log('SET CONTENTS ORDER ERROR', e))
            .finally(() => {
                this.setState({loading: false})
                this.handleCloseOrdering()
            })
    }

    setArtworkContentsOrder = () => {
        postArtworkContentOrder(this.props.relatedId, this.state.orderingList)
            .then(response => { this.setState({ selectedContents: response.data.sort((a, b) => a.orderPosition - b.orderPosition) })})
            .catch(e => console.log('SET CONTENTS ORDER ERROR', e))
            .finally(() => {
                this.setState({loading: false})
                this.handleCloseOrdering()
            })
    }

    render() {
        return (
            <div>
                {this.state.showAlert && <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}> {this.state.alertMessage} </SweetAlert>}
                {this.state.showAlertSuccess && <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}> {this.state.successMessage} </SweetAlert>}
                <div className="text-center mt-0">
                    <h5>{this.state.showOrdering ? "Content List | Ordering" : "Content List"}</h5>
                </div>
                {this.state.showOrdering &&
                    <div style={{ display: 'flex' }}>
                        <button onClick={this.handleCloseOrdering}
                                style={{marginLeft: 'auto'}}
                                type="button"
                                className="btn btn-primary mb-5">
                            <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                            Back to Contents
                        </button>
                    </div>}
                {this.state.loading
                    ? <Loader color={'green'} loading={this.state.loading} />
                    : <div>
                        {this.state.showOrdering
                            ? <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead className="table-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Content name</th>
                                        <th>Creator</th>
                                    </tr>
                                    </thead>
                                    <DragAndDropList
                                        list={this.state.orderingList}
                                        onDragEnd={this.handleDragEnd}
                                        dragItemStyle={{
                                            background: 'lightgreen',
                                            borderRadius: '16px',
                                        }}
                                        dragListStyle={{
                                            background: 'lightblue',
                                            borderRadius: '16px',
                                        }}>
                                        {(item) => (
                                            <>
                                                <td>{item.id}</td>
                                                <td>{item.content.title}</td>
                                                <td></td>
                                            </>
                                        )}
                                    </DragAndDropList>
                                </Table>
                            </div>
                            : <div>
                                <Row style={{ marginTop: '20px' }}>
                                    <Col md={6}>
                                        <ReactSearchAutocomplete
                                            styling={{ backgroundColor: '#fff', zIndex: 2, placeholderColor: '#939294', borderRadius: '4px', border: '1px solid #ced4da', height: '35px', fontSize: 16 }}
                                            items={this.state.contents}
                                            fuseOptions={{ keys: ['title', 'author'] }}
                                            resultStringKeyName="title"
                                            placeholder={'Type content name here'}
                                            onSearch={(ss, ee) => this.handleOnSearch111(ss, ee)}
                                            onHover={() => {}}
                                            onSelect={item => {
                                                let found = this.state.selectedContents.find(a => a.content.id === item.id);

                                                if (!found) {
                                                    this.setState({ selectedContents: [...this.state.selectedContents, { id: '', content: item, isDefault: false }] });
                                                }
                                            }}
                                            onFocus={() => {}}
                                            formatResult={formatResult}
                                        />
                                    </Col>
                                </Row>
                                {this.props.section !== "creator" &&
                                    <Row>
                                        <Col md={2}>
                                            <button
                                                onClick={() =>
                                                    this.setState({
                                                        showOrdering: true,
                                                        orderingList: this.state.selectedContents
                                                    })
                                                }
                                                className="btn btn-primary w-xm mt-2">
                                                Change order
                                            </button>
                                        </Col>
                                    </Row>}
                                <Row style={{ marginTop: '15px' }}>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Content name</th>
                                                    <th>Creator</th>
                                                    <th>Showcase on home (Max 2)</th>
                                                    <th>Remove</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.selectedContents.map((item, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <th scope="row">{idx + 1}</th>
                                                            <td>{item.content.title}</td>
                                                            <td></td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="customCheckcolor1"
                                                                    name="isDefault"
                                                                    checked={item.isDefault}
                                                                    onChange={e => {
                                                                        this.setState(prevState => ({
                                                                            selectedContents: prevState.selectedContents.map(content =>
                                                                                content.content.title === item.content.title
                                                                                    ? {
                                                                                        ...content,
                                                                                        isDefault: e.target.checked
                                                                                    }
                                                                                    : content
                                                                            )
                                                                        }));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {' '}
                                                                <i
                                                                    className="mdi mdi-delete font-size-18"
                                                                    id="deletetooltip"
                                                                    onClick={() => {
                                                                        const reducedArr = this.state.selectedContents.filter((item, itemIndex) => {
                                                                            return itemIndex !== idx;
                                                                        });

                                                                        this.setState({
                                                                            selectedContents: reducedArr
                                                                        });
                                                                    }}
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>}

                        <div className="text-center mt-4">
                            <button
                                disabled={this.props.viewStatus}
                                onClick={() => this.handleAction(this.state.showOrdering)}
                                type="submit" className="btn btn-primary w-md">
                                Save {this.state.showOrdering ? " order" : ""}
                            </button>
                        </div>
                    </div>}
            </div>
        )
    }
}

Contents.propTypes = {
    section: PropTypes.oneOf(['exhibition','artwork', 'creator']),
    relatedId: PropTypes.string,
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab3: PropTypes.func,

    //t: PropTypes.any,
    history: PropTypes.any,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Contents));

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.title}</span>
        </>
    );
};
