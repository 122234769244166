import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CropEasyParticle from '../../../components/Crop/CropEasy';
import { Col, Row, Label, Button, Input, Table } from 'reactstrap';
import Loader from '../../../components/Common/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { API_URL } from '../../../constants/apiurl';

import { getSponsors, postSponsor, deleteSponsor } from '../../../helpers/Api/Occurences/occurances_apis';
import { getExhibId } from '../../../constants/utils';

class Sponsors extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,
            sponsors: [],
            link: '',
            image: '',
            showCropEditor: false,
            showPreview: false
        };

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getSponsors();
    }

    componentDidUpdate(previousProps) {
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getSponsors();
        }
    }

    getSponsors = async () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({ loading: true, sponsors: [] });

            const response = await getSponsors(eid.id);
            if (response.error) {
                console.log('error ---- ' + JSON.stringify(response));
                this.setState({ loading: false });
            } else {
                this.setState({
                    loading: false,
                    sponsors: response.data ?? []
                });
            }
        }
    };

    handleCallback = childData => {
        this.setState({
            showCropEditor: false,
            image: childData,
            showPreview: true
        });
    };

    onInputChange = e => {
        const file = e.target.files[0];
        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
        if (size > 300) {
            this.setState({
                showAlert: true,
                alertMessage: 'Please ensure image size is less than 300 KB',
                selectedUploadtype: '',
                vselectedUploadtype: ''
            });
            e.target.value = null;
        } else {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    this.setState({ image: reader.result, showCropEditor: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    submitSponsor = async () => {
        let postData = [
            {
                id: null,
                link: this.state.link,
                image: this.state.image
            }
        ];

        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({ loading: true });
        const response = await postSponsor(postData, eid.id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({
                showAlertSuccess: true,
                successMessage: 'Inserted Successfully!',
                link: '',
                image: '',
                showPreview: false
            });
            this.setState({ loading: false });
            this.getSponsors();
        }
    };

    removeSponsor = async id => {
        if (confirm('Are you sure?')) {
            let eid = getExhibId();
            if (eid.id === undefined || eid === undefined) {
                return;
            }

            this.setState({ loading: true });
            const response = await deleteSponsor(id, eid.id);
            if (response.error) {
                console.log('error ---- ' + JSON.stringify(response));
                this.setState({ loading: false });
            } else {
                this.setState({
                    showAlertSuccess: true,
                    successMessage: 'Delete Successfully!'
                });
                this.setState({ loading: false });
                this.getSponsors();
            }
        }
    };

    render() {
        return (
            <div>
                <h4>Sponsors</h4>
                {this.state.showAlert ? (
                    <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showAlertSuccess ? (
                    <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}>
                        {this.state.successMessage}
                    </SweetAlert>
                ) : null}

                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <div>
                        {this.state.showCropEditor ? (
                            <CropEasyParticle photoURL={this.state.image} parentCallback={this.handleCallback} shape="rect" isPng={true} />
                        ) : (
                            <Row>
                                <Row style={{ marginTop: '20px' }}>
                                    <Col md={3}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">URL</Label>
                                            <Input
                                                readOnly={this.state.viewStatus}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter URL"
                                                name="link"
                                                value={this.state.link}
                                                onChange={e =>
                                                    this.setState({
                                                        link: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col md={1}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Image</Label>
                                            {this.state.showPreview ? (
                                                <>
                                                    <img width={50} style={{ borderRadius: '25px' }} height={50} src={this.state.image} />
                                                    <i
                                                        className="mdi mdi-delete font-size-14"
                                                        style={{ color: 'red' }}
                                                        id="deletetooltip"
                                                        onClick={() => {
                                                            this.setState({ image: '', showPreview: false });
                                                        }}
                                                    ></i>
                                                </>
                                            ) : (
                                                <>
                                                    <Button style={{ backgroundColor: '#C0C0C0' }} className="btn-rounded btn-sm" onClick={() => this.fileInputRef.current.click()}>
                                                        <i
                                                            className="mdi mdi-plus font-size-45"
                                                            style={{
                                                                color: '#2E2E2E',
                                                                width: '70px',
                                                                height: '70px'
                                                            }}
                                                            id="deletetooltip"
                                                        ></i>
                                                    </Button>
                                                    <input onChange={e => this.onInputChange(e)} name="image" multiple={false} ref={this.fileInputRef} accept=".jpg,.jpeg,.png" type="file" hidden />
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">{'\t'}</Label>
                                            <br />
                                            <Button
                                                style={{
                                                    marginTop: '3px',
                                                    marginLeft: '8px',
                                                    width: '80px'
                                                }}
                                                color={'primary'}
                                                onClick={() => {
                                                    if (this.state.link === '' || this.state.image === '') {
                                                        this.setState({
                                                            showAlert: true,
                                                            alertMessage: 'All fields are required!'
                                                        });
                                                    } else {
                                                        this.submitSponsor();
                                                    }
                                                }}
                                                className="btn-md"
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="text-center mt-4">{this.state.verror !== '' && <span style={{ color: 'red' }}>{this.state.verror}</span>}</div>
                                </Row>
                                <Row style={{ marginTop: '15px' }}>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>URL</th>
                                                        <th>Image</th>
                                                        <th>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.sponsors.map((item, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <th scope="row">{idx + 1}</th>
                                                                <td>{item.link}</td>
                                                                <td align="center">
                                                                    <img width={50} style={{ borderRadius: '25px' }} height={50} src={item.id === null ? item.image : API_URL + item.image} />
                                                                </td>
                                                                <td>
                                                                    <i
                                                                        className="mdi mdi-delete font-size-18"
                                                                        id="deletetooltip"
                                                                        onClick={() => {
                                                                            this.removeSponsor(item.id);
                                                                        }}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

Sponsors.propTypes = {
    viewStatus: PropTypes.bool,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sponsors));
