/* Tags */
export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';

export const GET_TAG = 'GET_TAG';
export const GET_TAG_FAIL = 'GET_TAG_FAIL';

export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAGS_FAIL = 'DELETE_TAGS_FAIL';

export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_FAIL = 'UPDATE_TAG_FAIL';
//others------

/* USERS PROFILE */
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const ADD_NEW_USER = 'ADD_NEW_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
