import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Alert,
    TextArea,
    Breadcrumb,
    BreadcrumbItem,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table
} from 'reactstrap';
//Import Breadcrumb

import axios from 'axios';
import { API_URL } from '../../../constants/apiurl';
import Loader from '../../../components/Common/Loader';
import { Image } from 'react-bootstrap';
import CropEasy from '../../../components/Crop/CropEasy';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Editor } from '@tinymce/tinymce-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { getOffsiteList, deleteOffsite, postOffsiteColor } from '../../../helpers/Api/Occurences/off_site_apis';
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class Offsite extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            offsitelist: [],
            selectedOffsite: [],
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,
            selectedType: '',
            title: '',
            description: '',
            backgroundColor: '',
            offsite_id: null,
            otherColor: ''
        };
        this.editorRef = null;
    }

    componentDidMount() {
        this.getExhibitionInformation();
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getOffsite();
            this.getExhibitionInformation();
        }
    }

    getExhibitionInformation = () => {
        let eid = this.getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({ loading: true });
            let obj = this.getAuthDetails();
            let axiosconf = axiosHeaderConfig(obj.access_token);
            axios
                .get(API_URL + '/tt/exhibition/site/' + eid.id + '?type=Off Site', axiosconf)
                .then(response => {
                    // console.log(">>>>>..."+JSON.stringify(response.data))
                    this.setState({
                        offsite_id: response.data.id,
                        title: response.data.title,
                        description: response.data.description,
                        backgroundColor: response.data.backgroundColor,
                        otherColor: response.data.foregroundColor,
                        loading: false
                    });
                })
                .catch(e => {
                    if (e.response.status === 500) {
                        this.setState({
                            title: this.props.title,
                            description: this.props.description,
                            loading: false
                        });
                    }
                    this.setState({ loading: false });
                });
        }
    };

    getOffsite = async () => {
        let eid = this.getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({
                loading: true
            });

            const response = await getOffsiteList(eid.id);
            if (response.error) {
                console.log('error ---->>>>>>>>>>> ' + JSON.stringify(response));
                this.setState({ loading: false });
            } else {
                // console.log("response get link ------>>>>> "+JSON.stringify(response))

                this.setState({
                    selectedOffsite: response.data.content,
                    loading: false
                });
            }
        }
    };

    async removeOffsite(id) {
        const response = await deleteOffsite(id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.getOffsite(this.state.id);
        }
    }

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    getExhibId = () => {
        if (localStorage.getItem('exhibid')) {
            const obj = JSON.parse(localStorage.getItem('exhibid'));
            return obj;
        }
    };

    convertToText(htmltext) {
        // Create a new div element
        var tempDivElement = document.createElement('html');

        // Set the HTML content with the given value
        tempDivElement.innerHTML = htmltext;

        // Retrieve the text property of the element
        return tempDivElement.textContent || tempDivElement.innerText || '';
    }
    postOffsite = async () => {
        let eid = this.getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({
                loading: true
            });
            var postData = {
                backgroundColor: this.state.backgroundColor,
                foregroundColor: this.state.otherColor,
                title: this.state.title,
                description:
                    "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                    this.state.description +
                    '</body></html>',
                id: this.state.offsite_id
            };

            const response = await postOffsiteColor(eid.id, postData);
            if (response.error) {
                console.log('error ---->>>>>>>>>>> ' + JSON.stringify(response));
                this.setState({ loading: false });
            } else {
                //console.log("data...... 2222 "+JSON.stringify(response.data))
                // console.log("response get link ------>>>>> "+JSON.stringify(response))
                this.setState({ showAlertSuccess: true, successMessage: 'Updated Successfully!', backgroundColor: response.data.backgroundColor, otherColor: response.data.foregroundColor });
                this.getOffsite();
                this.getExhibitionInformation();
            }
        }
    };

    render() {
        return (
            <div>
                {this.state.showAlert ? (
                    <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showAlertSuccess ? (
                    <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}>
                        {this.state.successMessage}
                    </SweetAlert>
                ) : null}

                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <div>
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={6}>
                                <h4>Route List</h4>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col md={12}>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Author</th>
                                                <th>Description</th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.selectedOffsite.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <th scope="row">{idx + 1}</th>
                                                        <td>{item.title}</td>
                                                        <td>{item.author}</td>
                                                        <td>{item.description}</td>

                                                        <td>
                                                            {' '}
                                                            <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.removeOffsite(item.id)}></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={12}>
                                <h4>Route Home Page Setting</h4>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col md={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Title</Label>
                                    <Input
                                        //readOnly={true}
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Enter Title"
                                        name="title"
                                        value={this.state.title}
                                        onChange={e => this.setState({ title: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Description</Label>
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                        cloudChannel="dev"
                                        onInit={(evt, editor) => (this.editorRef = editor)}
                                        //onBlur={(e) => handelBlur(editorRef)}
                                        value={this.state.description}
                                        init={{
                                            height: 300,
                                            apply_source_formatting: true,
                                            menubar: false,
                                            plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                            paste_as_text: true,
                                            mobile: {
                                                toolbar_drawer: 'floating'
                                            },
                                            toolbar:
                                                'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                        }}
                                        onEditorChange={content => this.setState({ description: content })}
                                    />

                                    {/* <textarea
                  rows={7}
                  readOnly={true}
                  type="textarea"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Description"
                  name="description"
                  value={this.convertToText(this.state.description)}

                    />*/}
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Background Color</Label>
                                    <div style={{ display: 'flex' }}>
                                        <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                            <input
                                                className="form-control form-control-color mw-100"
                                                type="color"
                                                id="example-color-input"
                                                value={this.state.backgroundColor}
                                                onChange={e =>
                                                    this.setState({
                                                        backgroundColor: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                        <div style={{ width: '120px' }}>
                                            <input readOnly={true} type="text" className="form-control" id="formrow-firstname-Input" placeholder="Enter Title" value={this.state.backgroundColor} />
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Title, Description and Icon colors</Label>
                                    <div style={{ display: 'flex' }}>
                                        <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                            <input
                                                className="form-control form-control-color mw-100"
                                                type="color"
                                                id="example-color-input"
                                                value={this.state.otherColor}
                                                onChange={e =>
                                                    this.setState({
                                                        otherColor: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                        <div style={{ width: '120px' }}>
                                            <Input readOnly={true} type="text" className="form-control" id="formrow-firstname-Input" placeholder="Enter Title" value={this.state.otherColor} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-center mt-4">
                            <button onClick={() => this.postOffsite()} disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Offsite.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab3: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any,
    activeTab: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Offsite));
