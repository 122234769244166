import React from 'react';
import axios from 'axios';
import Loader from '../../../../components/Common/Loader';
import {API_URL} from '../../../../constants/apiurl';
import PropTypes from 'prop-types';
import {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Card, CardBody, CardTitle, Col, Form, Input, Label, Row, Table} from 'reactstrap';
import ColorPicker from './components/color-picker';
import SizingType from './components/sizing-type';
import TxtAlign from './components/txt-align';
import UploadType from './components/upload-type';
import DragAndDropList from "../../../../components/DragAndDropList";
import {postPageOrder} from "../../../../helpers/Api/Occurences/routes";
import RouteStepPageLink from "../RouteStepPageLink";
import {Editor} from "@tinymce/tinymce-react";

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class RouteSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Global
            viewStatus: false,
            loading: false,
            showAlert: false,
            alertMessage: '',
            showErrorAlert: false,
            alertErrorMessage: '',

            // FORM
            id: '',
            orderPosition: 0,
            text: '',
            txtAlign: '',
            txtColor: '',
            txtSize: 0,
            isQuote: false,
            credits: '',
            sizingType: '',
            arInteraction: false,
            bgColor: '',
            bgImage: '',
            bgVideo: '',
            externalLink: '',
            linksDescription: '',

            // files upload
            files: [],
            selectedUploadtype: '',
            vselectedUploadtype: '',

            // Pages Ordering
            showPagesOrdering: false,
            pagesOrderingList: [],

            // Page Links
            showLinks: false,
            pageIdForLinks: '',
            pageTitleForLinks: ''
        };

        this.editorRef = null;
        this.editorRefDescrip = null;
    }

    componentDidMount() {
        const {
            match: {params}
        } = this.props;

        if (params.idd === 'view') {
            this.setState({viewStatus: true});
        }
    }

    addFile = e => {
        if (this.state.selectedUploadtype === '') {
            this.setState({vselectedUploadtype: 'Please select the type'});
            return;
        }

        const file = e.target.files[0];
        if (!file) {
            return;
        }

        const tmp = [...this.state.files];
        tmp.push({type: this.state.selectedUploadtype, file: file, filename: file.name});

        this.setState({
            selectedUploadtype: '',
            files: tmp
        });
    };

    removeFile = index => {
        const tmp = [...this.state.files];
        tmp.splice(index, 1);

        this.setState({files: tmp});
    };

    onSubmit = async e => {
        e.preventDefault();

        let postData = {
            id: this.state.id || null,
            arInteraction: this.state.arInteraction,
            bgColor: this.state.bgColor,
            credits: this.state.credits,
            isQuote: this.state.isQuote,
            orderPosition: this.state.orderPosition,
            sizingType: this.state.sizingType,
            text: this.state.text,
            txtAlign: this.state.txtAlign,
            txtColor: this.state.txtColor,
            txtSize: this.state.txtSize,
            externalLink: this.state.externalLink,
            linksDescription: this.state.linksDescription
        };

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let axiosFormDataConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + obj.access_token
            }
        };

        try {
            this.setState({loading: true});

            const page = await axios.post(API_URL + '/tt/exhibition/route/step/' + this.props.stepId + '/page', postData, axiosconf);

            for (const f of this.state.files) {
                if (f.file != null) {
                    let formData = new FormData();
                    formData.append('file', f.file, f.filename);

                    await axios.post(API_URL + '/tt/exhibition/route/step/page/' + page.data.id + '/file?type=' + f.type, formData, axiosFormDataConfig);
                }
            }

            this.props.parentCallbackUpdate(this.props.stepId);
            this.setState({
                id: '',
                loading: false,
                arInteraction: false,
                bgColor: '',
                credits: '',
                isQuote: false,
                orderPosition: 0,
                sizingType: '',
                text: '',
                txtAlign: '',
                txtColor: '',
                txtSize: '',
                // files upload
                files: [],
                selectedUploadtype: '',
                vselectedUploadtype: '',
                externalLink: '',
                linksDescription: '',

            });
        } catch (e) {
            this.setState({
                loading: false,
                showErrorAlert: true,
                alertErrorMessage: e.response.data.message
            });
        }
    };

    removePage = pageId => {
        if (confirm('Are you sure?')) {
            this.setState({loading: true});

            let obj = getAuthDetails();
            let axiosconf = axiosHeaderConfig(obj.access_token);
            axios
                .delete(API_URL + '/tt/exhibition/route/step/page/' + pageId, axiosconf)
                .then(() => {
                    this.props.parentCallbackUpdate(this.props.stepId);
                    this.setState({loading: false});
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        loading: false,
                        showErrorAlert: true,
                        alertErrorMessage: e.response.data.message
                    });
                });
        }
    };

    removeMedia = (pageId, id) => {
        if (confirm('Are you sure?')) {
            this.setState({loading: true});

            let obj = getAuthDetails();
            let axiosconf = axiosHeaderConfig(obj.access_token);
            axios
                .delete(API_URL + '/tt/exhibition/route/step/page/' + pageId + '/file/' + id, axiosconf)
                .then(() => {
                    this.props.parentCallbackUpdate(this.props.stepId);
                    this.setState({loading: false});
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        loading: false,
                        showErrorAlert: true,
                        alertErrorMessage: e.response.data.message
                    });
                });
        }
    };

    setPagesOrder = () => {
        let postData = this.state.pagesOrderingList.map(item => (
            {
                id: item.id,
                arInteraction: item.arInteraction,
                bgColor: item.bgColor,
                credits: item.credits,
                isQuote: item.isQuote,
                orderPosition: item.orderPosition,
                sizingType: item.sizingType,
                text: item.text,
                txtAlign: item.txtAlign,
                txtColor: item.txtColor,
                txtSize: item.txtSize,
                externalLink: item.externalLink,
                linksDescription: item.linksDescription
            }
        ));

        this.setState({loading: true});
        postPageOrder(this.props.stepId, postData)
            .then(response => {
                this.setState({pagesOrderingList: [], loading: false});
            })
            .catch(e => console.log('SET PAGES ORDER ERROR', e))
            .finally(() => {
                this.setState({loading: false})
                this.handleClosePagesOrdering();
                this.props.parentCallbackUpdate(this.props.stepId);
            });

    }

    handleClosePagesOrdering = () => {
        this.setState({
            showPagesOrdering: false,
            pagesOrderingList: []
        });
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    handleDragEnd = ({destination, source}) => {
        if (!destination) return;
        this.setState({pagesOrderingList: this.reorder(this.state.pagesOrderingList, source.index, destination.index)});
    };

    handleCloseLinks = () => {
        this.setState({
            showLinks: false,
            pageIdForLinks: '',
            pageTitleForLinks: ''
        });
    };

    render() {
        return (
            <>
                {this.state.showAlert ? (
                    <SweetAlert title="" warning onConfirm={() => this.setState({showAlert: false})}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showErrorAlert ? (
                    <SweetAlert error title="" onConfirm={() => this.setState({showErrorAlert: false})}>
                        {this.state.alertErrorMessage}
                    </SweetAlert>
                ) : null}

                {!this.state.loading && !this.state.showLinks && this.state.showPagesOrdering && (
                    <React.Fragment>
                        <Card>
                            <div style={{display: 'flex'}}>
                                <button onClick={this.handleClosePagesOrdering} style={{marginLeft: 'auto'}}
                                        type="button" className="btn btn-primary">
                                    <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                    Back to Pages
                                </button>
                            </div>

                            <div className="text-center mt-0" style={{margin: 30}}>
                                <h5>List | Ordering</h5>
                            </div>

                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead className="table-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Text</th>
                                        <th>Credits</th>
                                        <th>AR</th>
                                        <th>Quote</th>
                                        <th>Sizing type</th>
                                        <th>Text align</th>
                                        <th>Text Color</th>
                                        <th>Text Size</th>
                                        <th>Background color</th>
                                        <th>Video</th>
                                        <th>Audio</th>
                                        <th>Background Image</th>
                                        <th>Background Video</th>
                                        <th>External Link</th>
                                        <th>Links Description</th>
                                    </tr>
                                    </thead>
                                    <DragAndDropList
                                        list={this.state.pagesOrderingList}
                                        onDragEnd={this.handleDragEnd}
                                        dragItemStyle={{
                                            background: 'lightgreen',
                                            borderRadius: '16px',
                                        }}
                                        dragListStyle={{
                                            background: 'lightblue',
                                            borderRadius: '16px',
                                        }}
                                    >
                                        {(item) => (
                                            <>
                                                <td>{item.id + 1}</td>
                                                <td>{item.text}</td>
                                                <td>{item.credits}</td>
                                                <td>{item.arInteraction}</td>
                                                <td>{item.isQuote}</td>
                                                <td>{item.sizingType}</td>
                                                <td>{item.txtAlign}</td>
                                                <td>{item.txtColor ? <div style={{
                                                    width: 60,
                                                    height: 40,
                                                    backgroundColor: `${item.txtColor}`
                                                }}/> : null}</td>
                                                <td>{item.txtSize}</td>
                                                <td>{item.bgColor ? <div style={{
                                                    width: 60,
                                                    height: 40,
                                                    backgroundColor: `${item.bgColor}`
                                                }}/> : null}</td>
                                                <td>
                                                    {item.video ? (
                                                        <>
                                                            <video width="200" height="120" controls>
                                                                <source src={API_URL + item.video} type="video/mp4"/>
                                                            </video>
                                                            <i
                                                                className="mdi mdi-delete font-size-18"
                                                                style={{color: 'red'}}
                                                                onClick={() => this.removeMedia(item.id, item.files.find(f => f.url === item.video).id)}
                                                            ></i>
                                                        </>
                                                    ) : null}
                                                </td>
                                                <td>
                                                    {item.audio ? (
                                                        <>
                                                            <audio controls>
                                                                <source src={API_URL + item.audio} type="audio/mpeg"/>
                                                            </audio>
                                                            <i
                                                                className="mdi mdi-delete font-size-18"
                                                                style={{color: 'red'}}
                                                                onClick={() => this.removeMedia(item.id, item.files.find(f => f.url === item.audio).id)}
                                                            ></i>
                                                        </>
                                                    ) : null}
                                                </td>
                                                <td>
                                                    {item.bgImage ? (
                                                        <>
                                                            <img src={API_URL + item.bgImage} width={100} height={80}/>
                                                            <i
                                                                className="mdi mdi-delete font-size-18"
                                                                style={{color: 'red'}}
                                                                onClick={() => this.removeMedia(item.id, item.files.find(f => f.url === item.bgImage).id)}
                                                            ></i>
                                                        </>
                                                    ) : null}
                                                </td>
                                                <td>
                                                    {item.bgVideo ? (
                                                        <>
                                                            <video width="200" height="120" controls>
                                                                <source src={API_URL + item.bgVideo} type="video/mp4"/>
                                                            </video>
                                                            <i
                                                                className="mdi mdi-delete font-size-18"
                                                                style={{color: 'red'}}
                                                                onClick={() => this.removeMedia(item.id, item.files.find(f => f.url === item.bgVideo).id)}
                                                            ></i>
                                                        </>
                                                    ) : null}
                                                </td>
                                                <td>{item.externalLink}</td>
                                                <td>{item.linksDescription}</td>
                                            </>
                                        )}
                                    </DragAndDropList>
                                </Table>
                            </div>
                            <div className="text-center mt-4" style={{margin: 30}}>
                                <button disabled={this.state.viewStatus}
                                        className="btn btn-primary w-md" onClick={this.setPagesOrder}>
                                    Save
                                </button>
                            </div>
                        </Card>
                    </React.Fragment>
                )}

                {!this.state.loading && this.state.showLinks && !this.state.showPagesOrdering && (
                    <RouteStepPageLink
                        pageId={this.state.pageIdForLinks}
                        pageTitle={this.state.pageTitleForLinks}
                        parentCloseLinks={() => this.handleCloseLinks()}
                    />
                )}

                {!this.state.loading && !this.state.showLinks && !this.state.showPagesOrdering && (
                    <Card>
                        <div className="text-center mt-0">
                            <h5>Exhibition | Pages for steps {this.props.stepTitle}</h5>
                        </div>

                        <div style={{display: 'flex'}}>
                            <button onClick={() => this.props.parentClosePages()} style={{marginLeft: 'auto'}}
                                    type="button" className="btn btn-primary">
                                <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                Back to route steps
                            </button>
                        </div>

                        {this.state.loading && (
                            <Loader color={'green'} loading={this.state.loading}/>
                        )}

                        {!this.state.loading && !this.state.showPagesOrdering && (
                            <div>
                                <Row>
                                    <Col lg={12}>
                                        <div>
                                            <Form className="form" onSubmit={this.onSubmit}>
                                                <Row style={{marginTop: 20}}>
                                                    <Col md={3}>
                                                        <Label htmlFor="formrow-firstname-Input">Text ({this.state.text?.length})</Label>
                                                        <textarea
                                                            readOnly={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Text"
                                                            name="text"
                                                            value={this.state.text}
                                                            onChange={e => this.setState({text: e.target.value})}
                                                        />
                                                        <Label htmlFor="formrow-firstname-Input">N.B.: Remember to limit the text to 83 characters when combined with video or audio</Label>
                                                    </Col>
                                                    <Col md={1}>
                                                        <ColorPicker readOnly={this.state.viewStatus} label="Text color"
                                                                     value={this.state.txtColor}
                                                                     onChange={e => this.setState({txtColor: e})}/>
                                                    </Col>
                                                    <Col md={1}>
                                                        <TxtAlign readOnly={this.state.viewStatus}
                                                                  value={this.state.txtAlign}
                                                                  onChange={e => this.setState({txtAlign: e})}/>
                                                    </Col>
                                                    <Col md={1}>
                                                        <Label htmlFor="formrow-firstname-Input">Text size</Label>
                                                        <Input
                                                            readOnly={this.state.viewStatus}
                                                            type="number"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="txtSize"
                                                            value={this.state.txtSize}
                                                            onChange={e => this.setState({txtSize: +e.target.value})}
                                                        />
                                                    </Col>
                                                    <Col md={1}>
                                                        <ColorPicker readOnly={this.state.viewStatus}
                                                                     label="Background color" value={this.state.bgColor}
                                                                     onChange={e => this.setState({bgColor: e})}/>
                                                    </Col>
                                                    <Col md={1}>
                                                        <SizingType readOnly={this.state.viewStatus}
                                                                    value={this.state.sizingType}
                                                                    onChange={e => this.setState({sizingType: e})}/>
                                                    </Col>
                                                    <Col md={1}>
                                                        <div style={{marginTop: 35}}>
                                                            <Input
                                                                readOnly={this.state.viewStatus}
                                                                type="checkbox"
                                                                name="isQuote"
                                                                value={this.state.isQuote}
                                                                onChange={e => this.setState({isQuote: e.target.checked})}
                                                            />
                                                            <Label check style={{marginLeft: 4}}>
                                                                Quote
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    <Col md={1}>
                                                        <div style={{marginTop: 35}}>
                                                            <Input
                                                                readOnly={this.state.viewStatus}
                                                                type="checkbox"
                                                                name="arInteraction"
                                                                value={this.state.arInteraction}
                                                                onChange={e => this.setState({arInteraction: e.target.checked})}
                                                            />
                                                            <Label check style={{marginLeft: 4}}>
                                                                AR inteaction
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Label htmlFor="formrow-firstname-Input">Credits</Label>
                                                        <Input
                                                            readOnly={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter credits"
                                                            name="text"
                                                            value={this.state.credits}
                                                            onChange={e => this.setState({credits: e.target.value})}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row style={{marginTop: '30px'}}>
                                                    <Col md={5}>
                                                        <UploadType
                                                            readOnly={this.state.viewStatus}
                                                            value={this.state.selectedUploadtype}
                                                            onChange={e =>
                                                                this.setState({
                                                                    selectedUploadtype: e,
                                                                    vselectedUploadtype: ''
                                                                })
                                                            }
                                                        />
                                                        {this.state.vselectedUploadtype !== '' && <span
                                                            style={{color: 'red'}}>{this.state.vselectedUploadtype}</span>}
                                                    </Col>
                                                    <Col md={5}>
                                                        <Label htmlFor="formrow-firstname-Input">Select file</Label>
                                                        <input
                                                            disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                            className="form-control"
                                                            type="file"
                                                            accept={
                                                                this.state.selectedUploadtype === 'Video' || this.state.selectedUploadtype === 'Background Video'
                                                                    ? '.mp4'
                                                                    : this.state.selectedUploadtype === 'Audio'
                                                                        ? '.mp3'
                                                                        : '.jpg,.jpeg,.png'
                                                            }
                                                            onChange={e => this.addFile(e)}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Label htmlFor="formrow-firstname-Input">External Link</Label>
                                                        <textarea
                                                            readOnly={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Link"
                                                            name="externalLink"
                                                            value={this.state.externalLink}
                                                            onChange={e => this.setState({externalLink: e.target.value})}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row style={{marginTop: '30px'}}>
                                                    <Col md={8}>
                                                        <Label htmlFor="formrow-firstname-Input">Links Description</Label>

                                                        <Editor
                                                            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                                            cloudChannel="dev"
                                                            onInit={(evt, editor) => (this.editorRef = editor)}
                                                            //onBlur={(e) => handelBlur(editorRef)}
                                                            value={this.state.linksDescription}
                                                            init={{
                                                                height: 200,
                                                                apply_source_formatting: true,
                                                                menubar: false,
                                                                plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                                                paste_as_text: true,
                                                                mobile: {
                                                                    toolbar_drawer: 'floating'
                                                                },
                                                                toolbar:
                                                                    'bold italic | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                                            }}
                                                            onEditorChange={content => {
                                                                //console.log("-------- "+JSON.stringify(content))
                                                                this.setState({ linksDescription: content });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        {this.state.files.length > 0 && (
                                                            <Row style={{marginTop: 10}}>
                                                                <Col md={12}>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <CardTitle className="h4">Images &
                                                                                Videos</CardTitle>

                                                                            <div className="table-responsive">
                                                                                <Table
                                                                                    className="table table-borderless mb-0">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th>#</th>
                                                                                        <th>Name</th>
                                                                                        <th>Types</th>
                                                                                        <th style={{width: 100}}>Remove</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {this.state.files.map((item, idx) => (
                                                                                        <tr key={idx}>
                                                                                            <th scope="row">{idx + 1}</th>
                                                                                            <td>{item.filename}</td>
                                                                                            <td>{item.type}</td>
                                                                                            <td>
                                                                                                <button
                                                                                                    disabled={this.state.viewStatus}
                                                                                                    onClick={() => {
                                                                                                        if (item.file == null) {
                                                                                                            this.removeMedia(this.state.id, item.id);
                                                                                                        }
                                                                                                        this.removeFile(idx);
                                                                                                    }
                                                                                                    }
                                                                                                    className="btn btn-danger btn-sm"
                                                                                                >
                                                                                                    Delete
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Col>
                                                </Row>

                                                <Row style={{marginTop: 20}}>
                                                    <Col md="12">
                                                        <div className="text-center">
                                                            <button disabled={this.state.viewStatus} type="submit"
                                                                    className="btn btn-primary w-md">
                                                                {this.state.id ? 'Edit' : 'Add'}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <button
                                            onClick={() =>
                                                this.setState({
                                                    showPagesOrdering: true,
                                                    pagesOrderingList: this.props.pages
                                                        ?.sort((a, b) => a.orderPosition - b.orderPosition)
                                                })
                                            }
                                            className="btn btn-primary w-xm"
                                        >
                                            Change Order
                                        </button>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 20}}>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                <tr>
                                                    <th style={{width: 50}}>Pos.</th>
                                                    <th>Text</th>
                                                    <th>Credits</th>
                                                    <th>AR</th>
                                                    <th>Quote</th>
                                                    <th>Sizing type</th>
                                                    <th>Text align</th>
                                                    <th>Text Color</th>
                                                    <th>Text Size</th>
                                                    <th>Background color</th>
                                                    <th>Video</th>
                                                    <th>Audio</th>
                                                    <th>Background Image</th>
                                                    <th>Background Video</th>
                                                    <th>External Link</th>
                                                    <th>Links Description</th>
                                                    <th style={{width: 160}}></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.props.pages
                                                    ?.sort((a, b) => a.orderPosition - b.orderPosition)
                                                    .map((item, idx) => (
                                                        <tr key={item.id}>
                                                            <td>{item.orderPosition}</td>
                                                            <td>{item.text}</td>
                                                            <td>{item.credits}</td>
                                                            <td>{item.arInteraction}</td>
                                                            <td>{item.isQuote}</td>
                                                            <td>{item.sizingType}</td>
                                                            <td>{item.txtAlign}</td>
                                                            <td>{item.txtColor ? <div style={{
                                                                width: 60,
                                                                height: 40,
                                                                backgroundColor: `${item.txtColor}`
                                                            }}/> : null}</td>
                                                            <td>{item.txtSize}</td>
                                                            <td>{item.bgColor ? <div style={{
                                                                width: 60,
                                                                height: 40,
                                                                backgroundColor: `${item.bgColor}`
                                                            }}/> : null}</td>
                                                            <td>
                                                                {item.video ? (
                                                                    <>
                                                                        <video width="200" height="120" controls>
                                                                            <source src={API_URL + item.video}
                                                                                    type="video/mp4"/>
                                                                        </video>
                                                                        <i
                                                                            className="mdi mdi-delete font-size-18"
                                                                            style={{color: 'red'}}
                                                                            onClick={() => this.removeMedia(item.id, item.files.find(f => f.url === item.video).id)}
                                                                        ></i>
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                {item.audio ? (
                                                                    <>
                                                                        <audio controls>
                                                                            <source src={API_URL + item.audio}
                                                                                    type="audio/mpeg"/>
                                                                        </audio>
                                                                        <i
                                                                            className="mdi mdi-delete font-size-18"
                                                                            style={{color: 'red'}}
                                                                            onClick={() => this.removeMedia(item.id, item.files.find(f => f.url === item.audio).id)}
                                                                        ></i>
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                {item.bgImage ? (
                                                                    <>
                                                                        <img src={API_URL + item.bgImage} width={100}
                                                                             height={80}/>
                                                                        <i
                                                                            className="mdi mdi-delete font-size-18"
                                                                            style={{color: 'red'}}
                                                                            onClick={() => this.removeMedia(item.id, item.files.find(f => f.url === item.bgImage).id)}
                                                                        ></i>
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                {item.bgVideo ? (
                                                                    <>
                                                                        <video width="200" height="120" controls>
                                                                            <source src={API_URL + item.bgVideo}
                                                                                    type="video/mp4"/>
                                                                        </video>
                                                                        <i
                                                                            className="mdi mdi-delete font-size-18"
                                                                            style={{color: 'red'}}
                                                                            onClick={() => this.removeMedia(item.id, item.files.find(f => f.url === item.bgVideo).id)}
                                                                        ></i>
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                            <td>{item.externalLink}</td>
                                                            <td>{item.linksDescription?.substring(0, 30)}{item.linksDescription?.length > 31 && (<text>...</text>)}</td>
                                                            <td>
                                                                <button
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            id: item.id,
                                                                            arInteraction: item.arInteraction,
                                                                            bgColor: item.bgColor,
                                                                            credits: item.credits,
                                                                            isQuote: item.isQuote,
                                                                            orderPosition: item.orderPosition,
                                                                            sizingType: item.sizingType,
                                                                            text: item.text,
                                                                            txtAlign: item.txtAlign,
                                                                            txtColor: item.txtColor,
                                                                            txtSize: item.txtSize,
                                                                            files: item.files,
                                                                            externalLink: item.externalLink,
                                                                            linksDescription: item.linksDescription
                                                                        });
                                                                    }}
                                                                    className="btn btn-primary w-xm"
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            showLinks: true,
                                                                            pageIdForLinks: item.id,
                                                                            pageTitleForLinks: 'Pos. ' + item.orderPosition
                                                                        })
                                                                    }
                                                                    className="btn btn-secondary w-xm"
                                                                >
                                                                    Links
                                                                </button>
                                                                &nbsp;
                                                                <button onClick={() => this.removePage(item.id)}
                                                                        className="btn btn-danger w-xm">
                                                                    Remove
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Card>
                )}
            </>
        );
    }
}

RouteSteps.propTypes = {
    match: PropTypes.object,
    stepId: PropTypes.number,
    stepTitle: PropTypes.string,
    parentClosePages: PropTypes.func,
    pages: PropTypes.array,
    parentCallbackUpdate: PropTypes.func
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouteSteps));
