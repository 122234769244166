import {
    GET_GALLERIES_SUCCESS,
    GET_GALLERIES_FAIL,
    GET_GALLERIES,
    DELETE_GALLERIES_SUCCESS,
    DELETE_GALLERIES_FAIL,
    DELETE_GALLERIES,
    UPDATE_GALLERIES,
    UPDATE_GALLERIES_FAIL,
    UPDATE_GALLERIES_SUCCESS
} from './actionTypes';

const INIT_STATE = {
    galleries: [],
    error: {}
};

const galleries = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_GALLERIES_SUCCESS:
            return {
                ...state,
                galleries: action.payload
            };

        case GET_GALLERIES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_GALLERIES_SUCCESS:
            return {
                ...state,
                galleries: state.galleries.filter(galleries => galleries.id.toString() !== action.payload.id.toString())
            };

        case DELETE_GALLERIES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_GALLERIES_SUCCESS:
            return {
                ...state,
                galleries: state.galleries.map(article => (article.id.toString() === action.payload.id.toString() ? { article, ...action.payload } : article))
            };

        case UPDATE_GALLERIES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default galleries;
