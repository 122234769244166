import {
    GET_EXHIBITIONS_SUCCESS,
    GET_EXHIBITIONS_FAIL,
    GET_EXHIBITIONS,
    DELETE_EXHIBITIONS_SUCCESS,
    DELETE_EXHIBITIONS_FAIL,
    DELETE_EXHIBITIONS,
    UPDATE_EXHIBITIONS,
    UPDATE_EXHIBITIONS_FAIL,
    UPDATE_EXHIBITIONS_SUCCESS
} from './actionTypes';

const INIT_STATE = {
    exhibitions: [],
    error: {}
};

const exhibitions = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EXHIBITIONS_SUCCESS:
            return {
                ...state,
                exhibitions: action.payload
            };

        case GET_EXHIBITIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_EXHIBITIONS_SUCCESS:
            return {
                ...state,
                exhibitions: state.exhibitions.filter(exhibitions => exhibitions.id.toString() !== action.payload.id.toString())
            };

        case DELETE_EXHIBITIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_EXHIBITIONS_SUCCESS:
            return {
                ...state,
                exhibitions: state.exhibitions.map(article => (article.id.toString() === action.payload.id.toString() ? { article, ...action.payload } : article))
            };

        case UPDATE_EXHIBITIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default exhibitions;
