import PropTypes from 'prop-types';
import React, { Component } from 'react';

//Simple bar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';

// svg
import { ReactComponent as VectorLogo } from '../../assets/icons/sidebar_icons/vector.svg';
import { ReactComponent as Union } from '../../assets/icons/sidebar_icons/union_1.svg';
import { ReactComponent as CreatorLogo } from '../../assets/icons/sidebar_icons/creator.svg';
import { ReactComponent as ArtWorkLogo } from '../../assets/icons/sidebar_icons/artWork_logo.svg';
import { ReactComponent as Particle } from '../../assets/icons/sidebar_icons/particle.svg';
import { ReactComponent as VenueLogo } from '../../assets/icons/sidebar_icons/venue.svg';
import { ReactComponent as TagLogo } from '../../assets/icons/sidebar_icons/tag.svg';
import { ReactComponent as SparkleLogo } from '../../assets/icons/sidebar_icons/sparkle.svg';
import { ReactComponent as OccurenceLogo } from '../../assets/icons/sidebar_icons/occurence.svg';
import { ReactComponent as ThemeLogo } from '../../assets/icons/sidebar_icons/theme.svg';
import { ReactComponent as ContentLogo } from '../../assets/icons/sidebar_icons/content.svg';
import { ReactComponent as AboutLogo } from '../../assets/icons/sidebar_icons/aboutlogo.svg';
class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.refDiv = React.createRef();
    }

    componentDidMount() {
        this.initMenu();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
        new MetisMenu('#side-menu');

        let matchingMenuItem = null;
        const ul = document.getElementById('side-menu');
        const items = ul.getElementsByTagName('a');
        for (let i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    // componentDidUpdate() {}

    scrollElement = item => {
        setTimeout(() => {
            if (this.refDiv.current !== null) {
                if (item) {
                    const currentPosition = item.offsetTop;
                    if (currentPosition > window.innerHeight) {
                        if (this.refDiv.current) this.refDiv.current.getScrollElement().scrollTop = currentPosition - 300;
                    }
                }
            }
        }, 300);
    };

    activateParentDropdown = item => {
        item.classList.add('active');
        const parent = item.parentElement;

        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show');
        }

        if (parent) {
            parent.classList.add('mm-active');
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add('mm-show'); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[0].classList.add('mm-active'); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add('mm-show'); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add('mm-show'); // li
                            parent5.childNodes[0].classList.add('mm-active'); // a tag
                        }
                    }
                }
            }
            this.scrollElement(item);
            return false;
        }
        this.scrollElement(item);
        return false;
    };

    activeCheckArtwork = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/artworks-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/artworks-list';
        }
    };

    activeCheckParticles = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/particle-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/particle-list';
        }
    };

    activeCheckVenue = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/venues-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/venues';
        }
    };

    activeCheckTag = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/tag-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/tags';
        }
    };

    activeCheckTheme = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/themes-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/themes';
        }
    };

    activeCheckExhibition = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/exhibition-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/exhibition-list';
        }
    };

    activeCheckArticle = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/article-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/articles';
        }
    };

    activeCheckExternal = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/external-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/external-list';
        }
    };

    activeCheckGallery = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/gallery-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/gallery-list';
        }
    };

    activeCheckCreatorInd = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/cindividuals-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/individual-creators';
        }
    };

    activeCheckCreatorComp = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/company-creator-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/company-creators';
        }
    };

    activeCheckCreator = () => {
        if ((this.props?.history?.location?.pathname).includes('/web/creator-details/')) {
            return this.props?.history?.location?.pathname;
        } else {
            return '/web/creators';
        }
    };

    render() {
        const {
            match: { params }
        } = this.props;
        return (
            <React.Fragment>
                <SimpleBar className="h-100" ref={this.refDiv}>
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li>
                                <NavLink to="/web/dashboard" className={isActive => (isActive ? 'active-style' : '')}>
                                    <VectorLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Dashboards')}</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/web/all-users-list" className={isActive => (isActive ? 'active-style' : '')}>
                                    <Union />
                                    <span style={{ marginLeft: 35 }}>{this.props.t('App Users')}</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to={this.activeCheckCreator()} className={isActive => (isActive ? 'active-style' : '')}>
                                    <CreatorLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Creators')}</span>
                                </NavLink>
                            </li>

                            {/*<li>
                                <NavLink
                                    to="/#"
                                    className={isActive => (isActive ? 'arrow' : 'has-arrow')}
                                    isActive={(_, location) => {
                                        let pathInd = '';
                                        if ((this.props?.history?.location?.pathname).includes('/web/cindividuals-details/')) {
                                            pathInd = this.props?.history?.location?.pathname;
                                        }
                                        let pathComp = '';
                                        if ((this.props?.history?.location?.pathname).includes('/web/company-creator-details/')) {
                                            pathComp = this.props?.history?.location?.pathname;
                                        }
                                        let pathCreator = '';
                                        if ((this.props?.history?.location?.pathname).includes('/web/creator-details/')) {
                                            pathCreator = this.props?.history?.location?.pathname;
                                        }
                                        const cLinks = ['/web/individual-creators', '/web/company-creators', '/web/creators', pathInd, pathComp, pathCreator];

                                        return cLinks.includes(location.pathname);
                                    }}
                                >
                                    <CreatorLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Creators')}</span>
                                </NavLink>
                                <ul className="sub-menu" aria-expanded="false" style={{ marginLeft: 17 }}>
                                    <li>
                                        <NavLink to={this.activeCheckCreatorInd()}>{this.props.t('Individual Creators')}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={this.activeCheckCreatorComp()}>{this.props.t('Creative Companies')}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={this.activeCheckCreator()}>{this.props.t('New Creators')}</NavLink>
                                    </li>
                                </ul>
                            </li>*/}

                            <li>
                                <NavLink to={this.activeCheckArtwork()} className={isActive => (isActive ? 'active-style' : '')}>
                                    <ArtWorkLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Artworks')}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={this.activeCheckParticles()} className={isActive => (isActive ? 'active-style' : '')}>
                                    <Particle />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Particles')}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={this.activeCheckVenue()} className={isActive => (isActive ? 'active-style' : '')}>
                                    <VenueLogo />
                                    <span style={{ marginLeft: 35 }}>{this.props.t('Venues')}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={this.activeCheckTag()} className={isActive => (isActive ? 'active-style' : '')}>
                                    <TagLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Tags')}</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/#"
                                    className={isActive => (isActive ? 'arrow' : 'has-arrow')}
                                    isActive={(_, location) => {
                                        let pathExb = '';
                                        if ((this.props?.history?.location?.pathname).includes('/web/exhibition-details/')) {
                                            pathExb = this.props?.history?.location?.pathname;
                                        }

                                        const occLinks = ['/web/exhibition-list', '/web/events', pathExb];

                                        return occLinks.includes(location.pathname);
                                    }}
                                >
                                    <OccurenceLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Occurences')}</span>
                                </NavLink>
                                <ul className="sub-menu" aria-expanded="false" style={{ marginLeft: 17 }}>
                                    <li>
                                        <Link to={this.activeCheckExhibition()}>{this.props.t('Exhibitions')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/web/events" style={this.props.t ? { pointerEvents: 'none' } : null}>
                                            {this.props.t('Events')}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={this.activeCheckTheme()} className={isActive => (isActive ? 'active-style' : '')}>
                                    <ThemeLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Themes')}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/web/sprakle" className={isActive => (isActive ? 'active-style' : '')} style={this.props.t ? { pointerEvents: 'none' } : null}>
                                    <SparkleLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Sparkles')}</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/#"
                                    className={isActive => (isActive ? 'arrow' : 'has-arrow')}
                                    isActive={(_, location) => {
                                        let pathArt = '';
                                        if ((this.props?.history?.location?.pathname).includes('/web/article-details/')) {
                                            pathArt = this.props?.history?.location?.pathname;
                                        }
                                        let pathGal = '';
                                        if ((this.props?.history?.location?.pathname).includes('/web/gallery-details/')) {
                                            pathGal = this.props?.history?.location?.pathname;
                                        }
                                        let pathExt = '';
                                        if ((this.props?.history?.location?.pathname).includes('/web/external-details/')) {
                                            pathExt = this.props?.history?.location?.pathname;
                                        }
                                        const conLink = ['/web/articles', '/web/gallery-list', pathArt, pathGal, pathExt];
                                        return conLink.includes(location.pathname);
                                    }}
                                >
                                    <ContentLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('Contents')}</span>
                                </NavLink>

                                <ul className="sub-menu" aria-expanded="false" style={{ marginLeft: 17 }}>
                                    <li>
                                        <Link to={this.activeCheckArticle()}>{this.props.t('Articles')}</Link>
                                    </li>
                                    <li>
                                        <Link to={this.activeCheckGallery()}>{this.props.t('Galleries')}</Link>
                                    </li>
                                    <li>
                                        <Link to={this.activeCheckExternal()}>{this.props.t('Externals')}</Link>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <NavLink
                                    to="/#"
                                    className={isActive => (isActive ? 'arrow' : 'has-arrow')}
                                    isActive={(_, location) => {
                                        const conLink = ['/web/privacy', '/web/terms', '/web/aboutus'];
                                        return conLink.includes(location.pathname);
                                    }}
                                >
                                    <AboutLogo />
                                    <span style={{ marginLeft: 30 }}>{this.props.t('About Particle')}</span>
                                </NavLink>

                                <ul className="sub-menu" aria-expanded="false" style={{ marginLeft: 17 }}>
                                    <li>
                                        <Link to="/web/aboutus">{this.props.t('About Us')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/web/privacy">{this.props.t('Privacy')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/web/terms">{this.props.t('Terms')}</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </SimpleBar>
            </React.Fragment>
        );
    }
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
    match: PropTypes.object,
    history: PropTypes.object
};

export default withRouter(withTranslation()(SidebarContent));
