/* Tags */
export const GET_VENUES = 'GET_VENUES';
export const GET_VENUES_FAIL = 'GET_VENUES_FAIL';
export const GET_VENUES_SUCCESS = 'GET_VENUES_SUCCESS';

export const DELETE_VENUES = 'DELETE_VENUES';
export const DELETE_VENUES_SUCCESS = 'DELETE_VENUES_SUCCESS';
export const DELETE_VENUES_FAIL = 'DELETE_VENUES_FAIL';

export const UPDATE_VENUES_SUCCESS = 'UPDATE_VENUES_SUCCESS';
export const UPDATE_VENUES = 'UPDATE_VENUES';
//others------

/* USERS PROFILE */
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const ADD_NEW_USER = 'ADD_NEW_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
