import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { css } from '@emotion/react';
import GridLoader from 'react-spinners/GridLoader';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 auto;
`;

class Loader extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    render() {
        return (
            <div className="sweet-loading" style={{ marginTop: '40px', marginBottom: '40px' }}>
                <GridLoader color={'#000000'} loading={this.props.loading} css={override} size={30} />
            </div>
        );
    }
}
Loader.propTypes = {
    color: PropTypes.string,
    className: PropTypes.any,
    loading: PropTypes.bool
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Loader));
