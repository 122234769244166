import { DELETE_TAG_SUCCESS, DELETE_TAGS_FAIL, GET_TAGS_SUCCESS, GET_TAGS_FAIL, GET_TAGS, DELETE_TAG, GET_TAG, GET_TAG_FAIL, UPDATE_TAG, UPDATE_TAG_FAIL } from './actionTypes';

export const getTags = () => ({
    type: GET_TAGS
});

export const getTag = tag => ({
    type: GET_TAG,
    payload: tag
});

export const getTagFail = error => ({
    type: GET_TAG_FAIL,
    payload: error
});

export const deleteTag = tag => ({
    type: DELETE_TAG,
    payload: tag
});

export const updateTag = tag => ({
    type: UPDATE_TAG,
    payload: tag
});

export const updateTagFailed = error => ({
    type: UPDATE_TAG_FAIL,
    payload: error
});

export const deleteTagSuccess = tag => ({
    type: DELETE_TAG_SUCCESS,
    payload: tag
});

export const getTagsSuccess = tags => ({
    type: GET_TAGS_SUCCESS,
    payload: tags
});

export const getTagsFail = error => ({
    type: GET_TAGS_FAIL,
    payload: error
});

export const deletTagsFailed = error => ({
    type: DELETE_TAGS_FAIL,
    payload: error
});

//-----Others
