import PropTypes from 'prop-types';

import React, { useState } from 'react';
const MobilePreviewsParticle = ({
    previewtype,
    setPreviewUrl,
    cropImageSet,
    titleColour,
    postTitleColour,
    ftitle,
    postTitle,
    descriptionColour,
    postDescriptionColor,
    description,
    postDescription,
    subTitle,
    postSubTitle,
    subTitleColour,
    postSubtitleColour,
    thumbnailType
}) => {
    return (
        <>
            <div style={{ marginTop: '-100px' }} className="device device-iphone-8 device-spacegray">
                <div className="device-frame">
                    {previewtype === 'Image' ? (
                        <div style={{ width: '100%', height: '99%', backgroundImage: 'url(' + setPreviewUrl + ')', backgroundSize: 'cover' }}>
                            <br />
                            <h4 style={{ color: cropImageSet ? titleColour : postTitleColour, marginLeft: '13px' }}>{cropImageSet ? ftitle : postTitle}</h4>
                            <div style={{ position: 'absolute', bottom: '130px', left: '15px' }}>
                                <h5 style={{ marginLeft: '16px', marginRight: '15px', whiteSpace: 'pre-wrap', color: cropImageSet ? subTitleColour : postSubtitleColour }}>
                                    {cropImageSet ? subTitle : postSubTitle}
                                </h5>
                                <p style={{ marginLeft: '16px', marginRight: '15px', color: cropImageSet ? descriptionColour : postDescriptionColor, whiteSpace: 'pre-wrap' }}>
                                    {cropImageSet ? description : postDescription}
                                </p>
                            </div>
                            <br />
                            <br />
                            <br />
                        </div>
                    ) : previewtype === 'Cover Page' ? (
                        <div style={{ width: '100%', height: '84%', backgroundImage: 'url(' + setPreviewUrl + ')', backgroundSize: 'cover' }}></div>
                    ) : (
                        <div style={{ marginTop: '50px' }}>
                            {thumbnailType === 'Venue' ? (
                                <div style={{ marginTop: '50px' }}>
                                    <div style={{ margin: '5px' }}>
                                        <img width={'100%'} src={setPreviewUrl}></img>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ margin: '5px' }}>
                                    <div style={{ width: '50%', height: '50%', textAlign: 'center', borderRadius: '50%' }}>
                                        <img style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', borderRadius: '50%', marginLeft: 'auto', marginRight: 'auto' }} src={setPreviewUrl}></img>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="device-stripe"></div>
                <div className="device-header"></div>
                <div className="device-sensors"></div>
                <div className="device-btns"></div>
                <div className="device-power"></div>
            </div>
        </>
    );
};
MobilePreviewsParticle.propTypes = {
    previewtype: PropTypes.string,
    setPreviewUrl: PropTypes.string,
    cropImageSet: PropTypes.bool,
    titleColour: PropTypes.string,
    postTitleColour: PropTypes.string,
    ftitle: PropTypes.string,
    postTitle: PropTypes.string,
    descriptionColour: PropTypes.string,
    postDescriptionColor: PropTypes.string,
    description: PropTypes.string,
    postDescription: PropTypes.string,
    thumbnailType: PropTypes.string,
    subTitle: PropTypes.string,
    postSubTitle: PropTypes.string,
    subTitleColour: PropTypes.string,
    postSubtitleColour: PropTypes.string
};

export default MobilePreviewsParticle;
