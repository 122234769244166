import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

// Crypto Redux States
import { GET_CHARTS_DATA } from './actionTypes';

function* dashboardSaga() {
    // yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;
