import {
    GET_PARTICLES_SUCCESS,
    GET_PARTICLES_FAIL,
    GET_PARTICLES,
    DELETE_PARTICLES_SUCCESS,
    DELETE_PARTICLES_FAIL,
    DELETE_PARTICLES,
    UPDATE_PARTICLES,
    UPDATE_PARTICLES_FAIL,
    UPDATE_PARTICLES_SUCCESS
} from './actionTypes';

const INIT_STATE = {
    particles: [],
    error: {}
};

const particles = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PARTICLES_SUCCESS:
            return {
                ...state,
                particles: action.payload
            };

        case GET_PARTICLES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_PARTICLES_SUCCESS:
            return {
                ...state,
                particles: state.particles.filter(particles => particles.id.toString() !== action.payload.id.toString())
            };

        case DELETE_PARTICLES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_PARTICLES_SUCCESS:
            return {
                ...state,
                particles: state.particles.map(article => (article.id.toString() === action.payload.id.toString() ? { article, ...action.payload } : article))
            };

        case UPDATE_PARTICLES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default particles;
