/* PARTICLES */
export const GET_PARTICLES = 'GET_PARTICLES';
export const GET_PARTICLES_FAIL = 'GET_PARTICLES_FAIL';
export const GET_PARTICLES_SUCCESS = 'GET_PARTICLES_SUCCESS';

export const DELETE_PARTICLES = 'DELETE_PARTICLES';
export const DELETE_PARTICLES_SUCCESS = 'DELETE_PARTICLES_SUCCESS';
export const DELETE_PARTICLES_FAIL = 'DELETE_PARTICLES_FAIL';

export const UPDATE_PARTICLES = 'UPDATE_PARTICLES';
export const UPDATE_PARTICLES_SUCCESS = 'UPDATE_PARTICLES_SUCCESS';
export const UPDATE_PARTICLES_FAIL = 'UPDATE_PARTICLES_FAIL';
//others------
