import axios from 'axios';
import { resolve } from './resolve.js';
import { API_URL } from '../../constants/apiurl';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});
const axiosHeaderConfigMutipart = token => ({
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export async function callUploadTypes() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator/upload/types', axiosconf).then(res => res.data));
}

export async function callCreators() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator', axiosconf).then(res => res.data));
}

export async function postCreatorsOrder(id, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return axios.post(`${API_URL}/tt/exhibition/${id}/creator/mapping/order`, postdata, axiosconf).then(res => res.data);
}

export async function postRelatedCreatorsOrder(id, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return axios.post(`${API_URL}/tt/creator/${id}/creator/mapping/order`, postdata, axiosconf).then(res => res.data);
}

