/* Tags */
export const GET_CINDIVIDUALS = 'GET_CINDIVIDUALS';
export const GET_CINDIVIDUALS_FAIL = 'GET_CINDIVIDUALS_FAIL';
export const GET_CINDIVIDUALS_SUCCESS = 'GET_CINDIVIDUALS_SUCCESS';
export const DELETE_CINDIVIDUALS = 'DELETE_CINDIVIDUALS';
export const DELETE_CINDIVIDUALS_SUCCESS = 'DELETE_CINDIVIDUALS_SUCCESS';
export const DELETE_CINDIVIDUALS_FAIL = 'DELETE_CINDIVIDUALS_FAIL';
export const UPDATE_CINDIVIDUALS_SUCCESS = 'UPDATE_CINDIVIDUALS_SUCCESS';
export const UPDATE_CINDIVIDUALS = 'UPDATE_CINDIVIDUALS';
export const DELETE_CREATORS = 'DELETE_CREATORS';
export const DELETE_CREATORS_FAIL = 'DELETE_CREATORS_FAIL';
//others------
