import axios from 'axios';
import { resolve } from './resolve.js';
import { API_URL } from '../../constants/apiurl';


//TODO: this file should be removed when ContentsGalleries/gallery-details.js refactor will be done. File content_apis.js should be used instead
const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});
const axiosHeaderConfigMutipart = token => ({
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export async function callGalleryVenues(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/content/' + id + '/venue/mapping', axiosconf).then(res => res.data));
}
export async function postGalleryVenues(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/content/' + id + '/venue/mapping', postData, axiosconf).then(res => res.data));
}

export async function callGalleryArtworks(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/content/' + id + '/artwork/mapping', axiosconf).then(res => res.data));
}
export async function postGalleryArtworks(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/content/' + id + '/artwork/mapping', postData, axiosconf).then(res => res.data));
}

export async function callGalleryCreators(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/content/' + id + '/creator/mapping', axiosconf).then(res => res.data));
}
export async function postGalleryCreators(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/content/' + id + '/creator/mapping', postData, axiosconf).then(res => res.data));
}
