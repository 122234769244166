import {
    GET_CREATORS_SUCCESS,
    GET_CREATORS_FAIL,
    DELETE_CREATORS_SUCCESS,
    UPDATE_CREATORS_SUCCESS,
    DELETE_CREATORS_FAIL
} from './actionTypes';

const INIT_STATE = {
    creators: [],
    error: {}
};

const creators = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CREATORS_SUCCESS:
            return {
                ...state,
                creators: action.payload
            };

        case GET_CREATORS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_CREATORS_SUCCESS:
            return {
                ...state,
                creators: state.creators.filter(creators => creators.id.toString() !== action.payload.id.toString())
            };

        case DELETE_CREATORS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_CREATORS_SUCCESS:
            return {
                ...state,
                creators: state.creators.map(creator => (creator.id.toString() === action.payload.id.toString() ? { creator, ...action.payload } : creator))
            };

        //----others ----

        default:
            return state;
    }
};

export default creators;
