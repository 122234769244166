import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import DragAndDropItem from "./DragAndDropItem";

class DragAndDropList extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {};
    }

    componentDidMount() {
        const {
            match: {params}
        } = this.props;
    }

    render() {
        return (
            <DragDropContext onDragEnd={this.props.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <tbody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                                ...(snapshot.isDraggingOver ? this.props.dragListStyle : {}),
                            }}>
                        {this.props.list.map((item, index) => (
                            <DragAndDropItem
                                key={item.id.toString()}
                                index={index}
                                item={item}
                                {...this.props}
                            />
                        ))}
                        {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

DragAndDropList.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    list: PropTypes.array,
    onDragEnd: PropTypes.func,
    dragListStyle: PropTypes.object,
    droppableHeader: PropTypes.func
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DragAndDropList));
