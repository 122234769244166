import {
    GET_TAGS_SUCCESS,
    GET_TAGS_FAIL,
    DELETE_TAG_SUCCESS,
    DELETE_TAGS_FAIL,
    GET_TAG,
    GET_TAG_FAIL,
    UPDATE_TAG,
    UPDATE_TAG_FAIL,
    ADD_USER_SUCCESS,
    ADD_USER_FAIL,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAIL
} from './actionTypes';

const INIT_STATE = {
    tags: [],
    error: {}
};

const tags = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TAGS_SUCCESS:
            return {
                ...state,
                tags: action.payload
            };

        case GET_TAGS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case GET_TAG:
            return {
                tags: action.payload
            };

        case GET_TAG_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_TAG_SUCCESS:
            return {
                ...state,
                tags: state.tags.filter(tag => tag.id.toString() !== action.payload.id.toString())
            };

        case DELETE_TAGS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_TAG:
            return {
                ...state,
                tags: state.tags.map(tag => (tag.id.toString() === action.payload.id.toString() ? { tag, ...action.payload } : tag))
            };

        case UPDATE_TAG_FAIL:
            return {
                ...state,
                error: action.payload
            };
        //----others ----

        case ADD_USER_SUCCESS:
            return {
                ...state,
                users: [...state.users, action.payload]
            };

        case ADD_USER_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: action.payload
            };

        case GET_USER_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default tags;
