import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Col, Row, Table} from 'reactstrap'
import axios from 'axios'
import { API_URL } from '../../../constants/apiurl'
import Loader from '../../../components/Common/Loader'
import SweetAlert from 'react-bootstrap-sweetalert'
import DragAndDropList from "../../../components/DragAndDropList"
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { postArtworksOrder } from '../../../helpers/Api/artwork_apis'

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
})

class Artworks extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            viewStatus: false,
            artworks: [],
            selectedArtworks: [],
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,

            // Artworks Ordering
            showOrdering: false,
            orderingList: []
        }
    }

    componentDidMount() {
        this.getArtworks()
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getArtworks()
        }
    }

    getArtworks = () => {
        let obj = this.getAuthDetails()
        let axiosconf = axiosHeaderConfig(obj.access_token)
        let eid = this.getExhibId()

        if (!eid || !eid.id) return
        
        this.setState({ loading: true})

        axios.get(`${API_URL}/tt/exhibition/${eid.id}/artwork/mapping`, axiosconf)
            .then(response => {
             this.setState({
                    selectedArtworks: response.data.sort((a, b) => a.orderPosition - b.orderPosition),
                    loading: false
                    });
                })
                .catch(e => { console.log(`------GET artwork ERROR-----${JSON.stringify(e)}`) })
    
    }

    getExhibId = () =>
        localStorage.getItem('exhibid') && JSON.parse(localStorage.getItem('exhibid'))
        
    handleAction = (ordering) =>
        ordering 
            ? this.setArtworksOrder()
            : this.postArtworks()

    postArtworks = () => {
        let obj = this.getAuthDetails()
        let axiosconf = axiosHeaderConfig(obj.access_token)
        let eid = this.getExhibId()

        if (!eid || !eid.id) return

        if(this.state.selectedArtworks.filter(a => a.isDefault).length > 2){
            this.setState({ showAlert: true, alertMessage: 'Max 2 selections allowed!' })
            return 
        }

        this.setState({ loading: true })

        axios
            .post(`${API_URL}/tt/exhibition/${eid.id}/artwork/mapping`, this.state.selectedArtworks, axiosconf)
            .then(response => {
                this.setState({ selectedArtworks: [] })
                this.setState({ showAlertSuccess: true, successMessage: 'Updated Artworks!' })
                this.getArtworks()
            })
            .catch(e => {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    this.setState({ showAlert: true, alertMessage: error.message });
                }
            })
    }

    setArtworksOrder = () => {
        let eid = this.getExhibId()

        if (!eid || !eid.id) return

        this.setState({loading: true});
        postArtworksOrder(eid.id, this.state.orderingList)
            .then(response => {
                this.setState({
                    selectedArtworks: response.sort((a, b) => a.orderPosition - b.orderPosition)
                })
            })
            .catch(e => console.log('SET ARTWORKS ORDER ERROR', e))
            .finally(() => {
                this.setState({loading: false})
                this.handleCloseOrdering()
            })
    }

    getAuthDetails = () => 
        localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser'))

    //------- Info handling ---- Country | State | City
    handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        let obj = this.getAuthDetails()
        let axiosconf = obj && axiosHeaderConfig(obj.access_token)
  
        axios
            .get(`${API_URL}/tt/artwork?page=0&size=20&key=${string}&status=Published&sort=name,asc`, axiosconf)
            .then(response => { this.setState({ artworks: response.data.content }) })
            .catch(e => { console.log(` error ${JSON.stringify(e)}`) })
    }

    handleCloseOrdering = () => {
        this.setState({
            showOrdering: false,
            orderingList: []
        })
    }

    handleDragEnd = ({destination, source}) => {
        if (!destination) return

        this.setState({orderingList: this.reorder(this.state.orderingList, source.index, destination.index)})
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    };

    render() {
        return (
            <div>
                {this.state.showAlert && <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}> {this.state.alertMessage}</SweetAlert>}
                {this.state.showAlertSuccess && <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}> {this.state.successMessage} </SweetAlert>}
                <div>
                    <div className="text-center mt-0">
                        <h5>{this.state.showOrdering ? "Artworks List | Ordering" : "Artworks List"}</h5>
                    </div>
                    {this.state.showOrdering &&
                        <div style={{ display: 'flex' }}>
                            <button onClick={this.handleCloseOrdering}
                                    style={{marginLeft: 'auto'}}
                                    type="button"
                                    className="btn btn-primary mb-5">
                                        <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                        Back to Artworks
                            </button>   
                        </div>}
                        {this.state.loading
                        ? <Loader color={'green'} loading={this.state.loading} />
                        : <div>
                            {this.state.showOrdering
                            ? <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead className="table-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Artwork name</th>
                                        <th>Creator</th>
                                    </tr>
                                    </thead>
                                    <DragAndDropList
                                        list={this.state.orderingList}
                                        onDragEnd={this.handleDragEnd}
                                        dragItemStyle={{
                                            background: 'lightgreen',
                                            borderRadius: '16px',
                                        }}
                                        dragListStyle={{
                                            background: 'lightblue',
                                            borderRadius: '16px',
                                        }}
                                    >
                                        {(item) => (
                                            <>
                                                <td>{item.id}</td>
                                                <td>{item.artwork.name}</td>
                                                <td></td>
                                            </>
                                        )}
                                    </DragAndDropList>
                                </Table>
                            </div>
                            : <div>
                                <Row style={{ marginTop: '20px' }}>
                                    <Col md={6}>
                                        <ReactSearchAutocomplete
                                            styling={{
                                                backgroundColor: '#fff',
                                                zIndex: 2,
                                                placeholder: 'Type Country',
                                                placeholderColor: '#939294',
                                                borderRadius: '4px',
                                                border: '1px solid #ced4da',
                                                height: '35px',
                                                fontSize: 16
                                            }}
                                            items={this.state.artworks}
                                            placeholder={'Type artwork name here'}
                                            onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                            onHover={() => {}}
                                            onSelect={item => {
                                                let found = this.state.selectedArtworks.find(a => a.artwork.id === item.id);

                                                if (!found) {
                                                    this.setState({ selectedArtworks: [...this.state.selectedArtworks, { id: '', artwork: item, isDefault: false }] });
                                                }
                                            }}
                                            onFocus={() => {}}
                                            formatResult={formatResult}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <button
                                            onClick={() =>
                                                this.setState({
                                                    showOrdering: true,
                                                    orderingList: this.state.selectedArtworks
                                                        ?.sort((a, b) => a.orderPosition - b.orderPosition)
                                                })
                                            }
                                            className="btn btn-primary w-xm mt-2">
                                                Change order
                                        </button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '15px' }}>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Artwork name</th>
                                                        <th>Creator</th>
                                                        <th>Showcase on home (Max 2)</th>
                                                        <th>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.selectedArtworks.map((item, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <th scope="row">{idx + 1}</th>
                                                                <td>{item.artwork.name}</td>
                                                                <td></td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customCheckcolor1"
                                                                        name="isDefault"
                                                                        checked={item.isDefault}
                                                                        onChange={e => {
                                                                            this.setState(prevState => ({
                                                                                selectedArtworks: prevState.selectedArtworks.map(artwork =>
                                                                                    artwork.artwork.name === item.artwork.name
                                                                                        ? {
                                                                                            ...artwork,
                                                                                            isDefault: e.target.checked
                                                                                        }
                                                                                        : artwork
                                                                                )
                                                                            }));
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {' '}
                                                                    <i
                                                                        className="mdi mdi-delete font-size-18"
                                                                        id="deletetooltip"
                                                                        onClick={() => {
                                                                            const reducedArr = this.state.selectedArtworks.filter((item, itemIndex) => {
                                                                                return itemIndex !== idx;
                                                                            });

                                                                            this.setState({
                                                                                selectedArtworks: reducedArr
                                                                            });
                                                                        }}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <br />
                            </div>}
                            <div className="text-center mt-4">
                                <button
                                    disabled={this.props.viewStatus}
                                    onClick={() => this.handleAction(this.state.showOrdering)}
                                    type="submit" className="btn btn-primary w-md">
                                        Save {this.state.showOrdering ? " order" : ""}
                                </button>
                            </div>
                    </div>}
                </div>
            </div>
        )
    }
}

Artworks.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab3: PropTypes.func,

    //t: PropTypes.any,
    history: PropTypes.any,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Artworks))

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </>
    );
};
