import { GET_CCOMPANIES_SUCCESS, GET_CCOMPANIES_FAIL, DELETE_CCOMPANIES_SUCCESS, DELETE_CCOMPANIES_FAIL, UPDATE_CCOMPANIES_SUCCESS } from './actionTypes';

const INIT_STATE = {
    ccompanies: [],
    error: {}
};

const ccompanies = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CCOMPANIES_SUCCESS:
            return {
                ...state,
                ccompanies: action.payload
            };

        case GET_CCOMPANIES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_CCOMPANIES_SUCCESS:
            return {
                ...state,
                ccompanies: state.ccompanies.filter(ccompanies => ccompanies.id.toString() !== action.payload.id.toString())
            };

        case DELETE_CCOMPANIES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_CCOMPANIES_SUCCESS:
            return {
                ...state,
                ccompanies: state.ccompanies.map(ccompany => (ccompany.id.toString() === action.payload.id.toString() ? { ccompany, ...action.payload } : ccompany))
            };

        //----others ----

        default:
            return state;
    }
};

export default ccompanies;
