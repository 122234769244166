import { artworks } from '../../common/data';
import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_FAIL,
    GET_USER_PROFILE_SUCCESS,
    ADD_NEW_USER,
    ADD_USER_SUCCESS,
    ADD_USER_FAIL,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DELETE_ARTWORKS_SUCCESS,
    DELETE_ARTWORKS_FAIL,
    GET_ARTWORKS_FAIL,
    GET_ARTWORKS_SUCCESS,
    GET_ARTWORKS,
    DELETE_ARTWORKS,
    UPDATE_ARTWORKS
} from './actionTypes';

export const getArtWorks = () => ({
    type: GET_ARTWORKS
});

export const deleteArtWorks = artworks => ({
    type: DELETE_ARTWORKS,
    payload: artworks
});

export const getArtWorksSuccess = artworks => ({
    type: GET_ARTWORKS_SUCCESS,
    payload: artworks
});

export const getArtWorksFail = error => ({
    type: GET_ARTWORKS_FAIL,
    payload: error
});
export const deleteArtWorksSuccess = artworks => ({
    type: DELETE_ARTWORKS_SUCCESS,
    payload: artworks
});

export const deleteArtWorksFail = error => ({
    type: DELETE_ARTWORKS_FAIL,
    payload: error
});

export const updateArtWorks = artworks => ({
    type: UPDATE_ARTWORKS,
    payload: artworks
});

//-----Others
export const getUserProfile = () => ({
    type: GET_USER_PROFILE
});

export const getUserProfileSuccess = userProfile => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload: userProfile
});

export const getUserProfileFail = error => ({
    type: GET_USER_PROFILE_FAIL,
    payload: error
});

export const addNewUser = user => ({
    type: ADD_NEW_USER,
    payload: user
});

export const addUserSuccess = user => ({
    type: ADD_USER_SUCCESS,
    payload: user
});

export const addUserFail = error => ({
    type: ADD_USER_FAIL,
    payload: error
});

export const updateUser = user => ({
    type: UPDATE_USER,
    payload: user
});

export const updateUserSuccess = user => ({
    type: UPDATE_USER_SUCCESS,
    payload: user
});

export const updateUserFail = error => ({
    type: UPDATE_USER_FAIL,
    payload: error
});
