import PropTypes, { string, object, array, oneOfType } from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Player } from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css';

const MobileVideoPreviews = ({ videoUrl, videoThumnailPreview, urlVidFlag }) => {
    const [preview, setPreview] = useState(false);

    return (
        <>
            <div style={{ marginTop: '-100px' }} className="device device-iphone-8 device-spacegray">
                <div className="device-frame">
                    {videoThumnailPreview !== '' && preview === false ? (
                        <div style={{ position: 'absolute', top: '110px', right: '30px', zIndex: 5 }}>
                            <button
                                onClick={() => {
                                    setPreview(true);
                                }}
                                type="button"
                                className="btn btn-light btn-sm"
                            >
                                Thumbnail
                            </button>
                        </div>
                    ) : (
                        <div style={{ position: 'absolute', top: '110px', right: '30px', zIndex: 5 }}>
                            <button
                                onClick={() => {
                                    setPreview(false);
                                }}
                                type="button"
                                className="btn btn-light btn-sm"
                            >
                                Video
                            </button>
                        </div>
                    )}

                    {preview ? (
                        <div style={{ marginTop: '50px' }}>
                            <div style={{ margin: '5px' }}>
                                <img width={'100%'} src={videoThumnailPreview}></img>
                            </div>
                        </div>
                    ) : (
                        <div style={{ width: '100%', height: '99%', backgroundSize: 'cover' }}>
                            <Player playsInline fluid={false} width={'100%'} height={'100%'}>
                                <source src={urlVidFlag ? videoUrl : URL.createObjectURL(videoUrl)} />
                            </Player>
                        </div>
                    )}
                </div>
                <div className="device-stripe"></div>
                <div className="device-header"></div>
                <div className="device-sensors"></div>
                <div className="device-btns"></div>
                <div className="device-power"></div>
            </div>
        </>
    );
};
MobileVideoPreviews.propTypes = {
    videoUrl: oneOfType([object, string]),
    videoThumnailPreview: PropTypes.string,
    urlVidFlag: PropTypes.bool
};

export default MobileVideoPreviews;
