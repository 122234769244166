const artworks = [
    {
        id: 1,
        title: 'Lucid themes gallery',
        creator: { id: 111, name: 'John Rambo' },
        created_date: '2022-04-19 02:26:48',
        collection: 'test collection',
        status: 'Publish',
        themes: 'Dark',
        tags: [
            { id: 1, tag: 'experimental art' },
            { id: 2, tag: 'Lucid art' }
        ],
        thumbnail_image: '',
        art_description: 'text description artwork testing testing....',
        images: [
            { id: 1, image: '.....' },
            { id: 2, image: '.....' },
            { id: 3, image: '.....' }
        ],
        other_information: { style: 'test style', technique_materials: 'technic material', real_measurements: 'test', collection: 'collection name', disclaimer: 'test disclaimer' }
    },
    {
        id: 2,
        title: 'McHenry arts',
        creator: { id: 111, name: 'John Rambo3' },
        created_date: '2022-04-19 02:26:48',
        collection: 'test collection',
        status: 'Publish',
        themes: 'Dark',
        tags: [
            { id: 1, tag: 'experimental art' },
            { id: 2, tag: 'Lucid art' }
        ],
        thumbnail_image: '',
        art_description: 'text description artwork testing testing....',
        images: [
            { id: 1, image: '.....' },
            { id: 2, image: '.....' },
            { id: 3, image: '.....' }
        ],
        other_information: { style: 'test style', technique_materials: 'technic material', real_measurements: 'test', collection: 'collection name', disclaimer: 'test disclaimer' }
    },
    {
        id: 3,
        title: 'villa arts',
        creator: { id: 111, name: 'John Rambo2' },
        created_date: '2022-04-19 02:26:48',
        collection: 'test collection',
        status: 'Publish',
        themes: 'Dark',
        tags: [
            { id: 1, tag: 'experimental art' },
            { id: 2, tag: 'Lucid art' }
        ],
        thumbnail_image: '',
        art_description: 'text description artwork testing testing....',
        images: [
            { id: 1, image: '.....' },
            { id: 2, image: '.....' },
            { id: 3, image: '.....' }
        ],
        other_information: { style: 'test style', technique_materials: 'technic material', real_measurements: 'test', collection: 'collection name', disclaimer: 'test disclaimer' }
    },
    {
        id: 4,
        title: 'Rainbow Gallery',
        creator: { id: 111, name: 'John Rambo1' },
        created_date: '2022-04-19 02:26:48',
        collection: 'test collection',
        status: 'Publish',
        themes: 'Dark',
        tags: [
            { id: 1, tag: 'experimental art' },
            { id: 2, tag: 'Lucid art' }
        ],
        thumbnail_image: '',
        art_description: 'text description artwork testing testing....',
        images: [
            { id: 1, image: '.....' },
            { id: 2, image: '.....' },
            { id: 3, image: '.....' }
        ],
        other_information: { style: 'test style', technique_materials: 'technic material', real_measurements: 'test', collection: 'collection name', disclaimer: 'test disclaimer' }
    }
];

export { artworks };
