import axios from 'axios';
import { resolve } from './resolve.js';
import { API_URL } from '../../constants/apiurl';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
export const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export async function callCountriesSearch(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/places/countries?page=0&size=10&name=' + string, axiosconf).then(res => res.data));
}

export async function callStatesSearch(string, country_id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/places/states/' + country_id + '?page=0&size=10&name=' + string, axiosconf).then(res => res.data));
}

export async function callCitiesSearch(string, state_id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/places/cities/' + state_id + '?page=0&size=10&name=' + string, axiosconf).then(res => res.data));
}

//Search Artwork
export async function searchArtwork(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/artwork?page=0&size=20&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchArtworkPublished(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/artwork?page=0&size=20&key=' + string + '&status=Published&sort=name,asc', axiosconf).then(res => res.data));
}

//Search Particles
export async function searchParticles(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/particle?page=0&size=20&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchVenue(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/venue?page=0&size=20&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchVenuePublished(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/venue?page=0&size=20&key=' + string + '&status=Published&sort=name,asc', axiosconf).then(res => res.data));
}

export async function searchTags(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/tag?page=0&size=20&name=' + string + '&sort=name,asc', axiosconf).then(res => res.data));
}

export async function searchPublishTags(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/tag?page=0&size=20&name=' + string + '&status=Published&sort=name,asc', axiosconf).then(res => res.data));
}

export async function searchThemes(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/theme?page=0&size=20&name=' + string + '&sort=name,asc', axiosconf).then(res => res.data));
}

export async function searchPublishThemes(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/theme?page=0&size=20&name=' + string + '&status=Published&sort=name,asc', axiosconf).then(res => res.data));
}

export async function searchCreators(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator?page=0&size=20&name=' + string, axiosconf).then(res => res.data));
}

export async function searchPublishCreators(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator?page=0&size=20&key=' + string + '&status=Published&sort=name,asc', axiosconf).then(res => res.data));
}

export async function searchCompany(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator?page=0&size=20&identifier=Company&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchIndividual(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator?page=0&size=20&identifier=Individual&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchCreatorsNew(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator?page=0&size=20&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchContent(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/content?page=0&size=20&key=' + string + '&status=Published&sort=title,asc', axiosconf).then(res => res.data));
}

export async function searchArticles(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/content?page=0&size=20&type=Article&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchExternals(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/content?page=0&size=20&type=External&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

/*
  export async function searchArticlesPublished(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token)
    return await resolve(axios.get(API_URL + '/tt/content?page=0&size=20&type=Article&key=' + string+'&status=Published&sort=title,asc', axiosconf).then(res => res.data));
  }*/

export async function searchGallery(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/content?page=0&size=20&type=Gallery&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchExhibition(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/exhibition?page=0&size=20&type=Exhibition&key=' + string + '&sort=id,desc', axiosconf).then(res => res.data));
}

export async function searchExhibitionPublished(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/exhibition?page=0&size=20&type=Exhibition&key=' + string + '&status=Published&status=Highlighted&sort=title,asc', axiosconf).then(res => res.data));
}

export async function callTags() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/tag', axiosconf).then(res => res.data));
}

export async function callThemes() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/theme', axiosconf).then(res => res.data));
}

export async function searchUser(string) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/users/all?page=0&size=20&key=' + string, axiosconf).then(res => res.data));
}

export async function getUserLastLoginTime(username) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/auth/users/session/for?username=' + username, axiosconf).then(res => res.data));
}

export const lengthVideo = file =>
    new Promise((resolve, reject) => {
        try {
            let video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function () {
                resolve(this);
            };

            video.onerror = function () {
                reject('Invalid video. Please select a video file.');
            };

            video.src = window.URL.createObjectURL(file);
        } catch (e) {
            reject(e);
        }
    });

export async function getDeeplink(postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/deep-link/create', postdata, axiosconf).then(res => res.data));
}
