import { IResponse } from "../interfaces/api/IResponse"
import { ITablePagination } from "../interfaces/table/ITablePagination"

export const getAccessToken = () =>
  localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser')).access_token || ''


export const axiosHeaderConfig = (contentType) => ({
  headers: {
    'Content-Type': !contentType ? 'application/json' : contentType,
    Accept: 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  }
})

export const getTablePaginationFromReponse = (response: IResponse<any>): ITablePagination => 
  ({ 
    size: response.size,
    page: response.number,
    totalItems: response.totalElements,
    totalPages: response.totalPages,
  })