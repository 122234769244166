import {
    DELETE_EXHIBITIONS_SUCCESS,
    DELETE_EXHIBITIONS_FAIL,
    GET_EXHIBITIONS_SUCCESS,
    GET_EXHIBITIONS_FAIL,
    GET_EXHIBITIONS,
    DELETE_EXHIBITIONS,
    UPDATE_EXHIBITIONS,
    UPDATE_EXHIBITIONS_SUCCESS,
    UPDATE_EXHIBITIONS_FAIL
} from './actionTypes';
//---
export const getExhibitions = () => ({
    type: GET_EXHIBITIONS
});
export const getExhibitionsSuccess = exhibitions => ({
    type: GET_EXHIBITIONS_SUCCESS,
    payload: exhibitions
});

export const getExhibitionsFail = error => ({
    type: GET_EXHIBITIONS_FAIL,
    payload: error
});
//-----

export const deleteExhibitions = exhibitions => ({
    type: DELETE_EXHIBITIONS,
    payload: exhibitions
});
export const deleteExhibitionsSuccess = exhibitions => ({
    type: DELETE_EXHIBITIONS_SUCCESS,
    payload: exhibitions
});

export const deleteExhibitionsFailed = error => ({
    type: DELETE_EXHIBITIONS_FAIL,
    payload: error
});

//---

export const updateExhibition = exhibitions => ({
    type: UPDATE_EXHIBITIONS,
    payload: exhibitions
});
export const updateExhibitionsSuccess = exhibitions => ({
    type: UPDATE_EXHIBITIONS_SUCCESS,
    payload: exhibitions
});
