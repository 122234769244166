import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_USERS, GET_USER_PROFILE, ADD_NEW_USER, DELETE_USER, UPDATE_USER } from './actionTypes';

import { getUsersSuccess, getUsersFail, getUserProfileSuccess, getUserProfileFail, addUserFail, addUserSuccess, updateUserSuccess, updateUserFail, deleteUserSuccess, deleteUserFail } from './actions';

//Include Both Helper File with needed methods
import { getUserProfile, addNewUser, updateUser, deleteUser, getAllUserss, postLogout } from '../../helpers/fakebackend_helper';
const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
function* fetchUsers() {
    try {
        let obj = getAuthDetails();
        //const response11 = yield call(postLogout, obj.access_token);
        const response = yield call(getAllUserss, obj.access_token);

        yield put(getUsersSuccess(response.content));
    } catch (error) {
        yield put(getUsersFail(error));
    }
}

function* fetchUserProfile() {
    try {
        const response = yield call(getUserProfile);
        yield put(getUserProfileSuccess(response));
    } catch (error) {
        yield put(getUserProfileFail(error));
    }
}

function* onAddNewUser({ payload: user }) {
    try {
        const response = yield call(addNewUser, user);
        yield put(addUserSuccess(response));
    } catch (error) {
        yield put(addUserFail(error));
    }
}

function* onUpdateUser({ payload: user }) {
    try {
        const response = yield call(updateUser, user);
        yield put(updateUserSuccess(response));
    } catch (error) {
        yield put(updateUserFail(error));
    }
}

function* onDeleteUser({ payload: user }) {
    try {
        const response = yield call(deleteUser, user);
        yield put(deleteUserSuccess(response));
    } catch (error) {
        yield put(deleteUserFail(error));
    }
}

function* allUsersSaga() {
    yield takeEvery(GET_USERS, fetchUsers);
    yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
    yield takeEvery(ADD_NEW_USER, onAddNewUser);
    yield takeEvery(UPDATE_USER, onUpdateUser);
    yield takeEvery(DELETE_USER, onDeleteUser);
}

export default allUsersSaga;
