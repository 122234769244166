import axios from 'axios';
import { resolve } from '../resolve.js';
import { API_URL } from '../../../constants/apiurl';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export async function getOnsiteList(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/exhibitions/' + id + '/route?page=0&sort=id,desc&size=20&type=On Site', axiosconf).then(res => res.data));
}

export async function deleteOnsite(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.delete(API_URL + '/tt/exhibition/route/mapping/' + id, axiosconf).then(res => res.data));
}

export async function postOnsiteColor(id, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/exhibition/site/' + id + '/on-site', postData, axiosconf).then(res => res.data));
}
