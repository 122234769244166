import {
    GET_ARTWORKS_SUCCESS,
    GET_ARTWORKS_FAIL,
    DELETE_ARTWORKS_SUCCESS,
    DELETE_ARTWORKS_FAIL,
    ADD_USER_SUCCESS,
    ADD_USER_FAIL,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAIL,
    UPDATE_ARTWORKS
} from './actionTypes';

const INIT_STATE = {
    artworks: [],
    error: {}
};

const artworks = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ARTWORKS_SUCCESS:
            return {
                ...state,
                artworks: action.payload
            };

        case GET_ARTWORKS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_ARTWORKS_SUCCESS:
            return {
                ...state,
                artworks: state.artworks.filter(artworks => artworks.id.toString() !== action.payload.id.toString())
            };

        case DELETE_ARTWORKS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        //----others ----

        case ADD_USER_SUCCESS:
            return {
                ...state,
                users: [...state.users, action.payload]
            };

        case ADD_USER_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: action.payload
            };

        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map(user => (user.id.toString() === action.payload.id.toString() ? { user, ...action.payload } : user))
            };

        case UPDATE_USER_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case GET_USER_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default artworks;
