import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Button, Col, Row, Table } from 'reactstrap';
import Loader from '../../../../components/Common/Loader';
import RouteDetails from './Details';
import RouteSteps from './Steps';
import { callAllRoutes, deleteRoute, postRouteOrder } from '../../../../helpers/Api/Occurences/routes';
import DragAndDropList from "../../../../components/DragAndDropList";

import { getExhibId } from '../../../../constants/utils';
import {API_URL} from "../../../../constants/apiurl";
import {IconButton} from "@mui/material";
import QrCode2 from "@mui/icons-material/QrCode2";

class RouteList extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            routelist: [],
            loading: false,
            showDetails: false,
            showSteps: false,
            id: '',
            routeid: '',
            routedetails: {},

            // Routes Ordering
            showOrdering: false,
            orderingList: []
        };
    }

    componentDidMount() {
        this.getRoutelist();
    }

    componentDidUpdate(previousProps) {
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getRoutelist();
        }
    }

    async getRoutelist() {
        let eid = getExhibId();

        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({ loading: true, id: eid.id });
            const response = await callAllRoutes(eid.id);
            if (response.error) {
                this.setState({ loading: false });
                console.log('error ---- ' + JSON.stringify(response));
            } else {
                this.setState({
                    routelist: response.data.sort((a, b) => a.orderPosition - b.orderPosition),
                    loading: false });
            }
        }
    }

    async removeRoute(id) {
        this.setState({ loading: true });
        const response = await deleteRoute(id);
        if (response.error) {
            this.setState({ loading: false });
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ loading: false })
            this.getRoutelist()
        }
    }

    handleCallbackRDetails = cdata => {
        this.setState({
            showDetails: cdata.showDetails,
            routedetails: {}
        });
        this.getRoutelist();
    }

    handleStepUpdate = id => {
        this.getRoutelist()
        setTimeout(() => {
            this.setState({ routedetails: this.state.routelist.find(r => r.id === id) });
        }, 1000);
    }

    handleCloseSteps = () => {
        this.setState({
            showSteps: false
        })
    }

    handleCloseOrdering = () => {
        this.setState({
            showOrdering: false,
            orderingList: []
        });
    }

    handleDragEnd = ({destination, source}) => {
        if (!destination) return
        this.setState({ orderingList: this.reorder(this.state.orderingList, source.index, destination.index) })
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    postOrder = () => {
        let eid = this.getExhibId()

        if (!eid || !eid.id)
            return

        this.setState({loading: true});

        postRouteOrder(eid.id, this.state.orderingList)
            .then(response => {
                this.setState({
                    routelist: response.sort((a, b) => a.orderPosition - b.orderPosition)
                });
            })
            .catch(e => console.log('SET ARTWORKS ORDER ERROR', e))
            .finally(() => {
                this.setState({loading: false})
                this.handleCloseOrdering();
            });

    }

    getExhibId = () =>
        localStorage.getItem('exhibid') && JSON.parse(localStorage.getItem('exhibid'))

    render() {
        return (
            <div>
                {this.state.showDetails && <RouteDetails routelist={this.state.routelist} parentCallbackRList={this.handleCallbackRDetails} routedetails={this.state.routedetails} routeid={this.state.routeid} />}
                {this.state.showSteps && <RouteSteps parentCallbackUpdate={this.handleStepUpdate} parentCloseSteps={this.handleCloseSteps} routedetails={this.state.routedetails} routeid={this.state.routeid} />}
                {!this.state.showDetails && !this.state.showSteps &&
                    <div>
                        <div className="text-center mt-0">
                            <h5>{this.state.showOrdering ? "Route List | Ordering" : "Route List"}</h5>
                        </div>

                            <div style={{ display: 'flex' }}>
                            {this.state.showOrdering 
                                ? <button onClick={this.handleCloseOrdering}
                                    style={{marginLeft: 'auto'}}
                                    type="button"
                                    className="btn btn-primary mb-5">
                                        <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                        Back to Routes
                                </button>
                                : <button onClick={() => { this.setState({ routeid: 'new', showDetails: true, routedetails: {}}) }}
                                    style={{ marginLeft: 'auto' }}
                                    type="button"
                                    className="btn btn-primary">
                                        <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                                        Create New Route
                                </button>
                            }
                            </div>
                        
                        {this.state.loading
                        ? <Loader color={'green'} loading={this.state.loading} />
                        : <div>
                            {this.state.showOrdering
                            ? <><React.Fragment>
                                        <div className="table-responsive mt-2">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Title</th>
                                                    <th>Author</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Deep Link</th>
                                                    <th style={{ width: 130 }}>QR Code</th>
                                                    <th style={{ width: 150 }}>Status</th>
                                                    <th style={{ width: 230 }}></th>
                                                </tr>
                                                </thead>
                                                <DragAndDropList
                                                    list={this.state.orderingList}
                                                    onDragEnd={this.handleDragEnd}
                                                    dragItemStyle={{
                                                        background: 'lightgreen',
                                                        borderRadius: '16px',
                                                    }}
                                                    dragListStyle={{
                                                        background: 'lightblue',
                                                        borderRadius: '16px',
                                                    }}
                                                >
                                                    {(item) => (
                                                        <>
                                                            <td>{item.id}</td>
                                                            <td className="text-truncate">{item.route.title}</td>
                                                            <td>{item.route.author}</td>
                                                            <td>{item.types?.map((typ, idx) => (
                                                                        <Button
                                                                            key={idx}
                                                                            style={{
                                                                                margin: '2px',
                                                                                backgroundColor: typ === 'Off Site' ? '#00d084' : '#0693e3',
                                                                                color: '#fff'
                                                                            }}
                                                                            color={'light'}
                                                                            className="btn-rounded btn-sm"
                                                                        >
                                                                            {typ}
                                                                        </Button>
                                                                    ))}
                                                                </td>
                                                            <td>{item.route.description?.substring(0, 23)}...</td>
                                                            <td>{item.deepLink}</td>
                                                            <td>
                                                                    <IconButton aria-label="download" size="large" disabled={item.qrCode == null} href={API_URL + item.qrCode} color="default">
                                                                        <QrCode2 />
                                                                    </IconButton>
                                                            </td>
                                                            <td>
                                                                <span className={item.route.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>
                                                                    {item.route.status}
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                        </>
                                                    )}
                                                </DragAndDropList>
                                            </Table>
                                        </div>
                                </React.Fragment>
                                <div className="text-center mt-4">
                                    <button
                                        disabled={this.props.viewStatus}
                                        onClick={this.postOrder}
                                        type="submit" className="btn btn-primary w-md">
                                        Save order
                                    </button>
                                </div>

                                </>
                            : <>
                                <Row>
                                    <Col md={2}>
                                        <button
                                            onClick={() =>
                                                this.setState({
                                                    showOrdering: true,
                                                    orderingList: this.state.routelist?.sort((a, b) => a.orderPosition - b.orderPosition)
                                                })
                                            }
                                            className="btn btn-primary w-xm mt-2"
                                        >
                                            Change order
                                        </button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '15px', marginBottom: '20px' }}>
                                    <Col md={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Title</th>
                                                        <th>Author</th>
                                                        <th>Type</th>
                                                        <th>Description</th>
                                                        <th>Deep Link</th>
                                                        <th style={{ width: 130 }}>QR Code</th>
                                                        <th>IAP Android ID</th>
                                                        <th>IAP iOS ID</th>
                                                        <th style={{ width: 150 }}>Status</th>
                                                        <th style={{ width: 230 }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.routelist?.map((item, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <td>{idx + 1}</td>
                                                                <td>{item.route.title}</td>
                                                                <td>{item.route.author}</td>
                                                                <td>
                                                                    {item.types?.map((typ, idx) => (
                                                                        <Button
                                                                            key={idx}
                                                                            style={{
                                                                                margin: '2px',
                                                                                backgroundColor: typ === 'Off Site' ? '#00d084' : '#0693e3',
                                                                                color: '#fff'
                                                                            }}
                                                                            color={'light'}
                                                                            className="btn-rounded btn-sm"
                                                                        >
                                                                            {typ}
                                                                        </Button>
                                                                    ))}
                                                                </td>
                                                                <td>{item.route.description?.substring(0, 23)}...</td>
                                                                <td>{item.deepLink}</td>
                                                                <td>
                                                                    <IconButton aria-label="download" size="large" disabled={item.qrCode == null} href={API_URL + item.qrCode} color="default">
                                                                        <QrCode2 />
                                                                    </IconButton>
                                                                </td>
                                                                <td>{item.route.iapAndroid}</td>
                                                                <td>{item.route.iapIos}</td>
                                                                <td>
                                                                    <span className={item.route.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>
                                                                        {item.route.status}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                routeid: item.route.id,
                                                                                showDetails: true,
                                                                                routedetails: item
                                                                            });
                                                                        }}
                                                                        className="btn btn-primary w-xm"
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    &nbsp;
                                                                    <button
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                routeid: item.route.id,
                                                                                showSteps: true,
                                                                                routedetails: item
                                                                            });
                                                                        }}
                                                                        className="btn btn-secondary w-xm"
                                                                    >
                                                                        Steps
                                                                    </button>
                                                                    &nbsp;
                                                                    <button onClick={() => this.removeRoute(item.id)} className="btn btn-danger w-xm">
                                                                        Remove
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row></>
                            }
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

RouteList.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab3: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouteList));
