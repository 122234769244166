import PropTypes from 'prop-types';
import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {Draggable} from 'react-beautiful-dnd';

class DragAndDropItem extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            winReady: false
        };
    }

    componentDidMount() {
        this.setState( { winReady: true});
    }

    render() {
        return (
            (this.state.winReady && (<Draggable index={this.props.index} draggableId={this.props.item.id.toString()}>
                    {(provided, snapshot) => (
                        <tr
                            key={this.props.item.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                // default item style
                                // default drag style
                                ...provided.draggableProps.style,
                                // customized drag style
                                ...(snapshot.isDragging ? this.props.dragItemStyle : {}),
                            }}
                        >
                            {this.props.children(this.props.item)}
                        </tr>
                    )}
                </Draggable>)
            )
        );
    }
}

DragAndDropItem.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    index: PropTypes.number,
    item: PropTypes.object,
    dragItemStyle: PropTypes.object,
    children: PropTypes.func
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DragAndDropItem));
