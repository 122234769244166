import axios from 'axios';
import { resolve } from './resolve.js';
import { API_URL } from '../../constants/apiurl';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});
const axiosHeaderConfigMutipart = token => ({
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});
/*------- TEST CODE----
'Content-Type': 'multipart/form-data',
export async function login(user, pass) {
  return await resolve(axios.post('http://some-api.com/auth', { user, pass }).then(res => res.data));
}

export async function getUser(id) {
  return await resolve(axios.get(`http://some-api.com/users/${id}`).then(res => res.data));
}
*/

//-----VENUES apis----------------

export async function callUploadTypesVenue() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator/upload/types', axiosconf).then(res => res.data));
}

export async function callVenueTypes() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/venue/types', axiosconf).then(res => res.data));
}

export async function callVenueStatus() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/venue/status', axiosconf).then(res => res.data));
}

export async function callVenueTags() {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/tag', axiosconf).then(res => res.data));
}

export async function callVenueDetails(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + `/tt/venue/${id}`, axiosconf).then(res => res.data));
}

export async function postNewVenue(postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/venue', postData, axiosconf).then(res => res.data));
}

export async function uploadVenueImage(id, type, formData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfigMutipart(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/venue/file/' + id + '?type=' + type, formData, axiosconf).then(res => res.data));
}

export async function deleteVenueImage(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.delete(API_URL + '/tt/venue/file/' + id, axiosconf).then(res => res.data));
}
