import {
    GET_THEMES_SUCCESS,
    GET_THEMES_FAIL,
    GET_THEMES,
    DELETE_THEMES_SUCCESS,
    DELETE_THEMES_FAIL,
    DELETE_THEMES,
    UPDATE_THEMES,
    UPDATE_THEMES_FAIL,
    UPDATE_THEMES_SUCCESS,
    GET_THEME,
    GET_THEME_FAIL,
    GET_THEME_SUCCESS,
    ADD_NEW_THEME,
    ADD_THEME_SUCCESS,
    ADD_THEME_FAIL
} from './actionTypes';

const INIT_STATE = {
    themes: [],
    error: {}
};

const themes = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_THEMES_SUCCESS:
            return {
                ...state,
                themes: action.payload
            };

        case GET_THEMES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_THEMES_SUCCESS:
            return {
                ...state,
                themes: state.themes.filter(themes => themes.id.toString() !== action.payload.id.toString())
            };

        case DELETE_THEMES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_THEMES_SUCCESS:
            return {
                ...state,
                themes: state.themes.map(theme => (theme.id.toString() === action.payload.id.toString() ? { theme, ...action.payload } : theme))
            };

        case UPDATE_THEMES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case ADD_THEME_SUCCESS:
            return {
                ...state,
                themes: [...state.themes, action.payload]
            };

        case ADD_THEME_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case GET_THEME_SUCCESS:
            return {
                ...state,
                theme: action.payload
            };

        case GET_THEME_FAIL:
            return {
                ...state,
                error: action.payload
            };
        //----others ----

        default:
            return state;
    }
};

export default themes;
