import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_EXHIBITIONS, DELETE_EXHIBITIONS, UPDATE_EXHIBITIONS } from './actionTypes';

import { getExhibitionsSuccess, getExhibitionsFail, deleteExhibitionsFailed, deleteExhibitionsSuccess } from './actions';

//Include Both Helper File with needed methods
import { getExhibitions, deleteExhibitions, updateExhibitionsByID } from '../../helpers/fakebackend_helper';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
function* fetchExhibitions() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getExhibitions, obj.access_token);

        yield put(getExhibitionsSuccess(response.content));
    } catch (error) {
        yield put(getExhibitionsFail(error));
    }
}

function* updateExhibitionsById({ payload: exhibitions }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: exhibitions.id, exhibitions: exhibitions };
        const response11 = yield call(updateExhibitionsByID, objj);
        const response = yield call(getExhibitions, obj.access_token);
        yield put(getExhibitionsSuccess(response.content));
        //const response = yield call(getTags,  obj.access_token)
        //yield put(getThemesSuccess(response11))
    } catch (error) {
        yield put(getExhibitionsFail(error));
    }
}

function* onDeleteExhibitions({ payload: exhibitions }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: exhibitions.id };
        const response11 = yield call(deleteExhibitions, objj);
        yield put(deleteExhibitionsSuccess(response11));
    } catch (error) {
        yield put(deleteExhibitionsFailed(error));
    }
}

function* exhibitionsSaga() {
    yield takeEvery(GET_EXHIBITIONS, fetchExhibitions);
    yield takeEvery(DELETE_EXHIBITIONS, onDeleteExhibitions);

    yield takeEvery(UPDATE_EXHIBITIONS, updateExhibitionsById);
}

export default exhibitionsSaga;
