import axios from 'axios'
import { API_URL } from '../../constants/apiurl.js'
import { axiosHeaderConfig } from '../../common/utils/index.tsx'

const section = 'tt/content'

export async function getContentsList(type, { page, size }, { field, order }) {
    return await axios.get(`${API_URL}/${section}?page=${page}&size=${size}&type=${type}&sort=${field},${order}`, axiosHeaderConfig())
    .then(res => res.data)
    .catch(error => console.log(`error ---- ${JSON.stringify(error)}`))
}

export async function getContentTagStatus() {
    return await axios.get(`${API_URL}/${section}/status`, axiosHeaderConfig())
}

export async function getContentVenues(id) {
    return await axios.get(`${API_URL}/${section}/${id}/venue/mapping`, axiosHeaderConfig())
}

export async function postContentVenues(id, postData) {
    return await axios.post(`${API_URL}/${section}/${id}/venue/mapping`, postData, axiosHeaderConfig())
}

export async function getContentArtworks(id) {
    return await axios.get(`${API_URL}/${section}/${id}/artwork/mapping`, axiosHeaderConfig())
    .then(res => res.data)
    .catch(error => console.log(`error ---- ${JSON.stringify(error)}`))
}

export async function postContentArtworks(id, postData) {
    return await axios.post(`${API_URL}/${section}/${id}/artwork/mapping`, postData, axiosHeaderConfig())
    .then(res => res.data)
    .catch(error => console.log(`error ---- ${JSON.stringify(error)}`))
}

export async function getContentCreators(id) {
    return await axios.get(`${API_URL}/${section}/${id}/creator/mapping`, axiosHeaderConfig())
}

export async function postContentCreators(id, postData) {
    return await axios.post(`${API_URL}/${section}/${id}/creator/mapping`, postData, axiosHeaderConfig())
}

//TODO: (be fix) remove useless properties into response 
export async function getContentArticle(id) {
    return await axios.get(`${API_URL}/${section}/${id}`, axiosHeaderConfig())
}

export async function postContentArticle(postData) {
    return await axios.post(`${API_URL}/${section}`, postData, axiosHeaderConfig())
}

export async function putContentArticle(id, putData) {
    return await axios.put(`${API_URL}/${section}/${id}`, putData, axiosHeaderConfig())
}

export async function getContentFiles(id) {
    return await axios.get(`${API_URL}/${section}/${id}/file`, axiosHeaderConfig())
}

//TODO: (be fix) add files array into response
//TODO: move error handling
export async function uploadContentFile(id, type, postData) {
    return await axios.post(`${API_URL}/${section}/${id}/file/?type=${type}`, postData, axiosHeaderConfig('multipart/form-data'))
}

export async function deleteContentFile(id) {
    return await axios.delete(`${API_URL}/${section}/file/${id}`, axiosHeaderConfig())
}

export async function getContentExternalLinks(id) {
    return await axios.get(`${API_URL}/${section}/${id}/link`, axiosHeaderConfig())
}

export async function postContentExternalLinks(id, postData) {
    return await axios.post(`${API_URL}/${section}/${id}/link`, postData, axiosHeaderConfig())
}

