//import { venues } from "common/data"
import {
    DELETE_CREATORS_SUCCESS,
    GET_CREATORS_SUCCESS,
    UPDATE_CREATORS_SUCCESS,
    DELETE_CREATORS,
    DELETE_CREATORS_FAIL,
    UPDATE_CREATORS,
    GET_CREATORS,
    GET_CREATORS_FAIL
} from './actionTypes';

export const getCreators = () => ({
    type: GET_CREATORS
});

export const getCreatorsSuccess = creators => ({
    type: GET_CREATORS_SUCCESS,
    payload: creators
});

export const getCreatorsFail = error => ({
    type: GET_CREATORS_FAIL,
    payload: error
});

export const deleteCreators = creators => ({
    type: DELETE_CREATORS,
    payload: creators
});

export const deleteCreatorsSuccess = creators => ({
    type: DELETE_CREATORS_SUCCESS,
    payload: creators
});

export const deleteCreatorsFailed = error => ({
    type: DELETE_CREATORS_FAIL,
    payload: error
});

export const updateCreators = creators => ({
    type: UPDATE_CREATORS,
    payload: creators
});

export const updateCreatorsSuccess = creators => ({
    type: UPDATE_CREATORS_SUCCESS,
    payload: creators
});

//-----Others
