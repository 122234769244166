import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    FormFeedback,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    CardImg,
    Alert,
    Table,
    Breadcrumb,
    BreadcrumbItem,
    Progress,
    Toast,
    ToastHeader, FormGroup
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import axios from 'axios';
import { API_URL } from '../../constants/apiurl';
import Loader from '../../components/Common/Loader';
import classnames from 'classnames';

import { updateCIndividuals } from '../../store/creator-individual/actions';
import { Editor } from '@tinymce/tinymce-react';
import CropEasy from '../../components/Crop/CropEasy';
import moment from 'moment';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import MobilePreviews from '../../components/MobilePreviews/MobilePreviews';
import MobileVideoPreviews from '../../components/MobilePreviews/MobileVideoPreviews';
import { searchPublishTags, searchPublishThemes } from '../../helpers/Api/common_apis';
import { lengthVideo } from '../../helpers/Api/common_apis';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDeeplink } from '../../helpers/Api/common_apis';
import CreatorExternalLinks from "../CreatorsCompany/components/CreatorExternalLinks";
import Contents from "../Exhibitions/components/Contents";
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class IndividualCreatorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            selectedFiles: [],
            statuslist: [],
            types: [],
            selectedTypes: [],
            uploadtypes: [],
            name: '',
            vname: '',
            type: '',
            vtype: '',
            //---- new------
            identifier: '',
            incountry: '',
            instate: '',
            incity: '',
            indob: '',
            inalive: true,
            hided: false,
            instates: [],
            incities: [],
            selected_instate: '',
            selected_pod_state: '',

            in_pod_country: '',
            in_pod_state: '',
            in_pod_city: '',
            in_pod_address: '',
            in_pod_timestamp: '',

            in_pod_states: [],
            in_pod_cities: [],
            //---

            country: '',
            statee: '',
            city: '',
            address: '',
            phone: '',
            website: '',
            email: '',
            //-----
            tags: [],
            selectedTags: [],
            selectTag: '',

            themes: [],
            selectedThemes: [],
            selectTheme: '',

            description: '',
            status: '',
            vstatus: '',
            //-----

            countries: [],
            selectedCountry: '',
            cstates: [],
            selectedState: '',
            cities: [],
            selectedCity: '',
            id: '',
            loading: false,
            files: [],

            viewStatus: false,
            editStatus: false,
            newStatus: false,

            selectedUploadtype: '',
            fielupload: '',
            vselectedUploadtype: '',
            base64: '',
            show: '',
            filename: '',
            showPreview: true,
            setPreviewUrl: '',
            previewtype: '',
            flashShow: false,
            paginateCountries: [],
            paginateCountriesDOB: [],
            paginateCountriesPOD: [],
            paginateNationalities: [],
            selectedNationality: '',
            selectType: '',
            setPageStatus: '',
            inid: '',
            showAlert: false,
            alertMessage: '',

            vcity: '',
            vstate: '',
            vcountry: '',
            vaddress: '',

            vpodcity: '',
            vpodstate: '',
            vpodcountry: '',
            vpodaddress: '',
            vpod: '',

            vindob: '',
            vincity: '',
            vinstate: '',
            vincountry: '',

            vtag: '',
            vtheme: '',
            vnationality: '',
            country_id: '',
            state_id: '',

            incountry_id: '',
            instate_id: '',

            in_pod_country_id: '',
            in_pod_state_id: '',
            showErrorAlert: false,
            alertErrorMessage: '',
            successFlag: false,
            showAlertWarning: false,

            //Video ----
            videoUrl: {},
            videoUrlFlag: false,
            videoThumnailPreview: '',
            videoThumnail: '',
            videoThumnailName: '',
            urlVidFlag: false,
            uploadPercentage: 0,
            authtoken: '',
            deeplink: '',
            copied: false
        };
        this.toggleTab = this.toggleTab.bind(this);
        this.editorRefDescrip = null;
        // this.myRef = React.createRef()
    }

    componentDidMount() {
        this.getCreatorsStatus();
        this.getInCreatorsTypes();
        this.getUploadTypes();

        const {
            match: { params }
        } = this.props;
        if (params.idd === 'view') {
            this.setState({ viewStatus: true, setPageStatus: 'View' });
        }

        if (params.idd === 'post') {
            this.setState({ newStatus: true, setPageStatus: 'Create' });
        }

        if (params.idd === 'edit') {
            this.setState({ setPageStatus: 'Edit' });
        }

        if (params.id !== 'new') {
            this.setState({ editStatus: true });
            this.getInCreatorsDetails(params.id);
            this.getDeeplinkData(params.id);
        } else {
            this.setState({ editStatus: false });
        }
    }
    async getDeeplinkData(id) {
        let postdata = {
            type: 'Creator',
            reference: id,
            contentType: null
        };
        const data = await getDeeplink(postdata);
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else {
            //console.log("------ "+JSON.stringify(data))
            this.setState({ deeplink: data.data.shortLink });
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });

            if (tab === '1' && this.state.newStatus === false) {
                this.getInCreatorsDetails(this.state.id);
            }
        }
    }

    //------- Info handling ---- Country | State | City
    handleOnSearch = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({ vcountry: '' });

        axios
            .get(API_URL + '/tt/places/countries?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    paginateCountries: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    handleOnSearchState = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({ vstate: '' });

        axios
            .get(API_URL + '/tt/places/states/' + this.state.country_id + '?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    cstates: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    handleOnSearchCity = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({ city: string, vcity: '' });

        axios
            .get(API_URL + '/tt/places/cities/' + this.state.state_id + '?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    cities: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    //------- DOB handling ---- Country | State | City
    handleOnSearchDOB = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/places/countries?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    paginateCountriesDOB: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    handleOnSearchDOBState = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({
            vinstate: ''
        });

        axios
            .get(API_URL + '/tt/places/states/' + this.state.incountry_id + '?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    instates: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    handleOnSearchDOBCity = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({
            vincity: '',
            incity: string
        });

        axios
            .get(API_URL + '/tt/places/cities/' + this.state.instate_id + '?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    incities: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    //------- POD handling ---- Country | State | City
    handleOnSearchPOD = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({
            vpodcountry: ''
        });

        axios
            .get(API_URL + '/tt/places/countries?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    paginateCountriesPOD: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    handleOnSearchPODState = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({
            vpodstate: ''
        });

        axios
            .get(API_URL + '/tt/places/states/' + this.state.in_pod_country_id + '?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    in_pod_states: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    handleOnSearchPODCity = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({
            vpodcity: '',
            in_pod_city: string
        });

        axios
            .get(API_URL + '/tt/places/cities/' + this.state.in_pod_state_id + '?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    in_pod_cities: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    //------- END serch Country State City ----

    handleOnSearchNationality = (string, results) => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/places/nationality?page=0&size=10&name=' + string, axiosconf)
            .then(response => {
                this.setState({
                    paginateNationalities: response.data.content
                });
            })
            .catch(e => {
                console.log('error ' + JSON.stringify(e));
            });
    };

    getInCreatorsTypes = () => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/creator/individual/types', axiosconf)
            .then(response => {
                this.setState({
                    types: response.data
                });
            })
            .catch(e => {});
    };

    async handleOnSearchTags(string, results) {
        const response = await searchPublishTags(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ tags: response.data.content, searchFlag: true });
        }
    }

    async handleOnSearchThemes(string, results) {
        const response = await searchPublishThemes(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ themes: response.data.content, searchFlag: true });
        }
    }

    getCreatorsStatus = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/creator/status', axiosconf)
            .then(response => {
                this.setState({
                    statuslist: response.data
                });
            })
            .catch(e => {});
    };

    getUploadTypes = () => {
        let obj = this.getAuthDetails();

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/creator/upload/types', axiosconf)
            .then(response => {
                this.setState({
                    uploadtypes: response.data
                });
            })
            .catch(e => {});
    };

    getInCreatorsDetails = id => {
        let obj = this.getAuthDetails();

        this.setState({ loading: true });

        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/creator/' + id, axiosconf)
            .then(response => {
                var dd = new Date(response.data.individual.dob).toISOString();

                var ddd = '';
                if (response.data.individual.alive === false) {
                    ddd = new Date(response.data.individual.placeOfDeath.timestamp).toISOString();
                    ddd = ddd.substring(0, 10);
                }

                this.setState({
                    loading: false,
                    name: response.data.name,
                    selectedTypes: response.data.types,
                    selectedNationality: response.data.nationality,
                    //----new ----
                    identifier: response.data.indentifier,
                    incountry: response.data.individual.country,
                    instate: response.data.individual.state,
                    incity: response.data.individual.city,
                    indob: dd.substring(0, 10),
                    inalive: response.data.individual.alive,

                    inid: response.data.individual.id,

                    in_pod_country: response.data.individual.alive === true ? '' : response.data.individual.placeOfDeath.country,
                    in_pod_state: response.data.individual.alive === true ? '' : response.data.individual.placeOfDeath.state,
                    in_pod_city: response.data.individual.alive === true ? '' : response.data.individual.placeOfDeath.city,
                    //in_pod_address: response.data.individual.alive === true ? "" : response.data.individual.placeOfDeath.address,
                    //new Date(response.data.individual.dob).toISOString()
                    in_pod_timestamp: ddd,

                    incountry_id: response.data.individual.countryId,
                    instate_id: response.data.individual.stateId,

                    in_pod_country_id: response.data.individual.alive === true ? '' : response.data.individual.placeOfDeath.countryId,
                    in_pod_state_id: response.data.individual.alive === true ? '' : response.data.individual.placeOfDeath.stateId,

                    //-----
                    country: response.data.contact.country,
                    country_id: response.data.contact.countryId,
                    statee: response.data.contact.state,
                    state_id: response.data.contact.stateId,
                    city: response.data.contact.city,
                    address: response.data.contact.address,
                    phone: response.data.contact.phone,
                    website: response.data.contact.website,
                    email: response.data.contact.email,

                    // tags: response.data.tags,
                    selectedTags: response.data.tags,

                    //themes: response.data.themes,
                    selectedThemes: response.data.themes,

                    description: response.data.description,
                    status: response.data.status,
                    //------
                    id: response.data.id,
                    files: response.data.files,
                    //-----
                    selectedCountry: response.data.contact === null ? '' : response.data.contact.country,
                    selectedState: response.data.contact === null ? '' : response.data.contact.state,
                    selectedCity: response.data.contact === null ? '' : response.data.contact.city,
                    hided: response.data.hided
                });
            })
            .catch(e => {
                this.setState({ loading: false });
            });
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    submitForm(e) {
        e.preventDefault();

        if (this.state.name === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vname: 'Theme name is required!' });
        }
        if (this.state.status === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vstatus: 'Status is required' });
        }
        if (this.state.selectedTypes.length === 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vtype: 'Add atleast one creator type' });
        }

        //-------
        if (this.state.selectedNationality === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vnationality: 'Nationality is required!' });
        }

        if (this.state.incountry === '' || this.state.instate === '' || this.state.incity === '' || this.state.indob === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({
                vincity: 'This field is required',
                vincountry: 'This field is required',
                vinstate: 'This field is required',
                vindob: 'This field is required'
            });
        }

        /* if (
      this.state.country === "" ||
      this.state.statee === "" ||
      this.state.city === "" ||
      this.state.address === ""
    ) {
     
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.setState({
        vcity: "This field is required",
        vcountry: "This field is required",
        vstate: "This field is required",
        vaddress: "This field is required",
      });
    }*/

        if (
            this.state.name !== '' &&
            this.state.status !== '' &&
            this.state.selectedTypes.length > 0 &&
            this.state.incountry !== '' &&
            this.state.instate !== '' &&
            this.state.incity !== '' &&
            this.state.indob !== '' &&
            this.state.selectedNationality !== ''
        ) {
            if (this.state.inalive === false && (this.state.in_pod_country === '' || this.state.in_pod_state === '' || this.state.in_pod_city === '' || this.state.in_pod_timestamp === '')) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.setState({
                    vpodaddress: 'This field is required',
                    vpodcity: 'This field is required',
                    vpodstate: 'This field is required',
                    vpodcountry: 'This field is required',
                    vpod: 'This field is required'
                });
            }

            if (this.state.inalive === false && this.state.in_pod_country !== '' && this.state.in_pod_state !== '' && this.state.in_pod_city !== '' && this.state.in_pod_timestamp !== '') {
                this.submitFormData();
            } else {
                if (this.state.inalive) {
                    this.submitFormData();
                }
            }
        } else {
            console.log('-------------');
        }
    }

    submitFormData = () => {
        let obj = this.getAuthDetails();
        const {
            match: { params }
        } = this.props;
        this.setState({ loading: true });
        let dd = new Date(this.state.indob);
        let dod = new Date(this.state.in_pod_timestamp);

        let axiosconf = axiosHeaderConfig(obj.access_token);
        //var isoStr = new Date().toISOString();

        /* let in_dobb = ""
     let in_pod_dd = ""
     if(this.state.indob !== "" || this.state.indob !== null){
       in_dobb = new Date(this.state.indob).toISOString();
     }
     if(this.state.in_pod_timestamp !== ""){
       in_pod_dd = new Date(this.state.in_pod_timestamp).toISOString();
     }*/
        let place_of_death_obj = {};

        if (this.state.inalive === false) {
            place_of_death_obj = {
                country: this.state.in_pod_country,
                countryId: this.state.in_pod_country_id,
                stateId: this.state.in_pod_state_id,
                state: this.state.in_pod_state,
                city: this.state.in_pod_city,
                timestamp: dod
            };
        }

        let postParams = {
            name: this.state.name,
            types: this.state.selectedTypes,
            identifier: 'Individual',
            nationality: this.state.selectedNationality,
            hided: this.state.hided,
            individual: {
                id: this.state.inid,
                country: this.state.incountry,
                countryId: this.state.incountry_id,
                stateId: this.state.instate_id,
                state: this.state.instate,
                city: this.state.incity,
                dob: dd,
                alive: this.state.inalive,
                placeOfDeath: place_of_death_obj
            },

            contact: {
                country: this.state.country,
                state: this.state.statee,
                countryId: this.state.country_id,
                stateId: this.state.state_id,
                city: this.state.city,
                address: this.state.address,
                phone: this.state.phone,
                website: this.state.website,
                email: this.state.email
            },
            themes: this.state.selectedThemes,

            tags: this.state.selectedTags,

            description:
                this.editorRefDescrip.getContent({ format: 'text' }).trim().length === 0
                    ? null
                    : "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                      this.state.description.replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                      '</body></html>',
            status: this.state.status,
            id: this.state.id
        };

        if (params.id === 'new') {
            if (this.state.id !== '') {
                this.props.onUpdateCIndividuals(postParams);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.getInCreatorsDetails(this.state.id);
                this.setState({
                    loading: false,
                    activeTab: '2',
                    newStatus: false,
                    showAlert: true,
                    successFlag: true,
                    alertMessage: 'Updated Successfully'
                });
            } else {
                //this.getTagDetails();
                axios
                    .post(API_URL + '/tt/creator', postParams, axiosconf)
                    .then(response => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        this.getInCreatorsDetails(response.data.id);
                        localStorage.setItem('creatorid', JSON.stringify({ id: response.data.id }));
                        this.setState({
                            loading: false,
                            activeTab: '2',
                            newStatus: false,
                            showAlert: true,
                            successFlag: true,
                            alertMessage: 'Created Successfully'
                        });

                        //this.props.history.push('/venues')
                    })
                    .catch(err => {
                        this.setState({ loading: false });
                        if (err.response) {
                            // Request made and server responded
                            console.log(err.response.data);
                            console.log(err.response.status);
                            console.log(err.response.headers);
                            this.setState({ showAlertWarning: true, alertMessage: err.response.data.message });
                        } else if (err.request) {
                            // The request was made but no response was received
                            console.log(err.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', err.message);
                            this.setState({ showAlertWarning: true, alertMessage: err.response.data.message });
                        }
                    });
            }
        } else {
            this.props.onUpdateCIndividuals(postParams);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getInCreatorsDetails(this.state.id);
            this.setState({
                loading: false,
                activeTab: '2',
                newStatus: false,
                showAlert: true,
                successFlag: true,
                alertMessage: 'Updated Successfully'
            });
        }
    };

    onInputChangeVideoThumbnai = e => {
        const file = e.target.files[0];

        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

        if (size > 100) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure image size is less than 100 KB'
                //selectedUploadtype: "",
                //vselectedUploadtype: "",
                //urlVidFlag: false
            });
            //e.target.value = null
            return;
        } else {
            var filename = file.name;
            this.setState({
                videoThumnailName: filename,
                vselectedUploadtype: '',
                urlVidFlag: false
            });
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64: reader.result, show: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    onInputChange = e => {
        if (this.state.selectedUploadtype === '') {
            this.setState({ vselectedUploadtype: 'Please select the type' });
            return;
        } else {
            if (this.state.selectedUploadtype === 'Video') {
                this.setState({ videoUrl: {}, videoUrlFlag: false, fielupload: '' });

                this.videoUpload(e);
            } else {
                const file = e.target.files[0];
                var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
                var isGIF = file.type === 'image/gif';

                if (size > 300 && !isGIF) {
                    this.setState({
                        showAlertWarning: true,
                        alertMessage: 'Please ensure image size is less than 300 KB',
                        selectedUploadtype: '',
                        vselectedUploadtype: '',
                        videoUrlFlag: false,
                        urlVidFlag: false
                    });
                    e.target.value = null;
                } else {
                    var filename = file.name;
                    this.setState({
                        filename: filename,
                        flashShow: false,
                        filename: e.target.files[0].name,
                        previewtype: '',
                        videoUrlFlag: false,
                        urlVidFlag: false
                    });
                    const reader = new FileReader();

                    reader.addEventListener(
                        'load',
                        () => {
                            if(isGIF) {
                                this.handleGIFCallback(reader.result);
                            } else {
                                this.setState({base64: reader.result, show: true});
                            }
                        },
                        false
                    );

                    if (file) {
                        reader.readAsDataURL(file);
                        //this.setState({imagePreviewUrl: file})
                    }
                }
            }
        }
    };

    uploadSubmit(e) {
        e.preventDefault();
        if (this.state.selectedUploadtype === '' || this.state.fielupload === '') {
            this.setState({
                vselectedUploadtype: 'Please select the type and file to upload'
            });
        } else {
            //this.setState({showAlert: true, alertMessage: "Thumbnail already submitted! Remove previous one first", selectedUploadtype: ""})
            if (this.state.selectedUploadtype === 'Video') {
                if (this.state.videoThumnail === '' || this.state.filename === '') {
                    this.setState({
                        vselectedUploadtype: 'Video file and video thumbnail are required!'
                    });
                } else {
                    this.uploadFileProgress();
                }
            } else {
                let obj = this.getAuthDetails();
                let axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + obj.access_token
                    }
                };
                this.setState({ base64: '', fielupload: '', selectedUploadtype: '' });

                let formData = new FormData();

                formData.append('file', this.state.fielupload, this.state.filename);

                this.setState({ loading: true });
                axios
                    .post(API_URL + '/tt/creator/file/' + this.state.id + '?type=' + this.state.selectedUploadtype, formData, axiosConfig)
                    .then(response => {
                        this.setState({
                            loading: false,
                            flashShow: false,
                            showPreview: true,
                            setPreviewUrl: '',
                            showAlert: true,
                            alertMessage: 'Uploaded Successfully',
                            successFlag: true
                        });
                        this.getInCreatorsDetails(this.state.id);
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({
                            loading: false
                        });
                        this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: e.response.data.message });
                    });
            }
        }
    }

    uploadFileProgress = () => {
        let formData = new FormData();
        formData.append('file', this.state.videoUrl, this.state.filename);
        formData.append('file', this.state.videoThumnail, this.state.videoThumnailName);
        let autTok = this.getAuthDetails();
        setTimeout(() => {
            this.setState({ authtoken: autTok.access_token });
            const options = {
                headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json', Authorization: 'Bearer ' + this.state.authtoken },
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    //console.log( `${loaded}kb of ${total}kb | ${percent}%` );

                    if (percent < 100) {
                        this.setState({ uploadPercentage: percent });
                    }
                }
            };
            axios
                .post(API_URL + '/tt/creator/file/' + this.state.id + '?type=' + this.state.selectedUploadtype, formData, options)
                .then(res => {
                    this.setState({ uploadPercentage: 100 });
                    this.setState({
                        loading: false,
                        videoUrlFlag: false,
                        flashShow: false,
                        showPreview: true,
                        setPreviewUrl: '',
                        previewtype: '',
                        showAlert: true,
                        alertMessage: 'Uploaded Successfully',
                        successFlag: true,
                        selectedUploadtype: '',
                        uploadPercentage: 0
                    });
                    this.getInCreatorsDetails(this.state.id);
                })
                .catch(e => {
                    console.log('error... ' + JSON.stringify(e));
                });
        }, 400);
    };

    async videoUpload(e) {
        const video = await lengthVideo(e.currentTarget.files[0]);
        console.log('duration.... ' + video.duration);
        if (video.duration > 1200) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure video duration is less than 20 Minutes',
                selectedUploadtype: '',
                vselectedUploadtype: '',
                videoUrlFlag: false,
                urlVidFlag: false
            });
            e.target.value = null;
            return;
        } else {
            var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            if (size > 250000) {
                this.setState({
                    showAlertWarning: true,
                    alertMessage: 'Please ensure video size is less than 250 MB.',
                    selectedUploadtype: '',
                    vselectedUploadtype: '',
                    videoUrlFlag: false,
                    urlVidFlag: false
                });
                e.target.value = null;
                return;
            } else {
                const reader = new FileReader();
                //var url = URL.createObjectURL(e.target.files[0].originFileObj);
                //console.log("url video... "+JSON.stringify(url))
                this.setState({ videoUrl: e.target.files[0], fielupload: e.target.files[0], videoUrlFlag: true, filename: e.target.files[0].name, urlVidFlag: false });
            }
        }
    }

    handleGIFCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }
        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                cropImageSet: true,
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
        // this.myRef.current.scrollIntoView()
    };

    handleCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }

        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                flashShow: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
        // this.myRef.current.scrollIntoView()
    };

    removeFile = id => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .delete(API_URL + '/tt/creator/file/' + id, axiosconf)
            .then(response => {
                this.setState({
                    showPreview: true,
                    setPreviewUrl: '',
                    flashShow: false,
                    previewtype: ''
                });
                this.getInCreatorsDetails(this.state.id);
            })
            .catch(e => {});
    };

    dataURLtoBlob = dataurl => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    render() {
        const {
            name,
            incity,
            instate,
            in_pod_city,
            in_pod_state,
            description,
            in_pod_country,
            statee,
            city,
            address,
            phone,
            website,
            email,
            type,
            tags,
            status,
            selectTag,
            selectedCountry,
            selectedState,
            selectedUploadtype,
            fielupload
        } = this.state;

        const {
            match: { params }
        } = this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Individual Creator</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <CardTitle className="mb-1">Individual Creator</CardTitle>
                        <Breadcrumb>
                            <BreadcrumbItem tag="p" href="#">
                                Individual Creator List
                            </BreadcrumbItem>
                            <BreadcrumbItem tag="p" href="#" active>
                                {this.state.setPageStatus} Individual Creator
                            </BreadcrumbItem>
                            {this.state.name !== '' && (
                                <BreadcrumbItem tag="b" href="#">
                                    {this.state.name}
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>

                        {this.state.showAlert ? (
                            <SweetAlert success={this.state.successFlag} title="" onConfirm={() => this.setState({ showAlert: false })}>
                                {this.state.alertMessage}
                            </SweetAlert>
                        ) : null}

                        {this.state.showAlertWarning ? (
                            <SweetAlert warning title="" onConfirm={() => this.setState({ showAlertWarning: false })}>
                                {this.state.alertMessage}
                            </SweetAlert>
                        ) : null}

                        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: '1005' }}>
                            <Toast isOpen={this.state.copied}>
                                <ToastHeader toggle={() => this.setState({ copied: !this.state.copied })}>Copied!</ToastHeader>
                            </Toast>
                        </div>
                        {params.id !== 'new' && (
                            <div style={{ display: 'flex', position: 'absolute', top: '150px', right: '30px' }}>
                                <Label htmlFor="formrow-firstname-Input" style={{ marginTop: '7px', marginRight: '10px' }}>
                                    Deep Link
                                </Label>
                                <div style={{ width: '350px' }}>
                                    <Input
                                        readOnly={true}
                                        ref={textarea => (this.textArea = textarea)}
                                        type="readonly"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        name="deeplink"
                                        value={this.state.deeplink}
                                    />
                                </div>
                                <CopyToClipboard text={this.state.deeplink} onCopy={() => this.setState({ copied: true })}>
                                    <button>
                                        <i className="dripicons-copy" />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        )}
                        <Card>
                            <ul className="nav nav-tabs nav-tabs-custom pt-2" role="tablist">
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '1'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('1');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Information</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '2'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('2');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Images & Videos</CardTitle>
                                    </NavLink>
                                </NavItem>
                                {/*<NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '3'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('3');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Contents</CardTitle>
                                    </NavLink>
                                </NavItem>*/}
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '3'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('3');
                                        }}
                                    >
                                        <CardTitle className="mb-4">External Links</CardTitle>
                                    </NavLink>
                                </NavItem>

                                <NavItem style={{ margin: '15px' }} className="position-absolute top-0 end-0">
                                    <button
                                        onClick={() => {
                                            this.props.history.push('/web/individual-creators');
                                        }}
                                        type="button"
                                        className="btn btn-secondary"
                                    >
                                        <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                        Back
                                    </button>
                                </NavItem>
                            </ul>
                            {this.state.loading ? (
                                <Loader color={'green'} loading={this.state.loading} />
                            ) : (
                                <TabContent className="p-4" activeTab={this.state.activeTab}>
                                    {this.state.editStatus && (
                                        <span className={this.state.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{this.state.status}</span>
                                    )}
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardBody>
                                                        <Form className="form" onSubmit={e => this.submitForm(e)}>
                                                            <CardTitle className="mb-4">General Information</CardTitle>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Name *</Label>
                                                                        <Input
                                                                            disabled={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Name"
                                                                            name="name"
                                                                            invalid={this.state.vname !== '' ? true : false}
                                                                            value={name}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    name: e.target.value,
                                                                                    vname: ''
                                                                                })
                                                                            }
                                                                        />

                                                                        {this.state.vname !== '' && <span style={{ color: 'red' }}>{this.state.vname}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Nationality *</Label>
                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholder: 'Type Nationality',
                                                                                    placeholderColor: '#939294',

                                                                                    height: '35px',
                                                                                    fontSize: 16,
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da'
                                                                                }}
                                                                                items={this.state.paginateNationalities}
                                                                                placeholder={this.state.selectedNationality === '' ? 'Type here' : this.state.selectedNationality}
                                                                                onSearch={(ss, ee) => this.handleOnSearchNationality(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        selectedNationality: item.name,
                                                                                        vnationality: ''
                                                                                    });
                                                                                }}
                                                                                onFocus={() => {}}
                                                                                formatResult={formatResult}
                                                                            />{' '}
                                                                            {this.state.vnationality !== '' && <span style={{ color: 'red' }}>{this.state.vnationality}</span>}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Status *</Label>
                                                                        <select
                                                                            disabled={this.state.viewStatus}
                                                                            value={status}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    status: e.target.value,
                                                                                    vstatus: ''
                                                                                })
                                                                            }
                                                                            className="form-select"
                                                                        >
                                                                            <option value="">Select Status</option>
                                                                            {this.state.statuslist.map((item, idx) => (
                                                                                <option key={idx} value={item}>
                                                                                    {item}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {this.state.vstatus !== '' && <span style={{ color: 'red' }}>{this.state.vstatus}</span>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Type of Creator *</Label>
                                                                        <select
                                                                            disabled={this.state.viewStatus}
                                                                            value={this.state.selectType}
                                                                            onChange={e => {
                                                                                this.setState({
                                                                                    selectType: e.target.value,
                                                                                    vtype: ''
                                                                                });
                                                                                this.setState({
                                                                                    selectedTypes: [...this.state.selectedTypes, e.target.value]
                                                                                });
                                                                            }}
                                                                            className="form-select"
                                                                        >
                                                                            <option value="">Select Type</option>
                                                                            {this.state.types?.map((item, idx) => (
                                                                                <option key={idx} value={item}>
                                                                                    {item}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {this.state.vtype !== '' && <span style={{ color: 'red' }}>{this.state.vtype}</span>}
                                                                        <br />
                                                                        {this.state.selectedTypes.map((item, idx) => (
                                                                            <button
                                                                                disabled={this.state.viewStatus}
                                                                                style={{
                                                                                    marginBottom: '5px',
                                                                                    marginLeft: '5px'
                                                                                }}
                                                                                key={idx}
                                                                                onClick={e => {
                                                                                    const reducedArr = this.state.selectedTypes.filter((item, itemIndex) => {
                                                                                        return itemIndex !== idx;
                                                                                    });

                                                                                    this.setState({
                                                                                        selectedTypes: reducedArr
                                                                                    });
                                                                                }}
                                                                                type="button"
                                                                                className="btn btn-light btn-label"
                                                                            >
                                                                                <i className="dripicons-tag-delete label-icon "></i>
                                                                                {item}
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Themes</Label>
                                                                        <ReactSearchAutocomplete
                                                                            styling={{
                                                                                backgroundColor: '#fff',
                                                                                zIndex: 1,
                                                                                placeholder: 'Type Nationality',
                                                                                placeholderColor: '#939294',

                                                                                height: '35px',
                                                                                fontSize: 16,
                                                                                borderRadius: '4px',
                                                                                border: '1px solid #ced4da'
                                                                            }}
                                                                            items={this.state.themes}
                                                                            placeholder={'Select Themes'}
                                                                            onSearch={(ss, ee) => this.handleOnSearchThemes(ss, ee)}
                                                                            onHover={() => {}}
                                                                            onSelect={item => {
                                                                                let found = this.state.selectedThemes.find(a => a.name === item.name);
                                                                                if (!found) {
                                                                                    this.setState({
                                                                                        selectTheme: item.id,
                                                                                        vtheme: ''
                                                                                    });
                                                                                    this.setState({
                                                                                        selectedThemes: [
                                                                                            ...this.state.selectedThemes,
                                                                                            {
                                                                                                id: '',
                                                                                                themeId: item.id,
                                                                                                name: item.name
                                                                                            }
                                                                                        ]
                                                                                    });
                                                                                }
                                                                            }}
                                                                            onFocus={() => {}}
                                                                            formatResult={formatResult}
                                                                        />

                                                                        {this.state.vtheme !== '' && <span style={{ color: 'red' }}>{this.state.vtheme}</span>}
                                                                        <br />
                                                                        {this.state.selectedThemes.map((item, idx) => (
                                                                            <button
                                                                                disabled={this.state.viewStatus}
                                                                                style={{
                                                                                    marginBottom: '5px',
                                                                                    marginLeft: '5px'
                                                                                }}
                                                                                key={idx}
                                                                                onClick={e => {
                                                                                    const reducedArr = this.state.selectedThemes.filter((item, itemIndex) => {
                                                                                        return itemIndex !== idx;
                                                                                    });

                                                                                    this.setState({
                                                                                        selectedThemes: reducedArr
                                                                                    });
                                                                                }}
                                                                                type="button"
                                                                                className="btn btn-light btn-label">
                                                                                <i className="dripicons-tag-delete label-icon "></i>
                                                                                {item.name}
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Tags</Label>
                                                                        <ReactSearchAutocomplete
                                                                            styling={{
                                                                                backgroundColor: '#fff',
                                                                                zIndex: 2,
                                                                                placeholder: 'Type Nationality',
                                                                                placeholderColor: '#939294',

                                                                                height: '35px',
                                                                                fontSize: 16,
                                                                                borderRadius: '4px',
                                                                                border: '1px solid #ced4da'
                                                                            }}
                                                                            items={this.state.tags}
                                                                            placeholder={'Select Tags'}
                                                                            onSearch={(ss, ee) => this.handleOnSearchTags(ss, ee)}
                                                                            onHover={() => {}}
                                                                            onSelect={item => {
                                                                                let found = this.state.selectedTags.find(a => a.tagId === item.id);
                                                                                if (!found) {
                                                                                    this.setState({
                                                                                        selectTag: item.id,
                                                                                        vtag: ''
                                                                                    });
                                                                                    this.setState({
                                                                                        selectedTags: [
                                                                                            ...this.state.selectedTags,
                                                                                            {
                                                                                                id: '',
                                                                                                tagId: item.id,
                                                                                                name: item.name
                                                                                            }
                                                                                        ]
                                                                                    });
                                                                                }
                                                                            }}
                                                                            onFocus={() => {}}
                                                                            formatResult={formatResult}
                                                                        />

                                                                        {this.state.vtag !== '' && <span style={{ color: 'red' }}>{this.state.vtag}</span>}
                                                                        <br />
                                                                        {this.state.selectedTags.map((item, idx) => (
                                                                            <button
                                                                                disabled={this.state.viewStatus}
                                                                                style={{
                                                                                    marginBottom: '5px',
                                                                                    marginLeft: '5px'
                                                                                }}
                                                                                key={idx}
                                                                                onClick={e => {
                                                                                    const reducedArr = this.state.selectedTags.filter((item, itemIndex) => {
                                                                                        return itemIndex !== idx;
                                                                                    });

                                                                                    this.setState({
                                                                                        selectedTags: reducedArr
                                                                                    });
                                                                                }}
                                                                                type="button"
                                                                                className="btn btn-light btn-label"
                                                                            >
                                                                                <i className="dripicons-tag-delete label-icon "></i>
                                                                                {item.name}
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Label htmlFor="formrow-firstname-Input">Visibility</Label>
                                                                    <FormGroup switch>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="switch"
                                                                            checked={this.state.hided}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    hided: !this.state.hided
                                                                                })
                                                                            }
                                                                        />
                                                                        <Label check>Hide in Creator section</Label>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-4">
                                                                <Col md={12}>
                                                                    <Label htmlFor="formrow-firstname-Input">Description</Label>
                                                                    <Editor
                                                                        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                                                        cloudChannel="dev"
                                                                        onInit={(evt, editor) => (this.editorRefDescrip = editor)}
                                                                        value={this.state.description}
                                                                        init={{
                                                                            height: 300,
                                                                            menubar: false,
                                                                            plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                                                            paste_as_text: true,
                                                                            mobile: {
                                                                                toolbar_drawer: 'floating'
                                                                            },
                                                                            toolbar:
                                                                                'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                                                        }}
                                                                        onEditorChange={content => this.setState({ description: content })}
                                                                    />
                                                                    {/*<textarea
                                    disabled={this.state.viewStatus}
                                    rows={5}
                                    type="textarea"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Enter Description"
                                    name="description"
                                    value={description}
                                    onChange={e =>
                                      this.setState({
                                        description: e.target.value,
                                      })
                                    }
                                  />*/}
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row>
                                                                <CardTitle className="mb-4">Place of Birth</CardTitle>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Country *</Label>

                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholder: 'Type Country',
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.paginateCountriesDOB}
                                                                                placeholder={this.state.incountry === '' ? 'Type Country name' : this.state.incountry}
                                                                                onSearch={(ss, ee) => this.handleOnSearchDOB(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        incountry: item.name,
                                                                                        instate: '',
                                                                                        incity: '',
                                                                                        vincountry: '',
                                                                                        incountry_id: item.id
                                                                                    });

                                                                                    // this.getStatesDOB(item.id)
                                                                                }}
                                                                                onFocus={() => {
                                                                                    // this.setState({ instate: "", incountry: "", incity: "", vincountry: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                        </div>
                                                                        {this.state.vincountry !== '' && <span style={{ color: 'red' }}>{this.state.vincountry}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">State *</Label>

                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholder: 'Type Country',
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.instates}
                                                                                placeholder={this.state.instate === '' ? 'Type State name' : this.state.instate}
                                                                                onSearch={(ss, ee) => this.handleOnSearchDOBState(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        instate: item.name,
                                                                                        incity: '',
                                                                                        vinstate: '',
                                                                                        instate_id: item.id
                                                                                    });
                                                                                    // this.getStatesDOB(item.id)
                                                                                }}
                                                                                onFocus={() => {
                                                                                    // this.setState({ instate: "", incountry: "", incity: "", vincountry: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                        </div>
                                                                        {this.state.vinstate !== '' && <span style={{ color: 'red' }}>{this.state.vinstate}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">City *</Label>
                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.incities}
                                                                                placeholder={this.state.incity === '' ? 'Type city here' : this.state.incity}
                                                                                onSearch={(ss, ee) => this.handleOnSearchDOBCity(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        incity: item.name,
                                                                                        vincity: ''
                                                                                    });
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({ city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                            {this.state.vincity !== '' && <span style={{ color: 'red' }}>{this.state.vincity}</span>}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Date of Birth *</Label>
                                                                        <input
                                                                            disabled={this.state.viewStatus}
                                                                            className="form-control"
                                                                            type="date"
                                                                            //defaultValue="13:45:00"
                                                                            id="example-time-input"
                                                                            name="indob"
                                                                            value={this.state.indob}
                                                                            onChange={e => {
                                                                                this.setState({
                                                                                    indob: e.target.value,
                                                                                    vindob: ''
                                                                                });
                                                                            }}
                                                                        />
                                                                        {this.state.vindob !== '' && <span style={{ color: 'red' }}>{this.state.vindob}</span>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row>
                                                                <CardTitle className="mb-4">Place of Death</CardTitle>
                                                                <Col md={12}>
                                                                    <div className="form-check form-check-primary mb-3">
                                                                        <input
                                                                            disabled={this.state.viewStatus}
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="customCheckcolor1"
                                                                            checked={this.state.inalive}
                                                                            onChange={e => {
                                                                                this.setState({
                                                                                    inalive: e.target.checked,
                                                                                    vpodaddress: '',
                                                                                    vpodcity: '',
                                                                                    vpodcountry: '',
                                                                                    vpodstate: ''
                                                                                });
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="customCheckcolor1">
                                                                            Creator is alive
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Date of Death</Label>
                                                                        <input
                                                                            disabled={this.state.inalive}
                                                                            className="form-control"
                                                                            type="date"
                                                                            //defaultValue="13:45:00"
                                                                            id="example-time-input"
                                                                            name="in_pod_timestamp"
                                                                            value={this.state.in_pod_timestamp}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    in_pod_timestamp: e.target.value,
                                                                                    vpod: ''
                                                                                })
                                                                            }
                                                                        />
                                                                        {this.state.vpod !== '' && <span style={{ color: 'red' }}>{this.state.vpod}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={8}>
                                                                    {/*<div className="mb-12">
                                  <Label htmlFor="formrow-firstname-Input">Address</Label>
                                  <Input
                                    disabled={this.state.inalive}
                                    className="form-control" 
                                  
                                    type="text" 
                                    placeholder="Enter address"
                                    name="in_pod_address"
                                    value={in_pod_address}
                                    invalid={in_pod_address !== "" ? true : false}
                                    onChange={(e) => this.setState({ in_pod_address: e.target.value, vpodaddress: "" })}

                                  />
                                  {this.state.vpodaddress !== "" && <span style={{ color: 'red' }}>{this.state.vpodaddress}</span>}

                                  </div>*/}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Country </Label>
                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                disabled={this.state.inalive}
                                                                                styling={{
                                                                                    backgroundColor: this.state.inalive ? '#eff2f7' : '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholder: 'Type Country',
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.paginateCountriesPOD}
                                                                                placeholder={this.state.in_pod_country === '' ? 'Type Country name' : this.state.in_pod_country}
                                                                                onSearch={(ss, ee) => (this.state.inalive ? {} : this.handleOnSearchPOD(ss, ee))}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        in_pod_country: item.name,
                                                                                        vpodcountry: '',
                                                                                        in_pod_country_id: item.id,
                                                                                        in_pod_state: '',
                                                                                        in_pod_city: ''
                                                                                    });

                                                                                    // this.getStatesPOD(item.id)
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({  })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                        </div>
                                                                        {this.state.vpodcountry !== '' && <span style={{ color: 'red' }}>{this.state.vpodcountry}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">State</Label>

                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: this.state.inalive ? '#eff2f7' : '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.in_pod_states}
                                                                                placeholder={this.state.in_pod_state === '' ? 'Type State here' : this.state.in_pod_state}
                                                                                onSearch={(ss, ee) => this.handleOnSearchPODState(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        in_pod_state_id: item.id,
                                                                                        selected_pod_state: item.name,
                                                                                        in_pod_state: item.name,
                                                                                        in_pod_city: '',
                                                                                        vpodstate: ''
                                                                                    });
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({ city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                        </div>
                                                                        {this.state.vpodstate !== '' && <span style={{ color: 'red' }}>{this.state.vpodstate}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">City</Label>

                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: this.state.inalive ? '#eff2f7' : '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.in_pod_cities}
                                                                                placeholder={this.state.in_pod_city === '' ? 'Type City here' : this.state.in_pod_city}
                                                                                onSearch={(ss, ee) => this.handleOnSearchPODCity(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        in_pod_city: item.name,
                                                                                        vpodcity: ''
                                                                                    });
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({ city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                        </div>
                                                                        {this.state.vpodcity !== '' && <span style={{ color: 'red' }}>{this.state.vpodcity}</span>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row>
                                                                <CardTitle className="mb-4">Links & Contacts</CardTitle>
                                                                <Col md={12}>
                                                                    <div className="mb-12">
                                                                        <Label htmlFor="formrow-firstname-Input">Address</Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            id="formFileLg"
                                                                            type="texarea"
                                                                            name="address"
                                                                            value={address}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    address: e.target.value,
                                                                                    vaddress: ''
                                                                                })
                                                                            }
                                                                        />

                                                                        {this.state.vaddress !== '' && <span style={{ color: 'red' }}>{this.state.vaddress}</span>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Country</Label>

                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholder: 'Type Country',
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.paginateCountries}
                                                                                placeholder={this.state.country === '' ? 'Type country here' : this.state.country}
                                                                                onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        selectedCountry: item.name,
                                                                                        country: item.name,
                                                                                        country_id: item.id,
                                                                                        statee: '',
                                                                                        city: ''
                                                                                    });
                                                                                    //console.log("select coutnry "+JSON.stringify(item.name))
                                                                                    //this.getStates(item.id)
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({selectedState: "", statee: "", city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                            {this.state.vcountry !== '' && <span style={{ color: 'red' }}>{this.state.vcountry}</span>}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">State</Label>

                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.cstates}
                                                                                placeholder={this.state.statee === '' ? 'Type state here' : this.state.statee}
                                                                                onSearch={(ss, ee) => this.handleOnSearchState(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        state_id: item.id,
                                                                                        statee: item.name,
                                                                                        selectedState: item.name,
                                                                                        city: '',
                                                                                        vstate: ''
                                                                                    });
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({ city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                            {this.state.vstate !== '' && <span style={{ color: 'red' }}>{this.state.vstate}</span>}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">City</Label>

                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.cities}
                                                                                placeholder={this.state.city === '' ? 'Type city here' : this.state.city}
                                                                                onSearch={(ss, ee) => this.handleOnSearchCity(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        city: item.name,
                                                                                        vcity: ''
                                                                                    });
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({ city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                        </div>
                                                                        {this.state.vcity !== '' && <span style={{ color: 'red' }}>{this.state.vcity}</span>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Website</Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Website"
                                                                            name="website"
                                                                            value={website}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    website: e.target.value
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Phone No</Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Phone no"
                                                                            name="phone"
                                                                            value={phone}
                                                                            onChange={e => this.setState({ phone: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Email</Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="example@xyz.com"
                                                                            name="email"
                                                                            value={email}
                                                                            onChange={e => this.setState({ email: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <br />
                                                            <br />
                                                            <div className="text-center mt-4">
                                                                {this.state.id !== '' ? (
                                                                    <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                        Save
                                                                    </button>
                                                                ) : (
                                                                    <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                        Save & Next
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </Form>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        {this.state.showErrorAlert ? (
                                            <SweetAlert error title="" onConfirm={() => this.setState({ showErrorAlert: false })}>
                                                {this.state.alertErrorMessage}
                                            </SweetAlert>
                                        ) : null}

                                        {this.state.show ? (
                                            <CropEasy
                                                ratio={
                                                    this.state.selectedUploadtype === 'Thumbnail'
                                                        ? 1
                                                        : this.state.selectedUploadtype === 'Cover Page'
                                                        ? 1 / 1.57
                                                        : this.state.selectedUploadtype === 'Video'
                                                        ? 1 / 0.6
                                                        : 1.2 / 2.2
                                                }
                                                photoURL={this.state.base64}
                                                parentCallback={this.handleCallback}
                                            />
                                        ) : (
                                            <Row>
                                                <Col lg={12}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    {this.state.showPreview && this.state.videoUrlFlag ? (
                                                                        <MobileVideoPreviews
                                                                            videoUrl={this.state.videoUrl}
                                                                            videoThumnailPreview={this.state.videoThumnailPreview}
                                                                            urlVidFlag={this.state.urlVidFlag}
                                                                        />
                                                                    ) : (
                                                                        <Card>
                                                                            <CardBody>
                                                                                <CardTitle className="h4">Images & Videos</CardTitle>
                                                                                <MobilePreviews
                                                                                    previewtype={this.state.previewtype}
                                                                                    setPreviewUrl={this.state.setPreviewUrl}
                                                                                    cropImageSet={false}
                                                                                    subTitleColour={''}
                                                                                    postSubtitleColor={''}
                                                                                    ftitle={''}
                                                                                    postTitle={''}
                                                                                    descriptionColour={''}
                                                                                    postDescriptionColor={''}
                                                                                    description={''}
                                                                                    postDescription={''}
                                                                                    thumbnailType={'Creator'}
                                                                                />
                                                                            </CardBody>
                                                                        </Card>
                                                                    )}
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <CardTitle className="h4">Upload Images & Videos</CardTitle>

                                                                    {this.state.uploadPercentage > 0 && this.state.uploadPercentage < 100 ? (
                                                                        <>
                                                                            <br />
                                                                            <br />
                                                                            <div className="text-center">{this.state.uploadPercentage} %</div>
                                                                            <Progress value={this.state.uploadPercentage} />
                                                                        </>
                                                                    ) : (
                                                                        <Form className="form" onSubmit={e => this.uploadSubmit(e)} style={{ margin: '30px  0 0 20px' }}>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <div className="mb-4">
                                                                                        <Label htmlFor="formrow-firstname-Input">Upload Type</Label>
                                                                                        <select
                                                                                            disabled={this.state.viewStatus}
                                                                                            value={selectedUploadtype}
                                                                                            onChange={e =>
                                                                                                this.setState({
                                                                                                    selectedUploadtype: e.target.value,
                                                                                                    vselectedUploadtype: '',
                                                                                                    videoUrl: '',
                                                                                                    videoThumnail: '',
                                                                                                    videoThumnailPreview: ''
                                                                                                })
                                                                                            }
                                                                                            className="form-select"
                                                                                        >
                                                                                            <option value="">Select Type</option>
                                                                                            {this.state.uploadtypes.map((item, idx) => (
                                                                                                <option key={idx} value={item}>
                                                                                                    {item}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                        {this.state.vselectedUploadtype !== '' && (
                                                                                            <span style={{ color: 'red' }}>{this.state.vselectedUploadtype}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <div className="mb-4">
                                                                                        <Label htmlFor="formrow-firstname-Input">Select file</Label>
                                                                                        <input
                                                                                            disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                            name="base64"
                                                                                            className="form-control"
                                                                                            id="formFile"
                                                                                            type="file"
                                                                                            accept={this.state.selectedUploadtype === 'Video' ? '.mp4' : '.jpg,.jpeg,.png,.gif'}
                                                                                            onChange={e => this.onInputChange(e)}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            {this.state.selectedUploadtype === 'Video' && (
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <div className="mb-4">
                                                                                            <Label htmlFor="formrow-firstname-Input">Select Video Thumbnail</Label>
                                                                                            <input
                                                                                                disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                                name="videothumb"
                                                                                                className="form-control"
                                                                                                id="formFile"
                                                                                                type="file"
                                                                                                accept={'.jpg,.jpeg,.png'}
                                                                                                onChange={e => this.onInputChangeVideoThumbnai(e)}
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                            <Row>
                                                                                <Col>
                                                                                    <Label htmlFor="formrow-firstname-Input"></Label>
                                                                                    <button type="submit" className="btn btn-primary w-md" style={{ marginBottom: '5px' }}>
                                                                                        Upload
                                                                                    </button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Form>
                                                                    )}
                                                                    {this.state.files.length > 0 && (
                                                                        <Card>
                                                                            <CardBody>
                                                                                <CardTitle className="h4">Images & Videos</CardTitle>
                                                                                <p className="card-title-desc"> </p>

                                                                                <div className="table-responsive">
                                                                                    <Table className="table table-borderless mb-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>Image</th>
                                                                                                <th>Types</th>
                                                                                                <th>Preview</th>
                                                                                                <th>Remove</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.files.map((item, idx) => (
                                                                                                <tr key={idx}>
                                                                                                    <th scope="row">{idx + 1}</th>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            width: '5%',
                                                                                                            height: '5%',
                                                                                                            textAlign: 'center'
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        <img
                                                                                                            style={{
                                                                                                                maxWidth: '70%',
                                                                                                                maxHeight: '70%',
                                                                                                                borderRadius: '5px',
                                                                                                                display: 'block',
                                                                                                                marginLeft: 'auto',
                                                                                                                marginRight: 'auto'
                                                                                                            }}
                                                                                                            src={item.type === 'Video' ? API_URL + item.urlVideoThumbnail : API_URL + item.url}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td>{item.type}</td>
                                                                                                    <td>
                                                                                                        <button
                                                                                                            disabled={this.state.viewStatus}
                                                                                                            onClick={() => {
                                                                                                                if (item.type === 'Video') {
                                                                                                                    this.setState({
                                                                                                                        loading: true,
                                                                                                                        videoUrl: '',
                                                                                                                        videoThumnailPreview: '',
                                                                                                                        videoThumnail: '',
                                                                                                                        urlVidFlag: false
                                                                                                                    });
                                                                                                                    setTimeout(() => {
                                                                                                                        this.setState({
                                                                                                                            videoUrlFlag: true,
                                                                                                                            videoUrl: API_URL + item.url,
                                                                                                                            videoThumnail: API_URL + item.urlVideoThumbnail,
                                                                                                                            videoThumnailPreview: API_URL + item.urlVideoThumbnail,
                                                                                                                            urlVidFlag: true,
                                                                                                                            selectedUploadtype: '',
                                                                                                                            loading: false
                                                                                                                        });
                                                                                                                    }, 500);
                                                                                                                } else {
                                                                                                                    this.setState({
                                                                                                                        previewtype: item.type,
                                                                                                                        showPreview: true,
                                                                                                                        setPreviewUrl: API_URL + item.url,
                                                                                                                        videoUrlFlag: false,
                                                                                                                        urlVidFlag: false,
                                                                                                                        selectedUploadtype: ''
                                                                                                                    });
                                                                                                                }
                                                                                                            }}
                                                                                                            type="button"
                                                                                                            className="btn btn-light btn-sm"
                                                                                                        >
                                                                                                            View
                                                                                                        </button>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <button
                                                                                                            disabled={this.state.viewStatus}
                                                                                                            onClick={() => this.removeFile(item.id)}
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                        >
                                                                                                            Delete
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}
                                    </TabPane>
                                    {/*<TabPane tabId="3">
                                        <Contents activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} section={"creator"} relatedId={this.state.id} />
                                    </TabPane>*/}
                                    <TabPane tabId="3">
                                        <CreatorExternalLinks activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                    </TabPane>
                                </TabContent>
                            )}
                        </Card>
                    </Container>
                    {/* container-fluid */}
                </div>
            </React.Fragment>
        );
    }
}

IndividualCreatorDetails.propTypes = {
    //cindividuals: PropTypes.array,
    className: PropTypes.any,
    match: PropTypes.object,
    onUpdateCIndividuals: PropTypes.func,

    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    onUpdateCIndividuals: cindividuals => dispatch(updateCIndividuals(cindividuals))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndividualCreatorDetails));

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </>
    );
};
