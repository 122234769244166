import { INITIAL_LIST_STATE } from './state'
import { DELETE_ARTICLES_FAIL, DELETE_ARTICLES_SUCCESS, LOAD_CONTENTS_LIST_FAIL, LOAD_CONTENTS_LIST_SUCCESS, LOAD_CONTENTS_LIST } from './types'

export const list = (state = INITIAL_LIST_STATE, action) => {
    switch (action.type) {

        case LOAD_CONTENTS_LIST:
            return {
                ...state,
                loading: true,
                type: action.payload,
            }

        case LOAD_CONTENTS_LIST_SUCCESS:
            return {
                ...state,
                items: action.payload.content,
                totalItems: action.payload.totalElements,
                totalPages: action.payload.totalPages,
                loading: false,
            }

        case LOAD_CONTENTS_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case DELETE_ARTICLES_SUCCESS:
            return {
                ...state,
                items: state.items.filter(item => item.id.toString() !== action.payload.id.toString()),
                totalItems: state.totalItems - 1
            }

        case DELETE_ARTICLES_FAIL:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}
