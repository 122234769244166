import axios from 'axios';
import Loader from '../../../../components/Common/Loader';
import {API_URL} from '../../../../constants/apiurl';
import {dataURLtoBlob} from '../../../../constants/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Button, Card, CardBody, Col, Form, Input, Label, Row, Table} from 'reactstrap';
import CropEasyParticle from '../../../../components/Crop/CropEasy';
import {
    deleteStep,
    deleteStepImage,
    postStep,
    postStepOrder,
    uploadStepTumbnail
} from '../../../../helpers/Api/Occurences/routes';
import RoutePages from './Pages';
import DragAndDropList from "../../../../components/DragAndDropList";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

const getExhibId = () => {
    if (localStorage.getItem('exhibid')) {
        const obj = JSON.parse(localStorage.getItem('exhibid'));
        return obj;
    }
};

class RouteSteps extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            // global
            viewStatus: false,
            loading: false,
            showAlert: false,
            alertMessage: '',

            physicalPoints: [],

            // Form
            stepId: '',
            title: '',
            vtitle: '',
            subTitle: '',
            orderPosition: '',
            image: '',
            imagePreview: '',
            imageName: '',
            showCropEditor: false,
            showPreview: false,
            physicalPoint: '',
            // Pages
            showPages: false,
            stepIdForPages: '',
            stepTitleForPages: '',
            pages: [],
            // Pages Ordering
            showStepsOrdering: false,
            stepsOrderingList: []
        };

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        const {
            match: {params}
        } = this.props;

        if (params.idd === 'view') {
            this.setState({viewStatus: true});
        } else {
            this.getPhysicalPoints();
        }
    }

    getPhysicalPoints = () => {
        let eid = getExhibId();
        if (eid.id === undefined || eid === undefined) {
            return;
        }

        this.setState({loading: true});

        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + eid.id + '/physical/point', axiosconf)
            .then(response => {
                this.setState({physicalPoints: response.data, loading: false});
            })
            .catch(e => console.log('GET PHYSICAL POINTS ERROR', e))
            .finally(() => this.setState({loading: false}));
    };

    onInputChange = e => {
        const file = e.target.files[0];
        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
        if (size > 300) {
            this.setState({
                showAlert: true,
                alertMessage: 'Please ensure image size is less than 300 KB',
                selectedUploadtype: '',
                vselectedUploadtype: ''
            });
            e.target.value = null;
        } else {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    this.setState({
                        imagePreview: reader.result,
                        imageName: file.name,
                        showCropEditor: true
                    });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    handleCallback = childData => {
        this.setState({
            showCropEditor: false,
            imagePreview: childData ? childData : '',
            image: childData ? dataURLtoBlob(childData) : '',
            showPreview: childData ? true : false
        });
    };

    async submitForm(e) {
        e.preventDefault();

        if (this.state.title === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vtitle: 'This field is required!'});
        }

        if (this.state.title !== '') {
            let postData = {
                id: this.state.stepId,
                title: this.state.title,
                subTitle: this.state.subTitle,
                orderPosition: +this.state.orderPosition,
                exhibitionPhysicalPointId: this.state.physicalPoint ? +this.state.physicalPoint : undefined
            };

            this.setState({loading: true});
            const response = await postStep(this.props.routedetails.route.id, postData);
            if (response.error) {
                this.setState({loading: false});
                console.log('error ---- ' + JSON.stringify(response));
            } else {
                if (this.state.image) {
                    await uploadStepTumbnail(response.data.id, this.state.image, this.state.imageName);
                }

                console.log('ciaone');

                this.setState({
                    loading: false,
                    stepId: '',
                    title: '',
                    vtitle: '',
                    subTitle: '',
                    orderPosition: '',
                    physicalPoint: '',
                    image: '',
                    imagePreview: '',
                    imageName: '',
                    showPreview: false
                });

                this.props.parentCallbackUpdate(this.props.routedetails.route.id);
            }
        }
    }

    setStepsOrder = () => {
        let postData = this.state.stepsOrderingList.map(item => (
            {
                id: item.id,
                title: item.title,
                subTitle: item.subTitle,
                orderPosition: item.orderPosition,
                exhibitionPhysicalPointId: item.physicalPoint ? +item.physicalPoint : undefined
            }
        ));

        this.setState({loading: true});
        postStepOrder(this.props.routedetails.route.id, postData)
            .then(response => {
                this.setState({stepsOrderingList: [], loading: false});
            })
            .catch(e => console.log('SET STEPS ORDER ERROR', e))
            .finally(() => {
                this.setState({loading: false})
                this.handleClosePagesOrdering();
                this.props.parentCallbackUpdate(this.props.routedetails.route.id);
            });

    }
    removeImage = async stepId => {
        this.setState({loading: true});
        const response = await deleteStepImage(stepId);
        if (response.error) {
            this.setState({loading: false});
        } else {
            this.setState({loading: false});
            this.props.parentCallbackUpdate(this.props.routedetails.route.id);
        }
    };

    removeStep = async id => {
        this.setState({loading: true});
        const response = await deleteStep(id);
        if (response.error) {
            this.setState({loading: false});
        } else {
            this.setState({loading: false});
            this.props.parentCallbackUpdate(this.props.routedetails.route.id);
        }
    };

    handlePagesUpdate = stepId => {
        this.props.parentCallbackUpdate(this.props.routedetails.route.id);
        setTimeout(() => {
            this.setState({pages: this.props.routedetails.routeSteps?.find(r => r.id === stepId).routeStepPages});
        }, 1200);
    };

    handleClosePages = () => {
        this.setState({
            showPages: false,
            stepIdForPages: '',
            stepTitleForPages: '',
            pages: []
        });
    };

    handleClosePagesOrdering = () => {
        this.setState({
            showStepsOrdering: false,
            stepsOrderingList: []
        });
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    handleDragEnd = ({destination, source}) => {
        if (!destination) return;
        this.setState({stepsOrderingList: this.reorder(this.state.stepsOrderingList, source.index, destination.index)});
    };

    render() {
        return (
            <>
                {this.state.showPages && !this.state.showStepsOrdering && (
                    <RoutePages
                        stepId={this.state.stepIdForPages}
                        stepTitle={this.state.stepTitleForPages}
                        pages={this.state.pages}
                        parentCallbackUpdate={this.handlePagesUpdate}
                        parentClosePages={() => this.handleClosePages()}
                    />
                )}

                {!this.state.showPages && this.state.showStepsOrdering && (
                    <React.Fragment>
                        <Card>
                            <div style={{display: 'flex'}}>
                                <button onClick={this.handleClosePagesOrdering} style={{marginLeft: 'auto'}}
                                        type="button" className="btn btn-primary">
                                    <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                    Back to Steps
                                </button>
                            </div>

                            <div className="text-center mt-0" style={{margin: 30}}>
                                <h5>List | Ordering</h5>
                            </div>

                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead className="table-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Subtitle</th>
                                        <th>Physical Point</th>
                                    </tr>
                                    </thead>
                                    <DragAndDropList
                                        list={this.state.stepsOrderingList}
                                        onDragEnd={this.handleDragEnd}
                                        dragItemStyle={{
                                            background: 'lightgreen',
                                            borderRadius: '16px',
                                        }}
                                        dragListStyle={{
                                            background: 'lightblue',
                                            borderRadius: '16px',
                                        }}
                                    >
                                        {(item) => (
                                            <>
                                                <td>{item.id + 1}</td>
                                                <td>{item.title}</td>
                                                <td>{item.subTitle}</td>
                                                <td>{item.exhibitionPhysicalPoint?.note}</td>
                                            </>
                                        )}
                                    </DragAndDropList>
                                </Table>
                            </div>
                            <div className="text-center mt-4" style={{margin: 30}}>
                                <button disabled={this.state.viewStatus}
                                        className="btn btn-primary w-md" onClick={this.setStepsOrder}>
                                    Save
                                </button>
                            </div>
                        </Card>
                    </React.Fragment>
                )}

                {!this.state.showPages && !this.state.showStepsOrdering && (
                    <React.Fragment>
                        <Card>
                            <div className="text-center mt-0">
                                <h5>Exhibition | Route Steps for {this.props.routedetails.route.title}</h5>
                            </div>

                            <div style={{display: 'flex'}}>
                                <button onClick={() => this.props.parentCloseSteps()} style={{marginLeft: 'auto'}}
                                        type="button" className="btn btn-primary">
                                    <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                    Back to route details
                                </button>
                            </div>
                            {this.state.loading ? (
                                <Loader color={'green'} loading={this.state.loading}/>
                            ) : (
                                <>
                                    {this.state.showCropEditor ? (
                                        <CropEasyParticle ratio={1} photoURL={this.state.imagePreview}
                                                          parentCallback={this.handleCallback} shape="rect"/>
                                    ) : (
                                        <>
                                            <Row>
                                                <Col lg={12}>
                                                    <Card>
                                                        <CardBody>
                                                            {this.state.showAlert ? (
                                                                <SweetAlert warning title={''}
                                                                            onConfirm={() => this.setState({showAlert: false})}>
                                                                    {this.state.alertMessage}
                                                                </SweetAlert>
                                                            ) : null}
                                                            <Form className="form" onSubmit={e => this.submitForm(e)}>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <Label
                                                                            htmlFor="formrow-firstname-Input">Title</Label>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Title"
                                                                            name="title"
                                                                            value={this.state.title}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    title: e.target.value,
                                                                                    vtitle: ''
                                                                                })
                                                                            }
                                                                        />
                                                                        {this.state.vtitle !== '' && <span
                                                                            style={{color: 'red'}}>{this.state.vtitle}</span>}
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <Label
                                                                            htmlFor="formrow-firstname-Input">Subtitle</Label>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Subtitle"
                                                                            name="subTitle"
                                                                            value={this.state.subTitle}
                                                                            onChange={e => this.setState({subTitle: e.target.value})}
                                                                        />
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <Label htmlFor="formrow-firstname-Input">Physical
                                                                            point</Label>
                                                                        <select
                                                                            readOnly={this.state.viewStatus}
                                                                            value={this.state.physicalPoint}
                                                                            onChange={e => this.setState({physicalPoint: e.target.value})}
                                                                            className="form-select"
                                                                        >
                                                                            <option value="">Select physical point
                                                                            </option>
                                                                            {this.state.physicalPoints.map(item => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.note}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </Col>
                                                                    <Col md={1}>
                                                                        <Label
                                                                            htmlFor="formrow-firstname-Input">Image</Label>
                                                                        {this.state.showPreview ? (
                                                                            <>
                                                                                <img width={50} height={50}
                                                                                     src={this.state.imagePreview}/>
                                                                                <i
                                                                                    className="mdi mdi-delete font-size-14"
                                                                                    style={{color: 'red'}}
                                                                                    id="deletetooltip"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            image: '',
                                                                                            imagePreview: '',
                                                                                            imageName: '',
                                                                                            showPreview: false
                                                                                        });
                                                                                    }}
                                                                                ></i>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Button
                                                                                    style={{backgroundColor: '#C0C0C0'}}
                                                                                    className="btn-rounded btn-sm"
                                                                                    onClick={() => this.fileInputRef.current.click()}
                                                                                >
                                                                                    <i
                                                                                        className="mdi mdi-plus font-size-45"
                                                                                        style={{
                                                                                            color: '#2E2E2E',
                                                                                            width: '70px',
                                                                                            height: '70px'
                                                                                        }}
                                                                                        id="deletetooltip"
                                                                                    ></i>
                                                                                </Button>
                                                                                <input
                                                                                    onChange={e => this.onInputChange(e)}
                                                                                    name="image"
                                                                                    multiple={false}
                                                                                    ref={this.fileInputRef}
                                                                                    accept=".jpg,.jpeg,.png"
                                                                                    type="file"
                                                                                    hidden
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </Col>
                                                                    <Col md={1}>
                                                                        <Label
                                                                            htmlFor="formrow-firstname-Input">{'\t'}</Label>
                                                                        <br/>
                                                                        <Button type="submit" color={'primary'}
                                                                                className="btn-md">
                                                                            {this.state.stepId ? 'Edit' : 'Add'}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <button
                                                        onClick={() =>
                                                            this.setState({
                                                                showStepsOrdering: true,
                                                                stepsOrderingList: this.props.routedetails.routeSteps
                                                                    ?.sort((a, b) => a.orderPosition - b.orderPosition)
                                                            })
                                                        }
                                                        className="btn btn-primary w-xm"
                                                    >
                                                        Change Order
                                                    </button>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: '15px'}}>
                                                <Col md={12}>
                                                    <div className="table-responsive">
                                                        <Table className="table mb-0">
                                                            <thead className="table-light">
                                                            <tr>
                                                                <th>Order position</th>
                                                                <th>Title</th>
                                                                <th>Subtitle</th>
                                                                <th>Physical Point (PP)</th>
                                                                <th>PP Position</th>
                                                                <th style={{width: 150}}>Image</th>
                                                                <th style={{width: 230}}/>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.props.routedetails.routeSteps
                                                                ?.sort((a, b) => a.orderPosition - b.orderPosition)
                                                                .map((item, idx) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>{item.orderPosition}</td>
                                                                            <td>{item.title}</td>
                                                                            <td>{item.subTitle}</td>
                                                                            <td>{item.exhibitionPhysicalPoint?.note}</td>
                                                                            <td>{item.physicalPointPosition}</td>
                                                                            <td>
                                                                                {item.files[0] ? (
                                                                                    <>
                                                                                        <img
                                                                                            src={API_URL + item.files[0].url}
                                                                                            width={50} height={50}/>
                                                                                        <i
                                                                                            className="mdi mdi-delete font-size-18"
                                                                                            style={{color: 'red'}}
                                                                                            id="deletetooltip"
                                                                                            onClick={() => this.removeImage(item.id)}
                                                                                        ></i>
                                                                                    </>
                                                                                ) : null}
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            stepId: item.id,
                                                                                            title: item.title,
                                                                                            subTitle: item.subTitle,
                                                                                            orderPosition: item.orderPosition,
                                                                                            physicalPoint: item.exhibitionPhysicalPoint?.id || ''
                                                                                        });
                                                                                    }}
                                                                                    className="btn btn-primary w-xm"
                                                                                >
                                                                                    Edit
                                                                                </button>
                                                                                &nbsp;
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.setState({
                                                                                            showPages: true,
                                                                                            stepIdForPages: item.id,
                                                                                            stepTitleForPages: item.title,
                                                                                            pages: item.routeStepPages
                                                                                        })
                                                                                    }
                                                                                    className="btn btn-secondary w-xm"
                                                                                >
                                                                                    Pages
                                                                                </button>
                                                                                &nbsp;
                                                                                <button
                                                                                    onClick={() => this.removeStep(item.id)}
                                                                                    className="btn btn-danger w-xm">
                                                                                    Remove
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </>
                            )}
                        </Card>
                    </React.Fragment>
                )}
            </>
        );
    }
}

RouteSteps.propTypes = {
    match: PropTypes.object,
    parentCallbackUpdate: PropTypes.func,
    parentCloseSteps: PropTypes.func,
    routeid: PropTypes.number,
    routedetails: PropTypes.object
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouteSteps));
