import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_PARTICLES, DELETE_PARTICLES, UPDATE_PARTICLES } from './actionTypes';

import { getParticlesSuccess, getParticlesFail, deleteParticlesFailed, deleteParticlesSuccess } from './actions';

//Include Both Helper File with needed methods
import { getParticles, deleteParticles } from '../../helpers/fakebackend_helper';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

function* fetchParticles() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getParticles, obj.access_token);
        //console.log("Particles saga"+JSON.stringify(response))

        yield put(getParticlesSuccess(response.content));
    } catch (error) {
        yield put(getParticlesFail(error));
    }
}

function* onDeleteParticles({ payload: particles }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: particles.id };
        const response11 = yield call(deleteParticles, objj);
        yield put(deleteParticlesSuccess(response11));
    } catch (error) {
        yield put(deleteParticlesFailed(error));
    }
}

function* particlesSaga() {
    yield takeEvery(GET_PARTICLES, fetchParticles);
    yield takeEvery(DELETE_PARTICLES, onDeleteParticles);
}

export default particlesSaga;
