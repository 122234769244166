import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as types from './types'
import { getDeeplink } from '../../helpers/Api/common_apis'

function* handleCreateDeepLink({ payload }) {
    try {
      const response = yield call(getDeeplink, payload)
      yield put(actions.CreateDeepLinkSuccess(response.data))
    } catch (error) {
      yield put(actions.CreateDeepLinkFail(error.message))
    }
}

export default function* linkSaga() {
    yield takeLatest(types.CREATE_DEEP_LINK, handleCreateDeepLink)
  }

