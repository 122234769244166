import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    Progress,
    Row,
    TabContent,
    Table,
    TabPane,
    Toast,
    ToastHeader
} from 'reactstrap';

import {API_URL} from '../../constants/apiurl';
import Loader from '../../components/Common/Loader';
import classnames from 'classnames';

import {getArtWorks, updateArtWorks} from '../../store/artworks/actions';

import CropEasy from '../../components/Crop/CropEasy';

import SweetAlert from 'react-bootstrap-sweetalert';

import {
    callArtworkDetailsById,
    callArtworkStatus,
    callArtworkVenues,
    deleteArtworkImage,
    getExternalLinks,
    postArtwork,
    postArtworkVenues,
    postExternalLinks,
    uploadArtworkImage
} from '../../helpers/Api/artwork_apis';
import {
    getDeeplink,
    lengthVideo,
    searchPublishCreators,
    searchPublishTags,
    searchPublishThemes,
    searchVenuePublished
} from '../../helpers/Api/common_apis';
import {callUploadTypes} from '../../helpers/Api/creator_apis';
import {dataURLtoBlob} from '../../constants/utils';

import MobilePreviews from '../../components/MobilePreviews/MobilePreviews';
import {ReactSearchAutocomplete} from 'react-search-autocomplete';

import {Editor} from '@tinymce/tinymce-react';
import MobileVideoPreviews from '../../components/MobilePreviews/MobileVideoPreviews';
import axios from 'axios';
import CropEasyParticle from '../../components/Crop/CropEasy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Contents from "../Exhibitions/components/Contents";
import "./artwork-detail.css";

class ArtWorksDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            selectedFiles: [],
            statuslist: [],
            uploadtypes: [],
            name: '',
            vname: '',
            creators: [],
            creator: {},
            selectCreator: '',
            vcreator: '',
            selectedDateType: '',
            vselectedDateType: '',
            date_of_creation: '',
            vdate_of_creation: '',
            end_date: '',
            vend_date: '',
            styleName: '',
            techniqueAndMaterial: '',
            realMeasurement: '',
            collection: '',
            disclaimer: '',
            //-----
            tags: [],
            selectedTags: [],
            selectTag: '',
            themes: [],
            selectedThemes: [],
            selectTheme: '',
            description: '',
            status: '',
            vstatus: '',
            //-----
            id: '',
            loading: false,
            files: [],
            viewStatus: false,
            editStatus: false,
            newStatus: false,
            selectedUploadtype: '',
            fielupload: '',
            vselectedUploadtype: '',
            base64: '',
            show: '',
            filename: '',
            showPreview: true,
            setPreviewUrl: '',
            previewtype: '',
            flashShow: false,
            //-----new for company
            setPageStatus: '',
            vtag: '',
            vthemes: '',
            idd: '',
            showAlert: '',
            alertMessage: '',
            venues: [],
            selectedVenues: [],
            successMessage: '',
            showAlertSuccess: false,
            alertErrorMessage: '',
            showErrorAlert: false,
            successFlag: false,
            showAlertWarning: false,
            //Video -----
            videoUrlFlag: false,
            videoUrl: {},
            videoThumnailPreview: '',
            videoThumnail: '',
            videoThumnailName: '',
            urlVidFlag: false,
            uploadPercentage: 0,
            authtoken: '',
            link: '',
            links: [],
            selectedType: '',
            type: '',
            vtype: '',
            linktext: '',
            vlinktext: '',
            url: '',
            vurl: '',
            showE: false,
            base64E: '',
            setPreviewUrlE: '',
            showPreviewE: false,
            selectedUploadtypeE: '',
            vselectedUploadtypeE: '',
            verror: '',
            statusE: 'Draft',
            deeplink: '',
            copied: false
        };
        this.toggleTab = this.toggleTab.bind(this);
        this.editorRefDescrip = null;
        this.editorStyle = null;
        this.editorTechniqMaterial = null;
        this.editorRealMeasure = null;
        this.editorCollection = null;
        this.editorDisclaimer = null;
        this.editorLink = null;
        // this.myRef = React.createRef()
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.getArtworkStatus();

        this.getUploadTypes();

        const {
            match: {params}
        } = this.props;
        if (params.idd === 'view') {
            this.setState({viewStatus: true, setPageStatus: 'View'});
        }

        if (params.idd === 'post') {
            this.setState({newStatus: true, setPageStatus: 'Create'});
        }

        if (params.idd === 'edit') {
            this.setState({setPageStatus: 'Edit'});
        }

        if (params.id !== 'new') {
            this.setState({editStatus: true});
            this.getArtworkDetails(params.id);
        } else {
            this.setState({editStatus: false});
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });

            if (tab === '1' && this.state.newStatus === false) {
                this.getArtworkDetails(this.state.id);
            }
        }
    }

    async getDeeplinkData(id) {
        let postdata = {
            type: 'Artwork',
            reference: id,
            contentType: null
        };
        const data = await getDeeplink(postdata);
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else {
            //console.log("------ "+JSON.stringify(data))
            this.setState({deeplink: data.data.shortLink});
        }
    }

    async getArtworkStatus() {
        const response = await callArtworkStatus();
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({statuslist: response.data});
        }
    }


    async handleOnSearchTags(string, results) {
        const response = await searchPublishTags(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({tags: response.data.content, searchFlag: true});
        }
    }

    async handleOnSearchThemes(string, results) {
        const response = await searchPublishThemes(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({themes: response.data.content, searchFlag: true});
        }
    }

    async handleOnSearchCreators(string, results) {
        const response = await searchPublishCreators(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({creators: response.data.content, searchFlag: true});
        }
    }

    async getUploadTypes() {
        const response = await callUploadTypes();
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({
                uploadtypes: response.data
            });
        }
    }

    async getArtworkDetails(id) {
        this.setState({loading: true});
        const response = await callArtworkDetailsById(id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({loading: false});
        } else {
            //console.log("themes --- "+JSON.stringify(response.data.creator))
            var dd = new Date(response.data.timestamp).toISOString();
            var de = response.data.endDate !== null ? new Date(response.data.endDate).toISOString().substring(0, 10) : '';
            this.setState({
                loading: false,
                name: response.data.name,
                selectCreator: response.data.creator.id,
                creator: response.data.creator,
                selectedDateType: response.data.dateType,
                date_of_creation: dd.substring(0, 10),
                end_date: de,
                styleName: response.data.styleName,
                techniqueAndMaterial: response.data.techniqueAndMaterial,
                realMeasurement: response.data.realMeasurement,
                collection: response.data.collection,
                disclaimer: response.data.disclaimer,
                link: response.data.link,

                //tags: response.data.tags,
                selectedTags: response.data.tags,

                //themes: response.data.themes,
                selectedThemes: response.data.themes,

                description: response.data.description,
                status: response.data.status,
                //------
                id: response.data.id,
                files: response.data.files
                //-----
            });
            this.getDeeplinkData(id);
        }
    }

    async submitForm(e) {
        e.preventDefault();
        if (this.state.name === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vname: 'Theme name is required!'});
        }

        if (this.state.status === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vstatus: 'Status is required!'});
        }
        if (this.state.selectCreator === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vcreator: 'Select creator!'});
        }

        if (this.state.selectedDateType === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vselectedDateType: 'Date is required!'});
        }

        if (this.state.selectedDateType !== 'SingleYear' && this.state.date_of_creation === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vdate_of_creation: 'Year is required!'});
        }

        if (this.state.selectedDateType === 'RangeOfYear' && this.state.end_date === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vend_date: 'End year is required!'});
        }

        if (
            this.state.name !== '' &&
            this.state.status !== '' &&
            this.state.selectCreator !== '' &&
            this.state.selectedDateType !== '' &&
            this.state.date_of_creation !== '' &&
            (this.state.selectedDateType !== 'RangeOfYear' || this.state.end_date !== '')
        ) {
            console.log('okk');

            const {
                match: {params}
            } = this.props;
            this.setState({loading: true});

            var endDate = this.state.selectedDateType === 'RangeOfYear' ? toIsoString(new Date(this.state.end_date)) : null

            let postParams = {
                name: this.state.name,
                tags: this.state.selectedTags,
                themes: this.state.selectedThemes,
                timestamp: toIsoString(new Date(this.state.date_of_creation)),
                endDate: endDate,
                dateType: this.state.selectedDateType,
                description:
                    this.editorRefDescrip.getContent({format: 'text'}).trim().length === 0
                        ? null
                        : "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                        this.state.description.replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                        '</body></html>',
                status: this.state.status,
                id: this.state.id,
                creator: {
                    id: this.state.selectCreator
                },
                styleName: this.state.styleName,
                techniqueAndMaterial: this.state.techniqueAndMaterial,
                realMeasurement: this.state.realMeasurement,
                collection: this.state.collection,
                disclaimer: this.state.disclaimer,
                link:
                    this.editorLink.getContent({format: 'text'}).trim().length === 0
                        ? null
                        : "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                        this.state.link.replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                        '</body></html>'
            };

            if (params.id === 'new') {
                if (this.state.id !== '') {
                    this.props.onUpdateArtworks(postParams);
                    this.setState({showAlertSuccess: true, loading: false, successMessage: 'Updated Successfully!'});
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    this.getArtworkDetails(this.state.id);
                    this.setState({
                        activeTab: '2',
                        newStatus: false
                    });
                    //this.props.history.push('/web/artworks-list');
                } else {
                    const response = await postArtwork(postParams);
                    if (response.error) {
                        console.log('error ---- ' + JSON.stringify(response));
                        this.setState({loading: false});
                    } else {
                        this.setState({
                            showAlertSuccess: true,
                            loading: false,
                            successMessage: 'Created Successfully!'
                        });
                        // this.props.onGetArtworks();
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        this.getArtworkDetails(response.data.id);
                        this.setState({
                            activeTab: '2',
                            newStatus: false
                        });
                    }
                }
            } else {
                this.props.onUpdateArtworks(postParams);
                this.setState({showAlertSuccess: true, loading: false, successMessage: 'Updated Successfully!'});
                window.scrollTo({top: 0, behavior: 'smooth'});
                this.getArtworkDetails(this.state.id);
                this.setState({
                    activeTab: '2',
                    newStatus: false
                });
                //this.props.history.push('/web/artworks-list');
            }
        }
    }

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    uploadFileProgress = () => {
        let formData = new FormData();
        formData.append('file', this.state.videoUrl, this.state.filename);

        if (this.state.videoThumnail !== '') {
            formData.append('file', this.state.videoThumnail, this.state.videoThumnailName);
        }

        let autTok = this.getAuthDetails();
        setTimeout(() => {
            this.setState({authtoken: autTok.access_token});
            const options = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + this.state.authtoken
                },
                onUploadProgress: progressEvent => {
                    const {loaded, total} = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    //console.log( `${loaded}kb of ${total}kb | ${percent}%` );

                    if (percent < 100) {
                        this.setState({uploadPercentage: percent});
                    }
                }
            };
            axios
                .post(API_URL + '/tt/artwork/' + this.state.id + '/file?type=' + this.state.selectedUploadtype, formData, options)
                .then(res => {
                    this.setState({uploadPercentage: 100});
                    this.setState({
                        loading: false,
                        videoUrlFlag: false,
                        flashShow: false,
                        showPreview: true,
                        setPreviewUrl: '',
                        previewtype: '',
                        showAlert: true,
                        alertMessage: 'Uploaded Successfully',
                        successFlag: true,
                        selectedUploadtype: '',
                        uploadPercentage: 0
                    });

                    this.getArtworkDetails(this.state.id);
                })
                .catch(e => {
                    console.log('error... ' + JSON.stringify(e));
                });
        }, 400);
    };

    onInputChangeVideoThumbnai = e => {
        const file = e.target.files[0];
        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

        if (size > 10000) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure image size is less than 10000 KB'
                //selectedUploadtype: "",
                //vselectedUploadtype: "",
                //urlVidFlag: false
            });
            //e.target.value = null
            return;
        } else {
            var filename = file.name;
            this.setState({
                videoThumnailName: filename,
                vselectedUploadtype: '',
                urlVidFlag: false
            });
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({base64: reader.result, show: true});
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    async videoUpload(e) {
        const video = await lengthVideo(e.currentTarget.files[0]);
        console.log('duration.... ' + video.duration);
        if (video.duration > 1200) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure video duration is less than 20 minutes.',
                selectedUploadtype: '',
                vselectedUploadtype: '',
                videoUrlFlag: false,
                urlVidFlag: false
            });
            e.target.value = null;
            return;
        } else {
            var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            if (size > 250000) {
                this.setState({
                    showAlertWarning: true,
                    alertMessage: 'Please ensure video size is less than 250 MB.',
                    selectedUploadtype: '',
                    vselectedUploadtype: '',
                    videoUrlFlag: false,
                    urlVidFlag: false
                });
                e.target.value = null;
                return;
            } else {
                const reader = new FileReader();
                //var url = URL.createObjectURL(e.target.files[0].originFileObj);
                //console.log("url video... "+JSON.stringify(url))
                this.setState({
                    videoUrl: e.target.files[0],
                    fielupload: e.target.files[0],
                    videoUrlFlag: true,
                    filename: e.target.files[0].name,
                    urlVidFlag: false
                });
            }
        }
    }

    onInputChange = e => {
        if (this.state.selectedUploadtype === '') {
            this.setState({vselectedUploadtype: 'Please select the type'});
            return;
        } else {
            if (this.state.selectedUploadtype === 'Video') {
                this.setState({videoUrl: {}, videoUrlFlag: false, fielupload: ''});

                this.videoUpload(e);
            } else {
                // if (this.state.selectedUploadtype === "Thumbnail") {
                // convert image file to base64 string
                //this.setState({videoUrlFlag: false})
                const file = e.target.files[0];

                var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

                if (size > 300) {
                    this.setState({
                        showAlertWarning: true,
                        alertMessage: 'Please ensure image size is less than 300 KB.',
                        selectedUploadtype: '',
                        vselectedUploadtype: '',
                        videoUrlFlag: false,
                        urlVidFlag: false
                    });
                    e.target.value = null;
                } else {
                    var filename = file.name;
                    this.setState({
                        filename: filename,
                        flashShow: false,
                        filename: e.target.files[0].name,
                        videoUrlFlag: false,
                        urlVidFlag: false
                    });

                    const reader = new FileReader();

                    reader.addEventListener(
                        'load',
                        () => {
                            // setInputImg(reader.result)
                            this.setState({base64: reader.result, show: true});
                        },
                        false
                    );

                    if (file) {
                        reader.readAsDataURL(file);
                        //this.setState({imagePreviewUrl: file})
                    }
                }
            }
        }
    };

    uploadSubmit(e) {
        e.preventDefault();
        if (this.state.selectedUploadtype === '' || this.state.fielupload === '') {
            this.setState({vselectedUploadtype: 'Please select the type and file to upload'});
        } else {
            if (this.state.selectedUploadtype === 'Video') {
                if (this.state.filename === '') {
                    this.setState({
                        vselectedUploadtype: 'Video file is required!'
                    });
                } else {
                    this.uploadFileProgress();
                }
            } else {
                this.uploadSubmitFile();
            }
        }
    }

    uploadSubmitFile = async () => {
        let formData = new FormData();
        formData.append('file', this.state.fielupload, this.state.filename);
        this.setState({loading: true});

        const response = await uploadArtworkImage(this.state.id, this.state.selectedUploadtype, formData);
        this.setState({base64: '', fielupload: '', selectedUploadtype: ''});

        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({loading: false});
            this.setState({
                loading: false,
                showErrorAlert: true,
                alertErrorMessage: response.error.response.data.message
            });
        } else {
            this.setState({
                loading: false,
                flashShow: false,
                showPreview: true,
                setPreviewUrl: '',
                previewtype: '',
                showAlert: true,
                alertMessage: 'Uploaded Successfully',
                successFlag: true,
                videoUrlFlag: false
            });
            this.getArtworkDetails(this.state.id);
        }
    };

    handleCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = dataURLtoBlob(childData);
        }
        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                flashShow: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
    };

    async removeFile(id) {
        const response = await deleteArtworkImage(id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({loading: false});
        } else {
            this.setState({showPreview: true, flashShow: false, previewtype: ''});
            this.getArtworkDetails(this.state.id);
        }
    }

    //----VENUE------

    async getVenues() {
        this.setState({loading: true});
        const response = await callArtworkVenues(this.state.id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({loading: false});
        } else {
            this.setState({
                selectedVenues: response.data,
                loading: false
            });
        }
    }

    async postVenues() {
        this.setState({loading: true});
        const response = await postArtworkVenues(this.state.id, this.state.selectedVenues);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({loading: false});
        } else {
            this.setState({
                showAlertSuccess: true,
                loading: false,
                successMessage: 'Updated Successfully!',
                selectedVenues: []
            });
            this.getVenues();
        }
    }

    async handleOnSearch(string, results) {
        const response = await searchVenuePublished(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({venues: response.data.content});
        }
    }

    //-----END VENUE-----

    //----------External Links --------------

    handleCallbackE = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = dataURLtoBlob(childData);
        }
        this.setState({showE: false, base64E: childData, setPreviewUrlE: cdata, showPreviewE: true, verror: ''});
    };

    onInputChangeE = e => {
        const file = e.target.files[0];
        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
        if (size > 300) {
            this.setState({
                showAlert: true,
                alertMessage: 'Please ensure image size is less than 300 KB',
                selectedUploadtype: '',
                vselectedUploadtype: ''
            });
            e.target.value = null;
        } else {
            //var filename = file.name;
            //this.setState({ filename: filename, filename: e.target.files[0].name })
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({base64E: reader.result, showE: true});
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
                //this.setState({imagePreviewUrl: file})
            }
        }
    };

    postLink = async () => {
        let postData = [];
        this.state.links.map(item => {
            let edata = {
                id: item.id === null ? null : item.id,
                type: item.type,
                title: item.title,
                link: item.link,
                thumbnail: item.thumbnail,
                status: this.state.statusE
            };
            postData.push(edata);
        });
        this.setState({loading: true});
        const response = await postExternalLinks(postData, this.state.id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({loading: false});
        } else {
            // console.log("response external link ------ "+JSON.stringify(response))
            this.setState({showAlertSuccess: true, successMessage: 'Inserted Successfully!'});
            this.setState({loading: false});
            this.getEALinks();
            //this.getCreators();
        }
    };

    async getEALinks() {
        this.setState({loading: true});
        const response = await getExternalLinks(this.state.id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({loading: false});
        } else {
            //console.log("response get link ------ " + JSON.stringify(response))
            //this.setState({ loading: false })
            this.setState({
                loading: false,
                links: response.data,
                statusE: response.data.length === 0 ? 'Draft' : response.data[0].status
            });
            //this.getCreators();
        }
    }

    //-----------END External Links ---------

    getMinYear = () => {
        const safeDateOfCreation = new Date(this.state.date_of_creation)
        safeDateOfCreation.setFullYear(safeDateOfCreation.getFullYear() + 1)
        return safeDateOfCreation
    };

    onSelectedDateTypeChange = e => {
        const safeSelectedDateType = e.target.value;
        switch (safeSelectedDateType) {
            case 'SingleYear':
            case 'StillOngoing':
                this.setState({
                    selectedDateType: e.target.value,
                    vselectedDateType: '',
                    end_date: ''
                })
                break;
            default:
                this.setState({
                    selectedDateType: e.target.value,
                    vselectedDateType: ''
                })
                break;
        }
    }

    render() {

        const renderYearContent = (year) => {
            const tooltipText = `Tooltip for year: ${year}`;
            return <span title={tooltipText}>{year}</span>;
        };

        const {
            match: {params}
        } = this.props;

        const {
            name,
            incity,
            instate,
            in_pod_city,
            in_pod_state,
            description,
            identifier,
            statee,
            city,
            address,
            phone,
            website,
            email,
            type,
            tags,
            status,
            selectTag,
            selectedCity,
            selectedCountry,
            selectedState,
            selectedUploadtype,
            fielupload
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Artwork</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <CardTitle className="mb-1">Artwork</CardTitle>
                        <Breadcrumb>
                            <BreadcrumbItem tag="p" href="#">
                                Artwork List
                            </BreadcrumbItem>
                            <BreadcrumbItem tag="p" href="#" active>
                                {this.state.setPageStatus} Artwork
                            </BreadcrumbItem>
                            {this.state.name !== '' && (
                                <BreadcrumbItem tag="b" href="#">
                                    {this.state.name}
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>
                        {this.state.showAlertSuccess ? (
                            <SweetAlert success title={''} onConfirm={() => this.setState({showAlertSuccess: false})}>
                                {this.state.successMessage}
                            </SweetAlert>
                        ) : null}

                        {this.state.showAlertWarning ? (
                            <SweetAlert warning title="" onConfirm={() => this.setState({showAlertWarning: false})}>
                                {this.state.alertMessage}
                            </SweetAlert>
                        ) : null}
                        <div className="position-fixed top-0 end-0 p-3" style={{zIndex: '1005'}}>
                            <Toast isOpen={this.state.copied}>
                                <ToastHeader
                                    toggle={() => this.setState({copied: !this.state.copied})}>Copied!</ToastHeader>
                            </Toast>
                        </div>
                        {params.id !== 'new' && (
                            <div style={{display: 'flex', position: 'absolute', top: '150px', right: '30px'}}>
                                <Label htmlFor="formrow-firstname-Input"
                                       style={{marginTop: '7px', marginRight: '10px'}}>
                                    Deep Link
                                </Label>
                                <div style={{width: '350px'}}>
                                    <Input
                                        readOnly={true}
                                        ref={textarea => (this.textArea = textarea)}
                                        type="readonly"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        style={{width: '100%'}}
                                        name="deeplink"
                                        value={this.state.deeplink}
                                    />
                                </div>
                                <CopyToClipboard text={this.state.deeplink}
                                                 onCopy={() => this.setState({copied: true})}>
                                    <button>
                                        <i className="dripicons-copy"/>
                                    </button>
                                </CopyToClipboard>
                            </div>
                        )}
                        <Card>
                            <ul className="nav nav-tabs nav-tabs-custom  pt-2" role="tablist">
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '1'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('1');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Information</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '2'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('2');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Images & Videos</CardTitle>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '3'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('3');
                                            this.getVenues();
                                        }}
                                    >
                                        <CardTitle className="mb-4">Venues</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '4'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('4');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Contents</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '5'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('5');
                                            this.getEALinks();
                                        }}
                                    >
                                        <CardTitle className="mb-4">External Links</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{margin: '15px'}} className="position-absolute top-0 end-0">
                                    <button
                                        onClick={() => {
                                            //this.props.onGetArtworks();
                                            this.props.history.push('/web/artworks-list');
                                        }}
                                        type="button"
                                        className="btn btn-secondary"
                                    >
                                        <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                        Back
                                    </button>
                                </NavItem>
                            </ul>
                            {this.state.loading ? (
                                <Loader color={'green'} loading={this.state.loading}/>
                            ) : (
                                <TabContent className="p-4" activeTab={this.state.activeTab}>
                                    {this.state.editStatus && (
                                        <span
                                            className={this.state.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{this.state.status}</span>
                                    )}
                                    <TabPane tabId="1">
                                        <br/>
                                        <Form className="form" onSubmit={e => this.submitForm(e)}>
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Name</Label>
                                                        <Input
                                                            disabled={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter Artwork Name"
                                                            name="name"
                                                            invalid={this.state.vname !== '' ? true : false}
                                                            value={name}
                                                            onChange={e => this.setState({
                                                                name: e.target.value,
                                                                vname: ''
                                                            })}
                                                        />
                                                        {this.state.vname !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vname}</span>}
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Creator</Label>

                                                        <ReactSearchAutocomplete
                                                            styling={{
                                                                backgroundColor: '#fff',
                                                                zIndex: 2,
                                                                placeholderColor: '#939294',

                                                                height: '35px',
                                                                fontSize: 16,
                                                                borderRadius: '4px',
                                                                border: '1px solid #ced4da'
                                                            }}
                                                            items={this.state.creators}
                                                            placeholder={this.state.creator === '' ? 'Select Creator' : this.state.creator.name}
                                                            onSearch={(ss, ee) => this.handleOnSearchCreators(ss, ee)}
                                                            onHover={() => {
                                                            }}
                                                            onSelect={item => {
                                                                this.setState({
                                                                    creator: item,
                                                                    vcreator: '',
                                                                    selectCreator: item.id
                                                                });
                                                            }}
                                                            onFocus={() => {
                                                            }}
                                                            formatResult={formatResult}
                                                        />
                                                        {this.state.vcreator !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vcreator}</span>}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="2">
                                                    <Label htmlFor="formrow-firstname-Input">Date of creation</Label>
                                                    <select
                                                        value={this.state.selectedDateType}
                                                        onChange={e =>
                                                            this.onSelectedDateTypeChange(e)
                                                        }
                                                        className="form-select">
                                                        <option value="">Select Type</option>
                                                        <option value="SingleYear">Single Year</option>
                                                        <option value="RangeOfYear">Range</option>
                                                        <option value="StillOngoing">Still Ongoing</option>
                                                    </select>
                                                    {this.state.vselectedDateType !== '' && <span style={{color: 'red'}}>{this.state.vselectedDateType}</span>}
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">
                                                            {this.state.selectedDateType === 'SingleYear' && ("Year")}
                                                            {this.state.selectedDateType === 'RangeOfYear' && ("Start Year")}
                                                            {this.state.selectedDateType === 'StillOngoing' && ("Start Year")}
                                                        </Label>
                                                        {this.state.selectedDateType && (<DatePicker
                                                            className="form-control"
                                                            name="date_of_creation"
                                                            invalid={this.state.vdate_of_creation !== '' ? true : false}
                                                            renderYearContent={renderYearContent}
                                                            selected={this.state.date_of_creation ? new Date(this.state.date_of_creation) : undefined}
                                                            onChange={e => this.setState({
                                                                date_of_creation: e,
                                                                vdate_of_creation: ''
                                                            })}
                                                            showYearPicker={true}
                                                            dateFormat="yyyy"
                                                        />)}
                                                        {this.state.vstart_date !== '' && <span
                                                            style={{color: 'red'}}>{this.state.vstart_date}</span>}
                                                    </div>
                                                </Col>
                                                {this.state.selectedDateType === 'RangeOfYear' && (<Col md="2">
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">End Year</Label>
                                                        <DatePicker
                                                            readOnly={this.state.viewStatus}
                                                            className="form-control"
                                                            name="end_date"
                                                            invalid={this.state.vend_date !== '' ? true : false}
                                                            selected={this.state.end_date ? new Date(this.state.end_date) : undefined}
                                                            onChange={e => this.setState({end_date: e, vend_date: ''})}
                                                            showYearPicker={true}
                                                            dateFormat="yyyy"
                                                            minDate={this.state.date_of_creation ? this.getMinYear() : undefined}
                                                        />
                                                        {this.state.vend_date !== '' && <span style={{color: 'red'}}>{this.state.vend_date}</span>}
                                                    </div>
                                                </Col>)}
                                            </Row>
                                            <Row className="mt-4">
                                                <Col md="6">
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Tags</Label>

                                                        <ReactSearchAutocomplete
                                                            styling={{
                                                                backgroundColor: '#fff',
                                                                zIndex: 2,
                                                                placeholder: 'Type Nationality',
                                                                placeholderColor: '#939294',

                                                                height: '35px',
                                                                fontSize: 16,
                                                                borderRadius: '4px',
                                                                border: '1px solid #ced4da'
                                                            }}
                                                            items={this.state.tags}
                                                            placeholder={'Select Tags'}
                                                            onSearch={(ss, ee) => this.handleOnSearchTags(ss, ee)}
                                                            onHover={() => {
                                                            }}
                                                            onSelect={item => {
                                                                let found = this.state.selectedTags.find(a => a.tagId === item.id);
                                                                if (!found) {
                                                                    this.setState({
                                                                        selectTag: item.id,
                                                                        vtag: ''
                                                                    });
                                                                    this.setState({
                                                                        selectedTags: [
                                                                            ...this.state.selectedTags,
                                                                            {
                                                                                id: '',
                                                                                tagId: item.id,
                                                                                name: item.name
                                                                            }
                                                                        ]
                                                                    });
                                                                }
                                                            }}
                                                            onFocus={() => {
                                                            }}
                                                            formatResult={formatResult}
                                                        />
                                                        {this.state.vtag !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vtag}</span>}
                                                        <br/>
                                                        {this.state.selectedTags.map((item, idx) => (
                                                            <button
                                                                style={{marginBottom: '5px', marginLeft: '5px'}}
                                                                key={idx}
                                                                onClick={e => {
                                                                    const reducedArr = this.state.selectedTags.filter((item, itemIndex) => {
                                                                        return itemIndex !== idx;
                                                                    });

                                                                    this.setState({
                                                                        selectedTags: reducedArr
                                                                    });
                                                                }}
                                                                type="button"
                                                                className="btn btn-light btn-label"
                                                            >
                                                                <i className="dripicons-tag-delete label-icon "></i>
                                                                {item.name}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Themes</Label>

                                                        <ReactSearchAutocomplete
                                                            styling={{
                                                                backgroundColor: '#fff',
                                                                zIndex: 1,
                                                                placeholder: 'Type Nationality',
                                                                placeholderColor: '#939294',

                                                                height: '35px',
                                                                fontSize: 16,
                                                                borderRadius: '4px',
                                                                border: '1px solid #ced4da'
                                                            }}
                                                            items={this.state.themes}
                                                            placeholder={'Select Themes'}
                                                            onSearch={(ss, ee) => this.handleOnSearchThemes(ss, ee)}
                                                            onHover={() => {
                                                            }}
                                                            onSelect={item => {
                                                                let found = this.state.selectedThemes.find(a => a.name === item.name);
                                                                if (!found) {
                                                                    this.setState({
                                                                        selectTheme: item.id,
                                                                        vtheme: ''
                                                                    });
                                                                    this.setState({
                                                                        selectedThemes: [
                                                                            ...this.state.selectedThemes,
                                                                            {
                                                                                id: '',
                                                                                themeId: item.id,
                                                                                name: item.name
                                                                            }
                                                                        ]
                                                                    });
                                                                }
                                                            }}
                                                            onFocus={() => {
                                                            }}
                                                            formatResult={formatResult}
                                                        />
                                                        {this.state.vthemes !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vthemes}</span>}
                                                        <br/>
                                                        {this.state.selectedThemes.map((item, idx) => (
                                                            <button
                                                                style={{marginBottom: '5px', marginLeft: '5px'}}
                                                                key={idx}
                                                                onClick={e => {
                                                                    const reducedArr = this.state.selectedThemes.filter((item, itemIndex) => {
                                                                        return itemIndex !== idx;
                                                                    });

                                                                    this.setState({
                                                                        selectedThemes: reducedArr
                                                                    });
                                                                }}
                                                                type="button"
                                                                className="btn btn-light btn-label"
                                                            >
                                                                <i className="dripicons-tag-delete label-icon "></i>
                                                                {item.name}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Status</Label>
                                                        <select
                                                            value={status}
                                                            disabled={this.state.viewStatus}
                                                            onChange={e => this.setState({
                                                                status: e.target.value,
                                                                vstatus: ''
                                                            })}
                                                            className="form-select"
                                                        >
                                                            <option value="">Select Status</option>
                                                            {this.state.statuslist.map((item, idx) => (
                                                                <option key={idx} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {this.state.vstatus !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vstatus}</span>}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label for="basicpill-namecard-input11">Description</Label>
                                                        <Editor
                                                            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                                            cloudChannel="dev"
                                                            onInit={(evt, editor) => (this.editorRefDescrip = editor)}
                                                            //onBlur={(e) => handelBlur(editorRef)}
                                                            value={this.state.description}
                                                            init={{
                                                                height: 300,
                                                                apply_source_formatting: true,
                                                                menubar: false,
                                                                plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                                                paste_as_text: true,
                                                                mobile: {
                                                                    toolbar_drawer: 'floating'
                                                                },
                                                                toolbar:
                                                                    'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                                            }}
                                                            onEditorChange={content => this.setState({description: content})}
                                                        />
                                                        {/*<textarea
                            disabled={this.state.viewStatus}
                            type="textarea"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Description"
                            name="description"
                            rows="9"

                            value={description}
                            onChange={(e) => this.setState({ description: e.target.value })}
                      />*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label for="basicpill-namecard-input11">Style</Label>

                                                        {/*<Editor apiKey='ojw6d71xw2rwubnrl4som33rasv5agejju9z0ahfh8847rg1' 
                        cloudChannel='dev' 
                        
                        onInit={(evt, editor) => this.editorStyle = editor}
                        //onBlur={(e) => handelBlur(editorRef)}
                        value={this.state.styleName}
                        init={{
                                height: 100,
                                apply_source_formatting : true,
                                menubar: false,
                                plugins: ["advlist autolink lists link anchor paste image code link anchor"],
                                paste_as_text: true,
                                mobile: {
                                  toolbar_drawer: "floating"
                                },
                                toolbar: "bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor",
                               
                        }}
                        onEditorChange={(content) => this.setState({styleName: content})} />*/}
                                                        <Input
                                                            disabled={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-namecard-input11"
                                                            placeholder="Artwork style"
                                                            name="styleName"
                                                            value={this.state.styleName}
                                                            onChange={e => this.setState({styleName: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label>Techniques & Materials</Label>
                                                        {/*<Editor apiKey='ojw6d71xw2rwubnrl4som33rasv5agejju9z0ahfh8847rg1' 
                        cloudChannel='dev' 
                        
                        onInit={(evt, editor) => this.editorTechniqMaterial = editor}
                        //onBlur={(e) => handelBlur(editorRef)}
                        value={this.state.techniqueAndMaterial}
                        init={{
                                height: 100,
                                apply_source_formatting : true,
                                menubar: false,
                                plugins: ["advlist autolink lists link anchor paste image code link anchor"],
                                paste_as_text: true,
                                mobile: {
                                  toolbar_drawer: "floating"
                                },
                                toolbar: "bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor",
                               
                        }}
                      onEditorChange={(content) => this.setState({techniqueAndMaterial: content})} />*/}
                                                        <Input
                                                            disabled={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-namecard-input11"
                                                            placeholder=""
                                                            name="techniqueAndMaterial"
                                                            value={this.state.techniqueAndMaterial}
                                                            onChange={e => this.setState({techniqueAndMaterial: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label for="basicpill-cardno-input12">Real Measurements</Label>
                                                        {/*<Editor apiKey='ojw6d71xw2rwubnrl4som33rasv5agejju9z0ahfh8847rg1' 
                        cloudChannel='dev' 
                        
                        onInit={(evt, editor) => this.editorRealMeasure = editor}
                        //onBlur={(e) => handelBlur(editorRef)}
                        value={this.state.realMeasurement}
                        init={{
                                height: 100,
                                apply_source_formatting : true,
                                menubar: false,
                                plugins: ["advlist autolink lists link anchor paste image code link anchor"],
                                paste_as_text: true,
                                mobile: {
                                  toolbar_drawer: "floating"
                                },
                                toolbar: "bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor",
                               
                        }}
                      onEditorChange={(content) => this.setState({realMeasurement: content})} />*/}
                                                        <Input
                                                            disabled={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-cardno-input12"
                                                            placeholder="XXXcm - XXXcm"
                                                            name="realMeasurement"
                                                            value={this.state.realMeasurement}
                                                            onChange={e => this.setState({realMeasurement: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            for="basicpill-card-verification-input0">Collection</Label>
                                                        {/*<Editor apiKey='ojw6d71xw2rwubnrl4som33rasv5agejju9z0ahfh8847rg1' 
                        cloudChannel='dev' 
                        
                        onInit={(evt, editor) => this.editorCollection = editor}
                        //onBlur={(e) => handelBlur(editorRef)}
                        value={this.state.collection}
                        init={{
                                height: 100,
                                apply_source_formatting : true,
                                menubar: false,
                                plugins: ["advlist autolink lists link anchor paste image code link anchor"],
                                paste_as_text: true,
                                mobile: {
                                  toolbar_drawer: "floating"
                                },
                                toolbar: "bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor",
                               
                        }}
                      onEditorChange={(content) => this.setState({collection: content})} />*/}
                                                        <Input
                                                            disabled={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="basicpill-card-verification-input0"
                                                            placeholder="Collection Name"
                                                            name="collection"
                                                            value={this.state.collection}
                                                            onChange={e => this.setState({collection: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label for="basicpill-expiration-input13">Disclaimer</Label>
                                                        {/*<Editor apiKey='ojw6d71xw2rwubnrl4som33rasv5agejju9z0ahfh8847rg1' 
                        cloudChannel='dev' 
                        
                        onInit={(evt, editor) => this.editorDisclaimer = editor}
                        //onBlur={(e) => handelBlur(editorRef)}
                        value={this.state.disclaimer}
                        init={{
                                height: 100,
                                apply_source_formatting : true,
                                menubar: false,
                                plugins: ["advlist autolink lists link anchor paste image code link anchor"],
                                paste_as_text: true,
                                mobile: {
                                  toolbar_drawer: "floating"
                                },
                                toolbar: "bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor",
                               
                        }}
                      onEditorChange={(content) => this.setState({disclaimer: content})} />*/}
                                                        <Input
                                                            disabled={this.state.viewStatus}
                                                            type="textarea"
                                                            rows="8"
                                                            className="form-control"
                                                            id="basicpill-expiration-input13"
                                                            placeholder=""
                                                            name="disclaimer"
                                                            value={this.state.disclaimer}
                                                            onChange={e => this.setState({disclaimer: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label for="basicpill-card-verification-input0">Other
                                                            Links</Label>
                                                        <Editor
                                                            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                                            cloudChannel="dev"
                                                            onInit={(evt, editor) => (this.editorLink = editor)}
                                                            //onBlur={(e) => handelBlur(editorRef)}
                                                            value={this.state.link}
                                                            init={{
                                                                height: 100,
                                                                apply_source_formatting: true,
                                                                menubar: false,
                                                                plugins: ['advlist autolink lists link anchor paste image code link anchor'],
                                                                paste_as_text: true,
                                                                mobile: {
                                                                    toolbar_drawer: 'floating'
                                                                },
                                                                toolbar:
                                                                    'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code | link | anchor'
                                                            }}
                                                            onEditorChange={content => this.setState({link: content})}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <br/>

                                            <div className="text-center mt-4">
                                                {this.state.id !== '' ? (
                                                    <button disabled={this.state.viewStatus} type="submit"
                                                            className="btn btn-primary w-md">
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button disabled={this.state.viewStatus} type="submit"
                                                            className="btn btn-primary w-md">
                                                        Save & Next
                                                    </button>
                                                )}
                                            </div>
                                        </Form>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        {this.state.showAlert ? (
                                            <SweetAlert success={this.state.successFlag} title=""
                                                        onConfirm={() => this.setState({showAlert: false})}>
                                                {this.state.alertMessage}
                                            </SweetAlert>
                                        ) : null}
                                        {this.state.showErrorAlert ? (
                                            <SweetAlert error title=""
                                                        onConfirm={() => this.setState({showErrorAlert: false})}>
                                                {this.state.alertErrorMessage}
                                            </SweetAlert>
                                        ) : null}
                                        {/*
                  <CropEasy ratio={this.state.selectedUploadtype === "Thumbnail" ? (1 / 0.6) : this.state.selectedUploadtype === "Cover Page" ? (1 / 1.57) : (9 / 16)} photoURL={this.state.base64} parentCallback={this.handleCallback} />
                */}

                                        {this.state.show ? (
                                            <CropEasy
                                                ratio={
                                                    this.state.selectedUploadtype === 'Thumbnail'
                                                        ? 1
                                                        : this.state.selectedUploadtype === 'Cover Page'
                                                            ? 1 / 1.57
                                                            : this.state.selectedUploadtype === 'Video'
                                                                ? 1 / 0.6
                                                                : 1.2 / 2.2
                                                }
                                                photoURL={this.state.base64}
                                                parentCallback={this.handleCallback}
                                            />
                                        ) : (
                                            <Row>
                                                <Col md={6}>
                                                    {this.state.showPreview && this.state.videoUrlFlag ? (
                                                        <MobileVideoPreviews videoUrl={this.state.videoUrl}
                                                                             videoThumnailPreview={this.state.videoThumnailPreview}
                                                                             urlVidFlag={this.state.urlVidFlag}/>
                                                    ) : (
                                                        <MobilePreviews
                                                            previewtype={this.state.previewtype}
                                                            setPreviewUrl={this.state.setPreviewUrl}
                                                            cropImageSet={false}
                                                            subTitleColour={''}
                                                            postSubtitleColor={''}
                                                            ftitle={''}
                                                            postTitle={''}
                                                            descriptionColour={''}
                                                            postDescriptionColor={''}
                                                            description={''}
                                                            postDescription={''}
                                                            thumbnailType={'Other'}
                                                        />
                                                    )}
                                                </Col>
                                                <Col md={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <CardTitle className="h4">Upload Images & Videos</CardTitle>

                                                            {this.state.uploadPercentage > 0 && this.state.uploadPercentage < 100 ? (
                                                                <>
                                                                    <br/>
                                                                    <br/>
                                                                    <div
                                                                        className="text-center">{this.state.uploadPercentage} %
                                                                    </div>
                                                                    <Progress value={this.state.uploadPercentage}/>
                                                                </>
                                                            ) : (
                                                                <Form className="form"
                                                                      onSubmit={e => this.uploadSubmit(e)}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <div className="mb-4">
                                                                                <Label
                                                                                    htmlFor="formrow-firstname-Input">Upload
                                                                                    Type</Label>
                                                                                <select
                                                                                    value={selectedUploadtype}
                                                                                    onChange={e =>
                                                                                        this.setState({
                                                                                            selectedUploadtype: e.target.value,
                                                                                            vselectedUploadtype: '',
                                                                                            videoUrl: '',
                                                                                            videoThumnail: '',
                                                                                            videoThumnailPreview: '',
                                                                                            videoUrlFlag: false
                                                                                        })
                                                                                    }
                                                                                    className="form-select"
                                                                                >
                                                                                    <option value="">Select Type
                                                                                    </option>
                                                                                    {this.state.uploadtypes.map((item, idx) => (
                                                                                        <option key={idx} value={item}>
                                                                                            {item}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {this.state.vselectedUploadtype !== '' &&
                                                                                    <span
                                                                                        style={{color: 'red'}}>{this.state.vselectedUploadtype}</span>}
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-4">
                                                                                <Label
                                                                                    htmlFor="formrow-firstname-Input">Select
                                                                                    file</Label>
                                                                                <input
                                                                                    disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                    name="base64"
                                                                                    className="form-control"
                                                                                    id="formFile"
                                                                                    type="file"
                                                                                    accept={this.state.selectedUploadtype === 'Video' ? '.mp4' : '.jpg,.jpeg,.png,.gif'}
                                                                                    onChange={e => this.onInputChange(e)}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    {this.state.selectedUploadtype === 'Video' && (
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <div className="mb-4">
                                                                                    <Label
                                                                                        htmlFor="formrow-firstname-Input">Select
                                                                                        Video Thumbnail</Label>
                                                                                    <input
                                                                                        disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                        name="videothumb"
                                                                                        className="form-control"
                                                                                        id="formFile"
                                                                                        type="file"
                                                                                        accept={'.jpg,.jpeg,.png'}
                                                                                        onChange={e => this.onInputChangeVideoThumbnai(e)}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    )}
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <div className="mb-4">
                                                                                <Label
                                                                                    htmlFor="formrow-firstname-Input"></Label>
                                                                                <br/>
                                                                                <button disabled={this.state.viewStatus}
                                                                                        type="submit"
                                                                                        className="btn btn-primary w-md">
                                                                                    Upload
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            )}
                                                        </CardBody>
                                                    </Card>

                                                    {this.state.files.length > 0 && (
                                                        <Card>
                                                            <CardBody>
                                                                <CardTitle className="h4">Images & Videos</CardTitle>
                                                                <p className="card-title-desc"></p>

                                                                <div className="table-responsive">
                                                                    <Table className="table table-borderless mb-0">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Image</th>
                                                                            <th>Types</th>
                                                                            <th>Preview</th>
                                                                            <th>Remove</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {this.state.files.map((item, idx) => (
                                                                            <tr key={idx}>
                                                                                <th scope="row">{item.id}</th>
                                                                                <td style={{
                                                                                    width: '5%',
                                                                                    height: '5%',
                                                                                    textAlign: 'center'
                                                                                }}>
                                                                                    {' '}
                                                                                    <img
                                                                                        style={{
                                                                                            maxWidth: '70%',
                                                                                            maxHeight: '70%',
                                                                                            borderRadius: '5px',
                                                                                            display: 'block',
                                                                                            marginLeft: 'auto',
                                                                                            marginRight: 'auto'
                                                                                        }}
                                                                                        src={item.type === 'Video' ? API_URL + item.urlVideoThumbnail : API_URL + item.url}
                                                                                    />
                                                                                </td>
                                                                                <td>{item.type}</td>
                                                                                <td>
                                                                                    <button
                                                                                        disabled={this.state.viewStatus}
                                                                                        onClick={() => {
                                                                                            if (item.type === 'Video') {
                                                                                                this.setState({
                                                                                                    loading: true,
                                                                                                    videoUrl: '',
                                                                                                    videoThumnailPreview: '',
                                                                                                    videoThumnail: '',
                                                                                                    urlVidFlag: false
                                                                                                });
                                                                                                setTimeout(() => {
                                                                                                    this.setState({
                                                                                                        videoUrlFlag: true,
                                                                                                        videoUrl: API_URL + item.url,
                                                                                                        videoThumnail: API_URL + item.urlVideoThumbnail,
                                                                                                        videoThumnailPreview: API_URL + item.urlVideoThumbnail,
                                                                                                        urlVidFlag: true,
                                                                                                        selectedUploadtype: '',
                                                                                                        loading: false
                                                                                                    });
                                                                                                }, 500);
                                                                                            } else {
                                                                                                this.setState({
                                                                                                    previewtype: item.type,
                                                                                                    showPreview: true,
                                                                                                    setPreviewUrl: API_URL + item.url,
                                                                                                    videoUrlFlag: false,
                                                                                                    urlVidFlag: false,
                                                                                                    selectedUploadtype: ''
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                        type="button"
                                                                                        className="btn btn-light btn-sm"
                                                                                    >
                                                                                        View
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        disabled={this.state.viewStatus}
                                                                                        onClick={() => this.removeFile(item.id)}
                                                                                        type="button"
                                                                                        className="btn btn-danger btn-sm"
                                                                                    >
                                                                                        Delete
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div>
                                            <div className="text-center mt-0">
                                                <h5>Venues</h5>
                                            </div>
                                            {this.state.showAlert ? (
                                                <SweetAlert warning title={''}
                                                            onConfirm={() => this.setState({showAlert: false})}>
                                                    {this.state.alertMessage}
                                                </SweetAlert>
                                            ) : null}

                                            {this.state.loading ? (
                                                <Loader color={'green'} loading={this.state.loading}/>
                                            ) : (
                                                <div>
                                                    <Row style={{marginTop: '20px'}}>
                                                        <Col md={6}>
                                                            <ReactSearchAutocomplete
                                                                styling={{
                                                                    backgroundColor: '#fff',
                                                                    zIndex: 2,
                                                                    placeholder: 'Type Country',
                                                                    placeholderColor: '#939294',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ced4da',
                                                                    height: '35px',
                                                                    fontSize: 16
                                                                }}
                                                                items={this.state.venues}
                                                                placeholder={'Type venue name here'}
                                                                onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                                                onHover={() => {
                                                                }}
                                                                onSelect={item => {
                                                                    let found = this.state.selectedVenues.find(a => a.venue.id === item.id);

                                                                    if (!found) {
                                                                        this.setState({
                                                                            selectedVenues: [...this.state.selectedVenues, {
                                                                                id: '',
                                                                                venue: item
                                                                            }]
                                                                        });
                                                                    }
                                                                }}
                                                                onFocus={() => {
                                                                }}
                                                                formatResult={formatResult}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{marginTop: '15px'}}>
                                                        <Col md={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead className="table-light">
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Venue name</th>

                                                                        <th>Remove</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.selectedVenues.map((item, idx) => {
                                                                        return (
                                                                            <tr key={idx}>
                                                                                <th scope="row">{idx + 1}</th>
                                                                                <td>{item.venue.name}</td>

                                                                                <td>
                                                                                    {' '}
                                                                                    <i
                                                                                        className="mdi mdi-delete font-size-18"
                                                                                        id="deletetooltip"
                                                                                        onClick={() => {
                                                                                            const reducedArr = this.state.selectedVenues.filter((item, itemIndex) => {
                                                                                                return itemIndex !== idx;
                                                                                            });

                                                                                            this.setState({
                                                                                                selectedVenues: reducedArr
                                                                                            });
                                                                                        }}
                                                                                    ></i>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <br/>
                                                    <div className="text-center mt-4">
                                                        <button disabled={this.state.viewStatus}
                                                                onClick={() => this.postVenues()} type="submit"
                                                                className="btn btn-primary w-md">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <Contents activeTab={this.state.activeTab.toString()}
                                                  viewStatus={this.state.viewStatus} editStatus={this.state.editStatus}
                                                  section={"artwork"} relatedId={this.state.id}/>
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <div>
                                            <div className="text-center mt-0">
                                                <h5>External Links</h5>
                                            </div>
                                            {this.state.showAlert ? (
                                                <SweetAlert warning title={''}
                                                            onConfirm={() => this.setState({showAlert: false})}>
                                                    {this.state.alertMessage}
                                                </SweetAlert>
                                            ) : null}

                                            {this.state.loading ? (
                                                <Loader color={'green'} loading={this.state.loading}/>
                                            ) : (
                                                <div>
                                                    {this.state.showE ? (
                                                        <CropEasyParticle ratio={1} photoURL={this.state.base64E}
                                                                          parentCallback={this.handleCallbackE}
                                                                          shape={'round'}/>
                                                    ) : (
                                                        <Row>
                                                            <Row style={{marginTop: '20px'}}>
                                                                <Col md={3}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">Link
                                                                            Type</Label>
                                                                        <select
                                                                            disabled={this.state.viewStatus}
                                                                            value={this.state.type}
                                                                            onChange={e => {
                                                                                this.setState({
                                                                                    type: e.target.value,
                                                                                    verror: ''
                                                                                });
                                                                            }}
                                                                            className="form-select"
                                                                        >
                                                                            <option value="">Select Type</option>
                                                                            <option value="Digital Artwork Link">Digital
                                                                                Artwork Link
                                                                            </option>
                                                                            <option value="AR Experience Link">AR
                                                                                Experience Link
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">Link
                                                                            Text</Label>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Link Text"
                                                                            name="linktext"
                                                                            // invalid={this.state.vtitle !== "" ? true : false}
                                                                            value={this.state.linktext}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    linktext: e.target.value,
                                                                                    verror: ''
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="formrow-firstname-Input">URL</Label>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter URL"
                                                                            name="url"
                                                                            // invalid={this.state.vtitle !== "" ? true : false}
                                                                            value={this.state.url}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    url: e.target.value,
                                                                                    verror: ''
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={1}>
                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="formrow-firstname-Input">Thumbnail</Label>
                                                                        {this.state.showPreviewE ? (
                                                                            <>
                                                                                <img width={50}
                                                                                     style={{borderRadius: '25px'}}
                                                                                     height={50}
                                                                                     src={this.state.base64E}/>
                                                                                <i
                                                                                    className="mdi mdi-delete font-size-14"
                                                                                    style={{color: 'red'}}
                                                                                    id="deletetooltip"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            showE: false,
                                                                                            base64E: '',
                                                                                            showPreviewE: false,
                                                                                            verror: ''
                                                                                        });
                                                                                    }}
                                                                                ></i>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {/*<input disabled={this.state.viewStatus === true} name="base64" className="form-control" id="formFile" type="file" accept=".jpg,.jpeg,.png"
                              onChange={(e) => this.onInputChange(e)} />*/}
                                                                                <Button
                                                                                    style={{backgroundColor: '#C0C0C0'}}
                                                                                    className="btn-rounded btn-sm"
                                                                                    onClick={() => this.fileInputRef.current.click()}
                                                                                >
                                                                                    <i
                                                                                        className="mdi mdi-plus font-size-45"
                                                                                        style={{
                                                                                            color: '#2E2E2E',
                                                                                            width: '70px',
                                                                                            height: '70px'
                                                                                        }}
                                                                                        id="deletetooltip"
                                                                                    ></i>
                                                                                </Button>
                                                                                <input
                                                                                    onChange={e => this.onInputChangeE(e)}
                                                                                    name="base64"
                                                                                    multiple={false}
                                                                                    ref={this.fileInputRef}
                                                                                    accept=".jpg,.jpeg,.png"
                                                                                    type="file"
                                                                                    hidden
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="formrow-firstname-Input">{'\t'}</Label>
                                                                        <br/>
                                                                        <Button
                                                                            style={{
                                                                                marginTop: '3px',
                                                                                marginLeft: '8px',
                                                                                width: '80px'
                                                                            }}
                                                                            color={'primary'}
                                                                            disabled={this.state.links.length < 2 ? false : true}
                                                                            onClick={() => {
                                                                                if ((this.state.type === '' || this.state.linktext === '' || this.state.url === '', this.state.base64E === '')) {
                                                                                    this.setState({
                                                                                        verror: 'All fields are required!'
                                                                                    });
                                                                                } else {
                                                                                    const exists = this.state.links.filter(item => item.type === this.state.type).length > 0;
                                                                                    if (exists) {
                                                                                        this.setState({
                                                                                            showAlert: true,
                                                                                            alertMessage: this.state.type + ' already added!'
                                                                                        });
                                                                                        return;
                                                                                    } else {
                                                                                        this.setState({
                                                                                            links: [
                                                                                                ...this.state.links,
                                                                                                {
                                                                                                    id: null,
                                                                                                    type: this.state.type,
                                                                                                    title: this.state.linktext,
                                                                                                    link: this.state.url,
                                                                                                    thumbnail: this.state.base64E,
                                                                                                    status: ''
                                                                                                }
                                                                                            ]
                                                                                        });

                                                                                        this.setState({
                                                                                            linktext: '',
                                                                                            url: '',
                                                                                            type: '',
                                                                                            thumbnail: '',
                                                                                            base64E: '',
                                                                                            showPreviewE: false,
                                                                                            verror: ''
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }}
                                                                            className="btn-md"
                                                                        >
                                                                            Add
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <div
                                                                    className="text-center mt-4">{this.state.verror !== '' &&
                                                                    <span
                                                                        style={{color: 'red'}}>{this.state.verror}</span>}</div>
                                                            </Row>
                                                            <Row style={{marginTop: '15px'}}>
                                                                <Col md={12}>
                                                                    <div className="table-responsive">
                                                                        <Table className="table mb-0">
                                                                            <thead className="table-light">
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Link Type</th>
                                                                                <th>Link Text</th>
                                                                                <th>URL</th>
                                                                                <th>Thumbnail</th>
                                                                                <th>Remove</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {this.state.links.map((item, idx) => {
                                                                                return (
                                                                                    <tr key={idx}>
                                                                                        <th scope="row">{idx + 1}</th>
                                                                                        <td>{item.type}</td>
                                                                                        <td>{item.title}</td>
                                                                                        <td>{item.link}</td>
                                                                                        <td align="center">
                                                                                            <img
                                                                                                width={50}
                                                                                                style={{borderRadius: '25px'}}
                                                                                                height={50}
                                                                                                src={item.id === null ? item.thumbnail : API_URL + item.thumbnail}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <i
                                                                                                className="mdi mdi-delete font-size-18"
                                                                                                id="deletetooltip"
                                                                                                onClick={() => {
                                                                                                    const reducedArr = this.state.links.filter((item, itemIndex) => {
                                                                                                        return itemIndex !== idx;
                                                                                                    });

                                                                                                    this.setState({
                                                                                                        links: reducedArr
                                                                                                    });
                                                                                                }}
                                                                                            ></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row style={{marginTop: '20px'}}>
                                                                <Col md={1}>
                                                                    <div className="text-center mt-2">
                                                                        <Label
                                                                            htmlFor="formrow-firstname-Input">Status</Label>
                                                                    </div>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <select
                                                                        disabled={this.state.viewStatus}
                                                                        value={this.state.statusE}
                                                                        onChange={e => {
                                                                            this.setState({
                                                                                statusE: e.target.value
                                                                            });
                                                                        }}
                                                                        className="form-select"
                                                                    >
                                                                        <option value="Draft">Draft</option>
                                                                        <option value="Publish">Publish</option>
                                                                    </select>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <button onClick={() => this.postLink()}
                                                                            disabled={this.state.viewStatus}
                                                                            type="submit"
                                                                            className="btn btn-primary w-md">
                                                                        Save
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </Row>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </TabPane>
                                </TabContent>
                            )}
                        </Card>
                    </Container>
                    {/* container-fluid */}
                </div>
            </React.Fragment>
        );
    }
}

ArtWorksDetails.propTypes = {
    // artworks: PropTypes.array,
    className: PropTypes.any,
    match: PropTypes.object,
    onUpdateArtworks: PropTypes.func,
    onGetArtworks: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({artworks}) => ({
    artworks: artworks
});

const mapDispatchToProps = dispatch => ({
    onUpdateArtworks: artworks => dispatch(updateArtWorks(artworks)),
    onGetArtworks: () => dispatch(getArtWorks())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArtWorksDetails));

const formatResult = item => {
    return (
        <>
            <span style={{display: 'block', textAlign: 'left'}}>{item.name}</span>
        </>
    );
};

function toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) + '.000Z';
}
// 2017-12-31T23:00:00.000Z
// 2018-01-01T00:00:00+01:00