import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    FormFeedback,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    CardImg,
    Alert,
    Table,
    Breadcrumb,
    BreadcrumbItem,
    Progress,
    Toast,
    ToastHeader
} from 'reactstrap';
import { call, put, takeEvery } from 'redux-saga/effects';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Import Breadcrumb
import { API_URL } from '../../constants/apiurl';
import Loader from '../../components/Common/Loader';
import classnames from 'classnames';
import { getVenues, updateVenue } from '../../store/venues/actions';

import { Image } from 'react-bootstrap';
import CropEasy from '../../components/Crop/CropEasy';
import moment from 'moment';
import FlashMessage from 'react-flash-message';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import SweetAlert from 'react-bootstrap-sweetalert';
import { callVenueTypes, callUploadTypesVenue, callVenueStatus, callVenueTags, callVenueDetails, postNewVenue, uploadVenueImage, deleteVenueImage } from '../../helpers/Api/venue_apis';

import { callCountriesSearch, callStatesSearch, callCitiesSearch, searchPublishTags } from '../../helpers/Api/common_apis';

import MobilePreviews from '../../components/MobilePreviews/MobilePreviews';
import MobileVideoPreviews from '../../components/MobilePreviews/MobileVideoPreviews';

import { dataURLtoBlob } from '../../constants/utils';
import { lengthVideo } from '../../helpers/Api/common_apis';

import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDeeplink } from '../../helpers/Api/common_apis';

class VenueDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            selectedFiles: [],
            statuslist: [],
            types: [],
            uploadtypes: [],
            name: '',
            vname: '',
            type: '',
            vtype: '',
            contact: {},
            country: '',
            statee: '',
            city: '',
            address: '',
            phone: '',
            website: '',
            email: '',
            tags: [],
            startTime: '',
            endTime: '',
            description: '',
            status: '',
            vstatus: '',
            countries: [],
            selectedCountry: '',
            cstates: [],
            selectedState: '',
            cities: [],
            selectedCity: '',
            id: '',
            loading: false,
            files: [],
            viewStatus: false,
            editStatus: false,
            newStatus: false,
            selectedTags: [],
            selectTag: '',
            selectedUploadtype: '',
            fielupload: '',
            vselectedUploadtype: '',
            base64: '',
            show: '',
            filename: '',
            showPreview: true,
            setPreviewUrl: '',
            previewtype: '',
            flashShow: false,
            paginateCountries: [],

            setPageStatus: '',

            country_id: '',
            state_id: '',
            showErrorAlert: false,
            alertErrorMessage: '',
            successFlag: false,
            showAlert: false,
            alertMessage: '',
            showAlertWarning: false,
            opneningHourAndInfo: '',
            //Video ----
            videoUrl: {},
            videoUrlFlag: false,
            videoThumnailPreview: '',
            videoThumnail: '',
            videoThumnailName: '',
            urlVidFlag: false,
            uploadPercentage: 0,
            authtoken: '',
            deeplink: '',
            copied: false
        };
        this.toggleTab = this.toggleTab.bind(this);
        // this.myRef = React.createRef()
    }

    componentDidMount() {
        this.getVenueTypes();
        this.getUploadTypes();

        this.getVenueStatus();

        const {
            match: { params }
        } = this.props;
        if (params.idd === 'view') {
            this.setState({ viewStatus: true, setPageStatus: 'View' });
        }

        if (params.idd === 'post') {
            this.setState({ newStatus: true, setPageStatus: 'Create' });
        }
        if (params.idd === 'edit') {
            this.setState({ setPageStatus: 'Edit' });
        }

        if (params.id !== 'new') {
            this.setState({ editStatus: true });
            this.getVenueDetails(params.id);
            this.getDeeplinkData(params.id);
        } else {
            this.setState({ editStatus: false });
        }
    }

    async getDeeplinkData(id) {
        let postdata = {
            type: 'Venue',
            reference: id,
            contentType: null
        };
        const data = await getDeeplink(postdata);
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else {
            //console.log("------ "+JSON.stringify(data))
            this.setState({ deeplink: data.data.shortLink });
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            if (tab === '1' && this.state.newStatus === false) {
                this.getVenueDetails(this.state.id);
            }
        }
    }

    async getUploadTypes() {
        const data = await callUploadTypesVenue();
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else this.setState({ uploadtypes: data.data });
    }

    async getVenueTypes() {
        const data = await callVenueTypes();
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else this.setState({ types: data.data });
    }

    async getVenueStatus() {
        const data = await callVenueStatus();
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else this.setState({ statuslist: data.data });
    }

    async handleOnSearchTags(string, results) {
        const response = await searchPublishTags(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ tags: response.data.content, searchFlag: true });
        }
    }

    async handleOnSearch(string, results) {
        const response = await callCountriesSearch(string);
        if (response.error) console.log('error ---- ' + JSON.stringify(response));
        else
            this.setState({
                paginateCountries: response.data.content
            });
    }

    async handleOnSearchState(string, results) {
        const response = await callStatesSearch(string, this.state.country_id);
        if (response.error) console.log('error ---- ' + JSON.stringify(response));
        else
            this.setState({
                cstates: response.data.content
            });
    }

    async handleOnSearchCity(string, results) {
        this.setState({ city: string });
        const response = await callCitiesSearch(string, this.state.state_id);
        if (response.error) console.log('error ---- ' + JSON.stringify(response));
        else
            this.setState({
                cities: response.data.content
            });
    }

    async getVenueDetails(id) {
        this.setState({ loading: true });
        const response = await callVenueDetails(id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({
                loading: false,
                name: response.data.name,
                type: response.data.type,
                opneningHourAndInfo: response.data.opneningHourAndInfo,
                country: response.data.contact === null ? '' : response.data.contact.country,
                country_id: response.data.contact === null ? '' : response.data.contact.countryId,
                state_id: response.data.contact === null ? '' : response.data.contact.stateId,
                statee: response.data.contact === null ? '' : response.data.contact.state,
                city: response.data.contact === null ? '' : response.data.contact.city,
                address: response.data.contact === null ? '' : response.data.contact.address,
                phone: response.data.contact === null ? '' : response.data.contact.phone,
                website: response.data.contact === null ? '' : response.data.contact.website,
                email: response.data.contact === null ? '' : response.data.contact.email,
                // tags: response.data.tags,
                selectedTags: response.data.tags,
                startTime: moment(response.data.startTime).format('HH:mm'),
                endTime: moment(response.data.endTime).format('HH:mm'),
                description: response.data.description,
                status: response.data.status,
                id: response.data.id,
                files: response.data.files,
                selectedCountry: response.data.contact === null ? '' : response.data.contact.country,
                selectedState: response.data.contact === null ? '' : response.data.contact.state,
                selectedCity: response.data.contact === null ? '' : response.data.contact.city
            });
        }
    }

    async submitForm(e) {
        e.preventDefault();
        if (this.state.name === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vname: 'Theme name is required!' });
        }
        if (this.state.status === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vstatus: 'Status is required' });
        }
        if (this.state.type === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vtype: 'Type is required' });
        }
        if ((this.state.name !== '' && this.state.status !== '', this.state.type !== '')) {
            const {
                match: { params }
            } = this.props;
            this.setState({ loading: true });

            let postParams = {
                name: this.state.name,
                type: this.state.type,
                contact: {
                    country: this.state.country,
                    countryId: this.state.country_id,
                    stateId: this.state.state_id,
                    state: this.state.statee,
                    city: this.state.city,
                    address: this.state.address,
                    phone: this.state.phone,
                    website: this.state.website,
                    email: this.state.email
                },
                tags: this.state.selectedTags,
                startTime: moment(new Date(), 'HH:mm').toISOString(),
                endTime: moment(new Date(), 'HH:mm').toISOString(),
                description: this.state.description,
                status: this.state.status,
                opneningHourAndInfo: this.state.opneningHourAndInfo,
                id: this.state.id
            };
            //console.log("post details ---> " + JSON.stringify(postParams))
            if (params.id === 'new') {
                if (this.state.id !== '') {
                    this.props.onUpdateVenue(postParams);
                    //this.props.history.push("/web/venues");
                    this.setState({
                        showAlert: true,
                        alertMessage: 'Updated Successfully',
                        successFlag: true
                    });
                    this.setState({ loading: false });

                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.getVenueDetails(this.state.id);
                    this.setState({
                        activeTab: '2',
                        newStatus: false
                    });
                } else {
                    //this.getTagDetails();
                    const response = await postNewVenue(postParams);
                    if (response.error) {
                        console.log('error ---- ' + JSON.stringify(response));
                        this.setState({ loading: false });
                    } else {
                        this.setState({ loading: false });
                        this.setState({
                            showAlert: true,
                            alertMessage: 'Created Successfully',
                            successFlag: true
                        });

                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        this.getVenueDetails(response.data.id);
                        this.setState({
                            activeTab: '2',
                            newStatus: false
                        });
                    }
                }
            } else {
                this.props.onUpdateVenue(postParams);
                this.setState({
                    showAlert: true,
                    alertMessage: 'Updated Successfully',
                    successFlag: true
                });
                this.setState({ loading: false });

                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.getVenueDetails(this.state.id);
                this.setState({
                    activeTab: '2',
                    newStatus: false
                });
            }
        }
    }

    onInputChangeVideoThumbnai = e => {
        const file = e.target.files[0];

        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

        if (size > 10000) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure image size is less than 10000 KB'
                //selectedUploadtype: "",
                //vselectedUploadtype: "",
                //urlVidFlag: false
            });
            //e.target.value = null
            return;
        } else {
            var filename = file.name;
            this.setState({
                videoThumnailName: filename,
                vselectedUploadtype: '',
                urlVidFlag: false
            });
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64: reader.result, show: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    handleGIFCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }
        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                cropImageSet: true,
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
        // this.myRef.current.scrollIntoView()
    };

    onInputChange = e => {
        if (this.state.selectedUploadtype === '') {
            this.setState({ vselectedUploadtype: 'Please select the type' });
            return;
        } else {
            if (this.state.selectedUploadtype === 'Video') {
                this.setState({ videoUrl: {}, videoUrlFlag: false, fielupload: '' });

                this.videoUpload(e);
            } else {
                // if (this.state.selectedUploadtype === "Thumbnail") {
                // convert image file to base64 string
                const file = e.target.files[0];

                var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
                var isGIF = file.type === 'image/gif';

                if (size > 300 && !isGIF) {
                    this.setState({
                        showAlertWarning: true,
                        alertMessage: 'Please ensure image size is less than 300 KB',
                        selectedUploadtype: '',
                        vselectedUploadtype: '',
                        videoUrlFlag: false,
                        urlVidFlag: false
                    });
                    e.target.value = null;
                } else {
                    var filename = file.name;
                    this.setState({
                        filename: filename,
                        flashShow: false,
                        filename: e.target.files[0].name,
                        previewtype: '',
                        videoUrlFlag: false,
                        urlVidFlag: false
                    });
                    const reader = new FileReader();
                    reader.addEventListener(
                        'load',
                        () => {
                            if(isGIF) {
                                this.handleGIFCallback(reader.result);
                            } else {
                                this.setState({base64: reader.result, show: true});
                            }
                        },
                        false
                    );

                    if (file) {
                        reader.readAsDataURL(file);
                    }
                }
            }
        }
    };

    uploadSubmit(e) {
        e.preventDefault();
        if (this.state.selectedUploadtype === '' || this.state.fielupload === '') {
            this.setState({
                vselectedUploadtype: 'Please select the type and file to upload'
            });
        } else {
            if (this.state.selectedUploadtype === 'Video') {
                if (this.state.filename === '') {
                    this.setState({
                        vselectedUploadtype: 'Video file is required!'
                    });
                } else {
                    this.uploadFileProgress();
                }
            } else {
                let formData = new FormData();
                formData.append('file', this.state.fielupload, this.state.filename);
                this.uploadSubmitFile(formData);
            }
        }
    }
    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    uploadFileProgress = () => {
        let formData = new FormData();
        formData.append('file', this.state.videoUrl, this.state.filename);
        if(this.state.videoThumnail !== '') {
            formData.append('file', this.state.videoThumnail, this.state.videoThumnailName);
        }
        let autTok = this.getAuthDetails();
        setTimeout(() => {
            this.setState({ authtoken: autTok.access_token });
            const options = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + this.state.authtoken
                },
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    //console.log( `${loaded}kb of ${total}kb | ${percent}%` );

                    if (percent < 100) {
                        this.setState({ uploadPercentage: percent });
                    }
                }
            };
            axios
                .post(API_URL + '/tt/venue/file/' + this.state.id + '?type=' + this.state.selectedUploadtype, formData, options)
                .then(res => {
                    this.setState({ uploadPercentage: 100 });
                    this.setState({
                        loading: false,
                        videoUrlFlag: false,
                        flashShow: false,
                        showPreview: true,
                        setPreviewUrl: '',
                        previewtype: '',
                        showAlert: true,
                        alertMessage: 'Uploaded Successfully',
                        successFlag: true,
                        selectedUploadtype: '',
                        uploadPercentage: 0
                    });
                    this.getVenueDetails(this.state.id);
                })
                .catch(e => {
                    console.log('error... ' + JSON.stringify(e));
                });
        }, 400);
    };

    uploadSubmitFile = async formData => {
        this.setState({ loading: true });

        const response = await uploadVenueImage(this.state.id, this.state.selectedUploadtype, formData);
        this.setState({ base64: '', fielupload: '', selectedUploadtype: '', videoUrl: {}, videoThumnailName: '', videoThumnail: '', videoThumnailPreview: '', videoUrlFlag: false, urlVidFlag: false });

        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
            this.setState({ videoUrlFlag: false, loading: false, showErrorAlert: true, alertErrorMessage: response.error.response.data.message });
        } else {
            this.setState({
                loading: false,
                videoUrlFlag: false,
                flashShow: false,
                showPreview: true,
                setPreviewUrl: '',
                previewtype: '',
                showAlert: true,
                alertMessage: 'Uploaded Successfully',
                successFlag: true
            });
            this.getVenueDetails(this.state.id);
        }
    };

    async videoUpload(e) {
        const video = await lengthVideo(e.currentTarget.files[0]);
        console.log('duration.... ' + video.duration);
        if (video.duration > 1200) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure video duration is less than 20 Minutes.',
                selectedUploadtype: '',
                vselectedUploadtype: '',
                videoUrlFlag: false,
                urlVidFlag: false
            });
            e.target.value = null;
            return;
        } else {
            var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            if (size > 250000) {
                this.setState({
                    showAlertWarning: true,
                    alertMessage: 'Please ensure video size is less than 250 MB.',
                    selectedUploadtype: '',
                    vselectedUploadtype: '',
                    videoUrlFlag: false,
                    urlVidFlag: false
                });
                e.target.value = null;
                return;
            } else {
                const reader = new FileReader();
                //var url = URL.createObjectURL(e.target.files[0].originFileObj);
                //console.log("url video... "+JSON.stringify(url))
                this.setState({ videoUrl: e.target.files[0], fielupload: e.target.files[0], videoUrlFlag: true, filename: e.target.files[0].name, urlVidFlag: false });
            }
        }
    }

    handleCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = dataURLtoBlob(childData);
        }

        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                flashShow: true,
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
    };

    async removeFile(id) {
        const response = await deleteVenueImage(id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({ previewtype: '', showPreview: true, setPreviewUrl: '' });
            this.getVenueDetails(this.state.id);
        }
    }

    render() {
        const {
            name,
            startTime,
            endTime,
            description,
            country,
            statee,
            city,
            address,
            phone,
            website,
            email,
            type,
            tags,
            status,
            selectTag,
            selectedCity,
            selectedCountry,
            selectedState,
            selectedUploadtype,
            fielupload
        } = this.state;
        const {
            match: { params }
        } = this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Venue Details</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <CardTitle className="mb-1">Venue</CardTitle>
                        <Breadcrumb>
                            <BreadcrumbItem tag="p" href="#">
                                Venue List
                            </BreadcrumbItem>
                            <BreadcrumbItem tag="p" href="#" active>
                                {this.state.setPageStatus} Venue
                            </BreadcrumbItem>
                            {this.state.name !== '' && (
                                <BreadcrumbItem tag="b" href="#">
                                    {this.state.name}
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>

                        {this.state.showAlertWarning ? (
                            <SweetAlert warning title="" onConfirm={() => this.setState({ showAlertWarning: false })}>
                                {this.state.alertMessage}
                            </SweetAlert>
                        ) : null}

                        {this.state.showAlert ? (
                            <SweetAlert success={this.state.successFlag} title="" onConfirm={() => this.setState({ showAlert: false })}>
                                {this.state.alertMessage}
                            </SweetAlert>
                        ) : null}

                        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: '1005' }}>
                            <Toast isOpen={this.state.copied}>
                                <ToastHeader toggle={() => this.setState({ copied: !this.state.copied })}>Copied!</ToastHeader>
                            </Toast>
                        </div>
                        {params.id !== 'new' && (
                            <div style={{ display: 'flex', position: 'absolute', top: '150px', right: '30px' }}>
                                <Label htmlFor="formrow-firstname-Input" style={{ marginTop: '7px', marginRight: '10px' }}>
                                    Deep Link
                                </Label>
                                <div style={{ width: '350px' }}>
                                    <Input
                                        readOnly={true}
                                        ref={textarea => (this.textArea = textarea)}
                                        type="readonly"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        name="deeplink"
                                        value={this.state.deeplink}
                                    />
                                </div>
                                <CopyToClipboard text={this.state.deeplink} onCopy={() => this.setState({ copied: true })}>
                                    <button>
                                        <i className="dripicons-copy" />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        )}

                        <Card>
                            <ul className="nav nav-tabs nav-tabs-custom  pt-2" role="tablist">
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '1'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('1');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Information</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '2'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('2');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Images & Videos</CardTitle>
                                    </NavLink>
                                </NavItem>

                                <NavItem style={{ margin: '15px' }} className="position-absolute top-0 end-0">
                                    <button
                                        onClick={() => {
                                            this.props.onGetVenues();
                                            this.props.history.push('/web/venues');
                                        }}
                                        type="button"
                                        className="btn btn-secondary"
                                    >
                                        <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                        Back
                                    </button>
                                </NavItem>
                            </ul>
                            {this.state.loading ? (
                                <Loader color={'green'} loading={this.state.loading} />
                            ) : (
                                <TabContent className="p-4" activeTab={this.state.activeTab}>
                                    {this.state.editStatus && (
                                        <span className={this.state.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{this.state.status}</span>
                                    )}

                                    <TabPane tabId="1">
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardBody>
                                                        <Form className="form" onSubmit={e => this.submitForm(e)}>
                                                            <CardTitle className="mb-4">General Information</CardTitle>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Type of Venue *</Label>
                                                                        <select
                                                                            value={type}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    type: e.target.value,
                                                                                    vtype: ''
                                                                                })
                                                                            }
                                                                            className="form-select"
                                                                        >
                                                                            <option value="">Select Type</option>
                                                                            {this.state.types.map((item, idx) => (
                                                                                <option key={idx} value={item}>
                                                                                    {item}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {this.state.vtype !== '' && <span style={{ color: 'red' }}>{this.state.vtype}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Venue Name</Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Venue Name"
                                                                            name="name"
                                                                            invalid={this.state.vname !== '' ? true : false}
                                                                            value={name}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    name: e.target.value,
                                                                                    vname: ''
                                                                                })
                                                                            }
                                                                        />
                                                                        {this.state.vname !== '' && <span style={{ color: 'red' }}>{this.state.vname}</span>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Status *</Label>
                                                                        <select
                                                                            value={status}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    status: e.target.value,
                                                                                    vstatus: ''
                                                                                })
                                                                            }
                                                                            className="form-select"
                                                                        >
                                                                            <option value="">Select Status</option>
                                                                            {this.state.statuslist.map((item, idx) => (
                                                                                <option key={idx} value={item}>
                                                                                    {item}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {this.state.vstatus !== '' && <span style={{ color: 'red' }}>{this.state.vstatus}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Tags</Label>
                                                                        <ReactSearchAutocomplete
                                                                            styling={{
                                                                                backgroundColor: '#fff',
                                                                                zIndex: 2,
                                                                                placeholder: 'Type Nationality',
                                                                                placeholderColor: '#939294',

                                                                                height: '35px',
                                                                                fontSize: 16,
                                                                                borderRadius: '4px',
                                                                                border: '1px solid #ced4da'
                                                                            }}
                                                                            items={this.state.tags}
                                                                            placeholder={'Select Tags'}
                                                                            onSearch={(ss, ee) => this.handleOnSearchTags(ss, ee)}
                                                                            onHover={() => {}}
                                                                            onSelect={item => {
                                                                                let found = this.state.selectedTags.find(a => a.tagId === item.id);
                                                                                if (!found) {
                                                                                    this.setState({
                                                                                        selectTag: item.id,
                                                                                        vtag: ''
                                                                                    });
                                                                                    this.setState({
                                                                                        selectedTags: [
                                                                                            ...this.state.selectedTags,
                                                                                            {
                                                                                                id: '',
                                                                                                tagId: item.id,
                                                                                                name: item.name
                                                                                            }
                                                                                        ]
                                                                                    });
                                                                                }
                                                                            }}
                                                                            onFocus={() => {}}
                                                                            formatResult={formatResult}
                                                                        />
                                                                        <br />
                                                                        {this.state.selectedTags.map((item, idx) => (
                                                                            <button
                                                                                style={{
                                                                                    marginBottom: '5px',
                                                                                    marginLeft: '5px'
                                                                                }}
                                                                                key={idx}
                                                                                onClick={e => {
                                                                                    const reducedArr = this.state.selectedTags.filter((item, itemIndex) => {
                                                                                        return itemIndex !== idx;
                                                                                    });

                                                                                    this.setState({
                                                                                        selectedTags: reducedArr
                                                                                    });
                                                                                }}
                                                                                type="button"
                                                                                className="btn btn-light btn-label"
                                                                            >
                                                                                <i className="dripicons-tag-delete label-icon "></i>
                                                                                {item.name}
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <CardTitle className="mb-4">Links & Contacts</CardTitle>
                                                                <Col md={12}>
                                                                    <div className="mb-12">
                                                                        <Label htmlFor="formrow-firstname-Input">Address</Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            id="formFileLg"
                                                                            type="texarea"
                                                                            name="address"
                                                                            value={address}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    address: e.target.value
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />

                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Country </Label>

                                                                        <div style={{ zIndex: 2 }}>
                                                                            <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholder: 'Type Country',
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16,
                                                                                }}
                                                                                items={this.state.paginateCountries}
                                                                                placeholder={this.state.country === '' ? 'Type Country here' : this.state.country}
                                                                                onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        selectedCountry: item.name,
                                                                                        country: item.name,
                                                                                        country_id: item.id,
                                                                                        statee: '',
                                                                                        selectedState: '',
                                                                                        city: ''
                                                                                    });
                                                                                    //console.log("select coutnry "+JSON.stringify(item.name))
                                                                                    //this.getStates(item.id)
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({selectedState: "", statee: "", city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">State</Label>
                                                                        <div style={{ zIndex: 2 }}>
                                                                        {!selectedCountry
                                                                            ? <Input
                                                                                disabled={true}
                                                                                type="text"
                                                                                placeholder="Type State here"
                                                                            />
                                                                            : <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.cstates}
                                                                                placeholder={this.state.statee === '' ? 'Type State here' : this.state.statee}
                                                                                onSearch={(ss, ee) => this.handleOnSearchState(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({
                                                                                        state_id: item.id,
                                                                                        statee: item.name,
                                                                                        selectedState: item.name,
                                                                                        city: ''
                                                                                    });
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({ city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">City</Label>
                                                                        <div style={{ zIndex: 2 }}>
                                                                        {!selectedState
                                                                            ? <Input
                                                                                disabled={true}
                                                                                type="text"
                                                                                placeholder="Type city here"
                                                                            />
                                                                            : <ReactSearchAutocomplete
                                                                                styling={{
                                                                                    backgroundColor: '#fff',
                                                                                    zIndex: 2,
                                                                                    placeholderColor: '#939294',
                                                                                    borderRadius: '4px',
                                                                                    border: '1px solid #ced4da',
                                                                                    height: '35px',
                                                                                    fontSize: 16
                                                                                }}
                                                                                items={this.state.cities}
                                                                                placeholder={this.state.city === '' ? 'Type city here' : this.state.city}
                                                                                onSearch={(ss, ee) => this.handleOnSearchCity(ss, ee)}
                                                                                onHover={() => {}}
                                                                                onSelect={item => {
                                                                                    this.setState({ city: item.name });
                                                                                }}
                                                                                onFocus={() => {
                                                                                    //this.setState({ city: "" })
                                                                                }}
                                                                                formatResult={formatResult}
                                                                            />}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Website</Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Website"
                                                                            name="website"
                                                                            value={website}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    website: e.target.value
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Phone No</Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Phone no"
                                                                            name="phone"
                                                                            value={phone}
                                                                            onChange={e => this.setState({ phone: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Email</Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="example@xyz.com"
                                                                            name="email"
                                                                            value={email}
                                                                            onChange={e => this.setState({ email: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <CardTitle className="mb-4">Venue Description</CardTitle>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Label htmlFor="formrow-firstname-Input">Description</Label>
                                                                    <textarea
                                                                        rows={5}
                                                                        type="textarea"
                                                                        className="form-control"
                                                                        id="formrow-firstname-Input"
                                                                        placeholder="Enter Description"
                                                                        name="description"
                                                                        value={description}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                description: e.target.value
                                                                            })
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Label htmlFor="formrow-firstname-Input">Opening Hours Info</Label>
                                                                    <textarea
                                                                        rows={5}
                                                                        type="textarea"
                                                                        className="form-control"
                                                                        id="formrow-firstname-Input"
                                                                        placeholder="Enter Opening hours info"
                                                                        name="opneningHourAndInfo"
                                                                        value={this.state.opneningHourAndInfo}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                opneningHourAndInfo: e.target.value
                                                                            })
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <div className="text-center mt-4">
                                                                {this.state.id !== '' ? (
                                                                    <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                        Save
                                                                    </button>
                                                                ) : (
                                                                    <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                        Save & Next
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </Form>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        {this.state.showErrorAlert ? (
                                            <SweetAlert error title="" onConfirm={() => this.setState({ showErrorAlert: false })}>
                                                {this.state.alertErrorMessage}
                                            </SweetAlert>
                                        ) : null}
                                        {this.state.show ? (
                                            <CropEasy
                                                ratio={
                                                    this.state.selectedUploadtype === 'Thumbnail' || this.state.selectedUploadtype === 'Video'
                                                        ? 1 / 0.6
                                                        : this.state.selectedUploadtype === 'Cover Page'
                                                        ? 1 / 1.57
                                                        : 9 / 16
                                                }
                                                photoURL={this.state.base64}
                                                parentCallback={this.handleCallback}
                                            />
                                        ) : (
                                            <Row>
                                                <Col lg={12}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    {this.state.showPreview && this.state.videoUrlFlag ? (
                                                                        <MobileVideoPreviews
                                                                            videoUrl={this.state.videoUrl}
                                                                            videoThumnailPreview={this.state.videoThumnailPreview}
                                                                            urlVidFlag={this.state.urlVidFlag}
                                                                        />
                                                                    ) : (
                                                                        <Card>
                                                                            <CardBody>
                                                                                <CardTitle className="h4">Images & Videos</CardTitle>
                                                                                <MobilePreviews
                                                                                    previewtype={this.state.previewtype}
                                                                                    setPreviewUrl={this.state.setPreviewUrl}
                                                                                    cropImageSet={false}
                                                                                    subTitleColour={''}
                                                                                    postSubtitleColor={''}
                                                                                    ftitle={''}
                                                                                    postTitle={''}
                                                                                    descriptionColour={''}
                                                                                    postDescriptionColor={''}
                                                                                    description={''}
                                                                                    postDescription={''}
                                                                                    thumbnailType={'Venue'}
                                                                                />
                                                                            </CardBody>
                                                                        </Card>
                                                                    )}
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <CardTitle className="h4">Upload Images & Videos</CardTitle>
                                                                    {this.state.uploadPercentage > 0 && this.state.uploadPercentage < 100 ? (
                                                                        <>
                                                                            <br />
                                                                            <br />
                                                                            <div className="text-center">{this.state.uploadPercentage} %</div>
                                                                            <Progress value={this.state.uploadPercentage} />
                                                                        </>
                                                                    ) : (
                                                                        <Form
                                                                            className="form"
                                                                            onSubmit={e => this.uploadSubmit(e)}
                                                                            style={{
                                                                                margin: '30px  0 0 20px'
                                                                            }}
                                                                        >
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <div className="mb-4">
                                                                                        <Label htmlFor="formrow-firstname-Input">Upload Type</Label>
                                                                                        <select
                                                                                            value={selectedUploadtype}
                                                                                            onChange={e =>
                                                                                                this.setState({
                                                                                                    selectedUploadtype: e.target.value,
                                                                                                    vselectedUploadtype: '',
                                                                                                    videoUrl: '',
                                                                                                    videoThumnail: '',
                                                                                                    videoThumnailPreview: '',
                                                                                                    videoUrlFlag: false
                                                                                                })
                                                                                            }
                                                                                            className="form-select"
                                                                                        >
                                                                                            <option value="">Select Type</option>
                                                                                            {this.state.uploadtypes?.map((item, idx) => (
                                                                                                <option key={idx} value={item}>
                                                                                                    {item}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                        {this.state.vselectedUploadtype !== '' && (
                                                                                            <span style={{ color: 'red' }}>{this.state.vselectedUploadtype}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <div className="mb-4">
                                                                                        <Label htmlFor="formrow-firstname-Input">Select file</Label>
                                                                                        <input
                                                                                            disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                            name="base64"
                                                                                            className="form-control"
                                                                                            id="formFile"
                                                                                            type="file"
                                                                                            accept={this.state.selectedUploadtype === 'Video' ? '.mp4' : '.jpg,.jpeg,.png,.gif'}
                                                                                            onChange={e => this.onInputChange(e)}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            {this.state.selectedUploadtype === 'Video' && (
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <div className="mb-4">
                                                                                            <Label htmlFor="formrow-firstname-Input">Select Video Thumbnail</Label>
                                                                                            <input
                                                                                                disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                                name="videothumb"
                                                                                                className="form-control"
                                                                                                id="formFile"
                                                                                                type="file"
                                                                                                accept={'.jpg,.jpeg,.png'}
                                                                                                onChange={e => this.onInputChangeVideoThumbnai(e)}
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                            <Row>
                                                                                <Col>
                                                                                    <Label htmlFor="formrow-firstname-Input"></Label>
                                                                                    <button style={{ marginBottom: '5px' }} type="submit" className="btn btn-primary w-md">
                                                                                        Upload
                                                                                    </button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Form>
                                                                    )}

                                                                    {this.state.files.length > 0 && (
                                                                        <Card>
                                                                            <CardBody>
                                                                                <CardTitle className="h4">Images & Videos</CardTitle>
                                                                                <p className="card-title-desc"> </p>
                                                                                <div className="table-responsive">
                                                                                    <Table className="table table-borderless mb-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>Image</th>
                                                                                                <th>Types</th>
                                                                                                <th>Preview</th>
                                                                                                <th>Remove</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.files.map((item, idx) => (
                                                                                                <tr key={idx}>
                                                                                                    <th scope="row">{idx + 1}</th>
                                                                                                    <td
                                                                                                        style={{
                                                                                                            width: '5%',
                                                                                                            height: '5%',
                                                                                                            textAlign: 'center'
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        <img
                                                                                                            style={{
                                                                                                                maxWidth: '70%',
                                                                                                                maxHeight: '70%',
                                                                                                                borderRadius: '5px',
                                                                                                                /*maxWidth: '100%', maxHeight: '100%',*/ display: 'block',
                                                                                                                marginLeft: 'auto',
                                                                                                                marginRight: 'auto'
                                                                                                            }}
                                                                                                            src={item.type === 'Video' ? API_URL + item.urlVideoThumbnail : API_URL + item.url}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td>{item.type}</td>
                                                                                                    <td>
                                                                                                        <button
                                                                                                            onClick={() => {
                                                                                                                if (item.type === 'Video') {
                                                                                                                    this.setState({
                                                                                                                        loading: true,
                                                                                                                        videoUrl: '',
                                                                                                                        videoThumnailPreview: '',
                                                                                                                        videoThumnail: '',
                                                                                                                        urlVidFlag: false
                                                                                                                    });
                                                                                                                    setTimeout(() => {
                                                                                                                        this.setState({
                                                                                                                            videoUrlFlag: true,
                                                                                                                            videoUrl: API_URL + item.url,
                                                                                                                            videoThumnail: API_URL + item.urlVideoThumbnail,
                                                                                                                            videoThumnailPreview: API_URL + item.urlVideoThumbnail,
                                                                                                                            urlVidFlag: true,
                                                                                                                            selectedUploadtype: '',
                                                                                                                            loading: false
                                                                                                                        });
                                                                                                                    }, 500);
                                                                                                                } else {
                                                                                                                    this.setState({
                                                                                                                        previewtype: item.type,
                                                                                                                        showPreview: true,
                                                                                                                        setPreviewUrl: API_URL + item.url,
                                                                                                                        videoUrlFlag: false,
                                                                                                                        urlVidFlag: false,
                                                                                                                        selectedUploadtype: ''
                                                                                                                    });
                                                                                                                }
                                                                                                            }}
                                                                                                            type="button"
                                                                                                            className="btn btn-light btn-sm"
                                                                                                        >
                                                                                                            View
                                                                                                        </button>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <button
                                                                                                            onClick={() => this.removeFile(item.id)}
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                        >
                                                                                                            Delete
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}
                                    </TabPane>
                                </TabContent>
                            )}
                        </Card>
                    </Container>
                    {/* container-fluid */}
                </div>
            </React.Fragment>
        );
    }
}

VenueDetails.propTypes = {
    venue: PropTypes.array,
    className: PropTypes.any,
    match: PropTypes.object,
    onUpdateVenue: PropTypes.func,
    onGetVenues: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({ venue }) => ({
    venue: venue
});

const mapDispatchToProps = dispatch => ({
    onUpdateVenue: venue => dispatch(updateVenue(venue)),
    onGetVenues: () => dispatch(getVenues())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VenueDetails));

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </>
    );
};
