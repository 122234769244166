import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Card, Container, CardTitle, Label, Input, Breadcrumb, BreadcrumbItem, NavItem, NavLink, TabContent, TabPane, Toast, ToastHeader } from 'reactstrap';

import { getExhibitions } from '../../store/exhibitions/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { API_URL } from '../../constants/apiurl';
import classnames from 'classnames';

import Information from './components/Information';
import ImageList from './components/ImageList';

import Creators from './components/Creators';

import Artworks from './components/Artworks';

import Contents from './components/Contents';

import RouteList from './components/route/List';
import ExternalLinks from './components/ExternalLinks';
import Onsite from './components/Onsite';
import Offsite from './components/Offsite';
import Insights from './components/Insights';
import Sponsors from './components/Sponsors';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDeeplink } from '../../helpers/Api/common_apis';
import PhysicalPoint from './components/PhysicalPoint';
import RelatedExhibitions from "./components/RelatedExhibitions";

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class ExhibitionDetails extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            activeTab: '1',
            loading: false,
            viewStatus: false,
            setPageStatus: '',
            newStatus: false,
            editStatus: false,
            primaryData: {},
            exhibition_id: '',
            paramId: '',
            title: '',
            showSAlert: false,
            alertMessage: '',
            description: '',
            backgroundColor: '',
            foregroundColor: '',
            deeplink: '',
            copied: false,

            interactionsAudio: false,
            interactionsVideo: false,
            interactionsAR: false,
            interactionsReaction: false,
            interactionsToken: false
        };
        this.toggleTab = this.toggleTab.bind(this);
    }
    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        this.setState({ paramId: params.id });

        if (params.idd === 'view') {
            this.setState({ viewStatus: true, setPageStatus: 'View' });
        }
        if (params.idd === 'post') {
            this.setState({ newStatus: true, setPageStatus: 'Create' });
        }
        if (params.idd === 'edit') {
            this.setState({ setPageStatus: 'Edit' });
        }

        if (params.id !== 'new') {
            this.setState({ editStatus: true });
            this.getExhibitionInformation(params.id);
        } else {
            this.setState({ editStatus: false });
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    getExhibitionInformation = id => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/' + id, axiosconf)
            .then(response => {
                this.setState({
                    title: response.data.title,
                    description: response.data.description,
                    backgroundColor: response.data.backgroundColor,
                    foregroundColor: response.data.foregroundColor
                });
                this.getDeeplinkData(id);
            })
            .catch(e => {
                this.setState({ loading: false });
            });
    };

    async getDeeplinkData(id) {
        let postdata = {
            type: 'Exhibition',
            reference: id,
            contentType: null
        };
        const data = await getDeeplink(postdata);
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else {
            //console.log("------ "+JSON.stringify(data))
            this.setState({ deeplink: data.data.shortLink });
        }
    }

    handleCallbackTab1 = childDataTab1 => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.setState({
            activeTab: childDataTab1.set_tab,
            newStatus: false,
            exhibition_id: childDataTab1.exhibition_id,
            title: childDataTab1.title,
            description: childDataTab1.description,
            backgroundColor: childDataTab1.backgroundColor,
            foregroundColor: childDataTab1.foregroundColor,
            showSAlert: childDataTab1.showSAlert,
            alertMessage: childDataTab1.alertMessage
        });
    };

    render() {
        const {
            match: { params }
        } = this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Exhibition Details</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <CardTitle className="mb-1">Exhibition</CardTitle>
                        <Breadcrumb>
                            <BreadcrumbItem tag="p" href="#">
                                Exhibition List
                            </BreadcrumbItem>
                            <BreadcrumbItem tag="p" href="#" active>
                                {this.state.setPageStatus} Exhibition
                            </BreadcrumbItem>
                            {this.state.title !== '' && (
                                <BreadcrumbItem tag="b" href="#">
                                    {this.state.title}
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>
                        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: '1005' }}>
                            <Toast isOpen={this.state.copied}>
                                <ToastHeader toggle={() => this.setState({ copied: !this.state.copied })}>Copied!</ToastHeader>
                            </Toast>
                        </div>
                        {params.id !== 'new' && (
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '150px',
                                    right: '30px'
                                }}
                            >
                                <Label htmlFor="formrow-firstname-Input" style={{ marginTop: '7px', marginRight: '10px' }}>
                                    Deep Link
                                </Label>
                                <div style={{ width: '350px' }}>
                                    <Input
                                        readOnly={true}
                                        ref={textarea => (this.textArea = textarea)}
                                        type="readonly"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        name="deeplink"
                                        value={this.state.deeplink}
                                    />
                                </div>
                                <CopyToClipboard text={this.state.deeplink} onCopy={() => this.setState({ copied: true })}>
                                    <button>
                                        <i className="dripicons-copy" />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        )}
                        <Card>
                            {this.state.showSAlert && (
                                <SweetAlert
                                    title=""
                                    success
                                    onConfirm={() => {
                                        this.setState({ showSAlert: false });
                                    }}
                                >
                                    {this.state.alertMessage}
                                </SweetAlert>
                            )}

                            <ul className="nav nav-tabs nav-tabs-custom  pt-2" role="tablist">
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '1'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('1');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Information</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '2'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('2');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Images & Videos</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '7'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('7');
                                        }}
                                    >
                                        <CardTitle className="mb-2">External Links</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '3'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('3');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Creators</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '4'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('4');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Contents</CardTitle>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '5'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('5');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Artworks</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '6'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('6');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Routes</CardTitle>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '8'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('8');
                                        }}
                                    >
                                        <CardTitle className="mb-2">On site</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '9'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('9');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Off Site</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '10'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('10');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Insights</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '11'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('11');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Sponsors</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '12'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('12');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Physical Point</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '13'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('13');
                                        }}
                                    >
                                        <CardTitle className="mb-2">Related Exhibitions</CardTitle>
                                    </NavLink>
                                </NavItem>

                                <NavItem style={{ margin: '15px' }} className="position-absolute top-0 end-0">
                                    <button
                                        onClick={() => {
                                            this.props.onGetExhibition();
                                            this.props.history.push('/web/exhibition-list');
                                        }}
                                        type="button"
                                        className="btn btn-secondary"
                                    >
                                        <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                        Back
                                    </button>
                                </NavItem>
                            </ul>

                            <TabContent className="p-4" activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <CardTitle className="mb-1">General Information</CardTitle>
                                    <Information
                                        parentCallbackTab1={this.handleCallbackTab1}
                                        activeTab={this.state.activeTab.toString()}
                                        paramId={this.state.paramId.toString()}
                                        viewStatus={this.state.viewStatus}
                                        editStatus={this.state.editStatus}
                                    />
                                </TabPane>

                                <TabPane tabId="2">
                                    <ImageList activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                </TabPane>
                                <TabPane tabId="3">
                                    <Creators activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                </TabPane>
                                <TabPane tabId="4">
                                    <Contents activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} section={"exhibition"}/>
                                </TabPane>
                                <TabPane tabId="5">
                                    <Artworks activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                </TabPane>
                                <TabPane tabId="6">
                                    <RouteList activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                </TabPane>

                                <TabPane tabId="7">
                                    <ExternalLinks activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                </TabPane>

                                <TabPane tabId="8">
                                    <Onsite
                                        activeTab={this.state.activeTab.toString()}
                                        viewStatus={this.state.viewStatus}
                                        editStatus={this.state.editStatus}
                                        title={this.state.title}
                                        description={this.state.description}
                                    />
                                </TabPane>
                                <TabPane tabId="9">
                                    <Offsite
                                        activeTab={this.state.activeTab.toString()}
                                        viewStatus={this.state.viewStatus}
                                        editStatus={this.state.editStatus}
                                        title={this.state.title}
                                        description={this.state.description}
                                    />
                                </TabPane>
                                <TabPane tabId="10">
                                    <Insights activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                </TabPane>
                                <TabPane tabId="11">
                                    <Sponsors activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} />
                                </TabPane>
                                <TabPane tabId="12">
                                    <PhysicalPoint activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} />
                                </TabPane>
                                <TabPane tabId="13">
                                    <RelatedExhibitions activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ExhibitionDetails.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    onGetExhibition: PropTypes.func,
    onUpdateExhibition: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    onGetExhibition: () => dispatch(getExhibitions())

    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExhibitionDetails));
