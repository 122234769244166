const CONTENTS_PREFIX = '[contents]'

export const RESET_CONTENT_DETAIL_STATE = `${CONTENTS_PREFIX} RESET_CONTENT_DETAIL_STATE`

export const LOAD_CONTENT_DETAIL = `${CONTENTS_PREFIX} LOAD_CONTENT_DETAIL`
export const LOAD_CONTENT_DETAIL_FAIL = `${CONTENTS_PREFIX} LOAD_CONTENT_DETAIL_FAIL`
export const LOAD_CONTENT_DETAIL_SUCCESS = `${CONTENTS_PREFIX} LOAD_CONTENT_DETAIL_SUCCESS`
export const NEW_CONTENT_DETAIL = `${CONTENTS_PREFIX} NEW_CONTENT_DETAIL`
export const CREATE_CONTENT_DETAIL = `${CONTENTS_PREFIX} CREATE_CONTENT_DETAIL`
export const CREATE_CONTENT_DETAIL_FAIL = `${CONTENTS_PREFIX} CREATE_CONTENT_DETAIL_FAIL`
export const CREATE_CONTENT_DETAIL_SUCCESS = `${CONTENTS_PREFIX} CREATE_CONTENT_DETAIL_SUCCESS`
export const SAVE_CONTENT_DETAIL = `${CONTENTS_PREFIX} SAVE_CONTENT_DETAIL`
export const SAVE_CONTENT_DETAIL_FAIL = `${CONTENTS_PREFIX} SAVE_CONTENT_DETAIL_FAIL`
export const SAVE_CONTENT_DETAIL_SUCCESS = `${CONTENTS_PREFIX} SAVE_CONTENT_DETAIL_SUCCESS`

export const LOAD_TAG_STATUS = `${CONTENTS_PREFIX} LOAD_TAG_STATUS`
export const LOAD_TAG_STATUS_FAIL = `${CONTENTS_PREFIX} LOAD_TAG_STATUS_FAIL`
export const LOAD_TAG_STATUS_SUCCESS = `${CONTENTS_PREFIX} LOAD_TAG_STATUS_SUCCESS`

export const LOAD_CONTENT_FILES = `${CONTENTS_PREFIX} LOAD_CONTENT_FILES`
export const LOAD_CONTENT_FILES_FAIL = `${CONTENTS_PREFIX} LOAD_CONTENT_FILES_FAIL`
export const LOAD_CONTENT_FILES_SUCCESS = `${CONTENTS_PREFIX} LOAD_CONTENT_FILES_SUCCESS`
export const ADD_CONTENT_FILE = `${CONTENTS_PREFIX} ADD_CONTENT_FILE`
export const SAVE_CONTENT_FILES = `${CONTENTS_PREFIX} SAVE_CONTENT_FILES`
export const SAVE_CONTENT_FILES_SUCCESS = `${CONTENTS_PREFIX} SAVE_CONTENT_FILES_SUCCESS`
export const SAVE_CONTENT_FILES_FAIL = `${CONTENTS_PREFIX} SAVE_CONTENT_FILES_FAIL`
export const DELETE_CONTENT_FILES = `${CONTENTS_PREFIX} DELETE_CONTENT_FILES`
export const DELETE_CONTENT_FILES_SUCCESS = `${CONTENTS_PREFIX} DELETE_CONTENT_FILES_SUCCESS`
export const DELETE_CONTENT_FILES_FAIL = `${CONTENTS_PREFIX} DELETE_CONTENT_FILES_FAIL`

export const LOAD_CONTENT_CREATORS = `${CONTENTS_PREFIX} LOAD_CONTENT_CREATORS`
export const LOAD_CONTENT_CREATORS_FAIL = `${CONTENTS_PREFIX} LOAD_CONTENT_CREATORS_FAIL`
export const LOAD_CONTENT_CREATORS_SUCCESS = `${CONTENTS_PREFIX} LOAD_CONTENT_CREATORS_SUCCESS`
export const ADD_CONTENT_CREATOR = `${CONTENTS_PREFIX} ADD_CONTENT_CREATOR`
export const REMOVE_CONTENT_CREATOR = `${CONTENTS_PREFIX} REMOVE_CONTENT_CREATOR`
export const SAVE_CONTENT_CREATORS = `${CONTENTS_PREFIX} SAVE_CONTENT_CREATORS`
export const SAVE_CONTENT_CREATORS_SUCCESS = `${CONTENTS_PREFIX} SAVE_CONTENT_CREATORS_SUCCESS`
export const SAVE_CONTENT_CREATORS_FAIL = `${CONTENTS_PREFIX} SAVE_CONTENT_CREATORS_FAIL`

export const LOAD_CONTENT_VENUES = `${CONTENTS_PREFIX} LOAD_CONTENT_VENUES`
export const LOAD_CONTENT_VENUES_FAIL = `${CONTENTS_PREFIX} LOAD_CONTENT_VENUES_FAIL`
export const LOAD_CONTENT_VENUES_SUCCESS = `${CONTENTS_PREFIX} LOAD_CONTENT_VENUES_SUCCESS`
export const ADD_CONTENT_VENUE = `${CONTENTS_PREFIX} ADD_CONTENT_VENUE`
export const REMOVE_CONTENT_VENUE = `${CONTENTS_PREFIX} REMOVE_CONTENT_VENUE`
export const SAVE_CONTENT_VENUES = `${CONTENTS_PREFIX} SAVE_CONTENT_VENUES`
export const SAVE_CONTENT_VENUES_SUCCESS = `${CONTENTS_PREFIX} SAVE_CONTENT_VENUES_SUCCESS`
export const SAVE_CONTENT_VENUES_FAIL = `${CONTENTS_PREFIX} SAVE_CONTENT_VENUES_FAIL`

export const LOAD_CONTENT_LINKS = `${CONTENTS_PREFIX} LOAD_CONTENT_LINKS`
export const LOAD_CONTENT_LINKS_FAIL = `${CONTENTS_PREFIX} LOAD_CONTENT_LINKS_FAIL`
export const LOAD_CONTENT_LINKS_SUCCESS = `${CONTENTS_PREFIX} LOAD_CONTENT_LINKS_SUCCESS`
export const ADD_CONTENT_LINK = `${CONTENTS_PREFIX} ADD_CONTENT_LINK`
export const REMOVE_CONTENT_LINK = `${CONTENTS_PREFIX} REMOVE_CONTENT_LINK`
export const SAVE_CONTENT_LINKS = `${CONTENTS_PREFIX} SAVE_CONTENT_LINKS`
export const SAVE_CONTENT_LINKS_SUCCESS = `${CONTENTS_PREFIX} SAVE_CONTENT_LINKS_SUCCESS`
export const SAVE_CONTENT_LINKS_FAIL = `${CONTENTS_PREFIX} SAVE_CONTENT_LINKS_FAIL`


