/* Themes */
export const GET_THEMES = 'GET_THEMES';
export const GET_THEMES_FAIL = 'GET_THEMES_FAIL';
export const GET_THEMES_SUCCESS = 'GET_THEMES_SUCCESS';

export const GET_THEME = 'GET_THEME';
export const GET_THEME_FAIL = 'GET_THEME_FAIL';
export const GET_THEME_SUCCESS = 'GET_THEME_SUCCESS';

export const ADD_NEW_THEME = 'ADD_NEW_THEME';
export const ADD_THEME_SUCCESS = 'ADD_THEME_SUCCESS';
export const ADD_THEME_FAIL = 'ADD_THEME_FAIL';

export const DELETE_THEMES = 'DELETE_THEMES';
export const DELETE_THEMES_SUCCESS = 'DELETE_THEMES_SUCCESS';
export const DELETE_THEMES_FAIL = 'DELETE_THEMES_FAIL';

export const UPDATE_THEMES = 'UPDATE_THEMES';
export const UPDATE_THEMES_SUCCESS = 'UPDATE_THEMES_SUCCESS';
export const UPDATE_THEMES_FAIL = 'UPDATE_THEMES_FAIL';
//others------
