import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    Progress,
    Row,
    TabContent,
    Table,
    TabPane,
    Toast,
    ToastHeader
} from 'reactstrap';
//Import Breadcrumb
import {updateGallery} from '../../store/content-galleries/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import {API_URL} from '../../constants/apiurl';
import Loader from '../../components/Common/Loader';
import CropEasy from '../../components/Crop/CropEasy';
import classnames from 'classnames';

import {
    getDeeplink,
    lengthVideo,
    searchArtworkPublished,
    searchPublishCreators,
    searchVenuePublished
} from '../../helpers/Api/common_apis';
import {
    callGalleryArtworks,
    callGalleryCreators,
    callGalleryVenues,
    postGalleryArtworks,
    postGalleryCreators,
    postGalleryVenues
} from '../../helpers/Api/gallery_apis';
import {ReactSearchAutocomplete} from 'react-search-autocomplete';
import MobileVideoPreviews from '../../components/MobilePreviews/MobileVideoPreviews';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentExternalLinks from "./components/ContentExternalLinks";

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class GalleryDetails extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            tagstatus: [],
            status: '',
            dstatus: '',
            gallery: '',
            vgallery: '',
            timestamp: '',
            vtimestamp: '',
            author: '',
            vauthor: '',
            title: '',

            loading: false,
            id: null,
            editStatus: false,
            file: '',
            imagePreviewUrl: '',

            show: false,
            viewStatus: false,
            thumbnail: '',
            setPageStatus: '',
            newStatus: false,

            activeTab: '1',
            selectedFiles: [],
            selectedTypes: [],
            uploadtypes: [],
            files: [],

            showAlert: '',
            alertMessage: '',
            base64: '',
            show: '',
            filename: '',
            showPreview: true,
            setPreviewUrl: '',
            previewtype: '',

            selectedUploadtype: '',
            fielupload: '',
            vselectedUploadtype: '',

            titleColour: '#dddddd',
            ftitle: '',

            descriptionColour: '#dddddd',
            description: '',

            postDescriptionColor: '',
            postSubtitleColor: '',
            postTitle: '',
            postDescription: '',
            cropImageSet: false,
            editUpload: '',
            fileID: '',
            venues: [],
            showAlertSuccess: false,
            successMessage: '',
            selectedVenues: [],
            selectedArtworks: [],
            artworks: [],
            creators: [],
            selectedCreators: [],
            showErrorAlert: false,
            alertErrorMessage: '',
            successFlag: false,
            showAlertWarning: false,

            //Video ----
            videoUrl: {},
            videoUrlFlag: false,
            videoThumnailPreview: '',
            videoThumnail: '',
            videoThumnailName: '',
            urlVidFlag: false,
            uploadPercentage: 0,
            authtoken: '',
            deeplink: '',
            copied: false
        };
        this.toggleTab = this.toggleTab.bind(this);
    }
    componentDidMount() {
        this.getTagStatus();
        this.getUploadTypes();

        const {
            match: { params }
        } = this.props;

        if (params.idd === 'view') {
            this.setState({ viewStatus: true, setPageStatus: 'View' });
        }

        if (params.idd === 'post') {
            this.setState({ newStatus: true, setPageStatus: 'Create' });
        }

        if (params.idd === 'edit') {
            this.setState({ setPageStatus: 'Edit' });
        }

        if (params.id !== 'new') {
            this.setState({ editStatus: true });
            this.getGalleryDetails(params.id);
            this.getDeeplinkData(params.id);
        } else {
            this.setState({ editStatus: false });
        }
    }

    async getDeeplinkData(id) {
        let postdata = {
            type: 'Content',
            reference: id,
            contentType: 'Gallery'
        };
        const data = await getDeeplink(postdata);
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else {
            //console.log("------ "+JSON.stringify(data))
            this.setState({ deeplink: data.data.shortLink });
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }

        if (tab === '4') {
            this.getVenues();
        }

        // if (tab === '4') {
        //     this.getArtworks();
        // }

        if (tab === '3') {
            this.getCreators();
        }
        if (tab === '1' && this.state.newStatus === false) {
            this.getGalleryDetails(this.state.id);
        }
    }

    //----VENUE------

    async getVenues() {
        this.setState({ loading: true });
        const response = await callGalleryVenues(this.state.id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({
                selectedVenues: response.data,
                loading: false
            });
        }
    }
    async postVenues() {
        this.setState({ loading: true });
        const response = await postGalleryVenues(this.state.id, this.state.selectedVenues);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({ showAlertSuccess: true, loading: false, successMessage: 'Updated Successfully!', selectedVenues: [] });
            this.getVenues();
        }
    }
    async handleOnSearch(string, results) {
        const response = await searchVenuePublished(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ venues: response.data.content });
        }
    }

    //-----END VENUE-----

    //----ARTWORK------

    async getArtworks() {
        this.setState({ loading: true });
        const response = await callGalleryArtworks(this.state.id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({
                selectedArtworks: response.data,
                loading: false
            });
        }
    }
    async postArtworks() {
        this.setState({ loading: true });
        const response = await postGalleryArtworks(this.state.id, this.state.selectedArtworks);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({ showAlertSuccess: true, loading: false, successMessage: 'Updated  Successfully!', selectedArtworks: [] });
            this.getArtworks();
        }
    }
    async handleOnSearchArtwork(string, results) {
        const response = await searchArtworkPublished(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ artworks: response.data.content });
        }
    }

    //-----END VENUE-----

    //----CREATORS ------
    async getCreators() {
        this.setState({
            loading: true
        });
        const response = await callGalleryCreators(this.state.id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({
                selectedCreators: response.data,
                loading: false
            });
        }
    }

    async handleOnSearchCreators(string, results) {
        const response = await searchPublishCreators(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({
                creators: response.data.content
            });
        }
    }

    async postCreators() {
        this.setState({ loading: true });
        const response = await postGalleryCreators(this.state.id, this.state.selectedCreators);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({ showAlertSuccess: true, successMessage: 'Updated Successfully!', selectedCreators: [] });
            this.getCreators();
        }
    }

    //----END CREATORS---

    getTagStatus = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios.get(API_URL + '/tt/content/status', axiosconf).then(response => {
            this.setState({
                tagstatus: response.data
            });
        });
    };

    getUploadTypes = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios.get(API_URL + '/tt/content/upload/types', axiosconf).then(response => {
            this.setState({
                uploadtypes: response.data
            });
        });
    };

    getGalleryDetails = id => {
        let obj = this.getAuthDetails();

        this.setState({ loading: true });

        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios
            .get(API_URL + '/tt/content/' + id, axiosconf)
            .then(response => {
                var dd = response.data.timestamp !== null ? new Date(response.data.timestamp).toISOString().substring(0, 10) : '';
                this.setState({
                    loading: false,
                    title: response.data.title,
                    author: response.data.author,
                    status: response.data.status,
                    article: response.data.article,

                    timestamp: dd,
                    id: response.data.id,

                    files: response.data.files
                });
            })
            .catch(e => {
                this.setState({ loading: false });
            });
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    submitForm = e => {
        e.preventDefault();

        const {
            match: { params }
        } = this.props;
        if (this.state.status === '' || this.state.status === null) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ dstatus: 'Select Status!' });
        }
        if (this.state.author === '' || this.state.author === null) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vauthor: 'Author is required!' });
        }

        if (this.state.status !== '' && this.state.author !== '') {
            let postParams = {
                title: this.state.title,
                author: this.state.author,
                article: this.state.article,
                status: this.state.status,
                timestamp: new Date(this.state.timestamp),
                id: this.state.id,
                type: 'Gallery'
            };
            this.submitFormData(postParams, params.id);
        }
    };

    submitFormData = (postParams, id) => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        if (id === 'new') {
            //this.getTagDetails();
            if (this.state.id === '' || this.state.id === null) {
                axios
                    .post(API_URL + '/tt/content', postParams, axiosconf)
                    .then(response => {
                        this.getDeeplinkData(response.data.id).then(deepLinkDataResponse => {
                            this.setState({ loading: false });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            this.getGalleryDetails(response.data.id);
                            localStorage.setItem('contentid', JSON.stringify({ id: response.data.id }));

                            this.setState({
                                activeTab: '2',
                                newStatus: false,
                                showAlertSuccess: true,
                                loading: false,
                                successMessage: 'Created Successfully!'
                            });
                        });
                    })
                    .catch(err => console.log('error ' + JSON.stringify(err)));
            } else {
                this.props.onUpdateGallery(postParams);

                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.getGalleryDetails(this.state.id);
                this.setState({
                    activeTab: '2',
                    newStatus: false,
                    showAlertSuccess: true,
                    loading: false,
                    successMessage: 'Updated Successfully!'
                });
            }
        } else {
            this.props.onUpdateGallery(postParams);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getGalleryDetails(this.state.id);
            this.setState({
                activeTab: '2',
                newStatus: false,
                showAlertSuccess: true,
                loading: false,
                successMessage: 'Updated Successfully!'
            });
        }
    };

    onInputChangeVideoThumbnai = e => {
        const file = e.target.files[0];

        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

        if (size > 10000) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure image size is less than 10000 KB'
                //selectedUploadtype: "",
                //vselectedUploadtype: "",
                //urlVidFlag: false
            });
            //e.target.value = null
            return;
        } else {
            var filename = file.name;
            this.setState({
                videoThumnailName: filename,
                vselectedUploadtype: '',
                urlVidFlag: false
            });
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64: reader.result, show: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    async videoUpload(e) {
        const video = await lengthVideo(e.currentTarget.files[0]);
        console.log('duration.... ' + video.duration);
        if (video.duration > 60) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure video duration is less than 60 seconds.',
                selectedUploadtype: '',
                vselectedUploadtype: '',
                videoUrlFlag: false,
                urlVidFlag: false
            });
            e.target.value = null;
            return;
        } else {
            var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            if (size > 10000) {
                this.setState({
                    showAlertWarning: true,
                    alertMessage: 'Please ensure video size is less than 10 MB.',
                    selectedUploadtype: '',
                    vselectedUploadtype: '',
                    videoUrlFlag: false,
                    urlVidFlag: false
                });
                e.target.value = null;
                return;
            } else {
                const reader = new FileReader();
                //var url = URL.createObjectURL(e.target.files[0].originFileObj);
                //console.log("url video... "+JSON.stringify(url))
                this.setState({ videoUrl: e.target.files[0], fielupload: e.target.files[0], videoUrlFlag: true, filename: e.target.files[0].name, urlVidFlag: false });
            }
        }
    }

    onInputChange = e => {
        if (this.state.selectedUploadtype === '') {
            this.setState({ vselectedUploadtype: 'Please select the type' });
            return;
        } else {
            if (this.state.selectedUploadtype === 'Video') {
                this.setState({ videoUrl: {}, videoUrlFlag: false, fielupload: '' });

                this.videoUpload(e);
            } else {
                const file = e.target.files[0];

                var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
                var isGIF = file.type === 'image/gif';

                if (size > 300 && !isGIF) {
                    this.setState({
                        showAlertWarning: true,
                        alertMessage: 'Please ensure image size is less than 300 KB',
                        selectedUploadtype: '',
                        vselectedUploadtype: '',
                        videoUrlFlag: false,
                        urlVidFlag: false
                    });
                    e.target.value = null;
                } else {
                    var filename = file.name;
                    this.setState({ filename: filename, filename: e.target.files[0].name, previewtype: '', videoUrlFlag: false, urlVidFlag: false });
                    const reader = new FileReader();

                    reader.addEventListener(
                        'load',
                        () => {
                            if(isGIF) {
                                this.handleGIFCallback(reader.result);
                            } else {
                                this.setState({base64: reader.result, show: true});
                            }
                        },
                        false
                    );

                    if (file) {
                        reader.readAsDataURL(file);
                        //this.setState({imagePreviewUrl: file})
                    }
                }
            }
        }
    };

    uploadSubmit(e) {
        e.preventDefault();
        if (this.state.editUpload && this.state.fileID !== '') {
            this.uploadFileSubmit();
        } else {
            if (this.state.selectedUploadtype === '' || this.state.fielupload === '') {
                this.setState({ vselectedUploadtype: 'Please select the type and file to upload' });
            } else {
                if (this.state.selectedUploadtype === 'Thumbnail') {
                    //this.setState({showAlert: true, alertMessage: "Thumbnail already submitted! Remove previous one first", selectedUploadtype: ""})

                    this.uploadFileSubmit();
                } else if (this.state.selectedUploadtype === 'Video') {
                    if (this.state.filename === '') {
                        this.setState({
                            vselectedUploadtype: 'Video file is required!'
                        });
                    } else {
                        this.uploadFileProgress();
                    }
                } else {
                    this.uploadFileSubmit();
                }
            }
        }
    }

    uploadFileProgress = () => {
        let formData = new FormData();

        let datpayload = {
            title: this.state.ftitle,
            titleColour: this.state.titleColour,
            description: this.state.description,
            descriptionColour: this.state.descriptionColour,
            id: this.state.fileID
        };
        let json = JSON.stringify(datpayload);

        let blob = new Blob([json], {
            type: 'application/json'
        });

        formData.append('file', this.state.videoUrl, this.state.filename);
        if(this.state.videoThumnail !== '') {
            formData.append('file', this.state.videoThumnail, this.state.videoThumnailName);
        }

        formData.append('payload', blob);

        let autTok = this.getAuthDetails();
        setTimeout(() => {
            this.setState({ authtoken: autTok.access_token });
            const options = {
                headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json', Authorization: 'Bearer ' + this.state.authtoken },
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    //console.log( `${loaded}kb of ${total}kb | ${percent}%` );

                    if (percent < 100) {
                        this.setState({ uploadPercentage: percent });
                    }
                }
            };
            axios
                .post(API_URL + '/tt/content/' + this.state.id + '/file/?type=' + this.state.selectedUploadtype, formData, options)
                .then(res => {
                    this.setState({ uploadPercentage: 100 });
                    this.setState({
                        loading: false,
                        videoUrlFlag: false,
                        flashShow: false,
                        showPreview: true,
                        setPreviewUrl: '',
                        previewtype: '',
                        showAlert: true,
                        alertMessage: 'Uploaded Successfully',
                        successFlag: true,
                        selectedUploadtype: '',
                        uploadPercentage: 0
                    });
                    this.getGalleryDetails(this.state.id);
                })
                .catch(e => {
                    console.log('error... ' + JSON.stringify(e));
                });
        }, 400);
    };

    uploadFileSubmit = () => {
        let formData = new FormData();
        let datpayload = {
            title: this.state.ftitle,
            descriptionColour: this.state.descriptionColour,
            titleColour: this.state.titleColour,
            description: this.state.description,
            id: this.state.fileID
        };
        const json = JSON.stringify(datpayload);
        const blob = new Blob([json], {
            type: 'application/json'
        });
        if (this.state.filename !== '') {
            formData.append('file', this.state.fielupload, this.state.filename);
        }
        formData.append('payload', blob);
        if (this.state.editUpload && this.state.fileID !== '') {
            this.updateFileUpload(formData, this.state.fileID);
        } else {
            this.newUploadFile(formData);
        }
    };

    updateFileUpload = (formData, fid) => {
        let obj = this.getAuthDetails();
        let axiosConfig = {};
        if (this.state.filename !== '') {
            axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + obj.access_token
                }
            };
        } else {
            axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + obj.access_token
                }
            };
        }

        this.setState({ loading: true });
        axios
            .put(API_URL + '/tt/content/' + fid + '/file/', formData, axiosConfig)
            .then(response => {
                this.setState({
                    loading: false,
                    showPreview: true,
                    setPreviewUrl: '',
                    cropImageSet: false,
                    ftitle: '',
                    description: '',
                    selectedUploadtype: '',
                    fileID: '',
                    editUpload: false,
                    filename: '',
                    fielupload: ''
                });
                this.setState({
                    base64: '',
                    fielupload: '',
                    selectedUploadtype: '',
                    showAlert: true,
                    alertMessage: 'Uploaded Successfully',
                    successFlag: true,
                    setPreviewUrl: '',
                    postDescription: '',
                    postSubTitle: '',
                    postTitle: ''
                });
                this.getGalleryDetails(this.state.id);
            })
            .catch(e => {
                console.log(e);
                this.setState({
                    loading: false
                });
                this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: e.response.data.message });
            });
    };

    newUploadFile = formData => {
        let obj = this.getAuthDetails();
        let axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + obj.access_token
            }
        };
        this.setState({ loading: true });
        axios
            .post(API_URL + '/tt/content/' + this.state.id + '/file/?type=' + this.state.selectedUploadtype, formData, axiosConfig)
            .then(response => {
                this.setState({
                    loading: false,
                    showPreview: true,
                    setPreviewUrl: '',
                    cropImageSet: false,
                    ftitle: '',
                    description: '',
                    selectedUploadtype: '',
                    fileID: '',
                    editUpload: false,
                    filename: '',
                    fielupload: ''
                });
                this.setState({
                    base64: '',
                    fielupload: '',
                    selectedUploadtype: '',
                    showAlert: true,
                    alertMessage: 'Uploaded Successfully',
                    successFlag: true,
                    setPreviewUrl: '',
                    postDescription: '',
                    postSubTitle: '',
                    postTitle: ''
                });
                this.getGalleryDetails(this.state.id);
            })
            .catch(e => {
                console.log(e);
                this.setState({
                    loading: false
                });
                this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: e.response.data.message });
            });
    };

    handleGIFCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }
        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                cropImageSet: true,
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
        // this.myRef.current.scrollIntoView()
    };

    handleCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }
        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                cropImageSet: true,
                vselectedUploadtype: '',
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
            // this.myRef.current.scrollIntoView()
        }
    };

    removeFile = id => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .delete(API_URL + '/tt/content/file/' + id, axiosconf)
            .then(response => {
                this.setState({ showPreview: true, setPreviewUrl: '', previewtype: '', cropImageSet: false });
                this.getGalleryDetails(this.state.id);
            })
            .catch(e => {});
    };

    dataURLtoBlob = dataurl => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    render() {
        const {
            match: { params }
        } = this.props;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Gallery Details</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <CardTitle className="mb-1">Gallery</CardTitle>
                        <Breadcrumb>
                            <BreadcrumbItem tag="p" href="#">
                                Gallery List
                            </BreadcrumbItem>
                            <BreadcrumbItem tag="p" href="#" active>
                                {this.state.setPageStatus} Gallery
                            </BreadcrumbItem>
                            {this.state.title !== '' && (
                                <BreadcrumbItem tag="b" href="#">
                                    {this.state.title}
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>

                        {this.state.showAlertSuccess ? (
                            <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}>
                                {this.state.successMessage}
                            </SweetAlert>
                        ) : null}

                        {this.state.showAlertWarning ? (
                            <SweetAlert warning title="" onConfirm={() => this.setState({ showAlertWarning: false })}>
                                {this.state.alertMessage}
                            </SweetAlert>
                        ) : null}

                        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: '1005' }}>
                            <Toast isOpen={this.state.copied}>
                                <ToastHeader toggle={() => this.setState({ copied: !this.state.copied })}>Copied!</ToastHeader>
                            </Toast>
                        </div>
                        {params.id !== 'new' && (
                            <div style={{ display: 'flex', position: 'absolute', top: '150px', right: '30px' }}>
                                <Label htmlFor="formrow-firstname-Input" style={{ marginTop: '7px', marginRight: '10px' }}>
                                    Deep Link
                                </Label>
                                <div style={{ width: '350px' }}>
                                    <Input
                                        readOnly={true}
                                        ref={textarea => (this.textArea = textarea)}
                                        type="readonly"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        name="deeplink"
                                        value={this.state.deeplink}
                                    />
                                </div>
                                <CopyToClipboard text={this.state.deeplink} onCopy={() => this.setState({ copied: true })}>
                                    <button>
                                        <i className="dripicons-copy" />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        )}
                        <Card>
                            <ul className="nav nav-tabs nav-tabs-custom  pt-2" role="tablist">
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '1'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('1');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Information</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '2'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('2');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Images & Videos</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '3'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('3');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Creator</CardTitle>
                                    </NavLink>
                                </NavItem>
                                {/*<NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '4'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('4');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Artwork</CardTitle>
                                    </NavLink>
                                </NavItem>*/}
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '4'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('4');
                                        }}
                                    >
                                        <CardTitle className="mb-4">Venue</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        disabled={this.state.newStatus}
                                        to="#"
                                        className={classnames({
                                            active: this.state.activeTab === '5'
                                        })}
                                        onClick={() => {
                                            this.toggleTab('5');
                                        }}
                                    >
                                        <CardTitle className="mb-4">External Links</CardTitle>
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{ margin: '15px' }} className="position-absolute top-0 end-0">
                                    <button
                                        onClick={() => {
                                            this.props.history.push('/web/gallery-list');
                                        }}
                                        type="button"
                                        className="btn btn-secondary"
                                    >
                                        <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                        Back
                                    </button>
                                </NavItem>
                            </ul>
                            {this.state.loading ? (
                                <Loader color={'green'} loading={this.state.loading} />
                            ) : (
                                <TabContent className="p-4" activeTab={this.state.activeTab}>
                                    <CardTitle className="mb-4">
                                        {this.state.editStatus && (
                                            <span className={this.state.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{this.state.status}</span>
                                        )}
                                    </CardTitle>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardBody>
                                                        <Form className="form" onSubmit={e => this.submitForm(e)}>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">Title</Label>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Title"
                                                                            name="title"
                                                                            value={this.state.title}
                                                                            onChange={e => this.setState({ title: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">Author</Label>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Author"
                                                                            name="author"
                                                                            // invalid={this.state.vauthor !== "" ? true : false}
                                                                            value={this.state.author}
                                                                            onChange={e => this.setState({ author: e.target.value, vauthor: '' })}
                                                                        />
                                                                        {this.state.vauthor !== '' && <span style={{ color: 'red' }}>{this.state.vauthor}</span>}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">Status</Label>
                                                                        <select
                                                                            disabled={this.state.viewStatus}
                                                                            value={this.state.status}
                                                                            onChange={e => this.setState({ status: e.target.value, dstatus: '' })}
                                                                            className="form-select"
                                                                        >
                                                                            <option value="">Select Status</option>
                                                                            {this.state.tagstatus.map((item, idx) => (
                                                                                <option key={idx} value={item}>
                                                                                    {item}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {this.state.dstatus !== '' && <span style={{ color: 'red' }}>{this.state.dstatus}</span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-4">
                                                                        <Label htmlFor="formrow-firstname-Input">Date of Post</Label>
                                                                        <input
                                                                            disabled={this.state.viewStatus}
                                                                            className="form-control"
                                                                            type="date"
                                                                            //defaultValue="13:45:00"
                                                                            id="example-time-input"
                                                                            name="timestamp"
                                                                            value={this.state.timestamp}
                                                                            onChange={e => this.setState({ timestamp: e.target.value, vtimestamp: '' })}
                                                                        />
                                                                        {this.state.vtimestamp !== '' && <span style={{ color: 'red' }}>{this.state.vtimestamp}</span>}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={12}>
                                                                    <Label htmlFor="formrow-firstname-Input">Article</Label>

                                                                    <textarea
                                                                        readOnly={this.state.viewStatus}
                                                                        type="textarea"
                                                                        rows="13"
                                                                        className="form-control"
                                                                        id="basicpill-expiration-input13"
                                                                        placeholder="Enter Article"
                                                                        value={this.state.article}
                                                                        onChange={e => this.setState({ article: e.target.value })}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <br />
                                                            <br />
                                                            <div className="text-center mt-4">
                                                                {this.state.id === '' || this.state.id === null ? (
                                                                    <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                        Save & Next
                                                                    </button>
                                                                ) : (
                                                                    <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                        Save
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </Form>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        {this.state.showAlert ? (
                                            <SweetAlert success={this.state.successFlag} title="" onConfirm={() => this.setState({ showAlert: false })}>
                                                {this.state.alertMessage}
                                            </SweetAlert>
                                        ) : null}

                                        {this.state.showErrorAlert ? (
                                            <SweetAlert error title="" onConfirm={() => this.setState({ showErrorAlert: false })}>
                                                {this.state.alertErrorMessage}
                                            </SweetAlert>
                                        ) : null}

                                        {this.state.show ? (
                                            <CropEasy
                                                ratio={
                                                    this.state.selectedUploadtype === 'Thumbnail'
                                                        ? 1
                                                        : this.state.selectedUploadtype === 'Cover Page'
                                                            ? 1 / 1.57
                                                            : this.state.selectedUploadtype === 'Video'
                                                                ? 1 / 0.6
                                                                : 1.2 / 2.2
                                                }
                                                photoURL={this.state.base64}
                                                parentCallback={this.handleCallback}
                                            />
                                        ) : (
                                            <Row>
                                                <Col lg={12}>
                                                    <Card>
                                                        <CardBody>
                                                            <div className="text-center mt-0">
                                                                <h5>Upload Images & Videos</h5>
                                                            </div>
                                                            <Row>
                                                                <Col md={5}>
                                                                    {this.state.showPreview && this.state.videoUrlFlag ? (
                                                                        <MobileVideoPreviews
                                                                            videoUrl={this.state.videoUrl}
                                                                            videoThumnailPreview={this.state.videoThumnailPreview}
                                                                            urlVidFlag={this.state.urlVidFlag}
                                                                        />
                                                                    ) : (
                                                                        <div ref={this.myRef} style={{ marginTop: '-100px' }} className="device device-iphone-8 device-spacegray">
                                                                            <div className="device-frame">
                                                                                {this.state.previewtype === 'Thumbnail' && (
                                                                                    <div style={{ marginTop: '50px' }}>
                                                                                        <div style={{ margin: '5px' }}>
                                                                                            <div style={{ width: '50%', height: '50%', textAlign: 'center' }}>
                                                                                                <img
                                                                                                    style={{
                                                                                                        maxWidth: '100%',
                                                                                                        maxHeight: '100%',
                                                                                                        display: 'block',
                                                                                                        marginLeft: 'auto',
                                                                                                        marginRight: 'auto'
                                                                                                    }}
                                                                                                    src={this.state.setPreviewUrl}
                                                                                                ></img>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {this.state.previewtype === 'Cover Page' && (
                                                                                    <div style={{ width: '100%', height: '84%', textAlign: 'center' }}>
                                                                                        <img
                                                                                            style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                                                                            src={this.state.setPreviewUrl}
                                                                                        ></img>
                                                                                        <p style={{ color: '#fff', marginTop: '10px' }}>Some Head text here</p>
                                                                                    </div>
                                                                                )}

                                                                                {this.state.previewtype === 'Image' && (
                                                                                    <div
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            height: '99%',
                                                                                            backgroundImage: 'url(' + this.state.setPreviewUrl + ')',
                                                                                            backgroundSize: 'cover'
                                                                                        }}
                                                                                    >
                                                                                        <br />
                                                                                        <h4
                                                                                            style={{
                                                                                                color: this.state.cropImageSet ? this.state.titleColour : this.state.postSubtitleColor,
                                                                                                marginLeft: '13px'
                                                                                            }}
                                                                                        >
                                                                                            {this.state.cropImageSet ? this.state.ftitle : this.state.postTitle}
                                                                                        </h4>
                                                                                        <div style={{ position: 'absolute', bottom: '130px', left: '15px' }}>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginLeft: '16px',
                                                                                                    marginRight: '15px',
                                                                                                    color: this.state.cropImageSet ? this.state.descriptionColour : this.state.postDescriptionColor,
                                                                                                    whiteSpace: 'pre-wrap'
                                                                                                }}
                                                                                            >
                                                                                                {this.state.cropImageSet ? this.state.description : this.state.postDescription}
                                                                                            </p>
                                                                                        </div>
                                                                                        <br />
                                                                                        <br />
                                                                                        <br />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div className="device-stripe"></div>
                                                                            <div className="device-header"></div>
                                                                            <div className="device-sensors"></div>
                                                                            <div className="device-btns"></div>
                                                                            <div className="device-power"></div>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                                <Col md={7}>
                                                                    <div>
                                                                        {this.state.uploadPercentage > 0 && this.state.uploadPercentage < 100 ? (
                                                                            <>
                                                                                <br />
                                                                                <br />
                                                                                <div className="text-center">{this.state.uploadPercentage} %</div>
                                                                                <Progress value={this.state.uploadPercentage} />
                                                                            </>
                                                                        ) : (
                                                                            <Form className="form" onSubmit={e => this.uploadSubmit(e)}>
                                                                                <Row style={{ marginTop: '30px' }}>
                                                                                    <Col md={6}>
                                                                                        <div className="mb-4">
                                                                                            <Label htmlFor="formrow-firstname-Input">Upload Type</Label>
                                                                                            <select
                                                                                                disabled={this.state.viewStatus}
                                                                                                value={this.state.selectedUploadtype}
                                                                                                onChange={e => {
                                                                                                    this.setState({
                                                                                                        selectedUploadtype: e.target.value,
                                                                                                        vselectedUploadtype: '',
                                                                                                        videoUrl: '',
                                                                                                        videoThumnail: '',
                                                                                                        videoThumnailPreview: '',
                                                                                                        videoUrlFlag: false
                                                                                                    });
                                                                                                    if (e.target.value === 'Thumbnail') {
                                                                                                        this.setState({
                                                                                                            ftitle: 'thumbnail',
                                                                                                            titleColour: '#ffffff',
                                                                                                            description: 'Thumbnail',
                                                                                                            descriptionColour: '#ffffff'
                                                                                                        });
                                                                                                    } else {
                                                                                                        this.setState({ ftitle: '', titleColour: '', description: '', descriptionColour: '' });
                                                                                                    }
                                                                                                }}
                                                                                                className="form-select"
                                                                                            >
                                                                                                <option value="">Select Type</option>
                                                                                                {this.state.uploadtypes.map((item, idx) => (
                                                                                                    <option key={idx} value={item}>
                                                                                                        {item}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                            {this.state.vselectedUploadtype !== '' && <span style={{ color: 'red' }}>{this.state.vselectedUploadtype}</span>}
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <div className="mb-4">
                                                                                            <Label htmlFor="formrow-firstname-Input">Select file</Label>
                                                                                            <input
                                                                                                disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                                name="base64"
                                                                                                className="form-control"
                                                                                                id="formFile"
                                                                                                type="file"
                                                                                                accept={this.state.selectedUploadtype === 'Video' ? '.mp4' : '.jpg,.jpeg,.png,.gif'}
                                                                                                onChange={e => this.onInputChange(e)}
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                                {this.state.selectedUploadtype === 'Video' && (
                                                                                    <Row>
                                                                                        <Col md={6}>
                                                                                            <div className="mb-4">
                                                                                                <Label htmlFor="formrow-firstname-Input">Select Video Thumbnail</Label>
                                                                                                <input
                                                                                                    disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                                    name="videothumb"
                                                                                                    className="form-control"
                                                                                                    id="formFile"
                                                                                                    type="file"
                                                                                                    accept={'.jpg,.jpeg,.png'}
                                                                                                    onChange={e => this.onInputChangeVideoThumbnai(e)}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {this.state.selectedUploadtype === 'Thumbnail' ||
                                                                                this.state.selectedUploadtype === '' ? null : (
                                                                                    <Row>
                                                                                        <Col md={9}>
                                                                                            <div className="mb-3">
                                                                                                <Label htmlFor="formrow-firstname-Input">Title</Label>
                                                                                                <Input
                                                                                                    readOnly={this.state.viewStatus}
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    id="formrow-themename-Input"
                                                                                                    placeholder="Enter short description here"
                                                                                                    name="ftitle"
                                                                                                    value={this.state.ftitle}
                                                                                                    onChange={e => this.setState({ ftitle: e.target.value })}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md={3}>
                                                                                            <div className="mb-3">
                                                                                                <Label htmlFor="formrow-firstname-Input">Title color</Label>

                                                                                                <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                                                                                    <input
                                                                                                        className="form-control form-control-color mw-100"
                                                                                                        type="color"
                                                                                                        id="example-color-input"
                                                                                                        value={this.state.titleColour}
                                                                                                        onChange={e => this.setState({ titleColour: e.target.value })}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}

                                                                                {this.state.selectedUploadtype === 'Thumbnail' ||
                                                                                this.state.selectedUploadtype === '' ? null : (
                                                                                    <Row>
                                                                                        <Col md={9}>
                                                                                            <div className="mb-3">
                                                                                                <Label htmlFor="formrow-firstname-Input">Description</Label>
                                                                                                <Input
                                                                                                    readOnly={this.state.viewStatus}
                                                                                                    type="textarea"
                                                                                                    rows={4}
                                                                                                    className="form-control"
                                                                                                    id="formrow-themename-Input"
                                                                                                    placeholder="Enter Theme name"
                                                                                                    name="description"
                                                                                                    value={this.state.description}
                                                                                                    onChange={e => this.setState({ description: e.target.value })}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md={3}>
                                                                                            <div className="mb-3">
                                                                                                <Label htmlFor="formrow-firstname-Input">Description color</Label>

                                                                                                <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                                                                                    <input
                                                                                                        className="form-control form-control-color mw-100"
                                                                                                        type="color"
                                                                                                        id="example-color-input"
                                                                                                        value={this.state.descriptionColour}
                                                                                                        onChange={e => this.setState({ descriptionColour: e.target.value })}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                <Row>
                                                                                    <Col md={4}>
                                                                                        {this.state.editUpload ? (
                                                                                            <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                                                Update
                                                                                            </button>
                                                                                        ) : (
                                                                                            <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                                                Upload
                                                                                            </button>
                                                                                        )}
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        {this.state.editUpload && (
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    this.setState({
                                                                                                        editUpload: false,
                                                                                                        fileID: '',
                                                                                                        base64: '',
                                                                                                        fielupload: '',
                                                                                                        filename: '',
                                                                                                        selectedUploadtype: '',
                                                                                                        description: '',
                                                                                                        ftitle: '',
                                                                                                        vselectedUploadtype: '',
                                                                                                        selectedUploadtype: '',
                                                                                                        showPreview: true,
                                                                                                        setPreviewUrl: ''
                                                                                                    })
                                                                                                }
                                                                                                className="btn btn-outline-secondary w-md"
                                                                                            >
                                                                                                Reset for New Upload
                                                                                            </button>
                                                                                        )}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Form>
                                                                        )}
                                                                    </div>
                                                                    {this.state.files.length > 0 && (
                                                                        <Card>
                                                                            <CardBody>
                                                                                <CardTitle className="h4">Images & Videos</CardTitle>

                                                                                <div className="table-responsive">
                                                                                    <Table className="table table-borderless mb-0">
                                                                                        <thead>
                                                                                        <tr>
                                                                                            <th>#</th>
                                                                                            <th>Image</th>
                                                                                            <th>Types</th>
                                                                                            <th>Preview</th>
                                                                                            <th>Remove</th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {this.state.files.map((item, idx) => (
                                                                                            <tr key={idx}>
                                                                                                <th scope="row">{item.id}</th>
                                                                                                <td style={{ width: '5%', height: '5%', textAlign: 'center' }}>
                                                                                                    {' '}
                                                                                                    <img
                                                                                                        style={{
                                                                                                            maxWidth: '70%',
                                                                                                            maxHeight: '70%',
                                                                                                            borderRadius: '5px',
                                                                                                            display: 'block',
                                                                                                            marginLeft: 'auto',
                                                                                                            marginRight: 'auto'
                                                                                                        }}
                                                                                                        src={item.type === 'Video' ? API_URL + item.urlVideoThumbnail : API_URL + item.url}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>{item.type}</td>
                                                                                                <td>
                                                                                                    <button
                                                                                                        disabled={this.state.viewStatus}
                                                                                                        onClick={() => {
                                                                                                            if (item.type === 'Video') {
                                                                                                                this.setState({
                                                                                                                    loading: true,
                                                                                                                    videoUrl: '',
                                                                                                                    videoThumnailPreview: '',
                                                                                                                    videoThumnail: '',
                                                                                                                    urlVidFlag: false,
                                                                                                                    editUpload: false
                                                                                                                });
                                                                                                                setTimeout(() => {
                                                                                                                    this.setState({
                                                                                                                        videoUrlFlag: true,
                                                                                                                        videoUrl: API_URL + item.url,
                                                                                                                        videoThumnail: API_URL + item.urlVideoThumbnail,
                                                                                                                        videoThumnailPreview: API_URL + item.urlVideoThumbnail,
                                                                                                                        urlVidFlag: true,
                                                                                                                        selectedUploadtype: '',
                                                                                                                        loading: false
                                                                                                                    });
                                                                                                                }, 500);
                                                                                                            } else {
                                                                                                                this.setState({
                                                                                                                    previewtype: item.type,
                                                                                                                    showPreview: true,
                                                                                                                    setPreviewUrl: API_URL + item.url,
                                                                                                                    postTitle: item.title,
                                                                                                                    postDescription: item.description,
                                                                                                                    postSubtitleColor: item.titleColour,
                                                                                                                    postDescriptionColor: item.descriptionColour,
                                                                                                                    cropImageSet: true,
                                                                                                                    ftitle: item.title,
                                                                                                                    description: item.description,
                                                                                                                    titleColour: item.titleColour,
                                                                                                                    descriptionColour: item.descriptionColour,
                                                                                                                    selectedUploadtype: item.type,
                                                                                                                    editUpload: true,
                                                                                                                    fileID: item.id,
                                                                                                                    videoUrlFlag: false,
                                                                                                                    urlVidFlag: false
                                                                                                                });
                                                                                                            }
                                                                                                        }}
                                                                                                        type="button"
                                                                                                        className="btn btn-light btn-sm"
                                                                                                    >
                                                                                                        View
                                                                                                    </button>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <button
                                                                                                        disabled={this.state.viewStatus}
                                                                                                        onClick={() => this.removeFile(item.id)}
                                                                                                        type="button"
                                                                                                        className="btn btn-danger btn-sm"
                                                                                                    >
                                                                                                        Delete
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div>
                                            <div className="text-center mt-0">
                                                <h5>Creators</h5>
                                            </div>

                                            {this.state.loading ? (
                                                <Loader color={'green'} loading={this.state.loading} />
                                            ) : (
                                                <div>
                                                    <Row style={{ marginTop: '20px' }}>
                                                        <Col md={6}>
                                                            <ReactSearchAutocomplete
                                                                styling={{
                                                                    backgroundColor: '#fff',
                                                                    zIndex: 2,
                                                                    placeholderColor: '#939294',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ced4da',
                                                                    height: '35px',
                                                                    fontSize: 16
                                                                }}
                                                                items={this.state.creators}
                                                                placeholder={'Type Creator name or Company here'}
                                                                onSearch={(ss, ee) => this.handleOnSearchCreators(ss, ee)}
                                                                onHover={() => {}}
                                                                onSelect={item => {
                                                                    let found = this.state.selectedCreators.find(a => a.creator.id === item.id);

                                                                    if (!found) {
                                                                        this.setState({ selectedCreators: [...this.state.selectedCreators, { id: '', creator: item }] });
                                                                    }
                                                                }}
                                                                onFocus={() => {
                                                                    this.setState({});
                                                                }}
                                                                formatResult={formatResult}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '15px' }}>
                                                        <Col md={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead className="table-light">
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Creator Name</th>
                                                                        <th>Type</th>
                                                                        <th>Remove</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.selectedCreators.map((item, idx) => {
                                                                        return (
                                                                            <tr key={idx}>
                                                                                <th scope="row">{idx + 1}</th>
                                                                                <td>{item.creator.name}</td>
                                                                                <td>
                                                                                    <Button style={{ margin: '2px' }} color={'light'} className="btn-rounded btn-sm">
                                                                                        {item.creator.identifier}
                                                                                    </Button>
                                                                                </td>

                                                                                <td>
                                                                                    {' '}
                                                                                    <i
                                                                                        className="mdi mdi-delete font-size-18"
                                                                                        id="deletetooltip"
                                                                                        onClick={() => {
                                                                                            const reducedArr = this.state.selectedCreators.filter((item, itemIndex) => {
                                                                                                return itemIndex !== idx;
                                                                                            });

                                                                                            this.setState({
                                                                                                selectedCreators: reducedArr
                                                                                            });
                                                                                        }}
                                                                                    ></i>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <br />
                                                    <div className="text-center mt-4">
                                                        <button onClick={() => this.postCreators()} disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </TabPane>
                                    {/*<TabPane tabId="4">
                                        <div>
                                            <div className="text-center mt-0">
                                                <h5>Artworks</h5>
                                            </div>
                                            {this.state.showAlert ? (
                                                <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}>
                                                    {this.state.alertMessage}
                                                </SweetAlert>
                                            ) : null}

                                            {this.state.loading ? (
                                                <Loader color={'green'} loading={this.state.loading} />
                                            ) : (
                                                <div>
                                                    <Row style={{ marginTop: '20px' }}>
                                                        <Col md={6}>
                                                            <ReactSearchAutocomplete
                                                                styling={{
                                                                    backgroundColor: '#fff',
                                                                    zIndex: 2,
                                                                    placeholder: 'Type Country',
                                                                    placeholderColor: '#939294',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ced4da',
                                                                    height: '35px',
                                                                    fontSize: 16
                                                                }}
                                                                items={this.state.artworks}
                                                                placeholder={'Type artwork name here'}
                                                                onSearch={(ss, ee) => this.handleOnSearchArtwork(ss, ee)}
                                                                onHover={() => {}}
                                                                onSelect={item => {
                                                                    let found = this.state.selectedArtworks.find(a => a.artwork.id === item.id);

                                                                    if (!found) {
                                                                        this.setState({ selectedArtworks: [...this.state.selectedArtworks, { id: '', artwork: item }] });
                                                                    }
                                                                }}
                                                                onFocus={() => {}}
                                                                formatResult={formatResult}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '15px' }}>
                                                        <Col md={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Artwork name</th>

                                                                            <th>Remove</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.selectedArtworks.map((item, idx) => {
                                                                            return (
                                                                                <tr key={idx}>
                                                                                    <th scope="row">{idx + 1}</th>
                                                                                    <td>{item.artwork.name}</td>

                                                                                    <td>
                                                                                        {' '}
                                                                                        <i
                                                                                            className="mdi mdi-delete font-size-18"
                                                                                            id="deletetooltip"
                                                                                            onClick={() => {
                                                                                                const reducedArr = this.state.selectedArtworks.filter((item, itemIndex) => {
                                                                                                    return itemIndex !== idx;
                                                                                                });

                                                                                                this.setState({
                                                                                                    selectedArtworks: reducedArr
                                                                                                });
                                                                                            }}
                                                                                        ></i>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <br />
                                                    <div className="text-center mt-4">
                                                        <button disabled={this.state.viewStatus} onClick={() => this.postArtworks()} type="submit" className="btn btn-primary w-md">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </TabPane>*/}
                                    <TabPane tabId="4">
                                        <div>
                                            <div className="text-center mt-0">
                                                <h5>Venues</h5>
                                            </div>
                                            {this.state.showAlert ? (
                                                <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}>
                                                    {this.state.alertMessage}
                                                </SweetAlert>
                                            ) : null}

                                            {this.state.loading ? (
                                                <Loader color={'green'} loading={this.state.loading} />
                                            ) : (
                                                <div>
                                                    <Row style={{ marginTop: '20px' }}>
                                                        <Col md={6}>
                                                            <ReactSearchAutocomplete
                                                                styling={{
                                                                    backgroundColor: '#fff',
                                                                    zIndex: 2,
                                                                    placeholder: 'Type Country',
                                                                    placeholderColor: '#939294',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ced4da',
                                                                    height: '35px',
                                                                    fontSize: 16
                                                                }}
                                                                items={this.state.venues}
                                                                placeholder={'Type venue name here'}
                                                                onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                                                onHover={() => {}}
                                                                onSelect={item => {
                                                                    let found = this.state.selectedVenues.find(a => a.venue.id === item.id);

                                                                    if (!found) {
                                                                        this.setState({ selectedVenues: [...this.state.selectedVenues, { id: '', venue: item }] });
                                                                    }
                                                                }}
                                                                onFocus={() => {}}
                                                                formatResult={formatResult}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '15px' }}>
                                                        <Col md={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead className="table-light">
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Venue name</th>

                                                                        <th>Remove</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.selectedVenues.map((item, idx) => {
                                                                        return (
                                                                            <tr key={idx}>
                                                                                <th scope="row">{idx + 1}</th>
                                                                                <td>{item.venue.name}</td>

                                                                                <td>
                                                                                    {' '}
                                                                                    <i
                                                                                        className="mdi mdi-delete font-size-18"
                                                                                        id="deletetooltip"
                                                                                        onClick={() => {
                                                                                            const reducedArr = this.state.selectedVenues.filter((item, itemIndex) => {
                                                                                                return itemIndex !== idx;
                                                                                            });

                                                                                            this.setState({
                                                                                                selectedVenues: reducedArr
                                                                                            });
                                                                                        }}
                                                                                    ></i>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <br />
                                                    <div className="text-center mt-4">
                                                        <button disabled={this.state.viewStatus} onClick={() => this.postVenues()} type="submit" className="btn btn-primary w-md">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="5">
                                        <ContentExternalLinks activeTab={this.state.activeTab.toString()} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                    </TabPane>
                                </TabContent>
                            )}
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

GalleryDetails.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    onUpdateGallery: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    onUpdateGallery: galleries => dispatch(updateGallery(galleries))
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GalleryDetails));

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </>
    );
};
