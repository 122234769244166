import { GET_CINDIVIDUALS_SUCCESS, GET_CINDIVIDUALS_FAIL, DELETE_CINDIVIDUALS_SUCCESS, DELETE_CINDIVIDUALS_FAIL, UPDATE_CINDIVIDUALS_SUCCESS } from './actionTypes';

const INIT_STATE = {
    cindividuals: [],
    error: {}
};

const cindividuals = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CINDIVIDUALS_SUCCESS:
            return {
                ...state,
                cindividuals: action.payload
            };

        case GET_CINDIVIDUALS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_CINDIVIDUALS_SUCCESS:
            return {
                ...state,
                cindividuals: state.cindividuals.filter(cindividuals => cindividuals.id.toString() !== action.payload.id.toString())
            };

        case DELETE_CINDIVIDUALS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_CINDIVIDUALS_SUCCESS:
            return {
                ...state,
                cindividuals: state.cindividuals.map(cindividual => (cindividual.id.toString() === action.payload.id.toString() ? { cindividual, ...action.payload } : cindividual))
            };

        //----others ----

        default:
            return state;
    }
};

export default cindividuals;
