import axios from 'axios';
import { resolve } from '../resolve.js';
import { API_URL } from '../../../constants/apiurl';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export async function callAllRoutes(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/exhibition/' + id + '/route/mapping', axiosconf).then(res => res.data));
}

export async function postRoute(id, postdata, types) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);

    return await resolve(axios.post(API_URL + '/tt/exhibition/' + id + '/route/mapping/v1?isDefault=false&types=' + types, postdata, axiosconf).then(res => res.data));
}

export async function updateRoute(id, postdata, types) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.put(API_URL + '/tt/exhibition/route/' + id + '/mapping/v1?types=' + types, postdata, axiosconf).then(res => res.data));
}

export async function deleteRoute(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.delete(API_URL + '/tt/exhibition/route/' + id + '/mapping/v1', axiosconf).then(res => res.data));
}

export async function postStep(routeId, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/exhibition/route/' + routeId + '/step/', postdata, axiosconf).then(res => res.data));
}

export async function postRouteOrder(id, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return axios.post(API_URL + '/tt/exhibition/' + id + '/route/mapping/order', postdata, axiosconf).then(res => res.data);
}

export function postStepOrder(routeId, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return axios.post(API_URL + '/tt/exhibition/route/' + routeId + '/step/order', postdata, axiosconf).then(res => res.data);
}

export function postPageOrder(stepId, postData) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return axios.post(API_URL + '/tt/exhibition/route/step/' + stepId + '/page/order', postData, axiosconf).then(res => res.data);
}

export async function uploadStepTumbnail(stepId, file, filename) {
    let obj = getAuthDetails();
    let axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + obj.access_token
        }
    };

    let formData = new FormData();
    formData.append('file', file, filename);

    axios.post(API_URL + '/tt/exhibition/route/step/' + stepId + '/file/?type=Thumbnail', formData, axiosConfig).then(res => res.data);
}

export async function deleteStepImage(stepId) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.delete(API_URL + '/tt/exhibition/route/step/' + stepId + '/file', axiosconf).then(res => res.data));
}

export async function deleteStep(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.delete(API_URL + '/tt/exhibition/route/step/' + id, axiosconf).then(res => res.data));
}
