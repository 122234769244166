import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, Table, Progress } from 'reactstrap';
//Import Breadcrumb
import { API_URL } from '../../../constants/apiurl';
import Loader from '../../../components/Common/Loader';
import CropEasyParticle from '../../../components/Crop/CropEasy';
import SweetAlert from 'react-bootstrap-sweetalert';

import MobilePreviewsParticle from '../../../components/MobilePreviews/MobilePreviewsParticle';
import { callUploadTypes, deleteParticleImages, uploadParticleImage, callParticleImages } from '../../../helpers/Api/particle_apis';
import { dataURLtoBlob, getPId } from '../../../constants/utils';
import CropEasy from '../../../components/Crop/CropEasy';
import MobileVideoPreviews from '../../../components/MobilePreviews/MobileVideoPreviews';
import { lengthVideo } from '../../../helpers/Api/common_apis';
import axios from 'axios';

class ImageList extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            id: '',
            file: '',
            imagePreviewUrl: '',
            show: false,
            thumbnail: '',
            uploadtypes: [],
            files: [],
            showAlert: false,
            alertMessage: '',
            showErrorAlert: false,
            alertErrorMessage: '',
            base64: '',
            filename: '',
            showPreview: true,
            setPreviewUrl: '',
            previewtype: '',
            selectedUploadtype: '',
            fielupload: '',
            vselectedUploadtype: '',
            loading: false,

            titleColour: '#dddddd',
            ftitle: '',
            vftitle: '',
            subTitle: '',
            subTitleColour: '#dddddd',
            descriptionColour: '#dddddd',
            description: '',
            vdescription: '',
            postDescriptionColor: '',
            postTitleColour: '',
            postSubTitle: '',
            postSubtitleColour: '',
            postTitle: '',
            postDescription: '',
            cropImageSet: false,
            editUpload: false,
            fileID: '',
            successFlag: false,
            showAlertWarning: false,

            //Video ----
            videoUrl: {},
            videoUrlFlag: false,
            videoThumnailPreview: '',
            videoThumnail: '',
            videoThumnailName: '',
            urlVidFlag: false,
            uploadPercentage: 0,
            authtoken: ''
        };
    }

    componentDidMount() {
        this.getUploadtype();
        this.getFiles();
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getFiles();
            this.getUploadtype();
        }
    }

    async getFiles() {
        if (this.props.particleid === undefined || this.props.particleid === '' || this.props.particleid === null) {
            return;
        } else {
            this.setState({ loading: true });
            const response = await callParticleImages(this.props.particleid);
            if (response.error) {
                console.log('error ---- ' + JSON.stringify(response));
                this.setState({ loading: false });
            } else {
                this.setState({
                    files: response.data.content,
                    loading: false
                });
            }
        }
    }

    async getUploadtype() {
        const response = await callUploadTypes();
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            console.log('upload type ----- ' + JSON.stringify(response.data));
            this.setState({
                uploadtypes: response.data,
                loading: false
            });
        }
    }

    handleGIFCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }
        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                cropImageSet: true,
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
        // this.myRef.current.scrollIntoView()
    };

    onInputChange = e => {
        if (this.state.selectedUploadtype === '') {
            this.setState({ vselectedUploadtype: 'Please select the type' });
            return;
        } else {
            if (this.state.selectedUploadtype === 'Video') {
                this.setState({ videoUrl: {}, videoUrlFlag: false, fielupload: '' });

                this.videoUpload(e);
            } else {
                const file = e.target.files[0];

                var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
                var isGIF = file.type === 'image/gif';

                if (size > 300 && !isGIF) {
                    this.setState({
                        showAlertWarning: true,
                        alertMessage: 'Please ensure image size is less than 300 KB',
                        selectedUploadtype: '',
                        vselectedUploadtype: ''
                    });
                    e.target.value = null;
                } else {
                    var filename = file.name;
                    this.setState({ filename: filename, filename: e.target.files[0].name });
                    const reader = new FileReader();

                    reader.addEventListener(
                        'load',
                        () => {
                            // setInputImg(reader.result)
                            if(isGIF) {
                                this.handleGIFCallback(reader.result);
                            } else {
                                this.setState({ base64: reader.result, show: true });
                            }
                        },
                        false
                    );

                    if (file) {
                        reader.readAsDataURL(file);
                        //this.setState({imagePreviewUrl: file})
                    }
                }
            }
        }
    };

    onInputChangeVideoThumbnai = e => {
        const file = e.target.files[0];

        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

        if (size > 10000) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure image size is less than 10000 KB'
                //selectedUploadtype: "",
                //vselectedUploadtype: "",
                //urlVidFlag: false
            });
            //e.target.value = null
            return;
        } else {
            var filename = file.name;
            this.setState({
                videoThumnailName: filename,
                vselectedUploadtype: '',
                urlVidFlag: false
            });
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64: reader.result, show: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    async uploadSubmit(e) {
        e.preventDefault();
        if (this.state.editUpload && this.state.fileID !== '') {
            this.uploadFile();
        } else {
            if (this.state.selectedUploadtype === '' || this.state.fielupload === '') {
                this.setState({ vselectedUploadtype: 'Please select the type and file to upload' });
            } else {
                if (this.state.selectedUploadtype === 'Video') {
                    if (this.state.filename === '') {
                        this.setState({
                            vselectedUploadtype: 'Video file is required!'
                        });
                    } else {
                        this.uploadFileProgress();
                    }
                } else {
                    this.uploadFile();
                }
            }
        }
    }

    async uploadFile() {
        let formData = new FormData();
        let datpayload = {
            title: this.state.ftitle,
            descriptionColour: this.state.descriptionColour,

            titleColour: this.state.titleColour,
            description: this.state.description,

            subTitle: this.state.subTitle,
            subTitleColour: this.state.subTitleColour,
            id: this.state.fileID
        };
        let json = JSON.stringify(datpayload);

        let blob = new Blob([json], {
            type: 'application/json'
        });

        if (this.state.filename !== '') {
            formData.append('file', this.state.fielupload, this.state.filename);
        }

        formData.append('payload', blob);

        this.setState({ loading: true });

        if (this.props.particleid !== '' || this.props.particleid !== undefined) {
            this.uploadFileData(this.props.particleid, formData);
        }
    }
    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };
    uploadFileProgress = () => {
        let formData = new FormData();
        let datpayload = {
            id: this.state.fileID,
            title: this.state.ftitle,
            titleColour: this.state.titleColour,
            description: this.state.description,
            descriptionColour: this.state.descriptionColour,
            subTitle: this.state.subTitle,
            subTitleColour: this.state.subTitleColour,
        };
        let json = JSON.stringify(datpayload);

        let blob = new Blob([json], {
            type: 'application/json'
        });
        formData.append('file', this.state.videoUrl, this.state.filename);
        if(this.state.videoThumnail !== '') {
            formData.append('file', this.state.videoThumnail, this.state.videoThumnailName);
        }

        formData.append('payload', blob);

        let autTok = this.getAuthDetails();
        //let eid = this.getExhibId();
        setTimeout(() => {
            this.setState({ authtoken: autTok.access_token });
            const options = {
                headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json', Authorization: 'Bearer ' + this.state.authtoken },
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    //console.log( `${loaded}kb of ${total}kb | ${percent}%` );

                    if (percent < 100) {
                        this.setState({ uploadPercentage: percent });
                    }
                }
            };
            axios
                .post(API_URL + '/tt/particle/' + this.props.particleid + '/file/v1?type=' + this.state.selectedUploadtype, formData, options)
                .then(res => {
                    this.setState({ uploadPercentage: 100 });
                    this.setState({
                        loading: false,
                        videoUrlFlag: false,
                        flashShow: false,
                        showPreview: true,
                        setPreviewUrl: '',
                        previewtype: '',
                        showAlert: true,
                        alertMessage: 'Uploaded Successfully',
                        successFlag: true,
                        selectedUploadtype: '',
                        uploadPercentage: 0
                    });
                    this.getFiles();
                })
                .catch(e => {
                    //console.log("error... "+JSON.stringify(e))
                    this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: 'Upload failed! ' + e.response.data.message, uploadPercentage: 0 });
                });
        }, 400);
    };

    async videoUpload(e) {
        const video = await lengthVideo(e.currentTarget.files[0]);
        console.log('duration.... ' + video.duration);
        if (video.duration > 1200) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure video duration is less than 20 Minutes',
                selectedUploadtype: '',
                vselectedUploadtype: '',
                videoUrlFlag: false,
                urlVidFlag: false
            });
            e.target.value = null;
            return;
        } else {
            var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            if (size > 250000) {
                this.setState({
                    showAlertWarning: true,
                    alertMessage: 'Please ensure video size is less than 250 MB.',
                    selectedUploadtype: '',
                    vselectedUploadtype: '',
                    videoUrlFlag: false,
                    urlVidFlag: false
                });
                e.target.value = null;
                return;
            } else {
                const reader = new FileReader();
                //var url = URL.createObjectURL(e.target.files[0].originFileObj);
                //console.log("url video... "+JSON.stringify(url))
                this.setState({ videoUrl: e.target.files[0], fielupload: e.target.files[0], videoUrlFlag: true, filename: e.target.files[0].name, urlVidFlag: false });
            }
        }
    }

    async uploadFileData(pid, formData) {
        const response = await uploadParticleImage(pid, this.state.selectedUploadtype, formData);
        this.setState({ base64: '', fielupload: '', selectedUploadtype: '' });
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response.error.response.data.message));
            this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: response.error.response.data.message });
        } else {
            //console.log("response data.. "+JSON.stringify(response))
            this.setState({
                loading: false,
                showPreview: true,
                cropImageSet: false,
                ftitle: '',
                description: '',
                selectedUploadtype: ''
            });

            this.setState({
                editUpload: false,
                fileID: '',
                base64: '',
                fielupload: '',
                selectedUploadtype: '',
                description: '',
                ftitle: '',
                subTitle: '',
                vftitle: '',
                filename: '',
                showAlert: true,
                alertMessage: 'Uploaded Successfully',
                successFlag: true,
                setPreviewUrl: '',
                postDescription: '',
                postSubTitle: '',
                postTitle: ''
            });
            this.getFiles();
        }
    }

    handleCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = dataURLtoBlob(childData);
        }
        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                successFlag: false,
                cropImageSet: true
            });
        }
    };

    async removeFile(id) {
        const response = await deleteParticleImages(id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response.error.message));
            this.setState({ loading: false });
        } else {
            this.setState({ showPreview: true, setPreviewUrl: '', previewtype: '', cropImageSet: false, loading: false });
            window.scrollTo({ top: 100, behavior: 'smooth' });
            this.getFiles();
        }
    }

    render() {
        return (
            <div>
                {' '}
                {this.state.showAlert ? (
                    <SweetAlert success={this.state.successFlag} title="" onConfirm={() => this.setState({ showAlert: false })}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}
                {this.state.showErrorAlert ? (
                    <SweetAlert error title="" onConfirm={() => this.setState({ showErrorAlert: false })}>
                        {this.state.alertErrorMessage}
                    </SweetAlert>
                ) : null}
                {this.state.showAlertWarning ? (
                    <SweetAlert warning title="" onConfirm={() => this.setState({ showAlertWarning: false })}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}
                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <div>
                        {this.state.show ? (
                            this.state.selectedUploadtype === 'Video' ? (
                                <CropEasy ratio={1 / 0.6} photoURL={this.state.base64} parentCallback={this.handleCallback} />
                            ) : (
                                <CropEasyParticle
                                    ratio={this.state.selectedUploadtype === 'Thumbnail' ? 1 : this.state.selectedUploadtype === 'Cover Page' ? 1 / 1.57 : 1.2 / 2.2}
                                    photoURL={this.state.base64}
                                    parentCallback={this.handleCallback}
                                    shape={this.state.selectedUploadtype === 'Thumbnail' ? 'round' : 'rect'}
                                />
                            )
                        ) : (
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <div className="text-center mt-0">
                                                <h5>Upload Images & Videos</h5>
                                            </div>
                                            <Row>
                                                <Col md={5}>
                                                    {this.state.showPreview && this.state.videoUrlFlag ? (
                                                        <MobileVideoPreviews videoUrl={this.state.videoUrl} videoThumnailPreview={this.state.videoThumnailPreview} urlVidFlag={this.state.urlVidFlag} />
                                                    ) : (
                                                        <MobilePreviewsParticle
                                                            previewtype={this.state.previewtype}
                                                            setPreviewUrl={this.state.setPreviewUrl}
                                                            cropImageSet={this.state.cropImageSet}
                                                            titleColour={this.state.titleColour}
                                                            subTitleColour={this.state.subTitleColour}
                                                            postSubtitleColour={this.state.postSubtitleColour}
                                                            postTitleColour={this.state.postTitleColour}
                                                            ftitle={this.state.ftitle}
                                                            subTitle={this.state.subTitle}
                                                            postTitle={this.state.postTitle}
                                                            postSubTitle={this.state.postSubTitle}
                                                            descriptionColour={this.state.descriptionColour}
                                                            postDescriptionColor={this.state.postDescriptionColor}
                                                            description={this.state.description}
                                                            postDescription={this.state.postDescription}
                                                            thumbnailType={'Particle'}
                                                        />
                                                    )}
                                                </Col>
                                                <Col md={7}>
                                                    <div>
                                                        {this.state.uploadPercentage > 0 && this.state.uploadPercentage < 100 ? (
                                                            <>
                                                                <br />
                                                                <br />
                                                                <div className="text-center">{this.state.uploadPercentage} %</div>
                                                                <Progress value={this.state.uploadPercentage} />
                                                            </>
                                                        ) : (
                                                            <Form className="form" onSubmit={e => this.uploadSubmit(e)}>
                                                                <Row style={{ marginTop: '30px' }}>
                                                                    <Col md={6}>
                                                                        <div className="mb-4">
                                                                            <Label htmlFor="formrow-firstname-Input">Upload Type</Label>
                                                                            <select
                                                                                disabled={this.state.viewStatus}
                                                                                value={this.state.selectedUploadtype}
                                                                                onChange={e => {
                                                                                    this.setState({
                                                                                        selectedUploadtype: e.target.value,
                                                                                        vselectedUploadtype: '',
                                                                                        videoUrl: '',
                                                                                        videoThumnail: '',
                                                                                        videoThumnailPreview: '',
                                                                                        videoUrlFlag: false
                                                                                    });
                                                                                    if (e.target.value === 'Thumbnail') {
                                                                                        this.setState({
                                                                                            ftitle: 'thumbnail',
                                                                                            titleColour: '#ffffff',
                                                                                            description: 'Thumbnail',
                                                                                            descriptionColour: '#ffffff'
                                                                                        });
                                                                                    } else {
                                                                                        this.setState({ ftitle: '', titleColour: '', description: '', descriptionColour: '' });
                                                                                    }
                                                                                }}
                                                                                className="form-select"
                                                                            >
                                                                                <option value="">Select Type</option>
                                                                                {this.state.uploadtypes.map((item, idx) => (
                                                                                    <option key={idx} value={item}>
                                                                                        {item}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            {this.state.vselectedUploadtype !== '' && <span style={{ color: 'red' }}>{this.state.vselectedUploadtype}</span>}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-4">
                                                                            <Label htmlFor="formrow-firstname-Input">Select file</Label>
                                                                            <input
                                                                                disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                name="base64"
                                                                                className="form-control"
                                                                                id="formFile"
                                                                                type="file"
                                                                                accept={this.state.selectedUploadtype === 'Video' ? '.mp4' : '.jpg,.jpeg,.png,.gif'}
                                                                                onChange={e => this.onInputChange(e)}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {this.state.selectedUploadtype === 'Video' && (
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <div className="mb-4">
                                                                                <Label htmlFor="formrow-firstname-Input">Select Video Thumbnail</Label>
                                                                                <input
                                                                                    disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                    name="videothumb"
                                                                                    className="form-control"
                                                                                    id="formFile"
                                                                                    type="file"
                                                                                    accept={'.jpg,.jpeg,.png'}
                                                                                    onChange={e => this.onInputChangeVideoThumbnai(e)}
                                                                                />
                                                                                {this.state.vselectedUploadtype !== '' && <span style={{ color: 'red' }}>{this.state.vselectedUploadtype}</span>}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    )}
                                                                {(this.state.selectedUploadtype === 'Image' || this.state.selectedUploadtype === 'Video') && (
                                                                    <Row>
                                                                        <Col md={9}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formrow-firstname-Input">Title</Label>
                                                                                <Input
                                                                                    readOnly={this.state.viewStatus}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="formrow-themename-Input"
                                                                                    placeholder="Enter short description here"
                                                                                    name="ftitle"
                                                                                    invalid={this.state.vftitle !== '' ? true : false}
                                                                                    value={this.state.ftitle}
                                                                                    onChange={e => this.setState({ ftitle: e.target.value, vftitle: '' })}
                                                                                />
                                                                                {this.state.vftitle !== '' && <span style={{ color: 'red' }}>{this.state.vftitle}</span>}
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formrow-firstname-Input">Title color</Label>

                                                                                <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                                                                    <input
                                                                                        className="form-control form-control-color mw-100"
                                                                                        type="color"
                                                                                        id="example-color-input"
                                                                                        value={this.state.titleColour}
                                                                                        onChange={e => this.setState({ titleColour: e.target.value })}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}

                                                                {( this.state.selectedUploadtype === 'Image' || this.state.selectedUploadtype === 'Video') && (
                                                                    <Row>
                                                                        <Col md={9}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formrow-firstname-Input">Sub Title</Label>
                                                                                <Input
                                                                                    readOnly={this.state.viewStatus}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="formrow-themename-Input"
                                                                                    placeholder="Enter short description here"
                                                                                    name="subTitle"
                                                                                    value={this.state.subTitle}
                                                                                    onChange={e => this.setState({ subTitle: e.target.value })}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formrow-firstname-Input">Sub Title color</Label>

                                                                                <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                                                                    <input
                                                                                        className="form-control form-control-color mw-100"
                                                                                        type="color"
                                                                                        id="example-color-input"
                                                                                        value={this.state.subTitleColour}
                                                                                        onChange={e => this.setState({ subTitleColour: e.target.value })}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                                {(this.state.selectedUploadtype === 'Image' || this.state.selectedUploadtype === 'Video') && (
                                                                    <Row>
                                                                        <Col md={9}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formrow-firstname-Input">Description</Label>
                                                                                <Input
                                                                                    readOnly={this.state.viewStatus}
                                                                                    type="textarea"
                                                                                    rows={4}
                                                                                    className="form-control"
                                                                                    id="formrow-themename-Input"
                                                                                    placeholder="Enter Theme name"
                                                                                    name="description"
                                                                                    invalid={this.state.vdescription !== '' ? true : false}
                                                                                    value={this.state.description}
                                                                                    onChange={e => this.setState({ description: e.target.value, vdescription: '' })}
                                                                                />
                                                                                {this.state.vdescription !== '' && <span style={{ color: 'red' }}>{this.state.vdescription}</span>}
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formrow-firstname-Input">Description color</Label>

                                                                                <div className="input-group colorpicker-default" title="Using format option" style={{ width: '60px' }}>
                                                                                    <input
                                                                                        className="form-control form-control-color mw-100"
                                                                                        type="color"
                                                                                        id="example-color-input"
                                                                                        value={this.state.descriptionColour}
                                                                                        onChange={e => this.setState({ descriptionColour: e.target.value })}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                                <Row>
                                                                    <Col md={4}>
                                                                        {this.state.editUpload ? (
                                                                            <button disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                                Update
                                                                            </button>
                                                                        ) : (
                                                                            <button disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                                Upload
                                                                            </button>
                                                                        )}
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {this.state.editUpload && (
                                                                            <button
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        editUpload: false,
                                                                                        fileID: '',
                                                                                        base64: '',
                                                                                        fielupload: '',
                                                                                        filename: '',
                                                                                        selectedUploadtype: '',
                                                                                        description: '',
                                                                                        ftitle: '',
                                                                                        subTitle: '',
                                                                                        showPreview: true,
                                                                                        vftitle: '',
                                                                                        setPreviewUrl: ''
                                                                                    })
                                                                                }
                                                                                className="btn btn-outline-secondary w-md"
                                                                            >
                                                                                Reset for New Upload
                                                                            </button>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )}
                                                    </div>
                                                    {this.state.files.length > 0 && (
                                                        <Card>
                                                            <CardBody>
                                                                <CardTitle className="h4">Images & Videos</CardTitle>

                                                                <div className="table-responsive">
                                                                    <Table className="table table-borderless mb-0">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Image</th>
                                                                            <th>Types</th>
                                                                            <th>Preview</th>
                                                                            <th>Remove</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {this.state.files.map((item, idx) => (
                                                                            <tr key={idx}>
                                                                                <th scope="row">{item.id}</th>
                                                                                <td style={{ width: '5%', height: '5%', textAlign: 'center' }}>
                                                                                    {' '}
                                                                                    <img
                                                                                        style={{
                                                                                            maxWidth: '70%',
                                                                                            maxHeight: '70%',
                                                                                            borderRadius: item.type === 'Thumbnail' ? '50%' : '5px',
                                                                                            display: 'block',
                                                                                            marginLeft: 'auto',
                                                                                            marginRight: 'auto'
                                                                                        }}
                                                                                        src={item.type === 'Video' ? API_URL + item.urlVideoThumbnail : API_URL + item.url}
                                                                                    />
                                                                                </td>
                                                                                <td>{item.type}</td>
                                                                                <td>
                                                                                    <button
                                                                                        disabled={this.props.viewStatus}
                                                                                        onClick={() => {
                                                                                            if (item.type === 'Video') {
                                                                                                this.setState({
                                                                                                    loading: true,
                                                                                                    videoUrl: '',
                                                                                                    videoThumnailPreview: '',
                                                                                                    videoThumnail: '',
                                                                                                    urlVidFlag: false
                                                                                                });
                                                                                                setTimeout(() => {
                                                                                                    this.setState({
                                                                                                        videoUrlFlag: true,
                                                                                                        videoUrl: API_URL + item.url,
                                                                                                        videoThumnail: API_URL + item.urlVideoThumbnail,
                                                                                                        videoThumnailPreview: API_URL + item.urlVideoThumbnail,
                                                                                                        urlVidFlag: true,
                                                                                                        selectedUploadtype: '',
                                                                                                        loading: false,
                                                                                                        editUpload: false
                                                                                                    });
                                                                                                }, 500);
                                                                                            } else {
                                                                                                this.setState({
                                                                                                    editUpload: item.type === 'Thumbnail' ? false : true,
                                                                                                    previewtype: item.type,
                                                                                                    showPreview: true,
                                                                                                    setPreviewUrl: API_URL + item.url,
                                                                                                    postTitle: item.title,
                                                                                                    postDescription: item.description,
                                                                                                    postTitleColour: item.titleColour,
                                                                                                    postDescriptionColor: item.descriptionColour,
                                                                                                    cropImageSet: true,
                                                                                                    postSubTitle: item.subTitle,
                                                                                                    postSubtitleColour: item.subTitleColour,
                                                                                                    descriptionColour: item.descriptionColour,
                                                                                                    description: item.description,
                                                                                                    subTitleColour: item.subTitleColour,
                                                                                                    subTitle: item.subTitle,
                                                                                                    titleColour: item.titleColour,
                                                                                                    ftitle: item.title,
                                                                                                    fileID: item.type === 'Thumbnail' ? '' : item.id,
                                                                                                    selectedUploadtype: item.type,
                                                                                                    vftitle: '',
                                                                                                    filename: '',
                                                                                                    fielupload: '',
                                                                                                    videoUrlFlag: false,
                                                                                                    urlVidFlag: false
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                        type="button"
                                                                                        className="btn btn-light btn-sm"
                                                                                    >
                                                                                        View
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        disabled={this.props.viewStatus}
                                                                                        onClick={() => this.removeFile(item.id)}
                                                                                        type="button"
                                                                                        className="btn btn-danger btn-sm"
                                                                                    >
                                                                                        Delete
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

ImageList.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab2: PropTypes.func,
    activeTab: PropTypes.string,
    particleid: PropTypes.string,
    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImageList));
