//import { venues } from "common/data"
import { DELETE_VENUES_SUCCESS, DELETE_VENUES_FAIL, GET_VENUES_SUCCESS, GET_VENUES_FAIL, GET_VENUES, DELETE_VENUES, UPDATE_VENUES_SUCCESS, UPDATE_VENUES } from './actionTypes';

export const getVenues = () => ({
    type: GET_VENUES
});

export const deleteVenues = venues => ({
    type: DELETE_VENUES,
    payload: venues
});

export const getVenuesSuccess = venues => ({
    type: GET_VENUES_SUCCESS,
    payload: venues
});

export const getVenuesFail = error => ({
    type: GET_VENUES_FAIL,
    payload: error
});
export const deleteVenuesSuccess = venues => ({
    type: DELETE_VENUES_SUCCESS,
    payload: venues
});

export const deleteVenuesFailed = error => ({
    type: DELETE_VENUES_FAIL,
    payload: error
});

export const updateVenue = venues => ({
    type: UPDATE_VENUES,
    payload: venues
});

export const updateVenuesSuccess = venues => ({
    type: UPDATE_VENUES_SUCCESS,
    payload: venues
});

//-----Others
