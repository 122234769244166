import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    CardImg,
    Alert,
    Table,
    Breadcrumb,
    BreadcrumbItem,
    FormGroup
} from 'reactstrap';

import axios from 'axios';
import { API_URL } from '../../constants/apiurl';
import { Editor } from '@tinymce/tinymce-react';
import Loader from '../../components/Common/Loader';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            terms: '',
            showAlertSuccess: false
        };
    }

    componentDidMount() {
        this.getAboutus();
    }

    getAboutus = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({ loading: true });

        axios.get(API_URL + '/tt/document?type=Terms And Conditions', axiosconf).then(response => {
            this.setState({
                loading: false,
                terms: response.data.content
            });
        });
    };

    submitForm(e) {
        e.preventDefault();
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({ loading: true });

        let postData = {
            type: 'Terms And Conditions',
            content:
                "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                this.state.terms.replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                '</body></html>'
        };
        axios.post(API_URL + '/tt/document', postData, axiosconf).then(response => {
            this.setState({
                loading: false,
                showAlertSuccess: true,
                successMessage: 'Updated Successfully!'
            });
            this.getAboutus();
        });
    }

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Terms and Conditions</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <CardTitle className="mb-1">Terms and Conditions</CardTitle>
                        <br />

                        {this.state.showAlertSuccess ? (
                            <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}>
                                {this.state.successMessage}
                            </SweetAlert>
                        ) : null}

                        <Card>
                            {this.state.loading ? (
                                <Loader color={'green'} loading={this.state.loading} />
                            ) : (
                                <CardBody>
                                    <Form className="form" onSubmit={e => this.submitForm(e)}>
                                        <Row>
                                            <Col md={12}>
                                                <Label htmlFor="formrow-firstname-Input">Terms and Conditions</Label>

                                                <Editor
                                                    apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                                    cloudChannel="dev"
                                                    value={this.state.terms}
                                                    init={{
                                                        height: 600,
                                                        menubar: false,
                                                        apply_source_formatting: true,
                                                        plugins: ['advlist autolink lists link anchor paste image code link'],
                                                        paste_as_text: true,
                                                        mobile: {
                                                            toolbar_drawer: 'floating'
                                                        },
                                                        file_picker_types: 'file image media',

                                                        plugins: [
                                                            'code',
                                                            'advlist',
                                                            'autolink',
                                                            'lists',
                                                            'link',
                                                            'image',
                                                            'charmap',
                                                            'preview',
                                                            'anchor',
                                                            'searchreplace',
                                                            'visualblocks',
                                                            'fullscreen',
                                                            'insertdatetime',
                                                            'media',
                                                            'table',
                                                            'help',
                                                            'wordcount'
                                                        ],
                                                        block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                                                        toolbar:
                                                            'undo redo | formatselect | fontselect | fontsizeselect | formatpainter casechange blocks | bold italic backcolor | ' +
                                                            'alignleft aligncenter alignright alignjustify | ' +
                                                            'bullist numlist checklist outdent indent | image |removeformat | a11ycheck code table help | code | link',
                                                        file_picker_callback: function (cb, value, meta) {
                                                            var input = document.createElement('input');
                                                            input.setAttribute('type', 'file');
                                                            input.setAttribute('accept', 'image/*');
                                                            input.onchange = function () {
                                                                var file = this.files[0];
                                                                var reader = new FileReader();
                                                                reader.onload = function () {
                                                                    cb(reader.result, { title: 'hola' });
                                                                };
                                                                reader.readAsDataURL(file);
                                                            };

                                                            input.click();
                                                        }
                                                    }}
                                                    onEditorChange={content => this.setState({ terms: content })}
                                                />
                                            </Col>
                                        </Row>

                                        <br />
                                        <br />
                                        <div className="text-center mt-4">
                                            <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                Save
                                            </button>
                                        </div>
                                    </Form>
                                </CardBody>
                            )}
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

Terms.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,

    history: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Terms));
