import { all, fork } from 'redux-saga/effects'
//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ProfileSaga from './auth/profile/saga';
import LayoutSaga from './layout/saga';
import contactsSaga from './contacts/saga';
import dashboardSaga from './dashboard/saga';
import allUsersSaga from './allusers/saga';
import allArtWorksSaga from './artworks/saga';
import tags from './tags/saga';
import venues from './venues/saga';
import themes from './themes/saga';
import creators from './creator/saga';
import cindividuals from './creator-individual/saga';
import ccompanies from './creator-company/saga';
import galleriesSaga from './content-galleries/saga';
import exhibitionsSaga from './exhibitions/saga';
import particlesSaga from './particles/saga';
import contentsDetailSaga from './contents/detail/saga'
import contentsListSaga from './contents/list/saga'
import linkSaga from './link/saga'

export default function* rootSaga() {
    yield all([
        fork(AccountSaga),
        fork(allArtWorksSaga),
        fork(allUsersSaga),
        fork(AuthSaga),
        fork(tags),
        fork(venues),
        fork(themes),
        fork(creators),
        fork(cindividuals),
        fork(ccompanies),
        fork(cindividuals),
        fork(contactsSaga),
        fork(contentsDetailSaga),
        fork(contentsListSaga),
        fork(dashboardSaga),
        fork(exhibitionsSaga),
        fork(galleriesSaga),
        fork(LayoutSaga),
        fork(linkSaga),
        fork(particlesSaga),
        fork(ProfileSaga),
        fork(tags),
        fork(themes),
        fork(venues),
    ])
}
