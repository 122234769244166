//import { venues } from "common/data"
import {
    DELETE_CCOMPANIES_SUCCESS,
    DELETE_CCOMPANIES_FAIL,
    GET_CCOMPANIES_SUCCESS,
    GET_CCOMPANIES_FAIL,
    GET_CCOMPANIES,
    DELETE_CCOMPANIES,
    UPDATE_CCOMPANIES_SUCCESS,
    UPDATE_CCOMPANIES
} from './actionTypes';

export const getCCompanies = () => ({
    type: GET_CCOMPANIES
});

export const deleteCCompanies = ccompanies => ({
    type: DELETE_CCOMPANIES,
    payload: ccompanies
});

export const getCCompaniesSuccess = ccompanies => ({
    type: GET_CCOMPANIES_SUCCESS,
    payload: ccompanies
});

export const getCCompaniesFail = error => ({
    type: GET_CCOMPANIES_FAIL,
    payload: error
});
export const deleteCCompaniesSuccess = ccompanies => ({
    type: DELETE_CCOMPANIES_SUCCESS,
    payload: ccompanies
});

export const deleteCCompaniesFailed = error => ({
    type: DELETE_CCOMPANIES_FAIL,
    payload: error
});

export const updateCCompanies = ccompanies => ({
    type: UPDATE_CCOMPANIES,
    payload: ccompanies
});

export const updateCCompaniesSuccess = ccompanies => ({
    type: UPDATE_CCOMPANIES_SUCCESS,
    payload: ccompanies
});

//-----Others
