export const GetContentsDetailState = ({ contents }) => contents && contents.detail
export const GetContentsDetailLoading = ({ contents }) => contents && contents.detail.loading
export const GetContentsDetailItem = ({ contents }) => contents && contents.detail.item
export const GetContentsDetailItemTimestamp = ({ contents }) => contents && contents.detail.item && contents.detail.item.timestamp && contents.detail.item.timestamp.substring(0, 10)
export const GetContentsDetailId = ({ contents }) => contents && contents.detail.item.id
export const GetContentsDetailCreators = ({ contents }) => contents && contents.detail.creators
export const GetContentsDetailFiles = ({ contents }) => contents && contents.detail.files
export const GetContentsDetailLinks = ({ contents }) => contents && contents.detail.links
export const GetContentsDetailVenues = ({ contents }) => contents && contents.detail.venues
export const GetContentsDetailActiveTab = ({ contents }) => contents && contents.detail.activeTab
export const GetContentDetailTagStatus = ({ contents }) => contents && contents.detail.tagStatus
