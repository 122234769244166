import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    NavItem,
    NavLink,
    TabPane,
    Table
} from 'reactstrap';
//Import Breadcrumb

import axios from 'axios';
import { API_URL } from '../../../constants/apiurl';
import Loader from '../../../components/Common/Loader';
import { Image } from 'react-bootstrap';
import CropEasy from '../../../components/Crop/CropEasy';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import { searchExhibitionPublished } from '../../../helpers/Api/common_apis';
import { callParticleExhibitions, postPrarticleExhibitions } from '../../../helpers/Api/particle_apis';
import { getPId } from '../../../constants/utils';

class Exhibitions extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            exhibitions: [],
            selectedExhibitions: [],
            loading: false,
            showAlert: false,
            alertMessage: '',
            successMessage: '',
            showAlertSuccess: false,
            showErrorAlert: false,
            alertErrorMessage: ''
        };
    }

    componentDidMount() {
        this.getExhibitions();
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getExhibitions();
        }
    }

    async getExhibitions() {
        if (this.props.particleid === undefined || this.props.particleid === '' || this.props.particleid === null) {
            return;
        } else {
            this.setState({
                loading: true
            });
            const response = await callParticleExhibitions(this.props.particleid);
            if (response.error) {
                console.log('error ---- ' + JSON.stringify(response));
                this.setState({ loading: false });
            } else {
                this.setState({
                    selectedExhibitions: response.data,
                    loading: false
                });
            }
        }
    }

    async postExhibitions() {
        if (this.props.particleid === undefined || this.props.particleid === '' || this.props.particleid === null) {
            return;
        } else {
            this.setState({ loading: true });
            const response = await postPrarticleExhibitions(this.props.particleid, this.state.selectedExhibitions);
            if (response.error) {
                this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: response.error.response.data.message });
                this.getExhibitions();
            } else {
                this.setState({ showAlertSuccess: true, successMessage: 'Updated Exhibitions!', selectedExhibitions: [] });
                this.getExhibitions();
            }
        }
    }

    async handleOnSearch111(string, results) {
        const response = await searchExhibitionPublished(string);

        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({
                exhibitions: response.data.content
            });
        }
    }

    render() {
        return (
            <div>
                <div className="text-center mt-0">
                    <h5>Exhibitions</h5>
                </div>

                {this.state.showErrorAlert ? (
                    <SweetAlert error title="" onConfirm={() => this.setState({ showErrorAlert: false })}>
                        {this.state.alertErrorMessage}
                    </SweetAlert>
                ) : null}
                {this.state.showAlertSuccess ? (
                    <SweetAlert success title={''} onConfirm={() => this.setState({ showAlertSuccess: false })}>
                        {this.state.successMessage}
                    </SweetAlert>
                ) : null}
                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <div>
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={6}>
                                <ReactSearchAutocomplete
                                    styling={{ backgroundColor: '#fff', zIndex: 2, placeholderColor: '#939294', borderRadius: '4px', border: '1px solid #ced4da', height: '35px', fontSize: 16 }}
                                    items={this.state.exhibitions}
                                    fuseOptions={{ keys: ['title'] }}
                                    resultStringKeyName="title"
                                    placeholder={'Type exhibition title here'}
                                    onSearch={(ss, ee) => this.handleOnSearch111(ss, ee)}
                                    onHover={() => {}}
                                    onSelect={item => {
                                        let found = this.state.selectedExhibitions.find(a => a.event.id === item.id);
                                        if (!found) {
                                            this.setState({ selectedExhibitions: [...this.state.selectedExhibitions, { id: '', event: item }] });
                                        }
                                    }}
                                    onFocus={() => {}}
                                    formatResult={formatResult}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col md={12}>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Exhibition name</th>

                                                <th>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.selectedExhibitions.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <th scope="row">{idx + 1}</th>
                                                        <td>{item.event.title}</td>

                                                        <td>
                                                            {' '}
                                                            <i
                                                                className="mdi mdi-delete font-size-18"
                                                                id="deletetooltip"
                                                                onClick={() => {
                                                                    const reducedArr = this.state.selectedExhibitions.filter((item, itemIndex) => {
                                                                        return itemIndex !== idx;
                                                                    });

                                                                    this.setState({
                                                                        selectedExhibitions: reducedArr
                                                                    });
                                                                }}
                                                            ></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <div className="text-center mt-4">
                            <button disabled={this.props.viewStatus} onClick={() => this.postExhibitions()} type="submit" className="btn btn-primary w-md">
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Exhibitions.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab3: PropTypes.func,
    particleid: PropTypes.string,

    //t: PropTypes.any,
    history: PropTypes.any,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Exhibitions));

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.title}</span>
        </>
    );
};
