import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as types from './types'
import * as selectors from './selectors'
import * as apis from '../apis'
import Swal from 'sweetalert2'
import { CreateDeepLink } from '../../link/actions'
import { isEqual } from 'lodash'
import { INITIAL_DETAIL_STATE } from './state'
import { LOCATION_CHANGE } from 'connected-react-router'


function* handleLocationChange({ payload }) {
  const { pathname } = payload.location
  const state = yield select(selectors.GetContentsDetailState)

    if(pathname.startsWith('/web/article-details/') || pathname.startsWith('/web/external-details/')){
      const id = pathname.split('/')[3]
      const mode = pathname.split('/')[4]

      yield put(actions.LoadContentTagStatus())

      return pathname.endsWith('new/post')
      ? yield put(actions.NewContentDetail())
      : yield put(actions.LoadContentDetail(id, mode))
    }

    if((!pathname.startsWith('/web/article-details/') || !pathname.startsWith('/web/external-details/')) && !isEqual(state, INITIAL_DETAIL_STATE)) 
      return yield put(actions.ResetContentDetailState())
}

function* handleCreateContentDetail({ payload }) {
  try {
    const response = yield call(apis.postContentArticle, payload)
    yield put(actions.CreateContentDetailSuccess(response.data))

  } catch (error) {
    yield put(actions.CreateContentDetailFail(error.message))
  }
}

function* handleSaveContentDetail({ payload }) {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const response = yield call(apis.putContentArticle, id, payload)
    yield put(actions.SaveContentDetailSuccess(response.data))

  } catch (error) {
    yield put(actions.SaveContentDetailFail(error.message))
  }
}

function* handleLoadContentDetail({ payload }) {
    try {
      const response = yield call(apis.getContentArticle, payload.id)
      yield put(actions.LoadContentDetailSuccess(response.data))

    } catch (error) {
      yield put(actions.LoadContentDetailFail(error.message))
    }
}

function* handleLoadTagStatus() {
  try {
    const response = yield call(apis.getContentTagStatus)
    yield put(actions.LoadContentTagStatusSuccess(response.data))

  } catch (error) {
    yield put(actions.LoadContentTagStatusFail(error.message))
  }
}


function* handleLoadContentCreators() {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const response = yield call(apis.getContentCreators, id)
    yield put(actions.LoadContentCreatorsSuccess(response.data))

  } catch (error) {
    yield put(actions.LoadContentCreatorsFail(error.message))
  }
}

function* handleLoadContentFiles() {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const response = yield call(apis.getContentFiles, id)
    yield put(actions.LoadContentFilesSuccess(response.data))

  } catch (error) {
    yield put(actions.LoadContentFilesFail(error.message))
  }
}

function* handleLoadContentLinks() {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const response = yield call(apis.getContentExternalLinks, id)
    yield put(actions.LoadContentLinksSuccess(response.data))

  } catch (error) {
    yield put(actions.LoadContentLinksFail(error.message))
  }
}

function* handleLoadContentVenues() {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const response = yield call(apis.getContentVenues, id)
    yield put(actions.LoadContentVenuesSuccess(response.data))

  } catch (error) {
    yield put(actions.LoadContentVenuesFail(error.message))
  }
}

function* handleSaveContentCreators() {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const creators = yield select(selectors.GetContentsDetailCreators)
    const response = yield call(apis.postContentCreators, id, creators)
    yield put(actions.SaveContentCreatorsSuccess(response.data))
  } catch (error) {
    yield put(actions.SaveContentCreatorsFail(error.message))
  }
}

function* handleSaveContentLinks() {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const links = yield select(selectors.GetContentsDetailLinks)
    const response = yield call(apis.postContentExternalLinks, id, links)
    yield put(actions.SaveContentLinksSuccess(response.data))
  } catch (error) {
    yield put(actions.SaveContentLinksFail(error.message))
  }
}

function* handleSaveContentFiles({ payload }) {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const response = yield call(apis.uploadContentFile, id, payload.type, payload.files)
    yield put(actions.SaveContentFilesSuccess())
  } catch (error) {
    yield put(actions.SaveContentFilesFail(error.message))
  }
}

function* handleDeleteContentFiles({ payload }) {
  try {
    const response = yield call(apis.deleteContentFile, payload)
    yield put(actions.DeleteContentFilesSuccess(payload))
  } catch (error) {
    yield put(actions.DeleteContentFilesFail(error.message))
  }
}

function* handleSaveContentVenues() {
  try {
    const id = yield select(selectors.GetContentsDetailId)
    const venues = yield select(selectors.GetContentsDetailVenues)
    const response = yield call(apis.postContentVenues, id, venues)
    yield put(actions.SaveContentVenuesSuccess(response.data))
  } catch (error) {
    yield put(actions.SaveContentVenuesFail(error.message))
  }
}

function* handleLoadContentDetailSuccess() {
  const item = yield select(selectors.GetContentsDetailItem)
  yield put(CreateDeepLink({ type: 'Content', reference: item.id, contentType: item.type}))
}


function* handleSaveContentFilesSuccess() {
  yield put(actions.LoadContentFiles())

  Swal.fire({
    icon: "success",
    text: 'Updated Successfully!',
    confirmButtonText: 'OK',
    confirmButtonColor: "#556ee6",
  })
}

function* handleSaveContentSuccess() {
  Swal.fire({
    icon: "success",
    text: 'Updated Successfully!',
    confirmButtonText: 'OK',
    confirmButtonColor: "#556ee6",
  })
}

function* handleCreateContentDetailSuccess() {
  Swal.fire({
    icon: "success",
    text: 'Created Successfully!',
    confirmButtonText: 'OK',
    confirmButtonColor: "#556ee6",
  })
}

function* handleAddContentFile() {
  Swal.fire({
    text: 'Preview the image and press upload button to upload the image.',
    confirmButtonText: 'OK',
    confirmButtonColor: "#556ee6",
  })
}

function* handleContentFail({ payload }) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
    confirmButtonColor: "#556ee6",
  })
}

export default function* contentsDetailSaga() {
    yield takeLatest(LOCATION_CHANGE, handleLocationChange)
    yield takeLatest(types.CREATE_CONTENT_DETAIL, handleCreateContentDetail)
    yield takeLatest(types.SAVE_CONTENT_DETAIL, handleSaveContentDetail)
    yield takeLatest(types.LOAD_CONTENT_DETAIL, handleLoadContentDetail)
    yield takeLatest(types.LOAD_TAG_STATUS, handleLoadTagStatus)
    yield takeLatest(types.LOAD_CONTENT_CREATORS, handleLoadContentCreators)
    yield takeLatest(types.LOAD_CONTENT_FILES, handleLoadContentFiles)
    yield takeLatest(types.LOAD_CONTENT_LINKS, handleLoadContentLinks)
    yield takeLatest(types.LOAD_CONTENT_VENUES, handleLoadContentVenues)
    yield takeLatest(types.SAVE_CONTENT_CREATORS, handleSaveContentCreators)
    yield takeLatest(types.SAVE_CONTENT_FILES, handleSaveContentFiles)
    yield takeLatest(types.DELETE_CONTENT_FILES, handleDeleteContentFiles)
    yield takeLatest(types.SAVE_CONTENT_LINKS, handleSaveContentLinks)
    yield takeLatest(types.SAVE_CONTENT_VENUES, handleSaveContentVenues)
    yield takeLatest(types.ADD_CONTENT_FILE, handleAddContentFile)
    yield takeLatest(types.CREATE_CONTENT_DETAIL_SUCCESS, handleCreateContentDetailSuccess)
    yield takeLatest(types.SAVE_CONTENT_FILES_SUCCESS, handleSaveContentFilesSuccess)
    yield takeLatest(types.SAVE_CONTENT_DETAIL_SUCCESS, handleSaveContentSuccess)
    yield takeLatest(types.SAVE_CONTENT_VENUES_SUCCESS, handleSaveContentSuccess)
    yield takeLatest(types.SAVE_CONTENT_CREATORS_SUCCESS, handleSaveContentSuccess)
    yield takeLatest(types.DELETE_CONTENT_FILES_SUCCESS, handleSaveContentSuccess)
    yield takeLatest(types.SAVE_CONTENT_LINKS_SUCCESS, handleSaveContentSuccess)
    yield takeLatest(types.LOAD_CONTENT_DETAIL_SUCCESS, handleLoadContentDetailSuccess)
    yield takeLatest(types.SAVE_CONTENT_FILES_FAIL, handleContentFail)
    yield takeLatest(types.SAVE_CONTENT_VENUES_FAIL, handleContentFail)
    yield takeLatest(types.SAVE_CONTENT_CREATORS_FAIL, handleContentFail)
    yield takeLatest(types.SAVE_CONTENT_LINKS_FAIL, handleContentFail)
    yield takeLatest(types.DELETE_CONTENT_FILES_FAIL, handleContentFail)

  }

