import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_CCOMPANIES, DELETE_CCOMPANIES, UPDATE_CCOMPANIES } from './actionTypes';

import { getCCompaniesSuccess, getCCompaniesFail, deleteCCompaniesFailed, deleteCCompaniesSuccess } from './actions';

//Include Both Helper File with needed methods
import { getCCompanies, deleteCCompanies, updateCCompaniesByID } from '../../helpers/fakebackend_helper';
import log from "eslint-plugin-react/lib/util/log";

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

function* fetchCCompanies() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getCCompanies, obj.access_token);

        yield put(getCCompaniesSuccess(response.content));
    } catch (error) {
        yield put(getCCompaniesFail(error));
    }
}
function* onDeleteCCompanies({ payload: ccompanies }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: ccompanies.id };
        const response11 = yield call(deleteCCompanies, objj);

        yield put(deleteCCompaniesSuccess(response11));
    } catch (error) {
        yield put(deleteCCompaniesFailed(error));
    }
}

function* updateCCompaniesById({ payload: ccompanies }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: ccompanies.id, ccompanies: ccompanies };

        const response11 = yield call(updateCCompaniesByID, objj);

        const response = yield call(getCCompanies, obj.access_token);
        yield put(getCCompaniesSuccess(response.content));
    } catch (error) {
        yield put(getCCompaniesFail(error));
    }
}

function* ccompaniesSaga() {
    yield takeEvery(GET_CCOMPANIES, fetchCCompanies);
    yield takeEvery(UPDATE_CCOMPANIES, updateCCompaniesById);
    yield takeEvery(DELETE_CCOMPANIES, onDeleteCCompanies);
}

export default ccompaniesSaga;
