import axios from 'axios';
import { API_URL } from '../../../../../constants/apiurl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from 'reactstrap';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class UploadType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadTypes: []
        };
    }

    componentDidMount() {
        let obj = getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .get(API_URL + '/tt/exhibition/route/step/page/upload/types', axiosconf)
            .then(response => this.setState({ uploadTypes: response.data }))
            .catch(e => {
                console.log('------GET upload types ERROR-----' + JSON.stringify(e));
            });
    }

    render() {
        return (
            <React.Fragment>
                <Label htmlFor="formrow-firstname-Input">Upload type</Label>
                <select value={this.props.value} disabled={this.props.readOnly} onChange={e => this.props.onChange(e.target.value)} className="form-select">
                    <option value="">Select upload type</option>
                    {this.state.uploadTypes.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
            </React.Fragment>
        );
    }
}

UploadType.propTypes = {
    readOnly: PropTypes.boolean,
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default UploadType;
