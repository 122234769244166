import { INITIAL_LINK_STATE } from './state'
import * as types from './types'

export const link = (state = INITIAL_LINK_STATE, action) => {
    switch (action.type) {

        case types.CREATE_DEEP_LINK:
                return {
                    ...state,
                    loading: true,
                }

        case types.CREATE_DEEP_LINK_SUCCESS:
            return {
                ...state,
                id: action.payload.id,
                previewLink: action.payload.previewLink,
                shortLink: action.payload.shortLink,
                loading: false,
            }

        case types.CREATE_DEEP_LINK_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        default:
            return state
    }
}
