import React from 'react';
import { Redirect } from 'react-router-dom';
// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
// Dashboard
import Dashboard from '../pages/Dashboard/index';
//All Users
import AllUsers from '../pages/AllUsers/AllUsers';

//Artworks list
import ArtworkList from '../pages/ArtWorks/ArtworkList';

//Artworks Details
import ArtworksDetails from '../pages/ArtWorks/ArtworkDetails';

import Tags from '../pages/Tags/tags';
import TagDetails from '../pages/Tags/tag-details';

//Venues
import Venues from '../pages/Venues/venues';
import VenuesDetails from '../pages/Venues/venues-details';

//Themes
import Themes from '../pages/Themes/themes';
import ThemesDetails from '../pages/Themes/themes-details';

import CreatorList from '../pages/Creators/creator-list';
import CreatorDetail from '../pages/Creators/creator-details';

import CreatorIndividualList from '../pages/CreatorsIndividual/individual-creator-list';
import IndividualCreatorDetail from '../pages/CreatorsIndividual/individual-creator-details';

import CompanyCreatorList from '../pages/CreatorsCompany/company-creator-list';
import CompanyCreatorDetails from '../pages/CreatorsCompany/company-creator-details';

import ArticleList from '../pages/ContentsArticles/list';
import ArticleDetails from '../pages/ContentsArticles/details';

import ExternalList from '../pages/ContentsExternals/list';
import ExternalDetails from '../pages/ContentsExternals/details';

import GalleryList from '../pages/ContentsGalleries/gallery-list';
import GalleryDetails from '../pages/ContentsGalleries/gallery-details';

import ExhibitionList from '../pages/Exhibitions/exhibition-list';
import ExhibitionDetails from '../pages/Exhibitions/exhibition-details';

import ParticleList from '../pages/Particles/particle-list';
import ParticleDetails from '../pages/Particles/particle-details';

import Privacy from '../pages/AboutParticle/Privacy';
import Aboutus from '../pages/AboutParticle/Aboutus';
import Terms from '../pages/AboutParticle/Terms';

const authProtectedRoutes = [
    //All Users
    { path: '/web/all-users-list', component: AllUsers },

    //About particle
    { path: '/web/aboutus', component: Aboutus },
    { path: '/web/privacy', component: Privacy },
    { path: '/web/terms', component: Terms },

    //Artworks list
    { path: '/web/artworks-list', component: ArtworkList },
    { path: '/web/artworks-details/:id/:idd', component: ArtworksDetails },

    //Article list
    { path: '/web/articles', component: ArticleList },
    { path: '/web/article-details/:id/:idd', component: ArticleDetails },
    ///gallery-list
    { path: '/web/gallery-list', component: GalleryList },
    { path: '/web/gallery-details/:id/:idd', component: GalleryDetails },
    //external-list
    { path: '/web/external-list', component: ExternalList },
    { path: '/web/external-details/:id/:idd', component: ExternalDetails },

    ///exhibition-list
    { path: '/web/exhibition-list', component: ExhibitionList },
    { path: '/web/exhibition-details/:id/:idd', component: ExhibitionDetails },

    //particle-list
    { path: '/web/particle-list', component: ParticleList },
    { path: '/web/particle-details/:id/:idd', component: ParticleDetails },

    //Tags list
    { path: '/web/tags', component: Tags },
    { path: '/web/tag-details/:id/:idd', component: TagDetails },

    //Venues list
    { path: '/web/venues', component: Venues },
    { path: '/web/venues-details/:id/:idd', component: VenuesDetails },

    //Themes list
    { path: '/web/themes', component: Themes },
    { path: '/web/themes-details/:id/:idd', component: ThemesDetails },

    //Creators list
    { path: '/web/creators', component: CreatorList },
    { path: '/web/creators-details/:id/:idd', component: CreatorDetail },
    { path: '/web/individual-creators', component: CreatorIndividualList },
    { path: '/web/cindividuals-details/:id/:idd', component: IndividualCreatorDetail },
    { path: '/web/company-creators', component: CompanyCreatorList },
    { path: '/web/company-creator-details/:id/:idd', component: CompanyCreatorDetails },

    //Other routes
    { path: '/web/dashboard', component: Dashboard },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: '/', exact: true, component: () => <Redirect to="/web/dashboard" /> }
];

const publicRoutes = [
    { path: '/logout', component: Logout },
    { path: '/login', component: Login }
];

export { authProtectedRoutes, publicRoutes };
