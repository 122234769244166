import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Toast,
    ToastHeader, Table
} from 'reactstrap';
//Import Breadcrumb

import { getParticles } from '../../store/particles/actions';

import classnames from 'classnames';

import Information from './components/Information';
import ImageList from './components/ImageList';

import Creators from './components/Creators';

import Artworks from './components/Artworks';

import Contents from './components/Contents';
import Exhibitions from './components/Exhibitions';
import Venues from './components/Venues';
import { callParticleInfomation, updateParticleStatus, getExternalLinks, postExternalLinks } from '../../helpers/Api/particle_apis';

import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../components/Common/Loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDeeplink } from '../../helpers/Api/common_apis';
import {dataURLtoBlob} from "../../constants/utils";
import CropEasyParticle from "../../components/Crop/CropEasy";
import {API_URL} from "../../constants/apiurl";
class ParticleDetails extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            activeTab: '1',
            loading: false,
            viewStatus: false,
            setPageStatus: '',
            newStatus: false,
            editStatus: false,
            thumbnail: '',
            link: '',
            links: [],
            showE: false,
            base64E: '',
            setPreviewUrlE: '',
            showPreviewE: false,
            selectedUploadtypeE: '',
            vselectedUploadtypeE: '',
            linktext: '',
            vlinktext: '',
            url: '',
            vurl: '',
            verror: '',
            paramId: '',
            particleid: '',
            particle_name: '',
            showSAlert: false,
            alertMessage: '',
            showWarnAlert: false,
            deeplink: '',
            copied: false
        };
        this.toggleTab = this.toggleTab.bind(this);
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        this.setState({ paramId: params.id });

        if (params.idd === 'view') {
            this.setState({ viewStatus: true, setPageStatus: 'View' });
        }
        if (params.idd === 'post') {
            this.setState({ newStatus: true, setPageStatus: 'Create' });
        }
        if (params.idd === 'edit') {
            this.setState({ setPageStatus: 'Edit' });
        }

        if (params.id !== 'new') {
            this.setState({ editStatus: true });
            this.getParticleInformation(params.id);
            this.getDeeplinkData(params.id);
        } else {
            this.setState({ editStatus: false });
        }
    }

    async getDeeplinkData(id) {
        let postdata = {
            type: 'Particle',
            reference: id,
            contentType: null
        };
        const data = await getDeeplink(postdata);
        if (data.error) console.log('error ---- ' + JSON.stringify(data));
        else {
            //console.log("------ "+JSON.stringify(data))
            this.setState({ deeplink: data.data.shortLink });
        }
    }

    async getParticleInformation(id) {
        this.setState({ loading: true });
        const response = await callParticleInfomation(id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({
                particleid: response.data.id,
                particle_name: response.data.name,
                status: response.data.status,
                loading: false
            });
        }
    }

    async updateStatus() {
        this.setState({ loading: true });
        const response = await updateParticleStatus(this.state.particleid);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response.error.response.data.message));
            this.setState({ loading: false });
            this.setState({
                showWarnAlert: true,
                alertMessage: response.error.response.data.message
            });
        } else {
            this.getParticleInformation(this.state.particleid);
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleCallbackTab1 = childDataTab1 => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.setState({
            activeTab: childDataTab1.set_tab,
            newStatus: false,
            particleid: childDataTab1.particleid,
            particle_name: childDataTab1.particle_name,
            showSAlert: childDataTab1.showSAlert,
            alertMessage: childDataTab1.alertMessage
        });
        this.getParticleInformation(childDataTab1.particleid);
    };

    //----------External Links --------------

    handleCallbackE = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = dataURLtoBlob(childData);
        }
        this.setState({ showE: false, base64E: childData, setPreviewUrlE: cdata, showPreviewE: true, verror: '' });
    };

    onInputChangeE = e => {
        const file = e.target.files[0];
        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
        if (size > 300) {
            this.setState({
                showAlert: true,
                alertMessage: 'Please ensure image size is less than 300 KB',
                selectedUploadtype: '',
                vselectedUploadtype: ''
            });
            e.target.value = null;
        } else {
            //var filename = file.name;
            //this.setState({ filename: filename, filename: e.target.files[0].name })
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64E: reader.result, showE: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
                //this.setState({imagePreviewUrl: file})
            }
        }
    };

    postLink = async () => {
        let postData = [];
        this.state.links.map(item => {
            let edata = {
                id: item.id === null ? null : item.id,
                type: item.type,
                title: item.title,
                link: item.link,
                thumbnail: item.thumbnail
            };
            postData.push(edata);
        });
        this.setState({ loading: true });
        const response = await postExternalLinks(postData, this.state.particleid);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            // console.log("response external link ------ "+JSON.stringify(response))
            this.setState({ showAlertSuccess: true, successMessage: 'Inserted Successfully!' });
            this.setState({ loading: false });
            this.getEALinks();
            //this.getCreators();
        }
    };

    async getEALinks() {
        this.setState({ loading: true });
        const response = await getExternalLinks(this.state.particleid);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            //console.log("response get link ------ " + JSON.stringify(response))
            //this.setState({ loading: false })
            this.setState({ loading: false, links: response.data });
            //this.getCreators();
        }
    }

    //-----------END External Links ---------

    render() {
        const {
            match: { params }
        } = this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Particle Details</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <CardTitle className="mb-1">Particle</CardTitle>

                        <Breadcrumb>
                            <BreadcrumbItem tag="p" href="#">
                                Particle List
                            </BreadcrumbItem>
                            <BreadcrumbItem tag="p" href="#" active>
                                {this.state.setPageStatus} Particle
                            </BreadcrumbItem>
                            {this.state.particle_name !== '' && (
                                <BreadcrumbItem tag="b" href="#">
                                    {this.state.particle_name}
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>
                        {this.state.showWarnAlert && (
                            <SweetAlert
                                title=""
                                warning
                                onConfirm={() => {
                                    this.setState({ showWarnAlert: false });
                                }}
                            >
                                {this.state.alertMessage}
                            </SweetAlert>
                        )}
                        {this.state.showSAlert && (
                            <SweetAlert
                                title=""
                                success
                                onConfirm={() => {
                                    this.setState({ showSAlert: false });
                                }}
                            >
                                {this.state.alertMessage}
                            </SweetAlert>
                        )}
                        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: '1005' }}>
                            <Toast isOpen={this.state.copied}>
                                <ToastHeader toggle={() => this.setState({ copied: !this.state.copied })}>Copied!</ToastHeader>
                            </Toast>
                        </div>
                        {this.state.particleid !== '' && (
                            <div style={{ display: 'flex', position: 'absolute', top: '150px', right: '30px' }}>
                                {params.id !== 'new' && (
                                    <Label htmlFor="formrow-firstname-Input" style={{ marginTop: '7px', marginRight: '10px' }}>
                                        Deep Link
                                    </Label>
                                )}
                                {params.id !== 'new' && (
                                    <div style={{ width: '350px' }}>
                                        <Input
                                            readOnly={true}
                                            ref={textarea => (this.textArea = textarea)}
                                            type="readonly"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder=""
                                            style={{ width: '100%' }}
                                            name="deeplink"
                                            value={this.state.deeplink}
                                        />
                                    </div>
                                )}
                                {params.id !== 'new' && (
                                    <CopyToClipboard text={this.state.deeplink} onCopy={() => this.setState({ copied: true })}>
                                        <button>
                                            <i className="dripicons-copy" />
                                        </button>
                                    </CopyToClipboard>
                                )}
                                &nbsp;
                                <div className="text-end">
                                    <div className="btn-group" role="group" style={{ alignSelf: 'flex-end' }} aria-label="Basic example">
                                        <Button
                                            style={{ width: '100px' }}
                                            onClick={() => {
                                                this.updateStatus();
                                            }}
                                            color={this.state.status === 'Draft' ? 'success' : 'danger'}
                                            className="btn-outline"
                                        >
                                            Draft
                                        </Button>
                                        <Button
                                            style={{ width: '100px' }}
                                            onClick={() => {
                                                this.updateStatus();
                                            }}
                                            color={this.state.status === 'Published' ? 'success' : 'danger'}
                                            className="btn-outline"
                                        >
                                            Published
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.loading ? (
                            <Loader color={'green'} loading={this.state.loading} />
                        ) : (
                            <Card className="mt-2">
                                <ul className="nav nav-tabs nav-tabs-custom  pt-2" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeTab === '1'
                                            })}
                                            onClick={() => {
                                                this.toggleTab('1');
                                            }}
                                        >
                                            <CardTitle className="mb-2">Information</CardTitle>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeTab === '2'
                                            })}
                                            onClick={() => {
                                                this.toggleTab('2');
                                            }}
                                        >
                                            <CardTitle className="mb-2">Images & Videos</CardTitle>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeTab === '3'
                                            })}
                                            onClick={() => {
                                                this.toggleTab('3');
                                            }}
                                        >
                                            <CardTitle className="mb-2">Creators</CardTitle>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeTab === '4'
                                            })}
                                            onClick={() => {
                                                this.toggleTab('4');
                                            }}
                                        >
                                            <CardTitle className="mb-2">Contents</CardTitle>
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeTab === '5'
                                            })}
                                            onClick={() => {
                                                this.toggleTab('5');
                                            }}
                                        >
                                            <CardTitle className="mb-2">Artworks</CardTitle>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeTab === '6'
                                            })}
                                            onClick={() => {
                                                this.toggleTab('6');
                                            }}
                                        >
                                            <CardTitle className="mb-2">Exhibitions</CardTitle>
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeTab === '7'
                                            })}
                                            onClick={() => {
                                                this.toggleTab('7');
                                            }}
                                        >
                                            <CardTitle className="mb-2">Venue</CardTitle>
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({
                                                active: this.state.activeTab === '8'
                                            })}
                                            onClick={() => {
                                                this.toggleTab('8');
                                                this.getEALinks();
                                            }}
                                        >
                                            <CardTitle className="mb-4">External Links</CardTitle>
                                        </NavLink>
                                    </NavItem>

                                    <NavItem style={{ margin: '15px' }} className="position-absolute top-0 end-0">
                                        <button
                                            onClick={() => {
                                                this.props.onGetParticle();
                                                this.props.history.push('/web/particle-list');
                                            }}
                                            type="button"
                                            className="btn btn-secondary"
                                        >
                                            <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                            Back
                                        </button>
                                    </NavItem>
                                </ul>
                                <TabContent className="p-4" activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <CardTitle className="mb-1">General Information</CardTitle>
                                        <Information
                                            parentCallbackTab1={this.handleCallbackTab1}
                                            activeTab={this.state.activeTab}
                                            paramId={this.state.paramId}
                                            viewStatus={this.state.viewStatus}
                                            editStatus={this.state.editStatus}
                                        />
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <ImageList
                                            particleid={this.state.particleid.toString()}
                                            activeTab={this.state.activeTab}
                                            viewStatus={this.state.viewStatus}
                                            editStatus={this.state.editStatus}
                                        />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Creators
                                            particleid={this.state.particleid.toString()}
                                            activeTab={this.state.activeTab}
                                            viewStatus={this.state.viewStatus}
                                            editStatus={this.state.editStatus}
                                        />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <Contents
                                            particleid={this.state.particleid.toString()}
                                            activeTab={this.state.activeTab}
                                            viewStatus={this.state.viewStatus}
                                            editStatus={this.state.editStatus}
                                        />
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <Artworks
                                            particleid={this.state.particleid.toString()}
                                            activeTab={this.state.activeTab}
                                            viewStatus={this.state.viewStatus}
                                            editStatus={this.state.editStatus}
                                        />
                                    </TabPane>
                                    <TabPane tabId="6">
                                        <Exhibitions
                                            particleid={this.state.particleid.toString()}
                                            activeTab={this.state.activeTab}
                                            viewStatus={this.state.viewStatus}
                                            editStatus={this.state.editStatus}
                                        />
                                    </TabPane>

                                    <TabPane tabId="7">
                                        <Venues particleid={this.state.particleid.toString()} activeTab={this.state.activeTab} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                    </TabPane>
                                    <TabPane tabId="8">
                                        <div>
                                            <div className="text-center mt-0">
                                                <h5>External Links</h5>
                                            </div>
                                            {this.state.showAlert ? (
                                                <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}>
                                                    {this.state.alertMessage}
                                                </SweetAlert>
                                            ) : null}

                                            {this.state.loading ? (
                                                <Loader color={'green'} loading={this.state.loading} />
                                            ) : (
                                                <div>
                                                    {this.state.showE ? (
                                                        <CropEasyParticle ratio={1} photoURL={this.state.base64E} parentCallback={this.handleCallbackE} shape={'round'} />
                                                    ) : (
                                                        <Row>
                                                            <Row style={{ marginTop: '20px' }}>
                                                                <Col md={3}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">Link Text</Label>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter Link Text"
                                                                            name="linktext"
                                                                            // invalid={this.state.vtitle !== "" ? true : false}
                                                                            value={this.state.linktext}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    linktext: e.target.value,
                                                                                    verror: ''
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">URL</Label>
                                                                        <Input
                                                                            readOnly={this.state.viewStatus}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="formrow-firstname-Input"
                                                                            placeholder="Enter URL"
                                                                            name="url"
                                                                            // invalid={this.state.vtitle !== "" ? true : false}
                                                                            value={this.state.url}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    url: e.target.value,
                                                                                    verror: ''
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={1}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">Thumbnail</Label>
                                                                        {this.state.showPreviewE ? (
                                                                            <>
                                                                                <img width={50} style={{ borderRadius: '25px' }} height={50} src={this.state.base64E} />
                                                                                <i
                                                                                    className="mdi mdi-delete font-size-14"
                                                                                    style={{ color: 'red' }}
                                                                                    id="deletetooltip"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            showE: false,
                                                                                            base64E: '',
                                                                                            showPreviewE: false,
                                                                                            verror: ''
                                                                                        });
                                                                                    }}
                                                                                ></i>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {/*<input disabled={this.state.viewStatus === true} name="base64" className="form-control" id="formFile" type="file" accept=".jpg,.jpeg,.png"
                              onChange={(e) => this.onInputChange(e)} />*/}
                                                                                <Button
                                                                                    style={{ backgroundColor: '#C0C0C0' }}
                                                                                    className="btn-rounded btn-sm"
                                                                                    onClick={() => this.fileInputRef.current.click()}
                                                                                >
                                                                                    <i
                                                                                        className="mdi mdi-plus font-size-45"
                                                                                        style={{ color: '#2E2E2E', width: '70px', height: '70px' }}
                                                                                        id="deletetooltip"
                                                                                    ></i>
                                                                                </Button>
                                                                                <input
                                                                                    onChange={e => this.onInputChangeE(e)}
                                                                                    name="base64"
                                                                                    multiple={false}
                                                                                    ref={this.fileInputRef}
                                                                                    accept=".jpg,.jpeg,.png"
                                                                                    type="file"
                                                                                    hidden
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-firstname-Input">{'\t'}</Label>
                                                                        <br />
                                                                        <Button
                                                                            style={{ marginTop: '3px', marginLeft: '8px', width: '80px' }}
                                                                            color={'primary'}
                                                                            disabled={this.state.links.length < 2 ? false : true}
                                                                            onClick={() => {
                                                                                if ((this.state.type === '' || this.state.linktext === '' || this.state.url === '', this.state.base64E === '')) {
                                                                                    this.setState({
                                                                                        verror: 'All fields are required!'
                                                                                    });
                                                                                } else {
                                                                                    const exists = this.state.links.filter(item => item.type === this.state.type).length > 0;
                                                                                    if (exists) {
                                                                                        this.setState({ showAlert: true, alertMessage: this.state.type + ' already added!' });
                                                                                        return;
                                                                                    } else {
                                                                                        this.setState({
                                                                                            links: [
                                                                                                ...this.state.links,
                                                                                                {
                                                                                                    id: null,
                                                                                                    title: this.state.linktext,
                                                                                                    link: this.state.url,
                                                                                                    thumbnail: this.state.base64E,
                                                                                                    status: ''
                                                                                                }
                                                                                            ]
                                                                                        });

                                                                                        this.setState({
                                                                                            linktext: '',
                                                                                            url: '',
                                                                                            thumbnail: '',
                                                                                            base64E: '',
                                                                                            showPreviewE: false,
                                                                                            verror: ''
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }}
                                                                            className="btn-md"
                                                                        >
                                                                            Add
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <div className="text-center mt-4">{this.state.verror !== '' && <span style={{ color: 'red' }}>{this.state.verror}</span>}</div>
                                                            </Row>
                                                            <Row style={{ marginTop: '15px' }}>
                                                                <Col md={12}>
                                                                    <div className="table-responsive">
                                                                        <Table className="table mb-0">
                                                                            <thead className="table-light">
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Link Text</th>
                                                                                <th>URL</th>
                                                                                <th>Thumbnail</th>
                                                                                <th>Remove</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {this.state.links.map((item, idx) => {
                                                                                return (
                                                                                    <tr key={idx}>
                                                                                        <th scope="row">{idx + 1}</th>
                                                                                        <td>{item.title}</td>
                                                                                        <td>{item.link}</td>
                                                                                        <td align="center">
                                                                                            <img
                                                                                                width={50}
                                                                                                style={{ borderRadius: '25px' }}
                                                                                                height={50}
                                                                                                src={item.id === null ? item.thumbnail : API_URL + item.thumbnail}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <i
                                                                                                className="mdi mdi-delete font-size-18"
                                                                                                id="deletetooltip"
                                                                                                onClick={() => {
                                                                                                    const reducedArr = this.state.links.filter((item, itemIndex) => {
                                                                                                        return itemIndex !== idx;
                                                                                                    });

                                                                                                    this.setState({
                                                                                                        links: reducedArr
                                                                                                    });
                                                                                                }}
                                                                                            ></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '20px' }}>
                                                                <Col md={2}>
                                                                    <button onClick={() => this.postLink()} disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                        Save
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </Row>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </TabPane>

                                </TabContent>
                            </Card>
                        )}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ParticleDetails.propTypes = {
    //particles: PropTypes.array,
    className: PropTypes.any,
    match: PropTypes.object,
    onGetParticle: PropTypes.func,
    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    onGetParticle: () => dispatch(getParticles())

    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ParticleDetails));
