import axios from 'axios';
import { del, get, post, postlogin, put } from './api_helper';
import * as url from './url_helper';

// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    if (user) return JSON.parse(user);
    return null;
};

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
    return axios
        .post(url.POST_FAKE_REGISTER, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299) return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = 'Sorry! the page you are looking for could not be found';
                        break;
                    case 500:
                        message = 'Sorry! something went wrong, please contact our support team';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
    return axios
        .post(url, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299) return response.data;
            throw response.data;
        })
        .catch(err => {
            var message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = 'Sorry! the page you are looking for could not be found';
                        break;
                    case 500:
                        message = 'Sorry! something went wrong, please contact our support team';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

// Login Method
const postJwtLogin = data => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
    let body = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
        body.append(key, `${value}`);
    });
    return postlogin(url.POST_LOGIN, body, axiosConfig);
};

export const postLogout = token => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/x-www-form-urlencoded',

            Authorization: 'Bearer ' + token
        }
    };

    let data = { test: 'test' };
    let body = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
        body.append(key, `${value}`);
    });
    return postlogin(url.POST_LOGOUT, body, axiosConfig);
};

export const getAllUserss = token => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        }
    };
    return get('/tt/users/all', axiosConfig);
};

// get Tags List
export const getTags = token => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        }
    };
    return get('/tt/tag', axiosConfig);
};

// get Tag
const getTagById = data => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + data.token
        }
    };
    let dat = get('/tt/tag/' + data.id, axiosConfig);

    return dat;
};

export const deleteTags = data => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + data.token
        }
    };
    return del('/tt/tag/' + data.id, axiosConfig);
};

export const updateTagbyId = data => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + data.token
        }
    };
    let postParams = {
        name: data.tag.name,
        type: data.tag.type,
        base64: data.tag.base64,
        description: data.tag.description,
        status: data.tag.status
    };
    //  console.log(data.id+" param backend "+JSON.stringify(postParams))
    return put('/tt/tag/' + data.id, postParams, axiosConfig);
};

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});
const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
// get Themes List--------------------
export const getThemes = token => {
    let axiosconf = axiosHeaderConfig(token);
    return get('/tt/theme', axiosconf);
};

export const getThemeByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    let dat = get('/tt/theme/' + data.id, axiosconf);
    return dat;
};

export const deleteThemes = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/theme/' + data.id, axiosconf);
};

export const updateThemeByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/theme/' + data.id, data.theme, axiosconf);
};

// get Venues List---------------
export const getVenues = token => {
    let axiosconf = axiosHeaderConfig(token);
    return get('/tt/venue', axiosconf);
};

export const deleteVenues = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/venue/' + data.id, axiosconf);
};

export const updateVenuesByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/venue/' + data.id, data.venues, axiosconf);
};

// END VENUES---------------------------

// get CIndividuals List

export const getCreators = token => {
    let axiosconf = axiosHeaderConfig(token);
    return get('/tt/creator?page=0&size=50', axiosconf);
};

export const updateCreatorsByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/creator/' + data.id, data.creators, axiosconf);
};

export const deleteCreators = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/creator/' + data.id, axiosconf);
};


export const getCIndividuals = token => {
    let axiosconf = axiosHeaderConfig(token);
    return get('/tt/creator?page=0&size=50&identifier=Individual', axiosconf);
};

export const deleteCIndividuals = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/creator/' + data.id, axiosconf);
};

export const updateCIndividualsByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/creator/' + data.id, data.cindividuals, axiosconf);
};

// get CCompanies List
export const getCCompanies = token => {
    let axiosconf = axiosHeaderConfig(token);
    return get('/tt/creator?page=0&size=20&identifier=Company', axiosconf);
};

export const deleteCCompanies = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/creator/' + data.id, axiosconf);
};

export const updateCCompaniesByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/creator/' + data.id, data.ccompanies, axiosconf);
};

// get Artworks List
export const getArtWorks = token => {
    let axiosconf = axiosHeaderConfig(token);
    return get('/tt/artwork?page=0&size=50', axiosconf);
};

export const updateArtworksByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/artwork/' + data.id, data.artworks, axiosconf);
};

export const deleteArtWorks = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/artwork/' + data.id, axiosconf);
};

// get Articles List
export const getArticles = token => {
    let axiosconf = axiosHeaderConfig(token);
    return get('/tt/content?page=0&size=50&type=Article', axiosconf);
};

export const updateArticlesByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/content/' + data.id, data.articles, axiosconf);
};

export const deleteArticles = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/content/' + data.id, axiosconf);
};

// get Galleries List
export const getGalleries = token => {
    let axiosconf = axiosHeaderConfig(token);
    return get('/tt/content?page=0&size=50&type=Gallery', axiosconf);
};

export const updateGalleriesByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/content/' + data.id, data.galleries, axiosconf);
};

export const deleteGalleries = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/content/' + data.id, axiosconf);
};

// get Exhibitions List
export const getExhibitions = token => {
    let axiosconf = axiosHeaderConfig(token);
    //tt/exhibition?page=0&size=20&sort=id,asc&type=Exhibition

    return get('/tt/exhibition?page=0&size=50&type=Exhibition', axiosconf);
};

export const updateExhibitionsByID = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return put('/tt/exhibition/' + data.id + '/for/' + data.exhibitions.venueid, data.exhibitions, axiosconf);
};

export const deleteExhibitions = data => {
    let axiosconf = axiosHeaderConfig(data.token);

    return del('/tt/exhibition/' + data.id, axiosconf);
};

// get Particles List
export const getParticles = token => {
    let axiosconf = axiosHeaderConfig(token);

    return get('/tt/particle?page=0&size=50&sort=id,asc', axiosconf);
};

export const deleteParticles = data => {
    let axiosconf = axiosHeaderConfig(data.token);
    return del('/tt/particle/' + data.id, axiosconf);
};

//---------------------OLD TEST CODE-----------------------
// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get contacts
//export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

export { getLoggedInUser, isUserAuthenticated, postFakeRegister, postFakeLogin, postFakeProfile, postFakeForgetPwd, postJwtRegister, postJwtLogin, postJwtForgetPwd, postJwtProfile, getTagById };
