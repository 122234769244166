import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter,k } from 'react-router-dom'
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, Breadcrumb, BreadcrumbItem, NavItem, NavLink, TabContent, TabPane, Table, Toast, ToastHeader } from 'reactstrap'
import { API_URL } from '../../constants/apiurl'
import { Editor } from '@tinymce/tinymce-react'
import Loader from '../../components/Common/Loader'
import CropEasy from '../../components/Crop/CropEasy'
import classnames from 'classnames'
import SweetAlert from 'react-bootstrap-sweetalert'
import { searchVenuePublished, searchPublishCreators } from '../../helpers/Api/common_apis'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ContentExternalLinks from './components/ContentExternalLinks'
import * as selectors from '../../store/contents/detail/selectors'
import * as actions from '../../store/contents/detail/actions'
import { GetDeepLink } from '../../store/link/selectors'

class ArticlesDetails extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            tagstatus: [],
            dstatus: '',
            varticle: '',
            vtimestamp: '',
            vauthor: '',
            vtitle: '',

            editStatus: false,
            newStatus: false,
            
            file: '',
            imagePreviewUrl: '',

            show: false,
            viewStatus: false,
            thumbnail: '',
            setPageStatus: '',

            uploadtypes: ['Thumbnail'],

            showAlert: '',
            alertMessage: '',
            base64: '',
            filename: '',
            showPreview: true,
            setPreviewUrl: '',
            previewtype: '',

            selectedUploadtype: '',
            fileupload: '',
            vselectedUploadtype: '',

            cropImageSet: false,
            loading: false,

            showAlertSuccess: false,
            successMessage: '',
            showErrorAlert: false,
            alertErrorMessage: '',
            showAlertWarning: false,
            copied: false,

            form: {
                title: '',
                author: '',
                article: '',                      
                status: '',
                timestamp: '',
                id: '',
                type: '',
            },

            inputLists: {
                venues: [],
                artworks: [],
                creators: [],
                links: [],
            },
        }
        this.editorRefDescrip = null
    }

    
    componentDidMount() {
        const { match: { params } } = this.props
        const { detail, timestamp } = this.props

        if (params.idd === 'view') 
            this.setState({ viewStatus: true, setPageStatus: 'View' })
        
        if (params.idd === 'post') 
            this.setState({ newStatus: true, setPageStatus: 'Create' })
        
        if (params.idd === 'edit') 
            this.setState({ setPageStatus: 'Edit' })
        
        if (params.id !== 'new') {
            this.setState({
                editStatus: true,
                form: {
                    title: detail.title,
                    author: detail.author,
                    article: detail.article,                   
                    status: detail.status,
                    timestamp: timestamp,
                    id: detail.id,
                }
             })

        } else {
            this.setState({ editStatus: false })
        }
    }


    toggleTab(tab, item, activeTab) {
        if(activeTab === tab)
            return

        if(!item)
            return

        switch (tab) {
            case 'venues':
                return this.props.dispatch(actions.LoadContentVenues())
            case 'creators':
                return this.props.dispatch(actions.LoadContentCreators())
            case 'links':
                return this.props.dispatch(actions.LoadContentLinks())
            case 'files':
                return this.props.dispatch(actions.LoadContentFiles())
            default:
                return this.getArticleDetails(item.id)
        }
   
    }
    
    postVenues = async () => 
        this.props.dispatch(actions.SaveContentVenues())
    
    addVenue = (venue, venues) => 
        !venues.find(a => a.venue.id === venue.id)
        && this.props.dispatch(actions.AddContentVenue(venue))

    removeVenue = (id) => 
        this.props.dispatch(actions.RemoveContentVenue(id))
    
    async handleOnSearchVenues(string, inputLists) {
        const response = await searchVenuePublished(string)

        //TODO: move error handling into apis file
        !!response.error
            ? console.log('error ---- ' + JSON.stringify(response))
            : this.setState({ inputLists: { ...inputLists, venues: response.data.content} })
        
    }
    //-----END VENUE-----

    //----CREATORS ------  
    postCreators = async () => 
        this.props.dispatch(actions.SaveContentCreators())
    
    addCreator = (creator, creators) => 
        !creators.find(a => a.creator.id === creator.id)
        && this.props.dispatch(actions.AddContentCreator(creator))

    removeCreator = (id) => 
        this.props.dispatch(actions.RemoveContentCreator(id))
    
    handleOnSearchCreators = async (string, inputLists) => {
        const response = await searchPublishCreators(string)

        //TODO: move error handling into apis file
        !!response.error
            ? console.log('error ---- ' + JSON.stringify(response))
            : this.setState({ inputLists: { ...inputLists, creators: response.data.content} })
    }
    //----END CREATORS---


    //---- LINKS -----------
    postLinks = async () => 
        this.props.dispatch(actions.SaveContentLinks())
    
    addLink = (link, links) => 
        !links.find(a => a.link.id === link.id)
        && this.props.dispatch(actions.AddContentLink(link))

    removeLink = (id) => 
        this.props.dispatch(actions.RemoveContentLink(id))
    //---- END LINKS -----------


    //---- FILES -----------
    async uploadFileSubmit() {
        this.setState({ base64: '', fileupload: '', selectedUploadtype: '' })

        let formData = new FormData()
        let datpayload = {
            title: '',
            descriptionColour: '',
            subTitleColour: '',
            description: ''
        }

        const json = JSON.stringify(datpayload);
        const blob = new Blob([json], { type: 'application/json'})
        formData.append('file', this.state.fileupload, this.state.filename);
        formData.append('payload', blob)

        this.props.dispatch(actions.SaveContentFiles(this.state.selectedUploadtype, formData))
        
        this.setState({
            showPreview: true,
            setPreviewUrl: '',
            cropImageSet: false,
            description: '',
            selectedUploadtype: ''
        })
    }

    async removeFile(id) {
       this.props.dispatch(actions.DeleteContentFiles(id))

        this.setState({
            showPreview: true,
            setPreviewUrl: '',
            previewtype: '',
            cropImageSet: false,
        })

    }
    //---- END FILES -----------

    async getArticleDetails(id) {
        this.props.dispatch(actions.LoadContentDetail(id))

        let dd = !!this.props.detail.timestamp ? new Date(this.props.detail.timestamp).toISOString().substring(0, 10) : ''

        this.setState({
            form: {
                ...this.state.form,
                timestamp: dd,
            }
        })
    }

    submitForm = (e, form, detail) => {
        e.preventDefault()

        const { match: { params } } = this.props

        window.scrollTo({ top: 0, behavior: 'smooth' })

        if(!form.title && !detail.title)
            this.setState({ vtitle: 'Title is required!' })
        
        if (!form.status && !detail.status)
            this.setState({ dstatus: 'Select Status!' })
        
        if (!form.author && !detail.author)
            this.setState({ vauthor: 'Author is required!' })
        
        if (!form.article && !detail.article)
            this.setState({ varticle: 'Article is required!' })
        
        if ((!!form.title || !!detail.title) && (!!form.status || !!detail.status) && (!!form.author || !!detail.author) && (!!form.article || !!detail.article)) {
            let data = { 
                title: form.title || detail.title,
                status: form.status || detail.status,
                author: form.author || detail.author,
                article:
                    this.editorRefDescrip.getContent({ format: 'text' }).trim().length === 0
                        ? null
                        : "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Document</title></head><body>" +
                          (form.article || detail.article).replace(/^<p><\/p>+|<p><\/p>+$/gim, "").replace(/^<p>&nbsp;<\/p>+|<p>&nbsp;<\/p>+$/gi, "").trim("\n") +
                          '</body></html>',
                timestamp: new Date(form.timestamp || detail.timestamp),
                type: 'Article',
            }
 
            params.id === 'new' && !this.props.detail.id
            ? this.createArticle(data)
            : this.updateArticle(data)
        }
    }

    createArticle = (postData) => {
        this.props.dispatch(actions.CreateContentDetail(postData))
        this.setState({ newStatus: false })
    }

    updateArticle = (putData) =>
        this.props.dispatch(actions.SaveContentDetail(putData))

    handleGIFCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }
        if (this.state.selectedUploadtype === 'Video' && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fileupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                cropImageSet: true,
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
        // this.myRef.current.scrollIntoView()
    }

    onInputChange = e => {
        if (this.state.selectedUploadtype === '') {
            this.setState({ vselectedUploadtype: 'Please select the type' });
            return;
        } else {
            const file = e.target.files[0];
            var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            var isGIF = file.type === 'image/gif';

            if (size > 300 && !isGIF) {
                this.setState({
                    showAlertWarning: true,
                    alertMessage: 'Please ensure image size is less than 300 KB',
                    selectedUploadtype: '',
                    vselectedUploadtype: ''
                });
                e.target.value = null;
            } else {
                var filename = file.name;
                this.setState({ filename: filename, filename: e.target.files[0].name });
                const reader = new FileReader();

                reader.addEventListener(
                    'load',
                    () => {
                        if(isGIF) {
                            this.handleGIFCallback(reader.result);
                        } else {
                            this.setState({base64: reader.result, show: true});
                        }
                    },
                    false
                );

                if (file) {
                    reader.readAsDataURL(file);
                    //this.setState({imagePreviewUrl: file})
                }
                /* }else{
           this.setState({ fileupload:  e.target.files[0], filename: e.target.files[0].name });
           this.setState({ setPreviewUrl: URL.createObjectURL(e.target.files[0]),  showPreview: true, })
           //this.myRef.current.scrollIntoView()
   
   
         }*/
            }
        }
    }

    uploadSubmit(e) {
        e.preventDefault();
        if (this.state.selectedUploadtype === '' || this.state.fileupload === '') {
            this.setState({ vselectedUploadtype: 'Please select the type and file to upload' });
        } else {
            this.uploadFileSubmit();
        }
    }

    handleCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData)
        }

        this.setState({
            fileupload: cdata,
            show: false,
            base64: childData,
            setPreviewUrl: childData,
            previewtype: this.state.selectedUploadtype,
            showPreview: true,
            cropImageSet: true
        })

        this.props.dispatch(actions.AddContentFile())
    };

    dataURLtoBlob = dataurl => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    render() {
        const { match: { params } } = this.props

        const { detail, venues, links, files, creators, history, loading, activeTab, deepLink, tagStatus, timestamp } = this.props
        const { form, inputLists } = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Article Details</title>
                    </MetaTags>
                    {this.state.show ? (
                        <CropEasy
                            ratio={this.state.selectedUploadtype === 'Thumbnail' ? 1 : this.state.selectedUploadtype === 'Cover Page' ? 1 / 1.57 : 1.2 / 2.2}
                            photoURL={this.state.base64}
                            parentCallback={this.handleCallback}
                        />
                    ) : (
                        <Container fluid={true}>
                            <CardTitle className="mb-1">Article</CardTitle>
                            <Breadcrumb>
                                <BreadcrumbItem tag="p" href="#">
                                    Article List
                                </BreadcrumbItem>
                                <BreadcrumbItem tag="p" href="#" active>
                                    {this.state.setPageStatus} Article
                                </BreadcrumbItem>
                                {!!detail.title && <BreadcrumbItem tag="b" href="#"> {detail.title} </BreadcrumbItem> }
                            </Breadcrumb>
                            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: '1005' }}>
                                <Toast isOpen={this.state.copied}>
                                    <ToastHeader toggle={() => this.setState({ copied: !this.state.copied })}>Copied!</ToastHeader>
                                </Toast>
                            </div>
                            {params.id !== 'new' && (
                                <div style={{ display: 'flex', position: 'absolute', top: '150px', right: '30px' }}>
                                    <Label htmlFor="formrow-firstname-Input" style={{ marginTop: '7px', marginRight: '10px' }}>
                                        Deep Link
                                    </Label>
                                    <div style={{ width: '350px' }}>
                                        <Input
                                            readOnly={true}
                                            ref={textarea => (this.textArea = textarea)}
                                            type="readonly"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder=""
                                            style={{ width: '100%' }}
                                            name="deeplink"
                                            value={deepLink}
                                        />
                                    </div>
                                    <CopyToClipboard text={deepLink} onCopy={() => this.setState({ copied: true })}>
                                        <button>
                                            <i className="dripicons-copy" />
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            )}
                            <Card>
                                <ul className="nav nav-tabs nav-tabs-custom  pt-2" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            to="#"
                                            className={classnames({ active: activeTab === 'information' })}
                                            onClick={() => { this.toggleTab('information', detail, activeTab) }}
                                        >
                                            <CardTitle className="mb-4">Information</CardTitle>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({ active: activeTab === 'files' })}
                                            onClick={() => { this.toggleTab('files', detail, activeTab) }}
                                        >
                                            <CardTitle className="mb-4">Images & Videos</CardTitle>
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({ active: activeTab === 'creators' })}
                                            onClick={() => { this.toggleTab('creators', detail, activeTab) }}
                                        >
                                            <CardTitle className="mb-4">Creator</CardTitle>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({ active: activeTab === 'venues' })}
                                            onClick={() => { this.toggleTab('venues', detail, activeTab) }}
                                        >
                                            <CardTitle className="mb-4">Venue</CardTitle>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={this.state.newStatus}
                                            to="#"
                                            className={classnames({ active: activeTab === 'links' })}
                                            onClick={() => { this.toggleTab('links', detail, activeTab) }}
                                        >
                                            <CardTitle className="mb-4">External Links</CardTitle>
                                        </NavLink>
                                    </NavItem>

                                    <NavItem style={{ margin: '15px' }} className="position-absolute top-0 end-0">
                                        <button
                                            onClick={() => {
                                                history.push('/web/articles');
                                            }}
                                            type="button"
                                            className="btn btn-secondary"
                                        >
                                            <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                                            Back
                                        </button>
                                    </NavItem>
                                </ul>
                                {loading ? (
                                    <Loader color={'green'} loading={loading} />
                                ) : (
                                    <TabContent className="p-4" activeTab={activeTab}>
                                        <TabPane tabId="information">
                                            <Row>
                                                <Col lg={12}>
                                                    <Card>
                                                        <CardBody>
                                                            <CardTitle className="mb-4">
                                                                {this.state.editStatus && (
                                                                    <span className={detail.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>
                                                                        {detail.status}
                                                                    </span>
                                                                )}
                                                            </CardTitle>

                                                            <Form className="form" onSubmit={e => this.submitForm(e, form, detail)}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="formrow-firstname-Input">Title</Label>
                                                                            <Input
                                                                                readOnly={this.state.viewStatus}
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                placeholder="Enter Title"
                                                                                name="title"
                                                                                //invalid={this.state.vtitle !== "" ? true : false}
                                                                                value={form.title || detail.title || ''}
                                                                                onChange={e => this.setState({ form: { ...form, title: e.target.value}, vtitle: '' })}
                                                                            />
                                                                            {this.state.vtitle !== '' && <span style={{ color: 'red' }}>{this.state.vtitle}</span>}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="formrow-firstname-Input">Author</Label>
                                                                            <Input
                                                                                readOnly={this.state.viewStatus}
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                placeholder="Enter Author"
                                                                                name="author"
                                                                                //invalid={this.state.vauthor !== "" ? true : false}
                                                                                value={form.author || detail.author || ''}
                                                                                onChange={e => this.setState({ form: { ...form, author: e.target.value}, vauthor: '' })}
                                                                            />
                                                                            {this.state.vauthor !== '' && <span style={{ color: 'red' }}>{this.state.vauthor}</span>}
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="formrow-firstname-Input">Status</Label>
                                                                            <select
                                                                                disabled={this.state.viewStatus}
                                                                                value={form.status || detail.status || ''}
                                                                                onChange={e => this.setState({ form: { ...form, status: e.target.value}, dstatus: '' })}
                                                                                className="form-select"
                                                                            >
                                                                                <option value="">Select Status</option>
                                                                                {tagStatus.map((item, idx) => (
                                                                                    <option key={idx} value={item}>
                                                                                        {item}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            {this.state.dstatus !== '' && <span style={{ color: 'red' }}>{this.state.dstatus}</span>}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-4">
                                                                            <Label htmlFor="formrow-firstname-Input">Date of Post</Label>
                                                                            <input
                                                                                disabled={this.state.viewStatus}
                                                                                className="form-control"
                                                                                type="date"
                                                                                //defaultValue="13:45:00"
                                                                                id="example-time-input"
                                                                                name="timestamp"
                                                                                value={form.timestamp || timestamp || ''}
                                                                                onChange={e => this.setState({ form: { ...form, timestamp: e.target.value}, vtimestamp: '' })}
                                                                            />
                                                                            {this.state.vtimestamp !== '' && <span style={{ color: 'red' }}>{this.state.vtimestamp}</span>}
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Label htmlFor="formrow-firstname-Input">Article</Label>
                                                                        <Editor
                                                                            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                                                            cloudChannel="dev"
                                                                            value={form.article || detail.article || ''}
                                                                            onInit={(evt, editor) => (this.editorRefDescrip = editor)}
                                                                            init={{
                                                                                height: 300,
                                                                                menubar: false,
                                                                                plugins: ['advlist autolink lists link anchor paste image  code  anchor'],
                                                                                paste_as_text: true,
                                                                                mobile: {
                                                                                    toolbar_drawer: 'floating'
                                                                                },
                                                                                toolbar:
                                                                                    'bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat  | code | link | anchor'
                                                                            }}
                                                                            onEditorChange={content => this.setState({ form: { ...form, article: content} })}
                                                                        />
                                                                        {this.state.varticle !== '' && <span style={{ color: 'red' }}>{this.state.varticle}</span>}
                                                                    </Col>
                                                                </Row>

                                                                <br />
                                                                <br />
                                                                <div className="text-center mt-4">
                                                                    {!!detail.title ? (
                                                                        <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                            Save
                                                                        </button>
                                                                    ) : (
                                                                        <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                            Save & Next
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </Form>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="files">
                                            {this.state.showAlert ? (
                                                <SweetAlert title="" onConfirm={() => this.setState({ showAlert: false })}>
                                                    {this.state.alertMessage}
                                                </SweetAlert>
                                            ) : null}

                                            {this.state.showErrorAlert ? (
                                                <SweetAlert error title="" onConfirm={() => this.setState({ showErrorAlert: false })}>
                                                    {this.state.alertErrorMessage}
                                                </SweetAlert>
                                            ) : null}

                                            {this.state.show ? (
                                                <CropEasy
                                                    ratio={this.state.selectedUploadtype === 'Thumbnail' ? 1 : this.state.selectedUploadtype === 'Cover Page' ? 1 / 1.57 : 1.2 / 2.2}
                                                    photoURL={this.state.base64}
                                                    parentCallback={this.handleCallback}
                                                />
                                            ) : (
                                                <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <div className="text-center mt-0">
                                                                    <h5>Upload Images & Videos</h5>
                                                                </div>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        {this.state.showPreview && (
                                                                            <div ref={this.myRef} style={{ marginTop: '-100px' }} className="device device-iphone-8 device-spacegray">
                                                                                <div className="device-frame">
                                                                                    {this.state.previewtype === 'Thumbnail' && (
                                                                                        <div style={{ marginTop: '50px' }}>
                                                                                            <div style={{ margin: '5px' }}>
                                                                                                <div style={{ width: '50%', height: '50%', textAlign: 'center' }}>
                                                                                                    <img
                                                                                                        style={{
                                                                                                            maxWidth: '100%',
                                                                                                            maxHeight: '100%',
                                                                                                            display: 'block',
                                                                                                            marginLeft: 'auto',
                                                                                                            marginRight: 'auto'
                                                                                                        }}
                                                                                                        src={this.state.setPreviewUrl}
                                                                                                    ></img>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                <div className="device-stripe"></div>
                                                                                <div className="device-header"></div>
                                                                                <div className="device-sensors"></div>
                                                                                <div className="device-btns"></div>
                                                                                <div className="device-power"></div>
                                                                            </div>
                                                                        )}
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div>
                                                                            {files.length < 1 && (
                                                                                <Form className="form" onSubmit={e => this.uploadSubmit(e)}>
                                                                                    <Row style={{ marginTop: '30px' }}>
                                                                                        <Col md={6}>
                                                                                            <div className="mb-4">
                                                                                                <Label htmlFor="formrow-firstname-Input">Upload Type</Label>
                                                                                                <select
                                                                                                    disabled={this.state.viewStatus}
                                                                                                    value={this.state.selectedUploadtype}
                                                                                                    onChange={e => this.setState({ selectedUploadtype: e.target.value, vselectedUploadtype: '' })}
                                                                                                    className="form-select"
                                                                                                >
                                                                                                    <option value="">Select Type</option>
                                                                                                    {this.state.uploadtypes.map((item, idx) => (
                                                                                                        <option key={idx} value={item}>
                                                                                                            {item}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                                {this.state.vselectedUploadtype !== '' && (
                                                                                                    <span style={{ color: 'red' }}>{this.state.vselectedUploadtype}</span>
                                                                                                )}
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md={6}>
                                                                                            <div className="mb-4">
                                                                                                <Label htmlFor="formrow-firstname-Input">Select file</Label>
                                                                                                <input
                                                                                                    disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                                    name="base64"
                                                                                                    className="form-control"
                                                                                                    id="formFile"
                                                                                                    type="file"
                                                                                                    accept=".jpg,.jpeg,.png"
                                                                                                    onChange={e => this.onInputChange(e)}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>

                                                                                    <Row>
                                                                                        <Col md={4}>
                                                                                            <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                                                Upload
                                                                                            </button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                            )}
                                                                        </div>
                                                                        {files.length > 0 && (
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <CardTitle className="h4">Images & Videos</CardTitle>

                                                                                    <div className="table-responsive">
                                                                                        <Table className="table table-borderless mb-0">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>#</th>
                                                                                                    <th>Image</th>
                                                                                                    <th>Types</th>
                                                                                                    <th>Preview</th>
                                                                                                    <th>Remove</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {files.map((item, idx) => (
                                                                                                    <tr key={idx}>
                                                                                                        <th scope="row">{item.id}</th>
                                                                                                        <td style={{ width: '5%', height: '5%', textAlign: 'center' }}>
                                                                                                            {' '}
                                                                                                            <img
                                                                                                                style={{
                                                                                                                    maxWidth: '70%',
                                                                                                                    maxHeight: '70%',
                                                                                                                    borderRadius: '5px',
                                                                                                                    display: 'block',
                                                                                                                    marginLeft: 'auto',
                                                                                                                    marginRight: 'auto'
                                                                                                                }}
                                                                                                                src={API_URL + item.url}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>{item.type}</td>
                                                                                                        <td>
                                                                                                            <button
                                                                                                                disabled={this.state.viewStatus}
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        previewtype: item.type,
                                                                                                                        showPreview: true,
                                                                                                                        setPreviewUrl: API_URL + item.url,
                                                                                                                        postTitle: item.title,
                                                                                                                        postDescription: item.description,
                                                                                                                        postSubtitleColor: item.subTitleColour,
                                                                                                                        postDescriptionColor: item.descriptionColour,
                                                                                                                        cropImageSet: false
                                                                                                                    });
                                                                                                                }}
                                                                                                                type="button"
                                                                                                                className="btn btn-light btn-sm"
                                                                                                            >
                                                                                                                View
                                                                                                            </button>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <button
                                                                                                                disabled={this.state.viewStatus}
                                                                                                                onClick={() => this.removeFile(item.id)}
                                                                                                                type="button"
                                                                                                                className="btn btn-danger btn-sm"
                                                                                                            >
                                                                                                                Delete
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}
                                        </TabPane>

                                        <TabPane tabId="creators">
                                            <div>
                                                <div className="text-center mt-0">
                                                    <h5>Creators</h5>
                                                </div>

                                                {loading ? (
                                                    <Loader color={'green'} loading={loading} />
                                                ) : (
                                                    <div>
                                                        <Row style={{ marginTop: '20px' }}>
                                                            <Col md={6}>
                                                                <ReactSearchAutocomplete
                                                                    styling={{
                                                                        backgroundColor: '#fff',
                                                                        zIndex: 2,
                                                                        placeholderColor: '#939294',
                                                                        borderRadius: '4px',
                                                                        border: '1px solid #ced4da',
                                                                        height: '35px',
                                                                        fontSize: 16
                                                                    }}
                                                                    items={inputLists.creators}
                                                                    placeholder={'Type Creator name or Company here'}
                                                                    onSearch={(ss, ee) => this.handleOnSearchCreators(ss, inputLists)}
                                                                    onHover={() => {}}
                                                                    onSelect={creator => this.addCreator(creator, creators)}
                                                                    formatResult={formatResult}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop: '15px' }}>
                                                            <Col md={12}>
                                                                <div className="table-responsive">
                                                                    <Table className="table mb-0">
                                                                        <thead className="table-light">
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Creator Name</th>
                                                                                <th>Type</th>
                                                                                <th>Remove</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {creators.map((item, idx) => {
                                                                                return (
                                                                                    <tr key={idx}>
                                                                                        <th scope="row">{idx + 1}</th>
                                                                                        <td>{item.creator.name}</td>
                                                                                        <td>
                                                                                            <Button style={{ margin: '2px' }} color={'light'} className="btn-rounded btn-sm">
                                                                                                {item.creator.identifier}
                                                                                            </Button>
                                                                                        </td>

                                                                                        <td>
                                                                                            {' '}
                                                                                            <i
                                                                                                className="mdi mdi-delete font-size-18"
                                                                                                id="deletetooltip"
                                                                                                onClick={() => this.removeCreator(item.id)}
                                                                                            ></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <br />
                                                        <div className="text-center mt-4">
                                                            <button onClick={() => this.postCreators()} disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="venues">
                                            <div>
                                                <div className="text-center mt-0">
                                                    <h5>Venues</h5>
                                                </div>
                                                {this.state.showAlert ? (
                                                    <SweetAlert warning title={''} onConfirm={() => this.setState({ showAlert: false })}>
                                                        {this.state.alertMessage}
                                                    </SweetAlert>
                                                ) : null}

                                                {loading ? (
                                                    <Loader color={'green'} loading={loading} />
                                                ) : (
                                                    <div>
                                                        <Row style={{ marginTop: '20px' }}>
                                                            <Col md={6}>
                                                                <ReactSearchAutocomplete
                                                                    styling={{
                                                                        backgroundColor: '#fff',
                                                                        zIndex: 2,
                                                                        placeholder: 'Type Country',
                                                                        placeholderColor: '#939294',
                                                                        borderRadius: '4px',
                                                                        border: '1px solid #ced4da',
                                                                        height: '35px',
                                                                        fontSize: 16
                                                                    }}
                                                                    items={inputLists.venues}
                                                                    placeholder={'Type venue name here'}
                                                                    onSearch={(ss, ee) => this.handleOnSearchVenues(ss, inputLists)}
                                                                    onHover={() => {}}
                                                                    onSelect={venue => this.addVenue(venue, venues)}
                                                                    onFocus={() => {}}
                                                                    formatResult={formatResult}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop: '15px' }}>
                                                            <Col md={12}>
                                                                <div className="table-responsive">
                                                                    <Table className="table mb-0">
                                                                        <thead className="table-light">
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Venue name</th>

                                                                                <th>Remove</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {venues.map((item, idx) => {
                                                                                return (
                                                                                    <tr key={idx}>
                                                                                        <th scope="row">{idx + 1}</th>
                                                                                        <td>{item.venue.name}</td>

                                                                                        <td>
                                                                                            {' '}
                                                                                            <i
                                                                                                className="mdi mdi-delete font-size-18"
                                                                                                id="deletetooltip"
                                                                                                onClick={() => this.removeVenue(item.id)}
                                                                                            ></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <br />
                                                        <div className="text-center mt-4">
                                                            <button disabled={this.state.viewStatus} onClick={() => this.postVenues()} type="submit" className="btn btn-primary w-md">
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="links">
                                            <ContentExternalLinks onPostLink={this.postLinks} onAddLink={this.addLink} links={links} activeTab={activeTab} viewStatus={this.state.viewStatus} editStatus={this.state.editStatus} />
                                        </TabPane>
                                    </TabContent>
                                )}
                            </Card>
                        </Container>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

ArticlesDetails.propTypes = {
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.any,
    match: PropTypes.object,
    onUpdateArticle: PropTypes.func,
    t: PropTypes.any,
    history: PropTypes.any,
    detail: PropTypes.object,
    loading: PropTypes.bool,
    venues: PropTypes.array,
    creators: PropTypes.array,
    links: PropTypes.array,
    files: PropTypes.array,
    activeTab : PropTypes.string,
    deepLink: PropTypes.string,
    tagStatus: PropTypes.array,
    timestamp: PropTypes.string,
}

const mapStateToProps = (state) => ({ 
    detail: selectors.GetContentsDetailItem(state),
    loading: selectors.GetContentsDetailLoading(state),
    venues: selectors.GetContentsDetailVenues(state),
    creators: selectors.GetContentsDetailCreators(state),
    links: selectors.GetContentsDetailLinks(state),
    files: selectors.GetContentsDetailFiles(state),
    activeTab: selectors.GetContentsDetailActiveTab(state),
    tagStatus: selectors.GetContentDetailTagStatus(state),
    deepLink: GetDeepLink(state),
    timestamp: selectors.GetContentsDetailItemTimestamp(state)
})
    
export default withRouter(connect(mapStateToProps)(ArticlesDetails))

const formatResult = item => {
    return (
        <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </>
    );
};
