import {
    DELETE_PARTICLES_SUCCESS,
    DELETE_PARTICLES_FAIL,
    GET_PARTICLES_SUCCESS,
    GET_PARTICLES_FAIL,
    GET_PARTICLES,
    DELETE_PARTICLES,
    UPDATE_PARTICLES,
    UPDATE_PARTICLES_SUCCESS,
    UPDATE_PARTICLES_FAIL
} from './actionTypes';
//---
export const getParticles = () => ({
    type: GET_PARTICLES
});
export const getParticlesSuccess = particles => ({
    type: GET_PARTICLES_SUCCESS,
    payload: particles
});

export const getParticlesFail = error => ({
    type: GET_PARTICLES_FAIL,
    payload: error
});
//-----

export const deleteParticles = particles => ({
    type: DELETE_PARTICLES,
    payload: particles
});
export const deleteParticlesSuccess = particles => ({
    type: DELETE_PARTICLES_SUCCESS,
    payload: particles
});

export const deleteParticlesFailed = error => ({
    type: DELETE_PARTICLES_FAIL,
    payload: error
});

//---

export const updateExhibition = particles => ({
    type: UPDATE_PARTICLES,
    payload: particles
});
export const updateParticlesSuccess = particles => ({
    type: UPDATE_PARTICLES_SUCCESS,
    payload: particles
});
