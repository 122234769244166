export const INITIAL_DETAIL_STATE = {
    activeTab: '',
    creators: [],
    error: {},
    files: [],
    item: {},
    links: [],
    loading: false,
    mode: '',
    showAlertSuccess: false,
    statusOptions: [],
    successMessage: '',
    tagStatus: [],
    venues: [],
}
