import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Form, Label, Progress, Row, Table } from 'reactstrap';
import axios from 'axios';
import Loader from '../../../components/Common/Loader';
import MobileVideoPreviews from '../../../components/MobilePreviews/MobileVideoPreviews';
import SweetAlert from 'react-bootstrap-sweetalert';
import CropEasy from '../../../components/Crop/CropEasy';
import { API_URL } from '../../../constants/apiurl';
import { lengthVideo } from '../../../helpers/Api/common_apis';

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class ImageList extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            id: '',

            file: '',
            imagePreviewUrl: '',

            show: false,

            thumbnail: '',

            uploadtypes: [],
            files: [],

            showAlert: false,
            alertMessage: '',
            successFlag: false,
            base64: '',
            filename: '',
            showPreview: true,
            setPreviewUrl: '',
            previewtype: '',

            selectedUploadtype: '',
            fielupload: '',
            vselectedUploadtype: '',

            loading: false,
            showErrorAlert: false,
            alertErrorMessage: '',
            showAlertWarning: false,
            //Video ----
            videoUrl: {},
            videoUrlFlag: false,
            videoThumnailPreview: '',
            videoThumnail: '',
            videoThumnailName: '',
            urlVidFlag: false,
            uploadPercentage: 0,
            authtoken: ''
        };
    }

    componentDidMount() {
        this.getUploadtype();
        this.getFiles();
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getFiles();
        }
    }

    getFiles = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        let eid = this.getExhibId();

        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            axios
                .get(API_URL + '/tt/exhibition/' + eid.id + '/file', axiosconf)
                .then(response => {
                    this.setState({
                        files: response.data.content,
                        loading: false
                    });
                })
                .catch(e => {
                    console.log('------GET FILE ERROR-----' + JSON.stringify(e));
                });
        }
    };

    getExhibId = () => {
        if (localStorage.getItem('exhibid')) {
            const obj = JSON.parse(localStorage.getItem('exhibid'));
            return obj;
        }
    };

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    getUploadtype = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);

        axios.get(API_URL + '/tt/exhibition/upload/types', axiosconf).then(response => {
            //console.log(" status ----> " + JSON.stringify(response.data))
            this.setState({
                uploadtypes: response.data,
                loading: false
            });
        });
    };

    onInputChangeVideoThumbnai = e => {
        const file = e.target.files[0];

        var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);

        if (size > 10000) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure image size is less than 10000 KB'
                //selectedUploadtype: "",
                //vselectedUploadtype: "",
                //urlVidFlag: false
            });
            //e.target.value = null
            return;
        } else {
            var filename = file.name;
            this.setState({
                videoThumnailName: filename,
                vselectedUploadtype: '',
                urlVidFlag: false
            });
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    // setInputImg(reader.result)
                    this.setState({ base64: reader.result, show: true });
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    onInputChange = e => {
        if (this.state.selectedUploadtype === '') {
            this.setState({ vselectedUploadtype: 'Please select the type' });
            return;
        } else {
            // if (this.state.selectedUploadtype === "Thumbnail") {
            // convert image file to base64 string

            if (this.state.selectedUploadtype === 'Video' || this.state.selectedUploadtype === 'Insight Video') {
                this.setState({ videoUrl: {}, videoUrlFlag: false, fielupload: '' });

                this.videoUpload(e);
            } else {
                const file = e.target.files[0];

                var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
                var isGIF = file.type == 'image/gif'
                if (size > 300 && !isGIF) {
                    this.setState({
                        showAlertWarning: true,
                        alertMessage: 'Please ensure image size is less than 300 KB',
                        selectedUploadtype: '',
                        vselectedUploadtype: ''
                    });
                    e.target.value = null;
                } else {
                    var filename = file.name;
                    this.setState({
                        filename: filename,
                        filename: e.target.files[0].name
                    });
                    const reader = new FileReader();

                    reader.addEventListener(
                        'load',
                        () => {
                            // setInputImg(reader.result)
                            if(isGIF) {
                                this.handleGIFCallback(reader.result);
                            } else {
                                this.setState({ base64: reader.result, show: true });
                            }
                        },
                        false
                    );

                    if (file) {
                        reader.readAsDataURL(file);
                        //this.setState({imagePreviewUrl: file})
                    }
                }
            }
        }
    };

    uploadSubmit(e) {
        e.preventDefault();
        if (this.state.selectedUploadtype === '' || this.state.fielupload === '') {
            this.setState({
                vselectedUploadtype: 'Please select the type and file to upload'
            });
        } else {
            if (this.state.selectedUploadtype === 'Video' || this.state.selectedUploadtype === 'Insight Video') {
                if (this.state.filename === '') {
                    this.setState({
                        vselectedUploadtype: 'Video file is required!'
                    });
                } else {
                    this.uploadFileProgress();
                }
            } else {
                this.uploadFileSubmit();
            }
        }
    }

    uploadFileSubmit = () => {
        let obj = this.getAuthDetails();
        let axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + obj.access_token
            }
        };
        this.setState({ base64: '', fielupload: '', selectedUploadtype: '' });

        let formData = new FormData();

        formData.append('file', this.state.fielupload, this.state.filename);

        this.setState({ loading: true });
        //tt/exhibition/{:exibitionId}/file?type=Image
        let eid = this.getExhibId();

        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            axios
                .post(API_URL + '/tt/exhibition/' + eid.id + '/file/?type=' + this.state.selectedUploadtype, formData, axiosConfig)
                .then(response => {
                    this.setState({
                        loading: false,
                        showPreview: true,
                        setPreviewUrl: '',
                        cropImageSet: false,

                        description: '',
                        selectedUploadtype: '',
                        showAlert: true,
                        alertMessage: 'Uploaded Successfully',
                        successFlag: true
                    });

                    this.getFiles();
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        loading: false,
                        showErrorAlert: true,
                        alertErrorMessage: e.response.data.message
                    });
                });
        }
    };

    uploadFileProgress = () => {
        let formData = new FormData();
        formData.append('file', this.state.videoUrl, this.state.filename);
        if(this.state.videoThumnail !== '') {
            formData.append('file', this.state.videoThumnail, this.state.videoThumnailName);
        }
        let autTok = this.getAuthDetails();
        let eid = this.getExhibId();
        setTimeout(() => {
            this.setState({ authtoken: autTok.access_token });
            const options = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + this.state.authtoken
                },
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    //console.log( `${loaded}kb of ${total}kb | ${percent}%` );

                    if (percent < 100) {
                        this.setState({ uploadPercentage: percent });
                    }
                }
            };
            axios
                .post(API_URL + '/tt/exhibition/' + eid.id + '/file/?type=' + this.state.selectedUploadtype, formData, options)
                .then(res => {
                    this.setState({ uploadPercentage: 100 });
                    this.setState({
                        loading: false,
                        videoUrlFlag: false,
                        flashShow: false,
                        showPreview: true,
                        setPreviewUrl: '',
                        previewtype: '',
                        showAlert: true,
                        alertMessage: 'Uploaded Successfully',
                        successFlag: true,
                        selectedUploadtype: '',
                        uploadPercentage: 0
                    });
                    this.getFiles();
                })
                .catch(e => {
                    console.log('error... ' + JSON.stringify(e));
                });
        }, 400);
    };

    async videoUpload(e) {
        const video = await lengthVideo(e.currentTarget.files[0]);
        console.log('duration.... ' + video.duration);
        if (video.duration > 1200) {
            this.setState({
                showAlertWarning: true,
                alertMessage: 'Please ensure video duration is less than 1200 seconds.',
                selectedUploadtype: '',
                vselectedUploadtype: '',
                videoUrlFlag: false,
                urlVidFlag: false
            });
            e.target.value = null;
            return;
        } else {
            var size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            if (size > 250000) {
                this.setState({
                    showAlertWarning: true,
                    alertMessage: 'Please ensure video size is less than 250 MB.',
                    selectedUploadtype: '',
                    vselectedUploadtype: '',
                    videoUrlFlag: false,
                    urlVidFlag: false
                });
                e.target.value = null;
                return;
            } else {
                const reader = new FileReader();
                //var url = URL.createObjectURL(e.target.files[0].originFileObj);
                //console.log("url video... "+JSON.stringify(url))
                this.setState({
                    videoUrl: e.target.files[0],
                    fielupload: e.target.files[0],
                    videoUrlFlag: true,
                    filename: e.target.files[0].name,
                    urlVidFlag: false
                });
            }
        }
    }

    handleCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }
        if ((this.state.selectedUploadtype === 'Video' || this.state.selectedUploadtype === 'Insight Video') && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                cropImageSet: true,
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
        // this.myRef.current.scrollIntoView()
    };

    handleGIFCallback = childData => {
        let cdata = '';
        if (childData !== '') {
            cdata = this.dataURLtoBlob(childData);
        }
        if ((this.state.selectedUploadtype === 'Video' || this.state.selectedUploadtype === 'Insight Video') && this.state.videoUrlFlag) {
            this.setState({
                videoThumnail: cdata,
                videoThumnailPreview: childData,
                show: false,
                urlVidFlag: false
            });
        } else {
            this.setState({
                fielupload: cdata,
                show: false,
                base64: childData,
                setPreviewUrl: childData,
                previewtype: this.state.selectedUploadtype,
                showPreview: true,
                showAlert: true,
                alertMessage: 'Preview the image and press upload button to upload the image.',
                cropImageSet: true,
                successFlag: false,
                videoUrlFlag: false,
                urlVidFlag: false
            });
        }
        // this.myRef.current.scrollIntoView()
    };

    removeFile = id => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        axios
            .delete(API_URL + '/tt/exhibition/file/' + id, axiosconf)
            .then(response => {
                this.setState({
                    showPreview: true,
                    setPreviewUrl: '',
                    previewtype: '',
                    cropImageSet: false
                });
                window.scrollTo({ top: 100, behavior: 'smooth' });
                this.getFiles();
            })
            .catch(e => {});
    };

    dataURLtoBlob = dataurl => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    render() {
        return (
            <div>
                {this.state.showAlertWarning ? (
                    <SweetAlert warning title="" onConfirm={() => this.setState({ showAlertWarning: false })}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}
                {this.state.showAlert ? (
                    <SweetAlert title="" success={this.state.successFlag} onConfirm={() => this.setState({ showAlert: false })}>
                        {this.state.alertMessage}
                    </SweetAlert>
                ) : null}

                {this.state.showErrorAlert ? (
                    <SweetAlert error title="" onConfirm={() => this.setState({ showErrorAlert: false })}>
                        {this.state.alertErrorMessage}
                    </SweetAlert>
                ) : null}
                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <div>
                        {this.state.show ? (
                            <CropEasy
                                ratio={
                                    this.state.selectedUploadtype === 'Thumbnail'
                                        ? 1
                                        : this.state.selectedUploadtype === 'Cover Page'
                                        ? 1 / 1.57
                                        : this.state.selectedUploadtype === 'Video' || this.state.selectedUploadtype === 'Insight Video' || this.state.selectedUploadtype === 'Insight Image'
                                        ? 1 / 0.6
                                        : 1.2 / 2.2
                                }
                                photoURL={this.state.base64}
                                parentCallback={this.handleCallback}
                            />
                        ) : (
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <div className="text-center mt-0">
                                                <h5>Upload Images & Videos</h5>
                                            </div>
                                            <Row>
                                                <Col md={6}>
                                                    {this.state.showPreview && this.state.videoUrlFlag ? (
                                                        <MobileVideoPreviews videoUrl={this.state.videoUrl} videoThumnailPreview={this.state.videoThumnailPreview} urlVidFlag={this.state.urlVidFlag} />
                                                    ) : (
                                                        <div ref={this.myRef} style={{ marginTop: '-100px' }} className="device device-iphone-8 device-spacegray">
                                                            <div className="device-frame">
                                                                {this.state.previewtype === 'Thumbnail' && (
                                                                    <div style={{ marginTop: '50px' }}>
                                                                        <div style={{ margin: '5px' }}>
                                                                            <div
                                                                                style={{
                                                                                    width: '50%',
                                                                                    height: '50%',
                                                                                    textAlign: 'center'
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    style={{
                                                                                        maxWidth: '100%',
                                                                                        maxHeight: '100%',
                                                                                        display: 'block',
                                                                                        marginLeft: 'auto',
                                                                                        marginRight: 'auto'
                                                                                    }}
                                                                                    src={this.state.setPreviewUrl}
                                                                                ></img>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {this.state.previewtype === 'Cover Page' && (
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '85%',
                                                                            backgroundImage: 'url(' + this.state.setPreviewUrl + ')',
                                                                            backgroundSize: 'cover'
                                                                        }}
                                                                    ></div>
                                                                )}

                                                                {(this.state.previewtype === 'Image' || this.state.previewtype === 'Insight Image') && (
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '99%',
                                                                            backgroundImage: 'url(' + this.state.setPreviewUrl + ')',
                                                                            backgroundSize: 'cover'
                                                                        }}
                                                                    >
                                                                        <br />
                                                                        <br />
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="device-stripe"></div>
                                                            <div className="device-header"></div>
                                                            <div className="device-sensors"></div>
                                                            <div className="device-btns"></div>
                                                            <div className="device-power"></div>
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col md={6}>
                                                    <div>
                                                        {this.state.uploadPercentage > 0 && this.state.uploadPercentage < 100 ? (
                                                            <>
                                                                <br />
                                                                <br />
                                                                <div className="text-center">{this.state.uploadPercentage} %</div>
                                                                <Progress value={this.state.uploadPercentage} />
                                                            </>
                                                        ) : (
                                                            <Form className="form" onSubmit={e => this.uploadSubmit(e)}>
                                                                <Row style={{ marginTop: '30px' }}>
                                                                    <Col md={6}>
                                                                        <div className="mb-4">
                                                                            <Label htmlFor="formrow-firstname-Input">Upload Type</Label>
                                                                            <select
                                                                                disabled={this.state.viewStatus}
                                                                                value={this.state.selectedUploadtype}
                                                                                onChange={e =>
                                                                                    this.setState({
                                                                                        selectedUploadtype: e.target.value,
                                                                                        vselectedUploadtype: '',
                                                                                        videoUrl: '',
                                                                                        videoThumnail: '',
                                                                                        videoThumnailPreview: '',
                                                                                        videoUrlFlag: false
                                                                                    })
                                                                                }
                                                                                className="form-select"
                                                                            >
                                                                                <option value="">Select Type</option>
                                                                                {this.state.uploadtypes?.map((item, idx) => (
                                                                                    <option key={idx} value={item}>
                                                                                        {item}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            {this.state.vselectedUploadtype !== '' && <span style={{ color: 'red' }}>{this.state.vselectedUploadtype}</span>}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-4">
                                                                            <Label htmlFor="formrow-firstname-Input">Select file</Label>
                                                                            <input
                                                                                disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                name="base64"
                                                                                className="form-control"
                                                                                id="formFile"
                                                                                type="file"
                                                                                accept={
                                                                                    this.state.selectedUploadtype === 'Video' || this.state.selectedUploadtype === 'Insight Video'
                                                                                        ? '.mp4'
                                                                                        : '.jpg,.jpeg,.png,.gif'
                                                                                }
                                                                                onChange={e => this.onInputChange(e)}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {(this.state.selectedUploadtype === 'Video' || this.state.selectedUploadtype === 'Insight Video') && (
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <div className="mb-4">
                                                                                <Label htmlFor="formrow-firstname-Input">Select Video Thumbnail</Label>
                                                                                <input
                                                                                    disabled={this.state.viewStatus === true || (this.state.selectedUploadtype === '' && true)}
                                                                                    name="videothumb"
                                                                                    className="form-control"
                                                                                    id="formFile"
                                                                                    type="file"
                                                                                    accept={'.jpg,.jpeg,.png,.gif'}
                                                                                    onChange={e => this.onInputChangeVideoThumbnai(e)}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <button disabled={this.state.viewStatus} type="submit" className="btn btn-primary w-md">
                                                                            Upload
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )}
                                                    </div>
                                                    {this.state.files.length > 0 && (
                                                        <Card>
                                                            <CardBody>
                                                                <CardTitle className="h4">Images & Videos</CardTitle>

                                                                <div className="table-responsive">
                                                                    <Table className="table table-borderless mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Image</th>
                                                                                <th>Types</th>
                                                                                <th>Preview</th>
                                                                                <th>Remove</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.files.map((item, idx) => (
                                                                                <tr key={idx}>
                                                                                    <th scope="row">{idx + 1}</th>
                                                                                    <td
                                                                                        style={{
                                                                                            width: '5%',
                                                                                            height: '5%',
                                                                                            textAlign: 'center'
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <img
                                                                                            style={{
                                                                                                maxWidth: '70%',
                                                                                                maxHeight: '70%',
                                                                                                borderRadius: '5px',
                                                                                                display: 'block',
                                                                                                marginLeft: 'auto',
                                                                                                marginRight: 'auto'
                                                                                            }}
                                                                                            src={
                                                                                                item.type === 'Video' || item.type === 'Insight Video'
                                                                                                    ? API_URL + item.urlVideoThumbnail
                                                                                                    : API_URL + item.url
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td>{item.type}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            disabled={this.state.viewStatus}
                                                                                            onClick={() => {
                                                                                                if (item.type === 'Video' || item.type === 'Insight Video') {
                                                                                                    this.setState({
                                                                                                        loading: true,
                                                                                                        videoUrl: '',
                                                                                                        videoThumnailPreview: '',
                                                                                                        videoThumnail: '',
                                                                                                        urlVidFlag: false
                                                                                                    });
                                                                                                    setTimeout(() => {
                                                                                                        this.setState({
                                                                                                            videoUrlFlag: true,
                                                                                                            videoUrl: API_URL + item.url,
                                                                                                            videoThumnail: API_URL + item.urlVideoThumbnail,
                                                                                                            videoThumnailPreview: API_URL + item.urlVideoThumbnail,
                                                                                                            urlVidFlag: true,
                                                                                                            selectedUploadtype: '',
                                                                                                            loading: false
                                                                                                        });
                                                                                                    }, 500);
                                                                                                } else {
                                                                                                    this.setState({
                                                                                                        previewtype: item.type,
                                                                                                        showPreview: true,
                                                                                                        setPreviewUrl: API_URL + item.url,
                                                                                                        videoUrlFlag: false,
                                                                                                        urlVidFlag: false,
                                                                                                        selectedUploadtype: ''
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                            type="button"
                                                                                            className="btn btn-light btn-sm"
                                                                                        >
                                                                                            View
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button
                                                                                            disabled={this.state.viewStatus}
                                                                                            onClick={() => this.removeFile(item.id)}
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

ImageList.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab2: PropTypes.func,
    activeTab: PropTypes.string,

    //t: PropTypes.any,
    history: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImageList));
