import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input } from 'reactstrap';

import Loader from '../../../components/Common/Loader';

import SweetAlert from 'react-bootstrap-sweetalert';

import { postParticle, updateParticle, callStatus, callSize, callParticleInfomation } from '../../../helpers/Api/particle_apis';

import { getParticles } from '../../../store/particles/actions';

class Informations extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            loading: false,
            name: '',
            vname: '',
            size: '',
            vsize: '',
            status: '',
            vstatus: '',
            showErrorAlert: false,
            alertErrorMessage: '',

            statuslist: [],
            sizelist: [],
            id: ''
        };
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {
        this.getSize();
        this.getStatus();
        const {
            match: { params }
        } = this.props;

        if (params.id !== 'new') {
            this.getParticleInformation(params.id);
        }
    }

    componentDidUpdate(previousProps) {
        //console.log(this.props.activeTab+" test........>>>>yodate "+JSON.stringify(previousProps))
        if (previousProps.activeTab !== this.props.activeTab) {
            this.getParticleInformation(this.state.id);
        }
    }

    async getSize() {
        const response = await callSize();

        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({
                sizelist: response.data
            });
        }
    }

    async getStatus() {
        const response = await callStatus();
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            //console.log("status error ---- "+JSON.stringify(response.data))
            this.setState({
                statuslist: response.data
            });
        }
    }

    async getParticleInformation(id) {
        this.setState({ loading: true });
        const response = await callParticleInfomation(id);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
            this.setState({ loading: false });
        } else {
            this.setState({
                name: response.data.name,
                id: response.data.id,
                size: response.data.size,
                status: response.data.status,
                loading: false
            });
        }
    }

    async submitForm(e) {
        e.preventDefault();

        if (this.state.name === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vname: 'This field is required!' });
        }

        if (this.state.size === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ vsize: 'This field is required!' });
        }
        /*if(this.state.status === ""){
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.setState({ vstatus: "This field is required!" })

    }*/

        if (this.state.name !== '' && this.state.size !== '') {
            this.setState({ loading: true });
            let postParams = {
                name: this.state.name,
                size: this.state.size,
                //  status: this.state.status,
                id: this.state.id
            };

            if (this.props.paramId === 'new') {
                if (this.state.id === '') {
                    const response = await postParticle(postParams);
                    if (response.error) {
                        console.log('error ---- ' + JSON.stringify(response));
                        this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: response.error.response.data.message });
                    } else {
                        this.setState({ loading: false, id: response.data.id });
                        this.getParticleInformation(response.data.id);
                        this.props.onGetParticles();
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        let passData = { set_tab: '2', new_status: false, particleid: response.data.id, particle_name: response.data.name, showSAlert: true, alertMessage: 'Created Successfully!' };
                        this.props.parentCallbackTab1(passData);
                    }
                } else {
                    const response = await updateParticle(postParams);
                    if (response.error) {
                        console.log('error ---- ' + JSON.stringify(response));
                        this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: response.error.response.data.message });
                    } else {
                        // localStorage.setItem("pid", JSON.stringify({id: response.data.id}))
                        this.setState({ loading: false });
                        this.getParticleInformation(this.state.id);
                        this.props.onGetParticles();
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        let passData = { set_tab: '2', new_status: false, particleid: response.data.id, particle_name: response.data.name, showSAlert: true, alertMessage: 'Updated Successfully!' };
                        this.props.parentCallbackTab1(passData);
                    }
                }
            } else {
                const response = await updateParticle(postParams);
                if (response.error) {
                    console.log('error ---- ' + JSON.stringify(response));
                    this.setState({ loading: false, showErrorAlert: true, alertErrorMessage: response.error.response.data.message });
                } else {
                    this.setState({ loading: false });
                    this.getParticleInformation(this.state.id);
                    this.props.onGetParticles();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    let passData = { set_tab: '2', new_status: false, particleid: response.data.id, particle_name: response.data.name, showSAlert: true, alertMessage: 'Updated Successfully!' };
                    this.props.parentCallbackTab1(passData);
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.showErrorAlert ? (
                    <SweetAlert error title="" onConfirm={() => this.setState({ showErrorAlert: false })}>
                        {this.state.alertErrorMessage}
                    </SweetAlert>
                ) : null}

                {this.state.loading ? (
                    <Loader color={'green'} loading={this.state.loading} />
                ) : (
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4">
                                {this.props.editStatus && (
                                    <span className={this.state.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{this.state.status}</span>
                                )}
                            </CardTitle>

                            <Form className="form" onSubmit={e => this.submitForm(e)}>
                                <Row>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Particle Name *</Label>
                                            <Input
                                                readOnly={this.props.viewStatus}
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter Name"
                                                name="name"
                                                invalid={this.state.vname !== '' ? true : false}
                                                value={this.state.name}
                                                onChange={e => this.setState({ name: e.target.value, vname: '' })}
                                            />
                                            {this.state.vname !== '' && <span style={{ color: 'red' }}>{this.state.vname}</span>}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Size *</Label>
                                            <select disabled={this.props.viewStatus} value={this.state.size} onChange={e => this.setState({ size: e.target.value, vsize: '' })} className="form-select">
                                                <option value="">Select Size</option>
                                                {this.state.sizelist.map((item, idx) => (
                                                    <option key={idx} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                            {this.state.vsize !== '' && <span style={{ color: 'red' }}>{this.state.vsize}</span>}
                                        </div>
                                    </Col>
                                    {/*<Col md={4}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">Status *</Label>
                            <select disabled={this.props.viewStatus} value={this.state.status}
                              onChange={(e) => this.setState({ status: e.target.value, vstatus: "" })} className="form-select">
                              <option value="">Select Status</option>
                              {this.state.statuslist.map((item, idx) => <option key={idx} value={item}>{item}</option>)}
                            </select>
                            {this.state.vstatus !== "" && <span style={{ color: 'red' }}>{this.state.vstatus}</span>}
        </div>
                        </Col>*/}
                                </Row>

                                <br />
                                <br />
                                <div className="text-center mt-4">
                                    {this.state.id === '' ? (
                                        <button disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                            Save & Next
                                        </button>
                                    ) : (
                                        <button disabled={this.props.viewStatus} type="submit" className="btn btn-primary w-md">
                                            Save
                                        </button>
                                    )}
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                )}
            </React.Fragment>
        );
    }
}

Informations.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    viewStatus: PropTypes.bool,
    editStatus: PropTypes.bool,
    parentCallbackTab1: PropTypes.func,

    //t: PropTypes.any,
    history: PropTypes.any,
    paramId: PropTypes.string,
    onGetParticles: PropTypes.func,
    activeTab: PropTypes.string
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
    // onDeleteTags: tags => dispatch(deleteTag(tags)),
    onGetParticles: () => dispatch(getParticles())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Informations));
