import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'react-drawer/lib/react-drawer.css';

import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { Link } from 'react-router-dom';

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

import megamenuImg from '../../assets/images/megamenu-img.png';

// import images
import github from '../../assets/images/brands/github.png';
import bitbucket from '../../assets/images/brands/bitbucket.png';
import dribbble from '../../assets/images/brands/dribbble.png';
import dropbox from '../../assets/images/brands/dropbox.png';
import mail_chimp from '../../assets/images/brands/mail_chimp.png';
import slack from '../../assets/images/brands/slack.png';

import logo from '../../assets/images/logo.svg';
import logoLightSvg from '../../assets/images/logo-light.svg';

//i18n
import { withTranslation } from 'react-i18next';

// Redux Store
import { toggleRightSidebar } from '../../store/actions';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            open: false,
            position: 'right'
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
    }
    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback();
    }

    /**
     * Toggles the sidebar
     */
    toggleRightbar() {
        this.props.toggleRightSidebar();
    }

    toggleFullscreen() {
        if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box d-lg-none d-md-block">
                                <Link to="/web/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="22" />
                                    </span>
                                </Link>

                                <Link to="/web/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoLightSvg} alt="" height="22" />
                                    </span>
                                </Link>
                            </div>

                            <button type="button" onClick={this.toggleMenu} className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn">
                                <i className="fa fa-fw fa-bars"></i>
                            </button>

                            {/*<form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t("Search") + "..."}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
    </form>*/}
                        </div>
                        <div className="d-flex">
                            {/*<Dropdown
                className="d-none d-lg-inline-block ms-1"
                isOpen={this.state.socialDrp}
                toggle={() => {
                  this.setState({ socialDrp: !this.state.socialDrp });
                }}
              >
                <DropdownToggle
                  className="btn header-item noti-icon"
                  tag="button"
                >
                  <i className="bx bx-customize"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={github} alt="Github" />
                          <span>Menu 1</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={bitbucket} alt="bitbucket" />
                          <span>Menu 2</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={dribbble} alt="dribbble" />
                          <span>Menu 3</span>
                        </Link>
                      </Col>
                    </Row>

                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={dropbox} alt="dropbox" />
                          <span>Menu 4</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={mail_chimp} alt="mail_chimp" />
                          <span>Menu 5</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={slack} alt="slack" />
                          <span>Menu 6</span>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </Dropdown>*/}

                            <div className="dropdown d-none d-lg-inline-block ms-1">
                                <button type="button" onClick={this.toggleFullscreen} className="btn header-item noti-icon" data-toggle="fullscreen">
                                    <i className="bx bx-fullscreen"></i>
                                </button>
                            </div>

                            <ProfileMenu />

                            {/*<div className="dropdown d-inline-block">
                <button
                  onClick={() => {
                    this.toggleRightbar();
                  }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
                </div>*/}
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

Header.propTypes = {
    t: PropTypes.any,
    toggleMenuCallback: PropTypes.any,
    showRightSidebar: PropTypes.any,
    toggleRightSidebar: PropTypes.func
};

const mapStatetoProps = state => {
    const { layoutType, showRightSidebar } = state.Layout;
    return { layoutType, showRightSidebar };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(Header));
