import { DELETE_ARTICLES_SUCCESS, DELETE_ARTICLES_FAIL, LOAD_CONTENTS_LIST_SUCCESS, LOAD_CONTENTS_LIST_FAIL, LOAD_CONTENTS_LIST, DELETE_ARTICLES } from './types'

export const LoadContentsList = (type) => ({ type: LOAD_CONTENTS_LIST, payload: type })
export const LoadContentsListSuccess = (articles) => ({ type: LOAD_CONTENTS_LIST_SUCCESS, payload: articles })
export const LoadContentsListFail = (error) => ({ type: LOAD_CONTENTS_LIST_FAIL, payload: error })

export const deleteArticles = articles => ({ type: DELETE_ARTICLES, payload: articles })
export const deleteArticlesSuccess = articles => ({ type: DELETE_ARTICLES_SUCCESS, payload: articles })
export const deleteArticlesFailed = error => ({ type: DELETE_ARTICLES_FAIL, payload: error })

