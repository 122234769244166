/* EXHIBITIONS */
export const GET_EXHIBITIONS = 'GET_EXHIBITIONS';
export const GET_EXHIBITIONS_FAIL = 'GET_EXHIBITIONS_FAIL';
export const GET_EXHIBITIONS_SUCCESS = 'GET_EXHIBITIONS_SUCCESS';

export const DELETE_EXHIBITIONS = 'DELETE_EXHIBITIONS';
export const DELETE_EXHIBITIONS_SUCCESS = 'DELETE_EXHIBITIONS_SUCCESS';
export const DELETE_EXHIBITIONS_FAIL = 'DELETE_EXHIBITIONS_FAIL';

export const UPDATE_EXHIBITIONS = 'UPDATE_EXHIBITIONS';
export const UPDATE_EXHIBITIONS_SUCCESS = 'UPDATE_EXHIBITIONS_SUCCESS';
export const UPDATE_EXHIBITIONS_FAIL = 'UPDATE_EXHIBITIONS_FAIL';
//others------
