import {
    DELETE_THEMES_SUCCESS,
    DELETE_THEMES_FAIL,
    GET_THEMES_SUCCESS,
    GET_THEMES_FAIL,
    GET_THEMES,
    DELETE_THEMES,
    GET_THEME,
    GET_THEME_FAIL,
    GET_THEME_SUCCESS,
    UPDATE_THEMES,
    UPDATE_THEMES_SUCCESS,
    UPDATE_THEMES_FAIL
} from './actionTypes';
//---
export const getThemes = () => ({
    type: GET_THEMES
});
export const getThemesSuccess = themes => ({
    type: GET_THEMES_SUCCESS,
    payload: themes
});

export const getThemesFail = error => ({
    type: GET_THEMES_FAIL,
    payload: error
});
//-----
export const getTheme = theme => ({
    type: GET_THEME,
    payload: theme
});
export const getThemeSuccess = theme => ({
    type: GET_THEME_SUCCESS,
    payload: theme
});

export const getThemeFail = error => ({
    type: GET_THEME_FAIL,
    payload: error
});

//----

export const deleteThemes = themes => ({
    type: DELETE_THEMES,
    payload: themes
});
export const deleteThemesSuccess = themes => ({
    type: DELETE_THEMES_SUCCESS,
    payload: themes
});

export const deleteThemesFailed = error => ({
    type: DELETE_THEMES_FAIL,
    payload: error
});

//---
export const updateTheme = themes => ({
    type: UPDATE_THEMES,
    payload: themes
});

export const updateThemeFailed = error => ({
    type: UPDATE_THEMES_FAIL,
    payload: error
});

export const updateThemeSuccess = themes => ({
    type: UPDATE_THEMES_SUCCESS,
    payload: themes
});
