import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { GET_CINDIVIDUALS, DELETE_CINDIVIDUALS, UPDATE_CINDIVIDUALS } from './actionTypes';

import { getCIndividualsSuccess, getCIndividualsFail, deleteCIndividualsFailed, deleteCIndividualsSuccess } from './actions';

//Include Both Helper File with needed methods
import { getCIndividuals, deleteCIndividuals, updateCIndividualsByID } from '../../helpers/fakebackend_helper';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

function* fetchCIndividuals() {
    try {
        let obj = getAuthDetails();
        const response = yield call(getCIndividuals, obj.access_token);

        yield put(getCIndividualsSuccess(response.content));
    } catch (error) {
        yield put(getCIndividualsFail(error));
    }
}
function* onDeleteCIndividuals({ payload: cindividuals }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: cindividuals.id };
        const response11 = yield call(deleteCIndividuals, objj);
        yield put(deleteCIndividualsSuccess(response11));
    } catch (error) {
        yield put(deleteCIndividualsFailed(error));
    }
}

function* updateCIndividualsById({ payload: cindividuals }) {
    try {
        let obj = getAuthDetails();
        let objj = { token: obj.access_token, id: cindividuals.id, cindividuals: cindividuals };

        const response11 = yield call(updateCIndividualsByID, objj);

        const response = yield call(getCIndividuals, obj.access_token);
        yield put(getCIndividualsSuccess(response.content));
    } catch (error) {
        yield put(getCIndividualsFail(error));
    }
}

function* cindividualsSaga() {
    yield takeEvery(GET_CINDIVIDUALS, fetchCIndividuals);
    yield takeEvery(UPDATE_CINDIVIDUALS, updateCIndividualsById);
    yield takeEvery(DELETE_CINDIVIDUALS, onDeleteCIndividuals);
}

export default cindividualsSaga;
