import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Modal, Button, ModalHeader, ModalBody, Label, CardTitle, Table } from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import axios from 'axios';
import { API_URL } from '../../constants/apiurl';
import moment from 'moment';

import ReactPaginate from 'react-paginate';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { searchUser } from '../../helpers/Api/common_apis';
import Loader from '../../components/Common/Loader';
import FileDownload from 'js-file-download';

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};

const getLoginTime = async username => {
    var uss = getAuthDetails();
    console.log('hello... ' + JSON.stringify(uss));
    var configheader = axiosHeaderConfig(uss.access_token);
    try {
        return await axios.get(API_URL + '/auth/users/session/for?username=' + username, configheader); //use data destructuring to get data from the promise object
        //return resp.data.lastAccess
    } catch (error) {
        console.log(error);
    }
};

class AllUsers extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            users: [],
            user: '',
            autocompleteUsers: [],
            modal: false,
            totalElements: '',
            totalPages: '',
            page: 0,
            id: 'id',
            seq: 'desc',
            searchFlag: false
        };
        this.handleUserClick = this.handleUserClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleUserClicks = this.handleUserClicks.bind(this);
    }

    componentDidMount() {
        this.getUsers(0, this.state.id, this.state.seq);
    }

    getUsers = (page, id, seq) => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({
            loading: true
        });
        axios
            .get(API_URL + '/tt/users/all?page=' + page + '&size=15&sort=' + id + ',' + seq, axiosconf)
            .then(response => {
                this.setState({
                    users: response.data.content,
                    loading: false,
                    totalElements: response.data.totalElements,
                    totalPages: response.data.totalPages
                });
            })
            .catch(e => {
                console.log('------GET artwork ERROR-----' + JSON.stringify(e));
            });
    };

    getUsersExport = () => {
        window.open( API_URL + '/tt/users/all/excel/export', "_blank");
    };

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    /*
  getLoginTime = (username) => {
    let obj = this.getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    
    axios.get(API_URL + '/auth/users/session/for?username='+username, axiosconf)
      .then((response) => {     
        console.log("user.... "+response.data.lastAccess)
        return response.data.lastAccess
      }).catch(e => {
        console.log("------GET artwork ERROR-----" + JSON.stringify(e))
      })
  }

  async getLoginTime(username) {
    const response = await getUserLastLoginTime(username);
    if (response.error) {
      console.log("error ---- " + JSON.stringify(response))
    } else {
      return response.data.lastAccess
    }
  }
*/
    handleUserClicks = () => {
        this.setState({ user: '' });
        this.toggle();
    };

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {}

    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    async handleOnSearch(string, results) {
        const response = await searchUser(string);
        if (response.error) {
            console.log('error ---- ' + JSON.stringify(response));
        } else {
            this.setState({ autocompleteUsers: response.data.content, searchFlag: true });
        }
    }

    handleUserClick = arg => {
        const user = arg;

        this.setState({
            user: {
                id: user.id,
                firstName: user.user.firstName,
                lastName: user.user.lastName,
                email: user.user.email,
                mobile: user.mobile,
                createdBy: user.user.createdBy
            }
        });

        this.toggle();
    };

    render() {
        const user = this.state.user;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>User List | Particle Dashboard</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <CardTitle className="mb-3">User List | Count(s) - {this.state.totalElements}</CardTitle>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <React.Fragment>
                                            <Row className="mb-2">
                                                <Col sm="4">
                                                    <ReactSearchAutocomplete
                                                        styling={{
                                                            backgroundColor: '#fff',
                                                            zIndex: 2,
                                                            placeholderColor: '#939294',
                                                            borderRadius: '4px',
                                                            border: '1px solid #ced4da',
                                                            height: '35px',
                                                            fontSize: 16
                                                        }}
                                                        //items={this.state.autocompleteVenues}
                                                        fuseOptions={{ keys: ['firstName', 'lastName'] }}
                                                        resultStringKeyName="firstName"
                                                        placeholder={'Search'}
                                                        onSearch={(ss, ee) => this.handleOnSearch(ss, ee)}
                                                        onHover={() => {}}
                                                        showClear={true}
                                                        onClear={() => {
                                                            setTimeout(() => {
                                                                this.setState({ searchFlag: false });
                                                            }, 500);
                                                        }}
                                                        onSelect={item => {
                                                            this.setState({ searchFlag: true, user: item });
                                                        }}
                                                        onFocus={() => {}}
                                                        //  formatResult={formatResult}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                    {this.state.searchFlag && (
                                                        <Button
                                                            color="primary"
                                                            style={{ marginTop: '3px' }}
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => {
                                                                this.setState({ searchFlag: false });
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col sm="2">
                                                        <Button
                                                            color="primary"
                                                            style={{ marginTop: '3px' }}
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => {
                                                                this.getUsersExport()
                                                            }}
                                                        >
                                                            Export

                                                        </Button>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col xl="12">
                                                    <div className="table-responsive">
                                                        <Table className="table mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th
                                                                        onClick={() => {
                                                                            this.setState({ id: 'id', seq: this.state.seq === 'asc' ? 'desc' : 'asc' });
                                                                            setTimeout(() => {
                                                                                this.getUsers(this.state.page, this.state.id, this.state.seq);
                                                                            }, 500);
                                                                        }}
                                                                    >
                                                                        ID
                                                                        {this.state.seq === 'asc' ? (
                                                                            <span className="react-bootstrap-table-sort-order">
                                                                                <span className="caret"></span>
                                                                            </span>
                                                                        ) : (
                                                                            <span className="react-bootstrap-table-sort-order dropup">
                                                                                <span className="caret"></span>
                                                                            </span>
                                                                        )}
                                                                    </th>
                                                                    <th>Name</th>
                                                                    <th>Email</th>
                                                                    <th>Registration Date</th>
                                                                    <th>Last Login</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            {this.state.loading ? (
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={'6'} align="center">
                                                                            <Loader color={'green'} loading={this.state.loading} />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ) : (
                                                                <tbody>
                                                                    {this.state.searchFlag
                                                                        ? this.state.autocompleteUsers.map((user, idx) => (
                                                                              <tr key={idx}>
                                                                                  <th scope="row">{user.id}</th>
                                                                                  <td>
                                                                                      {user.user['firstName']}
                                                                                      <br />
                                                                                      <span style={{ fontSize: '11px' }}>
                                                                                          Mobile: {user?.mobile?.countryCode}-{user?.mobile?.mobileNumber}
                                                                                      </span>
                                                                                  </td>

                                                                                  <td>{user.user.email}</td>
                                                                                  <td>{moment(user.user['dateCreated']).format('DD MMM YYYY')}</td>
                                                                                  <td>{moment(user.user['lastUpdated']).format('DD MMM YYYY')}</td>
                                                                                  <td>
                                                                                      <div className="d-flex gap-3">
                                                                                          &nbsp; &nbsp;
                                                                                          <Link className="text-success" to="#">
                                                                                              <i className="mdi mdi-eye font-size-18" id="edittooltip" onClick={() => this.handleUserClick(user)}></i>
                                                                                          </Link>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          ))
                                                                        : this.state.users?.map((user, idx) => (
                                                                              <tr key={idx}>
                                                                                  <th scope="row">{user.id}</th>
                                                                                  <td>
                                                                                      {user.user['firstName']}
                                                                                      <br />
                                                                                      <span style={{ fontSize: '11px' }}>
                                                                                          Mobile: {user?.mobile?.countryCode}-{user?.mobile?.mobileNumber}
                                                                                      </span>
                                                                                  </td>

                                                                                  <td>{user.user.email}</td>
                                                                                  <td> {moment(user.user['dateCreated']).format('DD MMM YYYY')}</td>
                                                                                  <td>{moment(user.user['lastUpdated']).format('DD MMM YYYY')}</td>
                                                                                  <td>
                                                                                      <div className="d-flex gap-3">
                                                                                          &nbsp; &nbsp;
                                                                                          <Link className="text-success" to="#">
                                                                                              <i className="mdi mdi-eye font-size-18" id="edittooltip" onClick={() => this.handleUserClick(user)}></i>
                                                                                          </Link>
                                                                                      </div>
                                                                                  </td>
                                                                              </tr>
                                                                          ))}
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br />
                                            {this.state.searchFlag === false && (
                                                <ReactPaginate
                                                    previousLabel={'Previous'}
                                                    nextLabel={'Next'}
                                                    breakLabel={'...'}
                                                    pageCount={Math.ceil(this.state.totalPages)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={ee => {
                                                        this.setState({ page: ee.selected });
                                                        this.getUsers(ee.selected, this.state.id, this.state.seq);
                                                    }}
                                                    containerClassName={'pagination justify-content-end'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link'}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link'}
                                                    activeClassName={'active'}
                                                />
                                            )}
                                        </React.Fragment>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Modal isOpen={this.state.modal} className={this.props.className}>
                            <ModalHeader toggle={this.toggle} tag="h4">
                                {'User Details'}
                            </ModalHeader>
                            <ModalBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        firstName: (user && user.firstName) || '',
                                        lastName: (user && user.lastName) || '',
                                        email: (user && user.email) || '',
                                        mobile: (user && user?.mobile?.countryCode + '-' + user?.mobile?.mobileNumber) || '',
                                        createdBy: (user && user.createdBy) || ''
                                    }}
                                    validationSchema={Yup.object().shape({
                                        firstName: Yup.string().required('Please Enter'),
                                        lastName: Yup.string().required('Please Enter Your Phone No'),
                                        email: Yup.string().required('Please Enter Your Email'),
                                        createdBy: Yup.string().required('Please Select Register Source')
                                    })}
                                >
                                    {({ errors, status, touched }) => (
                                        <Form>
                                            <Row>
                                                <Col className="col-12">
                                                    <div className="mb-3">
                                                        <Label className="form-label">First Name</Label>
                                                        <Field disabled={true} name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Last Name</Label>
                                                        <Field name="lastName" disabled={true} type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Mobile No.</Label>
                                                        <Field disabled={true} name="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Email</Label>
                                                        <Field disabled={true} name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Register Source</Label>
                                                        <Field disabled={true} name="createdBy" type="text" className={'form-control' + (errors.createdBy && touched.createdBy ? ' is-invalid' : '')} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col></Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </ModalBody>
                        </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

AllUsers.propTypes = {
    className: PropTypes.any
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllUsers));
