import axios from 'axios';
import { resolve } from '../resolve.js';
import { API_URL } from '../../../constants/apiurl';

const getAuthDetails = () => {
    if (localStorage.getItem('authUser')) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        return obj;
    }
};
const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export async function postExternalLinks(postdata, id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/exhibition/' + id + '/link', postdata, axiosconf).then(res => res.data));
}

export async function getExternalLinks(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/exhibition/' + id + '/link', axiosconf).then(res => res.data));
}

export async function postExhibitionContentOrder(id, postdata) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(`${API_URL}/tt/exhibition/${id}/content/mapping/order`, postdata, axiosconf).then(res => res.data));
}

export async function postContentExternalLinks(postdata, id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/content/' + id + '/link', postdata, axiosconf).then(res => res.data));
}

export async function getContentExternalLinks(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/content/' + id + '/link', axiosconf).then(res => res.data));
}

export async function postCreatorExternalLinks(postdata, id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/creator/' + id + '/link', postdata, axiosconf).then(res => res.data));
}

export async function getCreatorExternalLinks(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/creator/' + id + '/link', axiosconf).then(res => res.data));
}


export async function postSponsor(postdata, id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.post(API_URL + '/tt/exhibition/' + id + '/sponsor/', postdata, axiosconf).then(res => res));
}

export async function deleteSponsor(sponsorId, id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.delete(API_URL + '/tt/exhibition/' + id + '/sponsor/' + sponsorId, axiosconf).then(res => res));
}

export async function getSponsors(id) {
    let obj = getAuthDetails();
    let axiosconf = axiosHeaderConfig(obj.access_token);
    return await resolve(axios.get(API_URL + '/tt/exhibitions/' + id + '/sponsor/v1', axiosconf).then(res => res.data));
}
